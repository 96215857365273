import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { IConClose, SharedButton } from "@components";
import { resetConfirm, selectAuth, selectMerchant } from "@redux";
import { IBenefit, ICoupon, IFreePoint, IReward } from "@interfaces";
import { enumTypes, exchangedType } from "@configs";

interface IProps {
    handleCloseDetail: () => void;
    data: IBenefit | IReward | ICoupon | IFreePoint;
    handleConfirm?: () => void;
    handleCalculatePoint?: () => void;
    isConfirm?: boolean;
    isHistory?: boolean;
    isMerchantFreePoint?: boolean;
    quantity?: number;
}

export const ComponentPopUpConfirm = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //redux states
    const { themeColor } = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    //page redux
    const {
        handleCloseDetail,
        data,
        handleConfirm,
        handleCalculatePoint,
        isConfirm = true,
        quantity,
    } = props;
    //page variable

    const handleConfirmPopup = () => {
        if (handleConfirm) {
            dispatch(resetConfirm());
            handleConfirm();
        }
    };


    // console.log(data);
    const setNamePopup = () => {
        const isPoint = '';
        // handleCalculatePoint && handleCalculatePoint()
        //     ? ", " + handleCalculatePoint() + " points"
        //     : "";

        switch (data.type) {
            case enumTypes.REWARD: {
                const name = " (" + data.name + " x " + quantity + isPoint + ")";
                return `${t("page.redemption_reward")}  ${name}`;
            }
            case enumTypes.BENEFIT: {
                const name = " (" + data.name + " x " + 1 + ")";
                return `${t("page.redemption")}  ${name}`;
            }
            case enumTypes.COUPON: {
                //@ts-ignore
                const unit =
                    ", " +
                    //@ts-ignore
                    data.benefit +
                    //@ts-ignore
                    (data.unit === "BAHT" ? t("page.BAHT") : t("page.percent_sign"));
                const name = " (" + data.name + " x " + 1 + unit + ")";
                return `${t("page.redemption")}  ${name}`;
            }
            case enumTypes.FREE_POINT: {
                const name =
                    " (" +
                    data.name +
                    " x " +
                    1 +
                    ", " +
                    data.point +
                    " " +
                    t("page._points") +
                    ")";
                return `${t("page.collection")} ${name}`;
            }
        }
    };

    return (
        <StyledContainerHistoryDetail isConfirm={isConfirm}>
            <div className="content">
                <div className="close" onClick={handleCloseDetail}>
                    <IConClose />
                </div>
                {isConfirm && <div className="confirmation">{t("page.confirmation")}</div>}
                <div className="title">{t("page.name")}</div>
                <div className="desc">{userInfo?.fullName}</div>
                <div className="title">{t("page.item")}</div>
                <div className="desc">{setNamePopup()}</div>

                {isConfirm && (
                    <>
                        <StyledButton
                            size="large"
                            bg_color={themeColor}
                            type="submit"
                            onClick={handleConfirmPopup}
                        >
                            {t("page.confirm")}
                        </StyledButton>
                    </>
                )}
            </div>
        </StyledContainerHistoryDetail>
    );
};

export const ComponentPopUpDone = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    //redux states
    const { themeColor } = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    //page redux
    const {
        handleCloseDetail,
        data,
        isHistory,
        handleCalculatePoint,
        isConfirm = true,
        isMerchantFreePoint,
    } = props;

    //page variable
    const dateFormat = () => {
        if (isMerchantFreePoint) {
            //@ts-ignore
            return moment(data?.updatedAt).format("DD/MM/YYYY h:mm:ss a");
        } else {
            if (isHistory) {
                const date = moment(new Date(data.updatedAtHistory)).format("DD/MM/YYYY h:mm:ss a");
                return date;
            } else return moment(new Date(Date.now())).format("DD/MM/YYYY h:mm:ss a");
        }
    };

    // console.log(data, isHistory);

    const setNamePopup = () => {
        if (isHistory) {
            if (isMerchantFreePoint) {
                return data?.exchangedType === exchangedType.RECEIVED
                    ? t("page.add_points_from_shop", { points: data.quantity })
                    : t("page.sub_points_from_shop", { points: data.quantity });
            } else {
                switch (data.type) {
                    case enumTypes.REWARD: {
                        if (data.exchangedType && data.exchangedType === exchangedType.RECEIVED) {
                            return t("page.used") + " " + data.point + " " + t("page._points");
                        } else {
                            const isPoint = '';
                            // handleCalculatePoint && handleCalculatePoint()
                            //     ? ", " + handleCalculatePoint() + " " + t("page._items")
                            //     : "";
                            //@ts-ignore
                            const count = data.count || data.quantityPoint;
                            const name = " (" + data.name + " x " + count + isPoint + ")";
                            return t("page.redemption") + name;
                        }
                    }
                    case enumTypes.BENEFIT: {
                        const name = " (" + data.name + " x " + 1 + ")";
                        return t("page.redemption") + name;
                    }
                    case enumTypes.COUPON: {
                        if (data.exchangedType === exchangedType.RECEIVED) {
                            const name = " (" + data.name + " x " + 1 + ")";
                            return t("page.collection") + name;
                        } else {
                            const name = " (" + data.name + " x " + 1 + ")";
                            return t("page.redemption") + name;
                        }
                    }
                    case enumTypes.FREE_POINT: {
                        return (
                            t("page.collection") +
                            " " +
                            "( " +
                            t("page.free_point") +
                            " + " +
                            data?.point +
                            " " +
                            t("page._points") +
                            ")"
                        );
                    }
                }
            }
        } else {
            switch (data.type) {
                case enumTypes.REWARD: {
                    if (data.exchangedType && data.exchangedType === exchangedType.RECEIVED) {
                        return t("page.used") + " " + data.point + " " + t("page._points");
                    } else {
                        const isPoint = '';
                        // handleCalculatePoint && handleCalculatePoint()
                        //     ? ", " + handleCalculatePoint() + " " + t("page._points")
                        //     : "";
                        //@ts-ignore
                        const count = data.count || data.quantityPoint;
                        const name = " (" + data.name + " x " + count + isPoint + ")";
                        return t("page.redemption") + name;
                    }
                }
                case enumTypes.BENEFIT: {
                    const name = " (" + data.name + " x " + 1 + ")";
                    return t("page.redemption") + name;
                }
                case enumTypes.COUPON: {
                    const name = " (" + data.name + " x " + 1 + ")";
                    return t("page.redemption") + name;
                }
                case enumTypes.FREE_POINT: {
                    return "x";
                }
            }
        }
    };

    return (
        <StyledContainerHistoryDetail isConfirm={isConfirm}>
            <div className="content">
                <div className="close" onClick={handleCloseDetail}>
                    <IConClose />
                </div>
                {isConfirm && <div className="confirmation">{t("page.confirmation")}</div>}
                <div className="title">{t("page.name")}</div>
                <div className="desc">{userInfo?.fullName}</div>
                <div className="title">{t("page.item")}</div>
                <div className="desc">{setNamePopup()}</div>
                {data?.code && (
                    <>
                        <div className="title">{t("page.ticket")}</div>{" "}
                        <div className="desc">{data?._id}</div>
                    </>
                )}
                <div className="title">{t("page.date_time")}:</div>
                <div className="desc">{dateFormat()}</div>
                {isConfirm && (
                    <>
                        <div className="title desConfirm">{t("page.desConfirm")}</div>

                        <StyledButton
                            size="large"
                            bg_color={themeColor}
                            type="submit"
                            onClick={handleCloseDetail}
                        >
                            {isHistory ? t("page.confirm") : t("page.done")}
                        </StyledButton>
                    </>
                )}
            </div>
        </StyledContainerHistoryDetail>
    );
};

const StyledContainerHistoryDetail = styled.div<{ isConfirm: boolean }>`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    max-width: 480px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    .content {
        width: calc(100% - 24px);
        padding: 28px 28px 12px 28px;
        padding-bottom: 28px;
        background-color: #fff;
        position: relative;
        border-radius: 8px;
        max-height: 90%;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMax}) {
            overflow: scroll;
        }
        .close {
            position: absolute;
            top: 20px;
            right: 28px;
            cursor: pointer;
        }
    }
    .confirmation {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(115, 115, 115, 0.31);
    }
    .title {
        margin: 24px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #989898;
    }
    .desc {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
    }
    .desConfirm {
        width: 100%;
        margin-top: 24px;
        text-align: center;
        font-weight: normal;
    }
`;

const StyledButton = styled(SharedButton)`
    width: 100%;
    margin: 26px auto 0 auto;
    padding: 0;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
`;
