import { combineReducers } from "redux";
import { AnyAction, Reducer } from "@reduxjs/toolkit";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import appReducer from "./auth";
import appReducer from "./app";
import merchantReducer from "./merchant";
import pageReducer from "./page";
import authReducer from "./auth";
import toastReducer from "./toast";
import configClientReducer from "./configClient";
import notSaveLocalReducer from "./notSaveLocal";
import cardUseReducer from "./cardUse";
import cartReducer from "./cart";
// const persistConfig = {
//     key: "root",
//     storage,
// };

// const authPersistedReducer = persistReducer(persistConfig, appReducer);
export * from "./app";
export * from "./merchant";
export * from "./auth";
export * from "./toast";
export * from "./configClient";
export * from "./page";
export * from "./notSaveLocal";
export * from "./cardUse";
export * from "./cart";

const productReducer = combineReducers({
  app: appReducer,
  merchant: merchantReducer,
  cart: cartReducer,
  auth: authReducer,
  toast: toastReducer,
  configClient: configClientReducer,
  page: pageReducer,
  notSaveLocal: notSaveLocalReducer,
  cardUse: cardUseReducer,
});

export type RootState = ReturnType<typeof productReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "RESET") {
    // reset state
    state = {} as RootState;
    // reset local storage
    localStorage.clear();
  }
  return productReducer(state, action);
};
export default rootReducer;
