export const PointIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.61276 5.24127C4.31116 5.55818 4.25 5.79097 4.25 5.93652C4.25 6.08208 4.31116 6.31486 4.61276 6.63177C4.92048 6.95513 5.42485 7.30319 6.14371 7.62269C7.57829 8.26028 9.64775 8.68652 12 8.68652C14.3523 8.68652 16.4217 8.26028 17.8563 7.62269C18.5752 7.30319 19.0795 6.95513 19.3872 6.63177C19.6888 6.31486 19.75 6.08208 19.75 5.93652C19.75 5.79097 19.6888 5.55818 19.3872 5.24127C19.0795 4.91792 18.5752 4.56986 17.8563 4.25036C16.4217 3.61277 14.3523 3.18652 12 3.18652C9.64775 3.18652 7.57829 3.61277 6.14371 4.25036C5.42485 4.56986 4.92048 4.91792 4.61276 5.24127ZM5.12837 1.96583C6.95115 1.15571 9.38169 0.686523 12 0.686523C14.6183 0.686523 17.0489 1.15571 18.8716 1.96583C19.7814 2.37019 20.5951 2.88406 21.1982 3.51781C21.8075 4.15801 22.25 4.97751 22.25 5.93652C22.25 6.89554 21.8075 7.71504 21.1982 8.35524C20.5951 8.98899 19.7814 9.50285 18.8716 9.90722C17.0489 10.7173 14.6183 11.1865 12 11.1865C9.38169 11.1865 6.95115 10.7173 5.12837 9.90722C4.21855 9.50285 3.4049 8.98899 2.80177 8.35524C2.19252 7.71504 1.75 6.89554 1.75 5.93652C1.75 4.97751 2.19252 4.15801 2.80177 3.51781C3.4049 2.88406 4.21855 2.37019 5.12837 1.96583Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.3086C3.69036 10.3086 4.25 10.8682 4.25 11.5586V12.3476C4.25 12.6451 4.57214 13.3226 6.05765 13.9979C7.43046 14.6219 9.41489 15.0406 11.674 15.0406C13.9331 15.0406 15.9175 14.6219 17.2903 13.9979C18.7759 13.3226 19.098 12.6451 19.098 12.3476V11.5586C19.098 10.8682 19.6576 10.3086 20.348 10.3086C21.0384 10.3086 21.598 10.8682 21.598 11.5586V12.3476C21.598 14.2281 19.9786 15.5221 18.3249 16.2738C16.5585 17.0768 14.2059 17.5406 11.674 17.5406C9.14211 17.5406 6.78954 17.0768 5.0231 16.2738C3.36936 15.5221 1.75 14.2281 1.75 12.3476V11.5586C1.75 10.8682 2.30964 10.3086 3 10.3086Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 16.0815C3.69036 16.0815 4.25 16.6412 4.25 17.3315V18.1205C4.25 18.4181 4.57214 19.0956 6.05765 19.7708C7.43046 20.3949 9.41489 20.8135 11.674 20.8135C13.9331 20.8135 15.9175 20.3949 17.2903 19.7708C18.7759 19.0956 19.098 18.4181 19.098 18.1205V17.3315C19.098 16.6412 19.6576 16.0815 20.348 16.0815C21.0384 16.0815 21.598 16.6412 21.598 17.3315V18.1205C21.598 20.001 19.9786 21.295 18.3249 22.0467C16.5585 22.8497 14.2059 23.3135 11.674 23.3135C9.14211 23.3135 6.78954 22.8497 5.0231 22.0467C3.36936 21.295 1.75 20.001 1.75 18.1205V17.3315C1.75 16.6412 2.30964 16.0815 3 16.0815Z"
      fill="black"
    />
  </svg>
);
