interface IProps {
  size?: number;
}
export const MinusIcon = (props: IProps) => {
  const { size = 32 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.2857 16H21.7143" stroke="black" strokeLinecap="round" />
      <circle cx="16" cy="16" r="15.5" stroke="black" />
    </svg>
  );
};
