import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { CSSProperties } from "styled-components";
import { FormikErrors, FormikTouched } from "formik";

import { StyledSignUp, SharedInput, SharedButton, IConPassword } from ".";
import { selectMerchant } from "@redux";
import { IHandleBlur, IHandleChange, IResetPassword } from "@interfaces";

interface IProps {
    values: IResetPassword;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<IResetPassword>;
    touched: FormikTouched<IResetPassword>;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
}

const styledPassword: CSSProperties = {
    position: "relative",
    left: "3px",
};

export const ComponentPassword = (props: IProps) => {
    //page props
    const { values, handleBlur, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    //page hook
    const { t } = useTranslation();
    //page variable
    const merchant = useSelector(selectMerchant);

    return (
        <StyledSignUp>
            <form onSubmit={handleSubmit}>
                <StyledContainer bg_color={merchant.themeColor}>
                    <div className="title">{t("page.reset_your_password")}</div>
                    <div className=" password">
                        <SharedInput
                            prefixIcon={<IConPassword style={styledPassword} />}
                            placeholder={t("page.new_password")}
                            type="password"
                            name="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            errors={errors.password}
                            touched={touched.password}
                        />
                        <SharedInput
                            prefixIcon={<IConPassword style={styledPassword} />}
                            placeholder={t("page.confirm_new_password")}
                            type="password"
                            name="conformPassword"
                            value={values.conformPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            errors={errors.conformPassword}
                            touched={touched.conformPassword}
                        />
                    </div>
                    <StyledButton
                        size="large"
                        bg_color={merchant.themeColor}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {t("page.submit")}
                    </StyledButton>
                </StyledContainer>
            </form>
        </StyledSignUp>
    );
};

const StyledContainer = styled.div<{ bg_color: string }>`
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 46px;
    }

    .password {
        margin: 6rem 0 3rem;
    }
`;

const StyledButton = styled(SharedButton)`
    margin-top: 24px;
`;
