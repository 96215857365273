import { IPagination } from "@interfaces";
import { getYears } from "@utils";

export const PAGINATION = 10;
export const PAGE_START = 1;
export const dateFormat = "DD/MM/YYYY";
export const dateFormatYear = "YYYY-MM-DD";
export const dateFormatHM = "DD/MM/YYYY HH:mm";
export const dateFormatMonth = "DD MMM YYYY";
export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || "th";
export const DAYS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

export const MONTHS = ["01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12];

export const YEARS = getYears();

export const BLANK_IMAGE_URL = "/images/initImage.png";
export const ALL_SELECT = "all";

export const TIME_RESEND = 60;

export const INITIAL_LIST_PARAMS: IPagination = {
  page: 1,
  limit: PAGINATION,
};

export const _10MB = 1048576 * 10;

//line
export const LINE_URL = "https://api.line.me/v2";
export const LINE_AUTH_URL = "https://api.line.me/oauth2/v2.1";
export const LINE_ACCESS_URL = "https://access.line.me/oauth2/v2.1";
export const LINE_SCOPE = "profile%20openid%20email";
export const LINE_REDIRECT_URI = process.env.REACT_APP_WEB_URL + "/line-login";
// export const LINE_REDIRECT_URI = "https://localhost:3000/line-login";
//faceBook
export const FACEBOOK_GRAPH_URL = "https://graph.facebook.com/v12.0/oauth";
export const FACEBOOK_INFO_URL =
  "https://graph.facebook.com/me?fields=name%2Cemail%2Cpicture&locale=en_US&method=get&pretty=0&sdk=joey&suppress_http_code=1";
//google
export const GOOGLE_USER_INFO = "https://www.googleapis.com/oauth2/v1/userinfo";

export const DEFAULT_EX_MONTH = 0;

export const DEFAULT_VALUES_TEL_TH = "+66";

export const MAX_LENGTH_NAME = 20;
