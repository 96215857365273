import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";

import { arrow } from "@components";
import { IInfo } from "@interfaces";
import { enumNavigation } from "@configs";
import { setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

export const ComponentEachStaff = (props: IProps) => {
  const history = useHistory();

  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };

  const organization_id = store.getState().auth.userInfo?.merchant?._id;
  let [eachStaff, setEachStaff] = useState({
    name: "",
    store_service_list: [
      {
        store_id: "",
        store_code: "",
        _id: "",
        service_list: [
          {
            service_id: "",
            service_code: "",
            varient_of_service: [],
            variant_of_service: [""],
            _id: "",
          },
        ],
      },
    ],
  });
  let [eachService, setEachService] = useState([]);
  let [ShowAll, setShowAll] = useState(true);
  let [ServiceCategory, setServiceCategory] = useState([]);
  let [Refresh, setRefresh] = useState(false);
  let [catName, setCatName] = useState("");

  useEffect(() => {
    let lastURL = window.location.href.split("/").pop();
    // console.log(lastURL);
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/staffSelectionByQuery?organization_id=${organization_id}&staff_code=${lastURL}`
      )
      .then((data) => {
        let newItemX = data.data.result.itemx[0];
        setEachStaff(newItemX);
        let itemLoop = data.data.result.itemy;
        for (let i = 0; i < itemLoop.length; i++) {
          let newService = data.data.result.itemy[i];
          newService = eachService;
          newService.push({
            cover: data.data.result.itemy[i].image_url_list[0].image_url,
            name: data.data.result.itemy[i].service_name,
            description: data.data.result.itemy[i].description,
            price: data.data.result.itemy[i].standard_price,
            id: data.data.result.itemy[i]._id,
            category: data.data.result.itemy[i].category,
          });
          setEachService(newService);
          // console.log(eachService);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong, try again!"));
    // Hair Category
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/chooseServiceCategory?organization_id=${organization_id}${catName}`
      )
      .then((data) => {
        let ItemLoop = data.data.result.length;
        if (Refresh !== true) {
          for (let i = 0; i < ItemLoop; i++) {
            let newCategory = data.data.result;
            newCategory = ServiceCategory;
            newCategory.push({
              cover: data.data.result[i].image_url_list[0].image_url,
              name: data.data.result[i].service_name,
              description: data.data.result[i].description,
              price: data.data.result[i].standard_price,
              id: data.data.result[i]._id,
              category: data.data.result[i].category,
              service_code: data.data.result[i].service_code,
            });
            setServiceCategory(newCategory);
          }
        }
        setRefresh(true);
        // console.log(
        //     `https://api-store.rocket-tech.app/1.0.0/chooseServiceCategory?organization_id=${organization_id}${catName}`
        // );
        console.log(ServiceCategory);
      })
      // Catch error because all services has no Image!
      .catch((err) => console.log(err, "\nSomething wrong on Choose Service, try again!"));
  }, [Refresh]);

  return (
    <>
      {/* {eachStaff((item, i) => ( */}
      <div className="SalonName preview fixBorderRadius">
        <div className="topSalonName">
          <h1>
            Stylist: {eachStaff ? eachStaff.name : ""}
            <Styledarrow />
          </h1>
          <div className="topStoreInfo"></div>
        </div>
        <hr />
        <div className="botSalonName">
          <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
            <li className="nav-item item">
              <a
                className="nav-link all active"
                id="all-tab"
                data-toggle="tab"
                href="#all"
                role="tab"
                aria-controls="all"
                aria-selected="true"
                onClick={() => {
                  // setShowAll(true);
                  // setHairTab(false);
                  // setHairsTab(false);
                  setServiceCategory([]);
                  setCatName("");
                  setRefresh(!Refresh);
                }}
              >
                All
              </a>
            </li>
            <li className="nav-item item">
              <a
                className="nav-link"
                id="hair-tab"
                data-toggle="tab"
                href="#hair"
                role="tab"
                aria-controls="hair"
                aria-selected="false"
                onClick={() => {
                  setServiceCategory([]);
                  setCatName("&category=hair");
                  // console.log(catName);
                  setRefresh(!Refresh);
                }}
              >
                บริการทำผม
              </a>
            </li>
            <li className="nav-item item">
              <a
                className="nav-link"
                id="hairs-tab"
                data-toggle="tab"
                href="#hairs"
                role="tab"
                aria-controls="hairs"
                aria-selected="false"
                onClick={() => {
                  setServiceCategory([]);
                  setCatName("&category=hairs");
                  // console.log(catName);
                  setRefresh(!Refresh);
                }}
              >
                บริการทำเล็บ
              </a>
            </li>
            <li className="nav-item item lastItem">
              <a
                className="nav-link"
                id="messages-tab"
                data-toggle="tab"
                href="#messages"
                role="tab"
                aria-controls="messages"
                aria-selected="false"
                onClick={() => {
                  setServiceCategory([]);
                  setCatName("&category=face");
                  // console.log(catName);
                  setRefresh(!Refresh);
                }}
              >
                ขนตาและคิ้ว
              </a>
            </li>
          </ul>
        </div>
      </div>
      {ShowAll && (
        <div
          className="tab-pane staffSection active"
          id="all"
          role="tabpanel"
          aria-labelledby="all-tab"
        >
          {ServiceCategory.length > 0 ? (
            ServiceCategory.map((item, i) => (
              <div className="allServices" key={item["id"]}>
                <div className="coverImage">
                  <img src={item["cover"] || "/images/default_service.png"} />
                </div>
                <div className="serviceInfo">
                  <h1>{item["name"]}</h1>
                  <p>{item["description"]}</p>
                  <div className="priceAndArrow">
                    <h2>
                      {item["price"]} <span style={{ fontWeight: "normal" }}>฿</span>
                    </h2>
                    <Link to={`./${window.location.href.split("/").pop()}/${item["service_code"]}`}>
                      <button className="staffButton">จองเลย</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p style={{ marginTop: "10px" }}>No Services to show!</p>
          )}
        </div>
      )}
    </>
  );
};

const Styledarrow = styled(arrow)`
  cursor: pointer;
`;
