import { IBasketItems, IGetProductOrderOnTableByQrOpenTableExample } from "@interfaces";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SummaryOrderIcon } from "../icon";
import { theme } from "@utils";
import { selectMerchant } from "@redux";

interface IProps {
  setBasketDrawer?: any;
  getPaymentDetails: () => void;
  basketDrawer: boolean;
}

export const SummaryOrderBtn = (props: IProps) => {
  const { t } = useTranslation();
  const { setBasketDrawer, getPaymentDetails, basketDrawer } = props;
  const cart = useSelector((state: any) => state.cart.cart);
  const { themeColor } = useSelector(selectMerchant);

  return (
    <SummaryBtnStyle themeColor={themeColor}>
      <button
        className="summaryBtnRow"
        style={{ zIndex: basketDrawer ? 1 : 1000 }}
        onClick={() => {
          setBasketDrawer(true);
          getPaymentDetails();
        }}
      >
        <div className="left">
          <span className="orderLengthBadge">{cart.length || 0}</span>
          <SummaryOrderIcon />
          <span>{t("page.summary_order")}</span>
        </div>
        <div className="right">
          <p>
            ฿
            {cart.length === 0
              ? 0
              : cart.reduce(
                  (total: number, cartItem: IBasketItems) => total + (cartItem.price_unit || 0),
                  0
                )}
          </p>
        </div>
      </button>
    </SummaryBtnStyle>
  );
};

const SummaryBtnStyle = styled.div<{ themeColor: string }>`
  .summaryBtnRow {
    width: 95%;
    max-width: 470px;
    height: 55px;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: ${(color) => color.themeColor};
    box-shadow: 0px 3px 10px rgba(143, 143, 143, 0.36);
    border-radius: 74px;
    padding: 14px 42px;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      span {
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
      }
      .orderLengthBadge {
        position: absolute;
        left: 20px;
        top: 0;
        background: #fff;
        padding: 0.5px 5px;
        border-radius: 50%;
        font-size: 10px;
        color: ${(color) => color.themeColor};
      }
    }
    .right {
      display: flex;
      p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
`;
