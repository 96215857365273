import styled from "styled-components";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { IBenefit } from "@interfaces";
import { ModulesBenefitItem } from "@modules";
import { IconLoadMore } from "@components";

// import { CouponItem } from "./Item";

interface IProps {
    data: IBenefit[];
    handleClick: (id: string) => void;
    loadingPagination: boolean;
    handleLoadMore: () => void;
}

export const ComponentBenefitList = (props: IProps) => {
    //props
    const { data, handleClick, loadingPagination, handleLoadMore } = props;
    return (
        <>
            <StyledContainerBenefitList>
                <InfiniteScroll
                    dataLength={data.length}
                    next={handleLoadMore}
                    hasMore={true}
                    loader={
                        loadingPagination && (
                            <div className="example">
                                <Spin
                                    size="large"
                                    spinning={loadingPagination}
                                    indicator={<IconLoadMore />}
                                />
                            </div>
                        )
                    }
                >
                    {data.map((item) => {
                        return (
                            <ModulesBenefitItem
                                handleClick={handleClick}
                                data={item}
                                key={item._id}
                            />
                        );
                    })}
                </InfiniteScroll>
            </StyledContainerBenefitList>
        </>
    );
};

const StyledContainerBenefitList = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
    .example {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
`;
