import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import topHeader from "./images/topHeader.svg";

import { selectAuth, selectMerchant, setDetailUser, resetDetailUser } from "@redux";
import { PageUserDetail } from "@pages";

interface IProp {
    children?: JSX.Element;
    handleInitalQrCode?: () => void;
}

export const ComponentHeaderAuth = (props: IProp) => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //page variable
    const merchant = useSelector(selectMerchant);
    const { detailUser } = useSelector(selectAuth);
    const { children, handleInitalQrCode } = props;

    const handleDetail = () => {
        dispatch(setDetailUser());
    };

    const handleBackHome = () => {
        dispatch(resetDetailUser());
        handleInitalQrCode && handleInitalQrCode();
    };

    return (
        <>
            <StyledContainerHeaderAuth
                bg_color={merchant.themeColor}
                sub_color={merchant.subColor || ""}
            >
                {/* <div className="image">
                    <img src={merchant.logoUrl} />
                </div> */}
                <div className="topHeader"></div>
                <div className="content">
                    {/* <div className="name">{merchant.name}</div> */}
                    <StyleHomeButton onClick={handleBackHome}>
                        {t("page.goback_home")}
                    </StyleHomeButton>
                    <StyledButton>
                        Lavish Salon
                    </StyledButton>
                </div>
                <div className="background--sub"></div>
            </StyledContainerHeaderAuth>
            {!detailUser ? children : <PageUserDetail />}
        </>
    );
};

const StyledContainerHeaderAuth = styled.div<{ bg_color: string; sub_color: string }>`
    width: 100%;
    height: 190px;
    background: url(${topHeader});
    position: relative;
    overflow: hidden;
    position: relative;
    .image {
        border-top-left-radius: 100%;
        border-bottom-left-radius: 30%;
        width: 45%;
        height: calc(100% + 20px);
        border-top: 10px solid ${(p) => p.sub_color};
        border-left: 10px solid ${(p) => p.sub_color};
        position: absolute;
        right: 0;
        top: -20px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content {
        position: absolute;
        left: 12px;
        top: 16px;
        display: flex;
        align-items: center;
        .name {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
            margin: 0 0 12px 0px;
            max-width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
        }
    }
    .background--sub {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 88px;
        width: 100%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 19%,
            rgba(248, 248, 248, 0.502) 65.33%,
            #f8f8f8 100%
        );
    }
`;

const StyledButton = styled.div`
    padding: 4px;
    // background: rgba(0, 0, 0, 0.22);
    // border-radius: 16px;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    // color: rgba(255, 255, 255, 0.8);
`;

const StyleHomeButton = styled.div`
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    margin: 0;
    width: 36px;
    height: 36px;
    padding: 3px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
`;
