interface IProps {
  width?: string;
  height?: string;
}
export const TotalQueueIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "14"}
      height={props.height || "16"}
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M11.8912 5.77791L12.2068 4.77343C12.5426 4.69717 12.8314 4.45362 12.9419 4.10199L13.0168 3.86351C13.0583 3.73168 12.9851 3.59148 12.8533 3.55005L4.74425 1.00186C4.61241 0.960433 4.47221 1.03359 4.43079 1.16542L4.35584 1.40393C4.24535 1.75553 4.34297 2.12052 4.57474 2.37515L4.2591 3.37961C3.96209 4.32477 4.08076 5.35601 4.58459 6.20888L5.33309 7.47617L3.99424 8.08753C3.09314 8.49891 2.40593 9.27691 2.10892 10.2221L1.79328 11.2265C1.45752 11.3028 1.16867 11.5464 1.05818 11.898L0.983229 12.1365C0.94181 12.2683 1.01496 12.4085 1.1468 12.4499L9.25586 14.9981C9.38769 15.0396 9.52789 14.9664 9.56931 14.8346L9.64426 14.5961C9.75475 14.2445 9.65713 13.8795 9.42536 13.6248L9.741 12.6204C10.038 11.6752 9.91934 10.644 9.41551 9.79111L8.66699 8.52381L10.0058 7.91245C10.9069 7.50107 11.5942 6.72305 11.8912 5.77791ZM9.59047 7.00292L7.74635 7.84502C7.61734 7.90378 7.51956 8.01456 7.47704 8.14988C7.43451 8.2852 7.45134 8.43202 7.52357 8.554L8.55453 10.2996C8.91429 10.9088 8.99907 11.6456 8.78697 12.3206L8.51301 13.1924L7.91664 13.005L6.7111 9.58644C6.65896 9.438 6.45725 9.37462 6.32958 9.46655L3.38535 11.5811L2.78898 11.3937L3.06293 10.5219C3.27504 9.84687 3.76602 9.29101 4.40964 8.99704L6.25375 8.15494C6.38277 8.09619 6.48055 7.9854 6.52307 7.85008C6.5656 7.71476 6.54877 7.56794 6.47654 7.44597L5.44558 5.7004C5.08579 5.09115 5.00101 4.35436 5.21311 3.6794L5.48707 2.8076L11.2111 4.60633L10.9371 5.47812C10.725 6.15309 10.2341 6.70895 9.59047 7.00292Z"
        fill="white"
      />
      <path
        d="M9.39343 6.13154L6.10542 5.09831C6.01108 5.06867 5.90811 5.09748 5.84284 5.17166C5.77749 5.24608 5.76193 5.35174 5.80356 5.4416L6.98278 7.49554C7.01523 7.55104 7.0661 7.58953 7.12339 7.60753C7.18067 7.62553 7.24442 7.62304 7.30279 7.5961L9.44465 6.58577C9.5302 6.53588 9.57789 6.44032 9.56684 6.34189C9.55575 6.24372 9.48776 6.16119 9.39343 6.13154Z"
        fill="white"
      />
    </svg>
  );
};
