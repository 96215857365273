import { YubEmailSignIn, YubEmailSignUp, YubEmailSignUpForm } from "./Email";
import { YubEmailTelUpForm, YubPhoneSignUp } from "./Phone";
import { YubResetPassword } from "./ResetPassword";
import { YubSocialSignUp } from "./SocialMedia";
import { YubSUserSetting } from "./UserSetting";

export const useYup = () => {
    return {
        YubEmailSignUp: YubEmailSignUp(),
        YubEmailSignUpForm: YubEmailSignUpForm(),
        YubPhoneSignUp: YubPhoneSignUp(),
        YubSocialSignUp: YubSocialSignUp(),
        YubResetPassword: YubResetPassword(),
        YubEmailSignIn: YubEmailSignIn(),
        YubEmailTelUpForm: YubEmailTelUpForm(),
        YubSUserSetting: YubSUserSetting(),
    };
};
