import styled from "styled-components";
import {
  IconFooterBooking,
  IconFooterCard,
  IconFooterHome,
  IconFooterLoyalty,
  IconFooterShop,
  IconFooterUser,
} from "../icon";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { selectAuth, selectMerchant } from "@redux";
import { useSelector } from "react-redux";
import { PATH_HOME, enumLanguage } from "@configs";
import { FilterMenuBar } from "../icon/FilterMenuBar";

interface IProps {
  pathname: string;
}

export const FooterComponent = (props: IProps) => {
  const { pathname } = props;
  const { auth, userInfo } = useSelector(selectAuth);
  const { themeColor } = useSelector(selectMerchant);
  const merchant = useSelector(selectMerchant);
  const i18nextLng = window.localStorage.i18nextLng;
  const history = useHistory();
  const handleClickBottomMenu = (path: string) => {
    if (!path.includes("pos-client")) {
      if (path.includes("service-client")) {
        window.open(`${path}auth/${auth?.accessToken}`, "_self");
        console.log(path);
      } else {
        window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/${path}`, "_self");
      }
    } else {
      window.open(path, "_self");
    }
  };

  return (
    <>
      {userInfo?.contact?.sellerId === "" || userInfo?.contact?.sellerId === undefined ? (
        <>
          {merchant?.bottomBarMenu?.menuType?.isType1 ? (
            <StyledMenuBar themeColor={themeColor}>
              <div className="sub-bg"></div>
              <div className="main-menubar">
                {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                  <>
                    <div className="control-menubar">
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>{merchant?.bottomBarMenu?.homeMenu?.bottomTitle || "Home"}</span>
                      </div>
                      {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                        if (!item?.isUse) {
                          return null;
                        }
                        return (
                          <div
                            className="box-menubar"
                            key={index}
                            onClick={() => handleClickBottomMenu(item.bottomLink)}
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className="img-menubar">
                                <img src={item.iconBottomName} alt="" />
                              </div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              </StyleImgVector>
                            ) : null}
                            <span>{item.bottomTitle || "Menu"}</span>
                          </div>
                        );
                      })}
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(
                            `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                            "_self"
                          )
                        }
                      >
                        <div className="img-menubar">
                          <img src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName} alt="" />
                        </div>
                        <span>
                          {merchant?.bottomBarMenu?.profileMenu?.bottomTitle || "Profile"}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="control-menubar">
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>{merchant?.bottomBarMenu?.homeMenu?.bottomTitleTh || "หน้าแรก"}</span>
                      </div>
                      {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                        if (!item?.isUse) {
                          return null;
                        }
                        return (
                          <div
                            className="box-menubar"
                            key={index}
                            onClick={() => handleClickBottomMenu(item.bottomLink)}
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className="img-menubar">
                                <img src={item.iconBottomName} alt="" />
                              </div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              </StyleImgVector>
                            ) : null}
                            <span>{item.bottomTitleTh || "เมนู"}</span>
                          </div>
                        );
                      })}
                      <div
                        className="box-menubar"
                        onClick={() =>
                          (window.location.href = window.location.href =
                            merchant?.prefix + `.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`)
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img
                                src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.bottomBarMenu?.profileMenu?.bottomTitleTh || "โปรไฟล์"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </StyledMenuBar>
          ) : null}
          {merchant?.bottomBarMenu?.menuType?.isType2 ? (
            <StyledMenuBarType2
              themeColor={themeColor}
              color={merchant?.bottomBarMenu?.normalColor}
            >
              <StyledSubMenu color={merchant?.bottomBarMenu?.clickColor}>
                <div className="sub-bg"></div>
                <div className="main-menubar">
                  {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                    <>
                      <div className="control-menubar">
                        <StyledImgType2
                          img={merchant?.bottomBarMenu?.homeMenu?.iconBottomName}
                          clickicon={merchant?.bottomBarMenu?.homeMenu?.iconClickStateBottomName}
                        >
                          <div
                            className={"box-menubar " + (pathname === PATH_HOME ? "active" : "")}
                            onClick={() =>
                              window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                            }
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className={"showimgtype2"}></div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyledImgType2
                                img={merchant?.bottomBarMenu?.homeMenu?.iconBottomName}
                                clickicon={
                                  merchant?.bottomBarMenu?.homeMenu?.iconClickStateBottomName
                                }
                              >
                                <div className={"showimgtype2"}></div>
                              </StyledImgType2>
                            ) : null}
                            <span>{merchant?.bottomBarMenu?.homeMenu?.bottomTitle || "Home"}</span>
                          </div>
                        </StyledImgType2>
                        {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <StyledImgType2
                              img={item.iconBottomName}
                              clickicon={item.iconClickStateBottomName}
                              key={index}
                            >
                              <div
                                className={
                                  "box-menubar " + (pathname === item.bottomLink ? "active" : "")
                                }
                                key={index}
                                onClick={() => handleClickBottomMenu(item.bottomLink)}
                              >
                                {merchant?.bottomBarMenu?.iconType.isImage ? (
                                  <div className={"showimgtype2"}></div>
                                ) : null}
                                {merchant?.bottomBarMenu?.iconType.isVector ? (
                                  <div className={"showimgtype2"}></div>
                                ) : null}
                                <span>{item.bottomTitle || "Menu"}</span>
                              </div>
                            </StyledImgType2>
                          );
                        })}
                        <StyledImgType2
                          img={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                          clickicon={merchant?.bottomBarMenu?.profileMenu?.iconClickStateBottomName}
                        >
                          <div
                            className={"box-menubar "}
                            onClick={() =>
                              window.open(
                                `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                                "_self"
                              )
                            }
                          >
                            <div className={"showimgtype2"}></div>
                            <span>
                              {merchant?.bottomBarMenu?.profileMenu?.bottomTitle || "Profile"}
                            </span>
                          </div>
                        </StyledImgType2>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="control-menubar">
                        <StyledImgType2
                          img={merchant?.bottomBarMenu?.homeMenu?.iconBottomName}
                          clickicon={merchant?.bottomBarMenu?.homeMenu?.iconClickStateBottomName}
                        >
                          <div
                            className="box-menubar"
                            onClick={() =>
                              window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                            }
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className={"showimgtype2"}></div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className={"showimgtype2"}></div>
                              </StyleImgVector>
                            ) : null}
                            <span>
                              {merchant?.bottomBarMenu?.homeMenu?.bottomTitleTh || "หน้าแรก"}
                            </span>
                          </div>
                        </StyledImgType2>
                        {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <StyledImgType2
                              img={item.iconBottomName}
                              clickicon={
                                merchant?.bottomBarMenu?.homeMenu?.iconClickStateBottomName
                              }
                              key={index}
                            >
                              <div
                                className="box-menubar"
                                key={index}
                                onClick={() => handleClickBottomMenu(item.bottomLink)}
                              >
                                {merchant?.bottomBarMenu?.iconType.isImage ? (
                                  <div className={"showimgtype2"}></div>
                                ) : null}
                                {merchant?.bottomBarMenu?.iconType.isVector ? (
                                  <StyleImgVector color={merchant?.normalColor}>
                                    <div className={"showimgtype2"}></div>
                                  </StyleImgVector>
                                ) : null}
                                <span>{item.bottomTitleTh || "เมนู"}</span>
                              </div>
                            </StyledImgType2>
                          );
                        })}
                        <StyledImgType2
                          img={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                          clickicon={merchant?.bottomBarMenu?.homeMenu?.iconClickStateBottomName}
                        >
                          <div
                            className="box-menubar"
                            onClick={() =>
                              window.open(
                                `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                                "_self"
                              )
                            }
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className={"showimgtype2"}></div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className={"showimgtype2"}></div>
                              </StyleImgVector>
                            ) : null}
                            <span>
                              {merchant?.bottomBarMenu?.profileMenu?.bottomTitleTh || "โปรไฟล์"}
                            </span>
                          </div>
                        </StyledImgType2>
                      </div>
                    </>
                  )}
                </div>
              </StyledSubMenu>
            </StyledMenuBarType2>
          ) : null}
          {merchant?.bottomBarMenu?.menuType?.isType3 ? (
            <StyledMenuBarType3 themeColor={themeColor}>
              <div className="sub-bg"></div>
              <div className="main-menubar">
                {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                  <>
                    <div className="control-menubar type3">
                      <div
                        className={"box-menubar " + (pathname === PATH_HOME ? "active" : "")}
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>{merchant?.bottomBarMenu?.homeMenu?.bottomTitle || "Home"}</span>
                      </div>
                      {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                        if (!item?.isUse) {
                          return null;
                        }
                        return (
                          <div
                            className={
                              "box-menubar " + (pathname === item.bottomLink ? "active" : "")
                            }
                            key={index}
                            onClick={() => handleClickBottomMenu(item.bottomLink)}
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className="img-menubar">
                                <img src={item.iconBottomName} alt="" />
                              </div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              </StyleImgVector>
                            ) : null}
                            <span>{item.bottomTitle || "Menu"}</span>
                          </div>
                        );
                      })}
                      <div
                        className={"box-menubar "}
                        onClick={() =>
                          window.open(
                            `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                            "_self"
                          )
                        }
                      >
                        <div className="img-menubar">
                          <img src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName} alt="" />
                        </div>
                        <span>
                          {merchant?.bottomBarMenu?.profileMenu?.bottomTitle || "Profile"}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="control-menubar type3">
                      <div
                        className={"box-menubar "}
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img src={merchant?.bottomBarMenu?.homeMenu?.iconBottomName} alt="" />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>{merchant?.bottomBarMenu?.homeMenu?.bottomTitleTh || "หน้าแรก"}</span>
                      </div>
                      {merchant?.bottomBarMenu?.bottomBarName.map((item: any, index: any) => {
                        if (!item?.isUse) {
                          return null;
                        }
                        return (
                          <div
                            className={
                              "box-menubar " + (pathname === item.bottomLink ? "active" : "")
                            }
                            key={index}
                            onClick={() => handleClickBottomMenu(item.bottomLink)}
                          >
                            {merchant?.bottomBarMenu?.iconType.isImage ? (
                              <div className="img-menubar">
                                <img src={item.iconBottomName} alt="" />
                              </div>
                            ) : null}
                            {merchant?.bottomBarMenu?.iconType.isVector ? (
                              <StyleImgVector color={merchant?.normalColor}>
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              </StyleImgVector>
                            ) : null}
                            <span>{item.bottomTitleTh || "เมนู"}</span>
                          </div>
                        );
                      })}
                      <div
                        className={"box-menubar "}
                        onClick={() =>
                          (window.location.href = window.location.href =
                            merchant?.prefix + `.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`)
                        }
                      >
                        {merchant?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector color={merchant?.normalColor}>
                            <div className="img-menubar">
                              <img
                                src={merchant?.bottomBarMenu?.profileMenu?.iconBottomName}
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.bottomBarMenu?.profileMenu?.bottomTitleTh || "โปรไฟล์"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </StyledMenuBarType3>
          ) : null}
        </>
      ) : (
        <>
          {merchant?.sellerThemeSetting?.bottomBarMenu?.menuType?.isType1 ? (
            <StyledMenuBar themeColor={themeColor}>
              <div className="sub-bg"></div>
              <div className="main-menubar">
                {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                  <>
                    <div className="control-menubar">
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitle ||
                            "Home"}
                        </span>
                      </div>
                      {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                        (item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <div
                              className="box-menubar"
                              key={index}
                              onClick={() => handleClickBottomMenu(item.bottomLink)}
                            >
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              ) : null}
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                <StyleImgVector
                                  color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                >
                                  <div className="img-menubar">
                                    <img src={item.iconBottomName} alt="" />
                                  </div>
                                </StyleImgVector>
                              ) : null}
                              <span>{item.bottomTitle || "Menu"}</span>
                            </div>
                          );
                        }
                      )}
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(
                            `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                            "_self"
                          )
                        }
                      >
                        <div className="img-menubar">
                          <img
                            src={
                              merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                ?.iconBottomName
                            }
                            alt=""
                          />
                        </div>
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu?.bottomTitle ||
                            "Profile"}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="control-menubar">
                      <div
                        className="box-menubar"
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitleTh ||
                            "หน้าแรก"}
                        </span>
                      </div>
                      {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                        (item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <div
                              className="box-menubar"
                              key={index}
                              onClick={() => handleClickBottomMenu(item.bottomLink)}
                            >
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              ) : null}
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                <StyleImgVector
                                  color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                >
                                  <div className="img-menubar">
                                    <img src={item.iconBottomName} alt="" />
                                  </div>
                                </StyleImgVector>
                              ) : null}
                              <span>{item.bottomTitleTh || "เมนู"}</span>
                            </div>
                          );
                        }
                      )}
                      <div
                        className="box-menubar"
                        onClick={() =>
                          (window.location.href = window.location.href =
                            merchant?.prefix + `.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`)
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                            ?.bottomTitleTh || "โปรไฟล์"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </StyledMenuBar>
          ) : null}
          {merchant?.sellerThemeSetting?.bottomBarMenu?.menuType?.isType2 ? (
            <StyledMenuBarType2
              themeColor={themeColor}
              color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
            >
              <StyledSubMenu color={merchant?.sellerThemeSetting?.bottomBarMenu?.clickColor}>
                <div className="sub-bg"></div>
                <div className="main-menubar">
                  {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                    <>
                      <div className="control-menubar">
                        <div
                          className="box-menubar"
                          onClick={() =>
                            window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                          }
                        >
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconClickStateBottomName
                                }
                                style={{
                                  fill: merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor,
                                }}
                                alt=""
                              />
                              {/* <FilterImage color={merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.iconBottomName}

                    /> */}
                              {/* <FilterMenuBar
                                                        image={merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.iconBottomName}
                                                        color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                    /> */}
                            </div>
                          ) : null}
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                            <StyleImgVector color={merchant?.normalColor}>
                              <div className="img-menubar">
                                <img
                                  src={
                                    merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                      ?.iconClickStateBottomName
                                  }
                                  alt=""
                                />
                                {/* <FilterMenuBar
                                                            image={merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.iconBottomName}
                                                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                        /> */}
                              </div>
                            </StyleImgVector>
                          ) : null}
                          <span>
                            {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitle ||
                              "Home"}
                          </span>
                        </div>
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                          (item: any, index: any) => {
                            if (!item?.isUse) {
                              return null;
                            }
                            return (
                              <div
                                className="box-menubar"
                                key={index}
                                onClick={() => handleClickBottomMenu(item.bottomLink)}
                              >
                                {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                  <div className="img-menubar">
                                    <img src={item.iconClickStateBottomName} alt="" />
                                    {/* <FilterMenuBar
                                                                    image={item.iconBottomName}
                                                                    color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                                /> */}
                                  </div>
                                ) : null}
                                {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                  <StyleImgVector
                                    color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                  >
                                    <div className="img-menubar">
                                      <img src={item.iconClickStateBottomName} alt="" />
                                      {/* <FilterMenuBar
                                                                        image={item.iconBottomName}
                                                                        color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                                    /> */}
                                    </div>
                                  </StyleImgVector>
                                ) : null}
                                <span>{item.bottomTitle || "Menu"}</span>
                              </div>
                            );
                          }
                        )}
                        <div
                          className="box-menubar"
                          onClick={() =>
                            window.open(
                              `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                              "_self"
                            )
                          }
                        >
                          <div className="img-menubar">
                            <img
                              src={merchant?.bottomBarMenu?.profileMenu?.iconClickStateBottomName}
                              alt=""
                            />
                            {/* <FilterMenuBar
                                                    image={merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                                        ?.iconBottomName}
                                                    color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                /> */}
                          </div>
                          <span>
                            {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                              ?.bottomTitle || "Profile"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="control-menubar">
                        <div
                          className="box-menubar"
                          onClick={() =>
                            window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                          }
                        >
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconClickStateBottomName
                                }
                                alt=""
                              />
                              {/* <FilterMenuBar
                                                        image={merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                                            ?.iconBottomName}
                                                        color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                    /> */}
                            </div>
                          ) : null}
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                            <StyleImgVector color={merchant?.normalColor}>
                              <div className="img-menubar">
                                <img
                                  src={
                                    merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                      ?.iconClickStateBottomName
                                  }
                                  alt=""
                                />
                                {/* <FilterMenuBar
                                                            image={merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                                                ?.iconBottomName}
                                                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                        /> */}
                              </div>
                            </StyleImgVector>
                          ) : null}
                          <span>
                            {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitleTh ||
                              "หน้าแรก"}
                          </span>
                        </div>
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                          (item: any, index: any) => {
                            if (!item?.isUse) {
                              return null;
                            }
                            return (
                              <div
                                className="box-menubar"
                                key={index}
                                onClick={() => handleClickBottomMenu(item.bottomLink)}
                              >
                                {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                  <div className="img-menubar">
                                    <img src={item.iconClickStateBottomName} alt="" />
                                    {/* <FilterMenuBar
                                                                    image={item.iconBottomName}
                                                                    color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                                /> */}
                                  </div>
                                ) : null}
                                {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                  <StyleImgVector
                                    color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                  >
                                    <div className="img-menubar">
                                      <img src={item.iconClickStateBottomName} alt="" />
                                      {/* <FilterMenuBar
                                                                        image={item.iconBottomName}
                                                                        color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                                    /> */}
                                    </div>
                                  </StyleImgVector>
                                ) : null}
                                <span>{item.bottomTitleTh || "เมนู"}</span>
                              </div>
                            );
                          }
                        )}
                        <div
                          className="box-menubar"
                          onClick={() =>
                            (window.location.href = window.location.href =
                              merchant?.prefix + `.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`)
                          }
                        >
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                    ?.iconClickStateBottomName
                                }
                                alt=""
                              />
                              {/* <FilterMenuBar
                                                        image={merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                                            ?.iconBottomName}
                                                        color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                    /> */}
                            </div>
                          ) : null}
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                            <StyleImgVector
                              color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                            >
                              <div className="img-menubar">
                                <img
                                  src={
                                    merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                      ?.iconClickStateBottomName
                                  }
                                  alt=""
                                />
                                {/* <FilterMenuBar
                                                            image={merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                                                ?.iconBottomName}
                                                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                                        /> */}
                              </div>
                            </StyleImgVector>
                          ) : null}
                          <span>
                            {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                              ?.bottomTitleTh || "โปรไฟล์"}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </StyledSubMenu>
            </StyledMenuBarType2>
          ) : null}
          {merchant?.sellerThemeSetting?.bottomBarMenu?.menuType?.isType3 ? (
            <StyledMenuBarType3 themeColor={themeColor}>
              <div className="sub-bg"></div>
              <div className="main-menubar">
                {i18nextLng === enumLanguage.EN || i18nextLng === enumLanguage.EN_GB ? (
                  <>
                    <div className="control-menubar type3">
                      <div
                        className={"box-menubar " + (pathname === PATH_HOME ? "active" : "")}
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitle ||
                            "Home"}
                        </span>
                      </div>
                      {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                        (item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <div
                              className={
                                "box-menubar " + (pathname === item.bottomLink ? "active" : "")
                              }
                              key={index}
                              onClick={() => handleClickBottomMenu(item.bottomLink)}
                            >
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              ) : null}
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                <StyleImgVector color={merchant?.normalColor}>
                                  <div className="img-menubar">
                                    <img src={item.iconBottomName} alt="" />
                                  </div>
                                </StyleImgVector>
                              ) : null}
                              <span>{item.bottomTitle || "Menu"}</span>
                            </div>
                          );
                        }
                      )}
                      <div
                        className={"box-menubar "}
                        onClick={() =>
                          window.open(
                            `https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`,
                            "_self"
                          )
                        }
                      >
                        <div className="img-menubar">
                          <img
                            src={
                              merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                ?.iconBottomName
                            }
                            alt=""
                          />
                        </div>
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu?.bottomTitle ||
                            "Profile"}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="control-menubar type3">
                      <div
                        className={"box-menubar " + (pathname === PATH_HOME ? "active" : "")}
                        onClick={() =>
                          window.open(`https://${merchant?.prefix}.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}`, "_self")
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.homeMenu?.bottomTitleTh ||
                            "หน้าแรก"}
                        </span>
                      </div>
                      {merchant?.sellerThemeSetting?.bottomBarMenu?.bottomBarName.map(
                        (item: any, index: any) => {
                          if (!item?.isUse) {
                            return null;
                          }
                          return (
                            <div
                              className={"box-menubar "}
                              key={index}
                              onClick={() => handleClickBottomMenu(item.bottomLink)}
                            >
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                                <div className="img-menubar">
                                  <img src={item.iconBottomName} alt="" />
                                </div>
                              ) : null}
                              {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                                <StyleImgVector
                                  color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                                >
                                  <div className="img-menubar">
                                    <img src={item.iconBottomName} alt="" />
                                  </div>
                                </StyleImgVector>
                              ) : null}
                              <span>{item.bottomTitleTh || "เมนู"}</span>
                            </div>
                          );
                        }
                      )}
                      <div
                        className={"box-menubar "}
                        onClick={() =>
                          (window.location.href = window.location.href =
                            merchant?.prefix + `.rocket-crm.${process.env.REACT_APP_PUBLIC_ENV}/profile`)
                        }
                      >
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isImage ? (
                          <div className="img-menubar">
                            <img
                              src={
                                merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                  ?.iconBottomName
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                        {merchant?.sellerThemeSetting?.bottomBarMenu?.iconType.isVector ? (
                          <StyleImgVector
                            color={merchant?.sellerThemeSetting?.bottomBarMenu?.normalColor}
                          >
                            <div className="img-menubar">
                              <img
                                src={
                                  merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                                    ?.iconBottomName
                                }
                                alt=""
                              />
                            </div>
                          </StyleImgVector>
                        ) : null}
                        <span>
                          {merchant?.sellerThemeSetting?.bottomBarMenu?.profileMenu
                            ?.bottomTitleTh || "โปรไฟล์"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </StyledMenuBarType3>
          ) : null}
        </>
      )}
    </>
  );
};

const StyledImgType2 = styled.div<{ img: string; clickicon: string }>`
  .showimgtype2 {
    background-image: url(${(props) => props.img});
    background-size: 24px;
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }
  .box-menubar.active {
    .showimgtype2 {
      background-image: url(${(props) => props.clickicon});
    }
  }
`;
const StyledSubMenu = styled.div`
  .main-menubar {
    .control-menubar {
      .box-menubar {
        &:hover {
          .img-menubar {
            img {
              filter: inherit;
            }
            .filtermenu {
              background-color: ${(props) => props.color}!important;
            }
          }
          span {
            color: ${(props) => props.color}!important;
          }
        }
      }
    }
  }
`;
const TextFilterMenu = styled.span`
  &:hover {
    color: ${(props) => props.color}!important;
  }
`;

const StyleImgVector = styled.div`
  .img-menubar {
    background-color: ${(props) => props.color};
  }
`;
const StyledMenuBar = styled.div<{ themeColor: string }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  max-width: 480px;
  transform: translateX(-50%);
  /* background-color: pink; */
  overflow: hidden;
  .sub-bg {
    z-index: -1;
    content: "";
    width: 114%;
    height: 100px;
    border-radius: 58%;
    background-color: ${(p) => p.themeColor};
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    position: relative;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
  }
  .main-menubar {
    width: 100%;
    height: 60px;
    background-color: ${(p) => p.themeColor};
    // box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 10px 36px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;
    z-index: 2;
    // &:after {
    //     z-index: -1;
    //     content: '';
    //     width: 100%;
    //     height: 100px;
    //     border-radius: 58%;
    //     background-color: ${(p) => p.themeColor};
    //     position: absolute;
    //     top: -45px;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
    .control-menubar {
      position: relative;
      top: -20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-qr-menubar {
        margin-top: -50px;
        border-radius: 100%;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
        border: 2px solid #fff;
        .img-menubar {
          background-color: #336c51;
          text-align: center;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      .box-menubar {
        text-align: center;
        .img-menubar {
          margin-bottom: 5px;
          img {
            width: 24px;
          }
        }
        span {
          font-size: 13px;
          line-height: 13px;
          color: #fff;
        }
      }
    }
  }

  .sub-menubar {
    width: 100%;
    height: 20px;
    background-color: #fff;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 136px;
      height: 5px;
      background-color: #000000;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
    }
  }
`;
const StyledMenuBarType2 = styled.div<{ themeColor: string }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  max-width: 480px;
  transform: translateX(-50%);
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  padding: 10px 16px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #fff;
  .main-menubar {
    width: 100%;
    // height: 60px;
    // background-color: ${(p) => p.themeColor};
    background-color: #fff;
    // box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    // padding: 10px 36px;
    position: relative;
    z-index: 2;
    // &:after {
    //     z-index: -1;
    //     content: '';
    //     width: 100%;
    //     height: 100px;
    //     border-radius: 58%;
    //     background-color: ${(p) => p.themeColor};
    //     position: absolute;
    //     top: -45px;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
    .control-menubar {
      position: relative;
      // top: -20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-qr-menubar {
        margin-top: -50px;
        border-radius: 100%;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
        border: 2px solid #fff;
        .img-menubar {
          background-color: #336c51;
          text-align: center;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
            fill: #ff0000;
          }
          path {
            fill: #ff0000;
          }
        }
      }
      .box-menubar.active {
        span {
          color: ${(props) => props.color};
        }
      }
      .box-menubar {
        text-align: center;
        .img-menubar {
          margin-bottom: 5px;
          img {
            width: 24px;
          }
          .filtermenu {
            mask-size: 100%;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            mask-position: center;
          }
        }
        span {
          font-size: 13px;
          line-height: 13px;
          // color: ${(props) => props.color};
        }
        &:hover {
          .img-menubar {
            img {
              filter: inherit;
            }
          }
          span {
            color: ${(props) => props.color};
          }
        }
      }
    }
  }

  .sub-menubar {
    width: 100%;
    height: 20px;
    background-color: #fff;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 136px;
      height: 5px;
      background-color: #000000;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
    }
  }
`;
const StyledMenuBarType3 = styled.div<{ themeColor: string }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  max-width: 480px;
  transform: translateX(-50%);
  .main-menubar {
    width: 100%;
    background-color: ${(p) => p.themeColor};
    // box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 0 36px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: relative;
    z-index: 2;
    height: 55px;
    // &:after {
    //     z-index: -1;
    //     content: '';
    //     width: 100%;
    //     height: 100px;
    //     border-radius: 58%;
    //     background-color: ${(p) => p.themeColor};
    //     position: absolute;
    //     top: -45px;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
    .type3 {
      justify-content: center !important;
      .box-menubar {
        text-align: center;
        padding: 10px;
        margin-top: -35px !important;
        .img-menubar {
          margin-bottom: 5px;
          img {
            width: 45px !important;
          }
        }
      }
    }
    .control-menubar {
      position: relative;
      // top: -20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: unset;
      .box-qr-menubar {
        margin-top: -50px;
        border-radius: 100%;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
        border: 2px solid #fff;
        .img-menubar {
          background-color: #336c51;
          text-align: center;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      .box-menubar {
        text-align: center;
        padding: 10px;
        margin-top: -30px;
        position: reletive;
        .img-menubar {
          margin-bottom: 5px;
          position: relative;
          z-index: 1;
          img {
            width: 24px;
          }
        }
        span {
          font-size: 13px;
          line-height: 13px;
          color: #fff;
          position: relative;
          z-index: 1;
          white-space: nowrap;
        }
      }
      .box-menubar.active {
        position: relative;
        &:after {
          content: "";
          width: 100%;
          height: 55px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 100%);
        }
      }
    }
  }

  .sub-menubar {
    width: 100%;
    height: 20px;
    background-color: #fff;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 136px;
      height: 5px;
      background-color: #000000;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
    }
  }
`;
