import { Layout, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FooterComponent,
  IconBasket,
  IconLoading,
  StyledContainer,
  StyledPageInner,
} from "@components";
import { ILayout } from "@interfaces";
import { selectApp, setLoading, store } from "@redux";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";

interface IBasket {
  booking_service: [];
}

export const LayoutApp = (props: ILayout) => {
  //page hook
  const dispatch = useDispatch();
  //page state
  const history = useHistory();
  const { loading } = useSelector(selectApp);
  const { pathname } = useLocation();
  const cardUse = store.getState().cardUse;

  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  }, []);

  const [localBasket, setLocalBasket] = useState<IBasket | null>();
  useEffect(() => {
    if (pathname.split("/")[1].toLowerCase() === "s-home") {
      let basket = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
      if (cardUse?.current_path === "") {
        setLocalBasket(basket);
      } else {
        localStorage.removeItem("preBookingBody");
      }
    }
  }, [pathname]);

  return (
    <Spin
      style={{ display: "flex" }}
      spinning={loading}
      size="large"
      indicator={<IconLoading size={80} />}
    >
      <StyledContainer>
        <Layout className="workspace">
          <Layout.Content>
            <StyledPageInner>
              <StyledChildren>{props.children}</StyledChildren>
            </StyledPageInner>
          </Layout.Content>
        </Layout>
        {pathname.split("/")[1] === "s-home" &&
          localBasket &&
          Object.keys(localBasket).length > 0 &&
          localBasket?.booking_service?.length > 0 && (
            <StyledBasket onClick={() => history.push("/basket")}>
              {cardUse?.current_path}
              <IconBasket />
              <p className="count-basket">{localBasket?.booking_service?.length}</p>
            </StyledBasket>
          )}
        {pathname.split("/")[1] !== "*" &&
          pathname.split("/")[1] !== "welcome" &&
          pathname.split("/")[1] !== "sign-up" &&
          pathname.split("/")[1] !== "sign-in" &&
          pathname.split("/")[1] !== "otp" &&
          pathname.split("/")[1] !== "checkout" && (
            <StyledFooterMenu>
              <FooterComponent pathname={pathname.split("/")[1]} />
            </StyledFooterMenu>
          )}
      </StyledContainer>
    </Spin>
  );
};

const StyledChildren = styled.div`
  // height: 100vh;
`;

const StyledFooterMenu = styled.div`
  background-color: white;
  // height: 70px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.05);
  .active {
    color: #f22f46;
  }
`;

const StyledBasket = styled.div`
  cursor: pointer;
  background-color: #f22f46;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  bottom: 85px;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: 15px;
  border-radius: 50%;
  /* width: 50px;
    height: 50px; */
  svg {
    margin: auto;
  }
  .count-basket {
    position: absolute;
    margin-left: 32px;
    margin-top: 7px;
    color: #f22f46;
    font-size: 12px;
    font-weight: 500;
  }
`;
