import { useTranslation } from "react-i18next";
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ComponentFromEmail } from "@components";
import { DAYS, enumExternalMethod, MONTHS, PATH_HOME, YEARS } from "@configs";
import { IDate, IEmailForm, ISelect, ISurveyQuestions } from "@interfaces";
import { useYup } from "@validations";
import { selectAuth, selectMerchant, setExternalLogin, setLoading } from "@redux";
import { authApi } from "@api";
import { useNotify } from "@utils";

interface IProps {
    handleChangeTerm: (e: boolean) => void;
    showTerm: boolean;
}

export const ModuleEmail = (props: IProps) => {
    //props
    const { handleChangeTerm, showTerm } = props;
    //page hook
    const { t } = useTranslation();
    const { YubEmailSignUpForm } = useYup();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    //redux state
    const merchant = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    const [errorPhone, setError] = useState<string>();
    const { termCondition } = useSelector(selectMerchant);
    //page variable
    const contact = userInfo?.contact;
    const dateCurrent = new Date(contact?.dateOfBirth || "");
    const daySelects: ISelect[] = DAYS.map((item) => {
        return {
            id: item,
            label: item,
        };
    });
    const monthSelects: ISelect[] = MONTHS.map((item) => {
        return {
            id: item,
            label: item,
        };
    });
    const yearSelects: ISelect[] = YEARS.map((item) => {
        return {
            id: item,
            label: item,
        };
    });
    //page state
    const [date, setDate] = useState<IDate>({
        day: (dateCurrent.getDate() || "").toString(),
        month: (dateCurrent.getMonth() + 1 || "").toString(),
        year: (dateCurrent.getFullYear() || "").toString(),
        error: "",
    });
    const [surveyQuestions, setSurveyQuestions] = useState<ISurveyQuestions[]>([]);

    const initialValues: IEmailForm = {
        password: "",
        conformPassword: "",
        fullName: contact?.fullName || "",
        phoneNumber: contact?.phoneNumber || "",
        dateOfBirth: "",
        acceptTermsAndConditions: false,
    };

    const registerSchema = Yup.object().shape(YubEmailSignUpForm);
    const fields = merchant.signUpSettings?.fields;

    useEffect(() => {
        const data: ISurveyQuestions[] =
            fields?.map((field) => {
                return {
                    questionId: field._id || "",
                    question: field.fieldName || "",
                    answers: [],
                    propertyName: field.propertyName,
                };
            }) || [];
        setSurveyQuestions([...data]);
    }, []);

    const handleChangeDay = (value: string) => {
        setDate({
            ...date,
            day: value,
            error: "",
        });
    };
    const handleChangeMonth = (value: string) => {
        setDate({
            ...date,
            month: value,
            error: "",
        });
    };
    const handleChangeYear = (value: string) => {
        setDate({
            ...date,
            year: value,
            error: "",
        });
    };

    const handleChangeAdditionField = (value: string) => {
        //
        value;
    };

    const handleRegister = async (
        values: IEmailForm,
        { setSubmitting }: FormikHelpers<IEmailForm>
    ) => {
        if (errorPhone) return;
        if (!date.day || !date.month || !date.year) {
            setDate({
                ...date,
                error: t("validation.is_required"),
            });
            return;
        }
        if (!values.phoneNumber) {
            setError(t("validation.isNumber"));
            return;
        }

        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const dateForm = new Date(date.month + "/" + date.day + "/" + date.year);
            await authApi.createPassword({
                ...values,
                dateOfBirth: dateForm,
                surveyQuestions,
                phoneNumber: values.phone,
            });
            success(t("message.sign_up.success"));
            dispatch(setExternalLogin({ type: enumExternalMethod.EMAIL, isSignUp: false }));
            history.push(PATH_HOME);
        } catch (errors: any) {
            const errorsResponse = errors.response.data.errors;
            if (Array.isArray(errorsResponse) && errorsResponse.length) {
                error(errorsResponse[0]);
            } else {
                error(t("message.sign_up.fail"));
            }
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: registerSchema,
        enableReinitialize: true,
        onSubmit: handleRegister,
    });

    const handleChangeAnswerByIndex = (answer: string | string[], index: number) => {
        if (Array.isArray(answer)) {
            surveyQuestions[index].answers = answer;
        } else {
            surveyQuestions[index].answers = [answer];
        }
        setSurveyQuestions([...surveyQuestions]);
    };

    const showTermCondition = () => {
        handleChangeTerm(true);
    };

    return (
        <ComponentFromEmail
            showTermCondition={showTermCondition}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            isSubmitting={isSubmitting}
            days={daySelects}
            months={monthSelects}
            years={yearSelects}
            onChangeDay={handleChangeDay}
            onChangeMonth={handleChangeMonth}
            onChangeYear={handleChangeYear}
            onChangeAdditionField={handleChangeAdditionField}
            handleSubmit={handleSubmit}
            date={date}
            surveyQuestions={surveyQuestions}
            handleChangeAnswerByIndex={handleChangeAnswerByIndex}
            setFieldTouched={setFieldTouched}
            errorPhone={errorPhone || ""}
            setFieldValue={setFieldValue}
            setError={setError}
            showTerm={showTerm}
            termCondition={termCondition || ""}
        />
    );
};
