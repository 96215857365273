import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper";

import { IReward } from "@interfaces";
import { rewardApi } from "@api";
import { selectAuth, setLoading, setToggleConfirm } from "@redux";
import {
    ComponentPopUpConfirm,
    ComponentPopUpDone,
    ComponentRewardDetail,
    ComponentSelectType,
} from "@components";
import { enumStatus, enumTypes, enumValidation, PATH_HOME } from "@configs";
import { useNotify, RenderMeta } from "@utils";

export const PageReward = () => {
    //page hooks
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const { t } = useTranslation();
    const history = useHistory();
    //redux state
    const { showConfirm, userInfo } = useSelector(selectAuth);
    //page state
    const [reward, setReward] = useState<IReward>();
    const [count, setCount] = useState<number>(1);
    const [currentImage, setCurrentImage] = useState<number>(enumValidation.NUMBER_ONE);
    const [showSelectType, setShowSelectType] = useState<boolean>(false);
    const [variant, setVariant] = useState<string>("");
    const [showDone, setShowDone] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (id) {
                dispatch(setLoading(true));
                try {
                    const res = await rewardApi.getDetailById(id);
                    const data = res.data as IReward;
                    setReward(data);
                    if (data.point && userInfo?.totalPoints)
                        setCount(data.point <= userInfo.totalPoints ? 1 : 0);
                } catch (err: any) {
                    if (err.response) {
                        const mess = Array.isArray(err.response.data.errors)
                            ? err.response.data.errors[0]
                            : err.response.data.errors;
                        error(mess);
                    } else error(t("message.error"));
                    history.goBack();
                } finally {
                    dispatch(setLoading(false));
                }
            }
        })();
    }, []);
    const handleAddCount = () => {
        if (reward) {
            if (
                userInfo?.totalPoints &&
                reward.point &&
                +userInfo?.totalPoints - handleCalculatePoint() > reward?.point
            ) {
                let maxCount = Infinity;
                if (reward.limit || reward.quantity) {
                    maxCount = Math.min(
                        reward.limit ? reward.limit : Infinity,
                        reward.quantity ? reward.quantity : Infinity
                    );
                }
                if (count < maxCount) {
                    setCount(count + 1);
                }
            }
        }
    };
    const handleMinusCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    const handleCalculatePoint = () => {
        if (reward && reward.point) {
            return reward.point * count;
        } else {
            return 0;
        }
    };

    const handleUseReward = async () => {
        //
        if (reward) {
            try {
                const res = await rewardApi.redeemed({
                    objectId: reward._id || "",
                    quantity: count,
                    status: enumStatus.ACTIVE,
                    type: enumTypes.REWARD,
                    variants: [variant],
                });
                if (res.data.message) {
                    success(res.data.message);
                }
                setShowDone(true);
            } catch (err: any) {
                const { errors } = err.response.data;
                if (errors) {
                    error(errors);
                } else {
                    error(t("message.use_coupon.fail"));
                }
                history.push(PATH_HOME);
            }
        }
    };

    const handleChangeImage = (swiper: SwiperCore) => {
        setCurrentImage(swiper.realIndex + 1);
    };

    const handleChangeVariant = (value: string) => {
        setVariant(value);
        setShowSelectType(false);
    };

    const handleToggleConfirm = () => {
        dispatch(setToggleConfirm());
    };

    const handleToggleShowSelectType = () => {
        setShowSelectType(!showSelectType);
    };

    const handleClosePopupDone = () => {
        setShowDone(false);
        history.push(PATH_HOME);
    };

    const pointLeft = () => {
        if (userInfo?.totalPoints && reward?.point && userInfo.totalPoints >= reward.point) {
            return userInfo.totalPoints - reward?.point * count;
        } else return 0;
    };

    return (
        <>
            <RenderMeta title={t("meta.reward_detail", { name: reward?.name })} />
            {reward && !showSelectType && (
                <ComponentRewardDetail
                    pointLeft={pointLeft()}
                    handleAddCount={handleAddCount}
                    handleMinusCount={handleMinusCount}
                    count={count}
                    data={reward}
                    handleToggleConfirm={handleToggleConfirm}
                    currentImage={currentImage}
                    handleChangeImage={handleChangeImage}
                    handleToggleShowSelectType={handleToggleShowSelectType}
                    variant={variant}
                    variants={reward?.variants || []}
                />
            )}
            {showConfirm && reward && (
                <ComponentPopUpConfirm
                    handleCloseDetail={handleToggleConfirm}
                    data={{ ...reward, count } as IReward}
                    handleConfirm={handleUseReward}
                    handleCalculatePoint={handleCalculatePoint}
                    quantity={count}
                />
            )}
            {showDone && reward && (
                <ComponentPopUpDone
                    handleCloseDetail={handleClosePopupDone}
                    data={{ ...reward, count } as IReward}
                    handleCalculatePoint={handleCalculatePoint}
                />
            )}
            {showSelectType && reward && (
                <ComponentSelectType
                    variant={variant}
                    variants={reward?.variants || []}
                    handleChangeVariant={handleChangeVariant}
                    handleToggleShowSelectType={handleToggleShowSelectType}
                />
            )}
        </>
    );
};
