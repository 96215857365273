import { useSelector } from "react-redux";

import { ComponentTIerDetail } from "@components";
import { selectAuth } from "@redux";

export const PageTierDetail = () => {
    //redux states
    const { userInfo } = useSelector(selectAuth);

    //variable
    const memberTier = userInfo?.memberTier;

    return (
        <ComponentTIerDetail
            avatar={userInfo?.avatar || ""}
            fullName={userInfo?.contact?.fullName || ""}
            memberTier={memberTier}
        />
    );
};
