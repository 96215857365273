import styled from "styled-components";

export const StyledEachStaff = styled.div<{ mainColor: string }>`
    .preview {
        width: calc(100%);
        position: relative;
        background-color: #fff;
        /* background-image: url("/images/logoAuth.png"); */
        background-repeat: no-repeat;
        background-position: 12px 12px;
        margin: 0 auto;
        /* box-shadow: rgb(231 230 220) 1px 5px 14px 3px; */
        box-shadow: rgb(196 196 196 / 25%) 0px 0px 11px 2px;
        padding: 16px 22px 0 22px;
        border-radius: 8px;
    }
    .SalonName {
        display: flex;
        flex-direction: column;
    }
    .fixBorderRadius {
        border-radius: 0;
    }
    .topSalonName h1 {
        font-size: 14px;
        color: #000;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }
    .topSalonName h1 svg {
        margin-left: 10px;
        width: 6px;
    }
    .topSalonName {
        display: flex;
        place-content: space-between;
    }
    .topStoreInfo svg {
        margin-left: 10px;
    }
    .botSalonName p {
        font-size: 14px;
        color: #919191;
        margin-bottom: 0;
    }
    hr {
        margin: 0.5rem 0;
        border-top: 1px solid #e1e1e1;
    }
    .nav-link.active {
        // &::after {
        //     content: "";
        //     position: absolute;
        //     left: 1.5px;
        //     margin-top: -5px;
        //     width: 3px;
        //     height: 70px;
        //     border-radius: 8px;
        //     background-color: red;
        //     -webkit-transform: translateX(-50%);
        //     -ms-transform: translateX(-50%);
        //     transform: translateX(-50%);
        }
    }
    .nav-bar {
        background-color: transparent;
        display: flex;
        flex-wrap: wrap;
        // margin-top: 10px;
        justify-content: flex-start;
        .item {
            /* width: 25%; */
            text-align: center;
            padding: 8px 0 15px 0;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: #989898;
            background: transparent;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
            font-style: normal;
        }
        .under {
            color: #000000;
            font-weight: 600;
            &::after {
                content: "";
                position: absolute;
                bottom: 8px;
                left: 45%;
                width: 50%;
                height: 2px;
                background-color: ${(color) => color.mainColor};
                transform: translateX(-50%);
            }
        }
    }
    .botSalonName .nav-tabs {
        border: none;
        place-content: space-between;
    }
    .nav-tabs .nav-link.active {
        border: 1px solid #000;
        background-color: #000;
        color: #fff;
    }
    .nav-tabs .nav-link {
        border: 1px solid #C4C4C4;
        background: #fff;
        border-radius: 20px;
        color: #C4C4C4;
        cursor: pointer;
    }
    .all {
        width: 60px;
    }
    .lastItem {
        margin-right: 0 !important;
    }

    // Services Section
    .staffSection {
        padding: 0 20px 20px 20px;
    }
    .staffSection .coverImage img {
        width: 110px;
        border-radius: 4px;
    }
    .staffSection .serviceInfo p {
        margin-bottom: 0;
        display: flex;
    }
    .staffSection .serviceInfo p span {
        margin-left: 5px;
    }
    .staffSection .serviceInfo {
        padding: 8px 0;
    }
    .staffSection .serviceInfo h1 {
        margin-bottom: 0.4rem;
    }
    .staffButton {
        background: #fff;
        border-radius: 20px;
        border: 1px solid ${(color) => color.mainColor};
        color: ${(color) => color.mainColor};
        width: 65px;
        cursor: pointer;
    }
    .allServices {
        margin-top: 20px;
        display: flex;
    }
    .serviceInfo h1 {
        font-size: 18px;
        margin-bottom: 0.3rem;
    }
    .serviceInfo p {
        font-size: 12px;
        color: #919191;
        margin-bottom: 1.4rem;
    }
    .serviceInfo {
        margin-left: 20px;
        width: 100%;
        position: relative;
    }
    .priceAndArrow h2 {
        font-size: 16px;
        color: ${(color) => color.mainColor};
        font-weight: bold;
        margin: 0;
    }
    .priceAndArrow {
        display: flex;
        place-content: space-between;
        width: 100%;
        padding: 0 10px 0 0;
        // margin-bottom: 10px;
        place-items: center;
        position: absolute;
        bottom: 5px;
    }
`;
