export const CouponIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="114"
      viewBox="0 0 114 114"
      fill="none"
    >
      <g opacity="0.42">
        <path
          d="M29.1771 29.6216C28.348 29.2388 27.364 29.5982 26.9765 30.4271L25.6848 33.1938L36.9156 33.1937L29.1771 29.6216Z"
          fill="#9B9B9B"
        />
      </g>
      <g opacity="0.42">
        <path
          d="M16.4551 52.9736L10.5367 65.6559C10.3487 66.0552 10.3299 66.5155 10.4826 66.9265C10.5672 67.1566 10.6986 67.3633 10.8677 67.5324C11.0063 67.671 11.1659 67.7838 11.3468 67.866L16.455 70.2217L16.4551 52.9736Z"
          fill="#9B9B9B"
        />
      </g>
      <g opacity="0.42">
        <path
          opacity="0.42"
          d="M74.6085 95.2457L81.3794 80.7339L62.8023 80.7339L69.1012 83.6414C69.935 84.0148 70.3012 85.0106 69.9137 85.8444C69.5309 86.6781 68.5422 87.0422 67.7107 86.657L59.7068 82.965C59.5236 82.8805 59.364 82.7677 59.2277 82.6314C58.7509 82.1546 58.5936 81.4101 58.8942 80.762C58.8989 80.7526 58.9083 80.7479 58.913 80.7386L39.2273 80.7386L72.4079 96.0512C73.2392 96.4364 74.2233 96.077 74.6085 95.2457Z"
          fill="#9B9B9B"
        />
      </g>
      <path
        className="main"
        opacity="0.42"
        d="M93.5561 75.7525L93.5561 38.1752C93.5561 37.2592 92.8116 36.5147 91.8956 36.5147L21.4384 36.5147C20.5224 36.5147 19.7779 37.2592 19.7779 38.1752L19.7779 75.7525C19.7755 76.2104 19.9611 76.6262 20.2617 76.9268C20.5624 77.2275 20.978 77.413 21.436 77.4153L91.8933 77.4153C92.8116 77.413 93.5538 76.6707 93.5561 75.7525Z"
        fill="#9B9B9B"
      />
      <path d="M24 41H29" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M82 72H87" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
