interface IProps {
    width?: number;
    color?: string;
}

export const IConClose = (props: IProps) => {
    const { width = 22, color = "#A5A5A5" } = props;

    return (
        <svg
            width={`${width}`}
            height={`${width}`}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1.00098 1L21.001 21" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M1.00098 21L21.001 1" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};
