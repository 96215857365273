import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router";

import {
    enumExternalAuthType,
    enumExternalMethod,
    FACEBOOK_GRAPH_URL,
    PATH_HOME,
    PATH_SIGN_UP,
    PATH_SIGN_UP_FORM,
} from "@configs";
import { IFBLogin, IExternalAuthState, IFBSignup } from "@interfaces";
import {
    addConfigClient,
    fbLogin,
    fbSignup,
    openToast,
    selectConfigClient,
    selectMerchant,
    setConfigClient,
    setExternalLogin,
    setLoading,
} from "@redux";
import { useNotify } from "@utils";

interface IProps {
    state: IExternalAuthState;
}

export const ModuleFaceBook = (props: IProps) => {
    //hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { error } = useNotify();

    //page variable
    // const appId = "340750267947980";
    // const clientSecret = "1a428b558779335d9bc151ea171afcba";
    const appId = process.env.REACT_APP_FACEBOOK_ID;
    const clientSecret = process.env.REACT_APP_FACEBOOK_SECRET;
    const scope = "name,email,picture";
    // redux states
    const { _id } = useSelector(selectMerchant);
    const configClient = useSelector(selectConfigClient);

    const handleFBLogin = () => {
        dispatch(
            setExternalLogin({
                type: enumExternalMethod.FACEBOOK,
                isSignUp: props.state.type === enumExternalAuthType.SIGNUP ? true : false,
            })
        );
        history.push(PATH_SIGN_UP);
    };

    const responseFacebook = async (response: any) => {
        dispatch(setLoading(true));
        if (response?.accessToken) {
            const longLiveTokenUrl = `${FACEBOOK_GRAPH_URL}/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${clientSecret}&fb_exchange_token=${response?.accessToken}`;
            const userId = response.userID;
            try {
                const res = await axios.get(longLiveTokenUrl);
                if (res?.data?.access_token) {
                    let response;
                    if (props.state.type === enumExternalAuthType.LOGIN) {
                        const fbLoginData: IFBLogin = {
                            userId,
                            token: res?.data?.access_token,
                        };

                        response = await dispatch(fbLogin(fbLoginData));

                        dispatch(
                            openToast({
                                message: t("page.login_successfully"),
                                type: "success",
                                autoHideDuration: 2000,
                            })
                        );
                        //WHAT: wrap function in create async thunk
                        //@ts-ignore
                        unwrapResult(response);
                    } else if (props.state.type === enumExternalAuthType.SIGNUP) {
                        const fbSignupData: IFBSignup = {
                            userId,
                            token: res?.data?.access_token,
                        };
                        response = await dispatch(fbSignup(fbSignupData));
                        //WHAT: wrap function in create async thunk
                        //@ts-ignore
                        unwrapResult(response);
                    }

                    //@ts-ignore
                    if (!response?.payload.accessToken) {
                        dispatch(
                            openToast({
                                message: t("page.email_password_correct"),
                                type: "error",
                                autoHideDuration: 2000,
                            })
                        );
                    } else {
                        //@ts-ignore
                        const authData = response.payload;
                        const index = configClient.findIndex((item) => item.merchantId === _id);
                        if (index >= 1) {
                            dispatch(
                                setConfigClient({
                                    index,
                                    configClient: {
                                        merchantId: _id || "",
                                        token: authData,
                                    },
                                })
                            );
                        } else {
                            dispatch(
                                addConfigClient({
                                    merchantId: _id || "",
                                    token: authData,
                                })
                            );
                        }
                        props.state.type === enumExternalAuthType.LOGIN
                            ? history.push(PATH_HOME)
                            : history.push(PATH_SIGN_UP_FORM);
                    }
                } else {
                    // dispatch(logout());
                    error(t("message.sign_failed"));
                }
            } catch (errors: any) {
                error(
                    errors.response.data.message ||
                        errors.response?.data?.error?.message ||
                        t("message.sign_failed")
                );
            } finally {
                dispatch(setLoading(false));
            }
        } else {
            // dispatch(logout());
            error(t("message.sign_failed"));
            dispatch(setLoading(false));
        }
    };

    return (
        <FacebookLogin
            appId={appId || ""}
            autoLoad={false}
            cssClass="fb-button"
            fields={scope}
            onClick={handleFBLogin}
            callback={responseFacebook}
            icon="fa-facebook"
            textButton={""}
            disableMobileRedirect={true}
            isMobile={true}
            version="3.2"
            xfbml={true}
            // redirectUri={"https://localhost:3000/sign-up"}
            // redirectUri={"https://rewarding-dev.adamo.tech/fb/callback"}
        />
    );
};
