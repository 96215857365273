import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper";

import { benefitApi } from "@api";
import { ComponentBenefitDetail, ComponentPopUpConfirm, ComponentPopUpDone } from "@components";
import { enumStatus, enumTypes, enumValidation, PATH_HOME } from "@configs";
import { IBenefit, IMembershipTier } from "@interfaces";
import { selectAuth, selectMerchant, setLoading, setToggleConfirm } from "@redux";
import { useNotify } from "@utils";

export const PageBenefit = () => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const history = useHistory();
    //redux state
    const { membershipTiers } = useSelector(selectMerchant);
    const { showConfirm } = useSelector(selectAuth);
    //page state
    const [benefit, setBenefit] = useState<IBenefit>();
    const [dataRanks, setDataRanks] = useState<IMembershipTier[]>([]);
    const [currentImage, setCurrentImage] = useState<number>(enumValidation.NUMBER_ONE);
    const [showDone, setShowDone] = useState<boolean>(false);
    //page variable
    const levelIds = benefit?.levelIds;

    useEffect(() => {
        if (levelIds?.length === 0) {
            setDataRanks(membershipTiers || []);
        } else {
            levelIds?.forEach((item) => {
                //
                const check = membershipTiers?.findIndex((value) => value._id === item) || 0;
                if (check >= 0 && membershipTiers) {
                    dataRanks.push(membershipTiers[check]);
                }
            });
            setDataRanks([...dataRanks]);
        }
    }, [benefit]);

    useEffect(() => {
        (async () => {
            dispatch(setLoading(true));
            const res = await benefitApi.getDetailBenefitById(id);
            const data = res.data as IBenefit;
            setBenefit(data);

            dispatch(setLoading(false));
        })();
    }, []);

    const handleClick = async () => {
        try {
            dispatch(setLoading(true));
            const res = await benefitApi.redeemed({
                objectId: benefit?._id || "",
                status: enumStatus.ACTIVE,
                type: enumTypes.BENEFIT,
            });
            if (res.data.message) {
                success(res.data.message);
            }
            setShowDone(true);
        } catch (err: any) {
            const { errors } = err.response.data;
            if (errors) {
                error(errors);
            } else {
                error(t("message.use_coupon.fail"));
            }
            history.push(PATH_HOME);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleChangeImage = (swiper: SwiperCore) => {
        setCurrentImage(swiper.realIndex + 1);
    };

    const handleToggleConfirm = () => {
        dispatch(setToggleConfirm());
    };

    const handleClosePopupDone = () => {
        setShowDone(false);
        history.push(PATH_HOME);
    };

    return (
        <>
            {benefit && (
                <ComponentBenefitDetail
                    data={benefit}
                    handleToggleConfirm={handleToggleConfirm}
                    dataRanks={dataRanks}
                    handleChangeImage={handleChangeImage}
                    currentImage={currentImage}
                />
            )}
            {showConfirm && benefit && (
                <ComponentPopUpConfirm
                    handleCloseDetail={handleToggleConfirm}
                    data={benefit as IBenefit}
                    handleConfirm={handleClick}
                />
            )}
            {showDone && benefit && (
                <ComponentPopUpDone
                    handleCloseDetail={handleClosePopupDone}
                    data={benefit as IBenefit}
                />
            )}
        </>
    );
};
