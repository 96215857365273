import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Avatar, Icon } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

import { ArrowRight, IConCamera, IConClose, IConTick } from "@components";
import { IUserSetting } from "@interfaces";
import { enumUseSetting, PATH_HOME } from "@configs";
import { theme } from "@utils";
import { selectAuth } from "@redux";

interface IProps {
    handleUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
    values: IUserSetting;
    handleChangeTitle: (title: enumUseSetting) => void;
    handleLinkLine: () => void;
}

export const ComponentUserSetting = (props: IProps) => {
    //page prop
    const { handleUploadImage, values, handleChangeTitle, handleLinkLine } = props;
    //page hooks
    const { t } = useTranslation();
    const history = useHistory();
    const inputRef = useRef();
    //redux page
    const { userInfo } = useSelector(selectAuth);

    //page variable
    const dateFormat = moment(values?.dateOfBirth).format("DD/MM/YYYY");

    const changeImg = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUploadImage(e);
        //@ts-ignore
        inputRef.current.value = "";
    };

    const goHome = () => {
        history.push(PATH_HOME);
    };

    return (
        <StyledUserSetting avatarCheck={!!values?.avatar}>
            <div className="btn-back" onClick={goHome}>
                <Icon type="left" />{" "}
            </div>
            <input
                //@ts-ignore
                ref={inputRef}
                type="file"
                hidden
                id="uploadImage"
                onChange={changeImg}
                accept="image/png, image/jpeg, image/jpg"
            />
            <div className="image">
                <div className="box_image">
                    {values?.avatar ? (
                        <Avatar alt="Avatar" size={90} src={values?.avatar} />
                    ) : (
                        <Avatar alt="Avatar" size={90} icon={<UserOutlined />} />
                    )}
                    <label className="camera" htmlFor="uploadImage">
                        <IConCamera size={26} />
                    </label>
                </div>
            </div>
            <div className="content">
                <StyledCartUser onClick={() => handleChangeTitle(enumUseSetting.FULL_NAME)}>
                    <div className="user_info">
                        <div className="title">{t("page.name")}</div>

                        <div className={values.fullName ? "desc" : "desc-hidden"}>
                            {values?.fullName || "---"}
                        </div>
                    </div>
                    <div className="arrowRight">
                        <ArrowRight />
                    </div>
                </StyledCartUser>
                <StyledCartUser onClick={() => handleChangeTitle(enumUseSetting.PHONE_NUMBER)}>
                    <div className="user_info">
                        <div className="title">{t("page.phone_number")}</div>
                        <div className={values.phoneNumber ? "desc" : "desc-hidden"}>
                            {values?.phoneNumber || "---"}
                        </div>
                    </div>
                    <div className="arrowRight">
                        <ArrowRight />
                    </div>
                </StyledCartUser>
                <StyledCartUser onClick={() => handleChangeTitle(enumUseSetting.EMAIL)}>
                    <div className="user_info">
                        <div className="title">{t("page.email")}</div>

                        <div className={values.email ? "desc" : "desc-hidden"}>
                            {values?.email || "---"}
                        </div>
                    </div>
                    <div className="arrowRight">
                        {values.email ? (
                            userInfo?.emailVerified ? (
                                <IConTick color={theme.colors.green} />
                            ) : (
                                <IConClose width={15} color="red" />
                            )
                        ) : (
                            <ArrowRight />
                        )}
                    </div>
                </StyledCartUser>
                <StyledCartUser onClick={() => handleChangeTitle(enumUseSetting.DATE_OF_BIRTH)}>
                    <div className="user_info">
                        <div className="title">{t("page.date_of_birth")}</div>
                        <div className={values.dateOfBirth ? "desc" : "desc-hidden"}>
                            {dateFormat || "---"}
                        </div>
                    </div>
                    <div className="arrowRight">
                        <ArrowRight />
                    </div>
                </StyledCartUser>
                <div className="line" onClick={handleLinkLine}>
                    <StyledCartUser>
                        <div className="user_info">
                            <div className="desc">
                                {userInfo?.lineUserId
                                    ? t("page.link_different")
                                    : t("page.link_line")}
                            </div>
                        </div>
                        <div className="arrowRight">
                            <ArrowRight />
                        </div>
                    </StyledCartUser>
                </div>
                {/* <div className="line_desc">
                    {userInfo?.lineUserId ? t("page.request_link") : t("page.request_connect_link")}
                </div> */}
            </div>
        </StyledUserSetting>
    );
};

const StyledUserSetting = styled.div<{ avatarCheck: boolean }>`
    .btn-back {
        position: absolute;
        padding: 6px;
        left: 10px;
        top: 20px;
        &:active {
            transform: scale(0.85);
        }
        svg {
            color: #aaaaaa;
            font-size: 20px;
        }
    }
    .image {
        width: 100%;
        padding: 46px 0;
        background: #f7f7f8;
        /* background: #000; */
        text-align: center;
        img {
            border-radius: 50%;
        }
    }
    .box_image {
        position: relative;
        width: fit-content;
        margin: 0 auto;
    }
    .camera {
        position: absolute;
        bottom: 2px;
        right: 2px;
        cursor: pointer;
        /* ${(p) => {
            if (!p.avatarCheck)
                return `bottom: -18px;
                right: -18px;`;
        }} */
    }
    .line {
        margin-top: 42px;
    }
    .line_desc {
        margin: 16px 12px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #707070;
    }
    .content {
        background: #f7f7f8;
        min-height: 100vh;
    }
`;

const StyledCartUser = styled.div`
    padding: 9px 16px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrowRight {
        cursor: pointer;
    }
    &:last-child {
        border-bottom: initial;
    }
    .user_info {
        overflow: hidden;
        word-break: break-word;
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: #707070;
            margin-bottom: 4px;
            line-height: normal;
        }
        .desc {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
        }
        .desc-hidden {
            visibility: hidden;
        }
    }
`;
