interface IProps {
    onClick?: () => void;
}

export const ArrowRight = (props: IProps) => {
    const { onClick } = props;

    return (
        <svg
            width="11"
            height="16"
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path d="M1.5 1L9.5 8" stroke="#C7C7C7" strokeWidth="1.5" strokeMiterlimit="10" />
            <path
                d="M1.5 1L9.5 8"
                stroke="#C7C7C7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1.5 15L9.5 8" stroke="#C7C7C7" strokeWidth="1.5" strokeMiterlimit="10" />
            <path
                d="M1.5 15L9.5 8"
                stroke="#C7C7C7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
