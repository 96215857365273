import { Avatar, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { BarChart, ChevronsDown, Maximize, Minimize, Settings, Triangle } from "react-feather";
import { useDispatch } from "react-redux";

import { StyledHeader } from "@components";
// import notifications from "src/demos/mock/notifications";
import { selectApp, setFullscreen, setMobileDrawer, setOptionDrawer, useAppSelector } from "@redux";

const { Header } = Layout;

export const ComponentHeader = () => {
    //hook
    const dispatch = useDispatch();
    //redux state
    const app = useAppSelector(selectApp);
    return (
        <StyledHeader>
            <Header>
                {app.mobile && (
                    <a
                        onClick={() => dispatch(setMobileDrawer(!app.mobileDrawer))}
                        className="trigger"
                    >
                        <BarChart size={20} strokeWidth={1} />
                    </a>
                )}
                <Link to="/">
                    <a className="brand">
                        <Triangle size={24} strokeWidth={1} />
                        <strong className="mx-1 ">Reward Platform</strong>
                    </a>
                </Link>

                <Menu mode="horizontal" className="menu-divider">
                    {!app.mobile && (
                        <Menu.Item>
                            <Link to="/apps/calendar">
                                <a>Calendar</a>
                            </Link>
                        </Menu.Item>
                    )}
                    {!app.mobile && (
                        <Menu.Item>
                            <Link to="/apps/messages">
                                <a>Messages</a>
                            </Link>
                        </Menu.Item>
                    )}
                    {!app.mobile && (
                        <Menu.Item>
                            <Link to="/apps/social">
                                <a>Social</a>
                            </Link>
                        </Menu.Item>
                    )}
                    {!app.mobile && (
                        <Menu.Item>
                            <Link to="/apps/chat">
                                <a>Chat</a>
                            </Link>
                        </Menu.Item>
                    )}
                    {app.mobile && (
                        <Menu.SubMenu title={<ChevronsDown size={20} strokeWidth={1} />}>
                            <Menu.Item>Calendar</Menu.Item>
                            <Menu.Item>Messages</Menu.Item>
                            <Menu.Item>Social</Menu.Item>
                            <Menu.Item>Chat</Menu.Item>
                        </Menu.SubMenu>
                    )}
                </Menu>

                <span className="mr-auto" />

                <Menu mode="horizontal">
                    {!app.mobile && (
                        <Menu.Item onClick={() => dispatch(setFullscreen())}>
                            {!app.fullscreen ? (
                                <Maximize size={20} strokeWidth={1} />
                            ) : (
                                <Minimize size={20} strokeWidth={1} />
                            )}
                        </Menu.Item>
                    )}

                    <Menu.Item onClick={() => dispatch(setOptionDrawer(!app.optionDrawer))}>
                        <Settings size={20} strokeWidth={1} />
                    </Menu.Item>
                    {/* <Menu.SubMenu
                        title={
                            <Badge count={5}>
                                <span className="submenu-title-wrapper">
                                    <Bell size={20} strokeWidth={1} />
                                </span>
                            </Badge>
                        }
                    >
                        <Menu.Item className="p-0 bg-transparent" style={{ height: "auto" }}>
                            <List
                                className="header-notifications"
                                itemLayout="horizontal"
                                dataSource={notifications}
                                footer={<div>5 Notifications</div>}
                                renderItem={(item) => (
                                    <StyledNotification>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item.avatar}
                                                title={<a href="javascript:;">{item.title}</a>}
                                                description={<small>{item.description}</small>}
                                            />
                                        </List.Item>
                                    </StyledNotification>
                                )}
                            />
                        </Menu.Item>
                    </Menu.SubMenu> */}

                    <Menu.SubMenu
                        title={
                            <Avatar src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg" />
                        }
                    >
                        <Menu.Item>Settings</Menu.Item>
                        <Menu.Item>Profile</Menu.Item>
                        <Menu.Item>Notifications</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item>
                            <Link to="https://one-readme.fusepx.com">
                                <a>Help?</a>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>Signout</Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </Header>
        </StyledHeader>
    );
};
