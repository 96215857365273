interface IProps {
  color?: string;
}
export const IconPromotionDrawerHeader = (props: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_4697_13124)">
        <path
          d="M19.2184 1.61036L19.8284 1.00017C20.0572 0.771299 20.0571 0.400322 19.8282 0.171494C19.5993 -0.0572168 19.2284 -0.0571777 18.9995 0.171689L18.3898 0.781689C18.5368 0.909033 18.68 1.04212 18.8189 1.18103C18.9579 1.32001 19.091 1.46329 19.2184 1.61036Z"
          fill={props.color || "#F22F46"}
        />
        <path
          d="M9.93603 7.4612C10.197 7.20026 10.197 6.77565 9.93603 6.51467C9.67509 6.25374 9.25048 6.25374 8.98954 6.51467C8.7286 6.77561 8.72856 7.20022 8.98954 7.46116C9.25048 7.72213 9.67505 7.72213 9.93603 7.4612Z"
          fill={props.color || "#F22F46"}
        />
        <path
          d="M8.98954 13.6152C8.7286 13.8761 8.72856 14.3007 8.98954 14.5617C9.25048 14.8226 9.67509 14.8227 9.93603 14.5617C10.197 14.3007 10.197 13.8761 9.93603 13.6152C9.67505 13.3543 9.25048 13.3543 8.98954 13.6152Z"
          fill={props.color || "#F22F46"}
        />
        <path
          d="M16.5621 4.26795C16.9043 4.95681 16.789 5.81521 16.215 6.38916C15.4959 7.1083 14.3299 7.1083 13.6107 6.38916C12.8916 5.67002 12.8916 4.504 13.6107 3.78486C14.1853 3.21033 15.0447 3.09533 15.734 3.43873L17.5586 1.61338C14.8628 -0.66221 10.827 -0.530413 8.28689 2.00963L0.171621 10.1246C-0.057207 10.3535 -0.057207 10.7244 0.171621 10.9533L9.04666 19.8283C9.27549 20.0571 9.64631 20.0573 9.8751 19.8285L17.9904 11.7131C20.5302 9.17326 20.6622 5.13791 18.3872 2.44209L16.5621 4.26795ZM8.16088 5.68607C8.87873 4.96822 10.0468 4.96818 10.7646 5.68607C11.4825 6.40396 11.4825 7.57201 10.7646 8.28986C10.0467 9.00771 8.87869 9.00771 8.16084 8.28986C7.44299 7.57201 7.44299 6.40392 8.16088 5.68607ZM10.7646 15.3903C10.0468 16.1082 8.87869 16.1082 8.16084 15.3903C7.44299 14.6725 7.44299 13.5044 8.16084 12.7866C8.87869 12.0687 10.0467 12.0687 10.7646 12.7866C11.4824 13.5045 11.4825 14.6725 10.7646 15.3903ZM13.5215 10.6767L5.53338 11.5643C5.22873 11.5982 4.92389 11.3848 4.88635 11.0467C4.85061 10.7251 5.08236 10.4353 5.404 10.3996L13.3921 9.51201C13.7137 9.47627 14.0035 9.70799 14.0391 10.0297C14.0749 10.3513 13.8431 10.641 13.5215 10.6767Z"
          fill={props.color || "#F22F46"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4697_13124">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
