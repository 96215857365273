import { useState } from "react";
import { useSelector } from "react-redux";

import { ComponentCurrentPoint, ComponentMembershipTier } from "@components";
import { selectMerchant } from "@redux";
import { IMembershipTier } from "@interfaces";

export const ModuleMemberShipTier = () => {
    //page hooks

    //page states
    const [membershipTier, setMembershipTier] = useState<IMembershipTier | undefined>();
    //redux states
    const { membershipTiers } = useSelector(selectMerchant);

    const handleClickNext = (id: string) => {
        const index =
            membershipTiers?.findIndex((membershipTier) => membershipTier._id === id) || 0;
        if (index >= 0 && membershipTiers) {
            setMembershipTier(membershipTiers[index]);
            document.body.style.overflow = "hidden";
            // dispatch(setToggleConfirm());
        }
    };

    const handleClose = () => {
        setMembershipTier(undefined);
        // dispatch(setToggleConfirm());
        document.body.style.overflow = "unset";
    };

    return (
        <>
            {membershipTiers?.length !== 0 && (
                <>
                    <ComponentMembershipTier handleClickNext={handleClickNext} />
                    {membershipTier && (
                        <ComponentCurrentPoint
                            membershipTier={membershipTier}
                            handleClose={handleClose}
                        />
                    )}
                </>
            )}
        </>
    );
};
