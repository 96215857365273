import moment from "moment";
import { useTranslation } from "react-i18next";

import { IBenefit, ICoupon, IFreePoint, IReward } from "@interfaces";

const ComponentHistoryCoupon = (props: { data: ICoupon }) => {
    const { qrtable } = props.data;

    //hooks
    const { t } = useTranslation();
    // variables
    const dateFormat = moment(new Date(Date.now())).format("DD/MM/YYYY h:mm:ss a");

    return (
        <>
            <div>
                <div className="h-p-title">{t("page.item")}</div>
                <div className="h-p-desc">{qrtable?.name}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.ticket")}</div>
                <div className="h-p-desc">{qrtable?.code}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.date_time")}</div>
                <div className="h-p-desc">{dateFormat}</div>
            </div>
        </>
    );
};

const ComponentHistoryReward = (props: { data: IReward }) => {
    //props
    const { reward } = props.data;
    //hooks
    const { t } = useTranslation();
    //variables
    const dateFormat = moment(new Date(Date.now())).format("DD/MM/YYYY h:mm:ss a");
    return (
        <>
            <div>
                <div className="h-p-title">{t("page.item")}</div>
                <div className="h-p-desc">{reward?.name}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.ticket")}</div>
                <div className="h-p-desc">{reward?.code}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.date_time")}</div>
                <div className="h-p-desc">{dateFormat}</div>
            </div>
        </>
    );
};

const ComponentHistoryBenefit = (props: { data: IBenefit }) => {
    //props
    const { reward } = props.data;
    //hooks
    const { t } = useTranslation();
    //variables
    const dateFormat = moment(new Date(Date.now())).format("DD/MM/YYYY h:mm:ss a");
    return (
        <>
            <div>
                <div className="h-p-title">{t("page.item")}</div>
                <div className="h-p-desc">{reward?.name}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.date_time")}</div>
                <div className="h-p-desc">{dateFormat}</div>
            </div>
        </>
    );
};

const ComponentHistoryFreePoint = (props: { data: IFreePoint }) => {
    const { qrtable } = props.data;
    //hooks
    const { t } = useTranslation();
    //variables
    const dateFormat = moment(new Date(Date.now())).format("DD/MM/YYYY h:mm:ss a");
    return (
        <>
            <div>
                <div className="h-p-title">{t("page.item")}</div>
                <div className="h-p-desc">{qrtable?.name}</div>
            </div>
            <div>
                <div className="h-p-title">{t("page.date_time")}</div>
                <div className="h-p-desc">{dateFormat}</div>
            </div>
        </>
    );
};

export {
    ComponentHistoryCoupon,
    ComponentHistoryBenefit,
    ComponentHistoryFreePoint,
    ComponentHistoryReward,
};
