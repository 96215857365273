export default {
    home: "Rewarding platform",
    coupon_list: "Coupons | Rewarding platform",
    free_point_list: "Free points | Rewarding platform",
    history_list: "Histories | Rewarding platform",
    benefit_list: "Benefits | Rewarding platform",

    reward_detail: "Reward | {{name}}",
    benefit_detail: "Benefit | {{name}}",
    coupon_detail: "Coupon | {{name}}",
    free_point_detail: "Free point | {{name}}",

    membership_tier: "Membership tier | Rewarding platform",
    membership_detail: "Membership detail | Rewarding platform",
    user_setting: "{{name}} | Rewarding platform",
    user: "User setting",
};
