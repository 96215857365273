export const IconShare = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="17.3576" cy="7.07143" r="2.57143" stroke="#333B45" strokeWidth="1.3" />
            <circle
                r="2.57143"
                transform="matrix(1 0 0 -1 17.3576 16.9286)"
                stroke="#333B45"
                strokeWidth="1.3"
            />
            <circle cx="7.07143" cy="12.2139" r="2.57143" stroke="#333B45" strokeWidth="1.3" />
            <path d="M9.2147 10.9284L15.3545 7.9259" stroke="#333B45" strokeWidth="1.3" />
            <path d="M9.2147 13.0716L15.3545 16.0741" stroke="#333B45" strokeWidth="1.3" />
        </svg>
    );
};
