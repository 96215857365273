import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ComponentSignUpEmail } from "@components";
import { IAuthConfig, IEmail } from "@interfaces";
import { useYup } from "@validations";
import { authApi } from "@api";
import {
    addConfigClient,
    selectConfigClient,
    selectMerchant,
    setAuth,
    setConfigClient,
    setExternalLogin,
    setLoading,
} from "@redux";
import { useNotify } from "@utils";
import { enumExternalMethod, PATH_HOME } from "@configs";

export const ModuleEmailSignUp = () => {
    //page hooks
    const { YubEmailSignUp } = useYup();
    const dispatch = useDispatch();
    const { error } = useNotify();
    const history = useHistory();
    // redux states
    const { _id } = useSelector(selectMerchant);
    const configClient = useSelector(selectConfigClient);
    //page variable
    const initialValues: IEmail = {
        email: "",
    };
    const registerSchema = Yup.object().shape(YubEmailSignUp);

    const handleRegister = async (values: IEmail, { setSubmitting }: FormikHelpers<IEmail>) => {
        //
        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const res = await authApi.registerEmail(values.email);
            const data = res.data as IAuthConfig;
            const authData = { ...data, merchantId: _id || "" };
            dispatch(setAuth(authData));

            const index = configClient.findIndex((item) => item.merchantId === _id);
            if (index >= 1) {
                dispatch(
                    setConfigClient({
                        index,
                        configClient: {
                            merchantId: _id || "",
                            token: authData,
                        },
                    })
                );
            } else {
                dispatch(
                    addConfigClient({
                        merchantId: _id || "",
                        token: authData,
                    })
                );
            }
            dispatch(setExternalLogin({ type: enumExternalMethod.EMAIL, isSignUp: true }));
            history.push(PATH_HOME);
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validationSchema: registerSchema,
            onSubmit: handleRegister,
        });
    return (
        <>
            <ComponentSignUpEmail
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            />
        </>
    );
};
