import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BLANK_IMAGE_URL,
  PAGE_START,
  PAGINATION,
  PATH_ORDER_HISTORY,
  dateFormatHM,
} from "@configs";
import { productAPI } from "@api";
import { selectMerchant, store } from "@redux";
import { useEffect, useState } from "react";
import { IProductOrderExample } from "@interfaces";
import moment from "moment";
import { TotalQueueIcon } from "../icon/TotalQueueIcon";
import { SharedButtonSub } from "../shared";
import { useNotify } from "@utils";

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const ComponentOrderHistory = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const { success } = useNotify();
  const dispatch = useDispatch();
  const orgInfo = useSelector((state: any) => state.merchant);
  const profileID = store.getState().auth.userInfo?._id;
  const [allOrders, setAllOrders] = useState<IProductOrderExample[]>([]);
  const [pageload, setPageload] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { themeColor } = useSelector(selectMerchant);
  const queryParams = new URLSearchParams(window.location.search);

  const getProductOrders = async (page?: number) => {
    try {
      const res = await productAPI.getProductOrders({
        organization_code: orgInfo.subCode,
        profile_id: profileID,
        xlimit: INITAL_PAGE.xlimit,
        xpage: page && page > 1 ? page : INITAL_PAGE.xpage,
        store_id: orgInfo.store_id,
      });
      if (res.data.error === "0") {
        if (page && page > 1) {
          setAllOrders([...allOrders, ...res.data.result]);
        } else {
          setAllOrders(res.data.result);
        }
        const pageCalc = page && page > 1 ? page : INITAL_PAGE.xpage;
        const isBool = (INITAL_PAGE.xlimit * pageCalc) < res.data.total ? true : false;
        setHasMore(isBool);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    const page = pageload + 1;
    setPageload(page);
    getProductOrders(page);
  };

  useEffect(() => {
    getProductOrders();
    const order_id = queryParams.get('order_id');
    if (order_id && order_id !== '') {
      success(`Order: ${order_id} success`);
    }
  }, []);

  return (
    <div className="wholeGrayBack">
      <h1>{t("page.order_history")}</h1>
      <div className="drawerStyleSection">
        <div className="drawerHeader">
          <h2>
            {t("page.my_order")} ({allOrders.length})
          </h2>
        </div>
        <div className="drawerBody">
          {allOrders &&
            allOrders.map((item, i) => (
              <div key={i} onClick={() => {
                history.push(PATH_ORDER_HISTORY + `/${item._id}`);
              }}>
                <div className="eachOrderRow">
                  <div className="titlePriceRow">
                    <p>
                      {item.order_code}{" "}
                      <span className="orderTime body3">
                        ({moment(item.created_date).format(dateFormatHM)})
                      </span>
                    </p>
                    <span>฿{item.grand_total.toFixed(2)}</span>
                  </div>
                  <div className="picturesStatus">
                    <div className="orderImagesRow">
                      {item.basket_items.map((basket, j) => (
                        <img src={basket.product_image_url || BLANK_IMAGE_URL} key={j} />
                      ))}
                    </div>
                    <div className="discountStatus">
                      {item.grand_total !== item.total_before_discount ?
                        <small>฿{item.total_before_discount.toFixed(2)}</small>
                        : <div style={{paddingTop: 20}}></div>
                      }
                      <div className="badgesRow">
                        {item.queue_before !== 0 && (
                          <p className={"queueBadge body5"}>
                            <div className="queueCircleIcon">
                              <TotalQueueIcon width="9" height="11" />
                            </div>
                            {t("page.wait_queue")} :{" "}
                            <span className="body4">{item.queue_before}</span>
                          </p>
                        )}
                        <p
                          className={
                            item.status === "pending"
                              ? "statusBadge pendingBadge"
                              : item.status === "complete"
                              ? "statusBadge completeBadge"
                              : "statusBadge cancelledBadge"
                          }
                        >
                          {item.status === "pending" && t("page.pending")}
                          {item.status === "complete" && t("page.complete")}
                          {item.status === "canceled" && t("page.canceled")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
            {hasMore && (
              <div className="loadMoreButton">
                <SharedButtonSub
                  text={t("page.load_more")}
                  onClick={fetchMoreData}
                  type={"default"}
                  style={{ background: themeColor }}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
