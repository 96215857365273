export enum enumTheme {
  DARK = "dark",
  LIGHT = "light",
}
export enum enumMedia {
  FACEBOOK = "FACEBOOK",
  LINE = "LINEOA",
  GOOGLE = "GOOGLE",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export enum enumTypeAdditionalFields {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
}

export enum enumStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}
export enum enumNotiStatus {
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  REJECT = "REJECT",
}

export enum enumTypes {
  COUPON = "COUPON",
  REWARD = "REWARD",
  BENEFIT = "BENEFIT",
  FREE_POINT = "FREE_POINT",
  MERCHANT_UPDATE_POINT = "MERCHANT_UPDATE_POINT",
}
export enum enumUnit {
  PERCENTAGE = "PERCENTAGE",
  BAHT = "BAHT",
}

export enum enumExternalAuthType {
  LOGIN = "login",
  SIGNUP = "signup",
  LINK_LINE = "linkLine",
}

export enum enumExternalMethod {
  LINE = "LINEOA",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  EMAIL = "EMAIL",
  TEL = "TEL",
}

export enum enumMergeSettingMatch {
  and = "and",
  or = "or",
}

export enum enumDrawerPlacement {
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

export enum enumHttpHeader {
  AccessToken = "access-token",
  ClientId = "client-id",
  MerchantId = "merchant-id",
}

export enum enumClientIdEnum {
  ADMIN = "rewarding-admin-site",
  MERCHANT = "rewarding-merchant-site",
  USER = "rewarding-user-site",
}

export enum enumValidation {
  MAX_LENGTH_INPUT = 255,
  MIN_NUMBER = 0,
  MAX_NUMBER = 99999999,
  MAX_PASSWORD = 32,
  MIN_PASSWORD = 6,
  MIN_PHONE_NUMBER = 9,
  MAX_PHONE_NUMBER = 15,
  LENGTH_100 = 100,
  LENGTH_2 = 2,
  LENGTH_2000 = 2000,
  MAX_DESC = 2000,
  MIN_DESC = 2,
  NUMBER_ONE = 1,
  MIN_LENGTH_CKEDITOR = 9,
  LENTH_OTP = 6,
}

export enum exchangedType {
  RECEIVED = "RECEIVED",
  REDEEMED = "REDEEMED",
}

export enum enumUseSetting {
  FULL_NAME = "fullName",
  PHONE_NUMBER = "phoneNumber",
  EMAIL = "email",
  DATE_OF_BIRTH = "dateOfBirth",
}

export enum enumNavigation {
  USER_POINT = 1,
  BENEFIT = 2,
  COUPON = 3,
  HISTORY = 4,
  COUPON_MERCHANT = 5,
}
export enum enumLanguage {
  EN = "en",
  TH = "th",
  EN_GB = "en-GB",
}
