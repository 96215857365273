import { Button, Modal } from "antd";
import { ClosedPlate } from "../icon/CheckHostModal/ClosedPlate";
import styled from "styled-components";
import { selectMerchant } from "@redux";
import { useSelector } from "react-redux";

interface IProps {
  showModal: boolean;
  closeHanlder: () => void;
  setShowAllStores: any;
}

export const ComponentCheckHostModal = (props: IProps) => {
  const { showModal, closeHanlder, setShowAllStores } = props;
  const { themeColor } = useSelector(selectMerchant);

  const backHandler = () => {
    closeHanlder();
    setShowAllStores(true);
  };
  return (
    //@ts-ignore
    <StyledModal visible={showModal} footer={null} width={"100%"} closable={false} centered>
      <ClosedPlate color={themeColor} />
      <p className="text">Sorry this store is closed</p>
      <Button className="btn" style={{ background: themeColor }} onClick={backHandler}>
        Back
      </Button>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)<{ themeColor: any }>`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  .ant-modal-content {
    border-radius: 14px;
  }
  .text {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }
  .btn {
    border-radius: 14px;
    padding: 0 32px;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 40px;
  }
`;
