// import { ErrorMessage } from "formik";
import { Input, TimePicker, Select, Checkbox } from "antd";
import { CSSProperties } from "styled-components";
import { ReactElement } from "react";
import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";

import { IHandleBlur, IHandleChange, IHandleChangeSelect, ISelect } from "@interfaces";
import { StyledError } from "@components";

const { Option } = Select;
interface IProps {
  name?: string;
  label?: string;
  descLabel?: string;
  value?: string | number;
  className?: string;
  style?: CSSProperties | undefined;
  styleParent?: CSSProperties | undefined;
  onChange?: IHandleChange;
  onBlur?: IHandleBlur;
  onClick?: () => void;
  type?: string;
  prefixIcon?: ReactElement;
  suffixIcon?: ReactElement | string;
  notFormik?: boolean;
  placeholder?: string;
  err?: string;
  notErr?: boolean;
  disable?: boolean;
  errors?: string | undefined;
  touched?: boolean | undefined;
  defaultValue?: string | number;
}

export const SharedInput = (props: IProps) => {
  //page props
  const {
    name,
    style,
    onChange,
    onBlur,
    onClick,
    value,
    type,
    prefixIcon,
    suffixIcon,
    // notFormik,
    placeholder,
    className,
    notErr,
    disable,
    errors,
    touched,
    label,
    descLabel,
    styleParent,
    defaultValue,
  } = props;

  return (
    <StyledContainer style={styleParent}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
          <span>{descLabel}</span>
        </label>
      )}
      <Input
        id={name}
        className={errors && touched ? `${className} input-invalid` : className}
        type={type}
        name={name}
        value={value}
        style={{ ...style }}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        prefix={prefixIcon}
        suffix={suffixIcon}
        placeholder={placeholder}
        disabled={disable}
        defaultValue={defaultValue}
      />
      {!notErr && (
        <StyledError className="custom-error" visible={!!(errors && touched)}>
          {errors || "errors"}
        </StyledError>
      )}
      {/* <ErrorMessage name={name} component={StyledError} /> */}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  /* // input form add contact module */
  /* margin-bottom: 22px; */
  .input-add-contact {
    height: 48px;
    input {
      font-size: 16px;
    }
  }
  input {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    height: 48px;
    font-size: 20px;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: #919191;
  }
  /* .ant-input {
        height: ${(p) => p.theme.heights.input};
        line-height: 27px;
        color: #000;
        padding: 15px;
        font-size: 16px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            height: 52px;
        }
    } */
  input {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    height: 48px;
    font-size: 16px;
    color: black;
    border: none;
  }
  // hide arrow type number
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .input-invalid {
    input {
      border-width: 1px;
      border-color: ${(p) => p.theme.colors.danger}!important;
      &:focus-within {
        border-color: ${(p) => p.theme.colors.danger}!important;
      }
    }
    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
    .ant-input:focus,
    .ant-input:hover,
    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:focus-within {
      border-width: 1px;
      border-color: ${(p) => p.theme.colors.danger}!important;
    }
  }
  .height_48 {
    .ant-input {
      height: 48px;
    }
  }
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 8px;
    display: inline-block;
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
      font-size: 14px;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #6c7084;
      display: inline-block;
      margin-left: 12px;
    }
  }
  .ant-input-prefix {
    font-size: 20px !important;
    svg {
      margin-bottom: 2px;
    }
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
    background-color: #f7f7f8;
    border-radius: 6px;
  }
  .ant-input {
    background-color: #f7f7f8;
    border-radius: 6px;
  }
  svg[data-icon="phone"] {
    transform: rotate(90deg);
  }
`;

interface IPropTime {
  onChange?: (time: moment.Moment, timeString?: string) => void;
  time: string;
}
const format = "HH:mm";
export const SharedTime = (props: IPropTime) => {
  const { onChange, time } = props;
  return (
    <StyledTimeContainer>
      <TimePicker defaultValue={moment(time, format)} format={format} onChange={onChange} />
    </StyledTimeContainer>
  );
};

const StyledTimeContainer = styled.div`
  display: inline-block;
  width: 100%;
  .ant-time-picker-icon,
  .ant-time-picker-clear {
    right: 12px !important;
  }
  .ant-time-picker-input {
    height: ${(p) => p.theme.heights.input};
    font-size: 16px;
  }
  .ant-time-picker-input:hover {
    border-color: #d9d9d9 !important;
  }
  .anticon.anticon-close-circle.ant-time-picker-clear {
    display: none !important;
  }
`;

interface IPropsSelect {
  name?: string;
  className?: string;
  label?: string;
  style?: CSSProperties;
  styleParent?: CSSProperties;
  data: Array<ISelect>;
  onChange: IHandleChangeSelect;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  type?: "multiple" | "tags";
  errors?: string | undefined;
  touched?: boolean | undefined;
}

export const SharedSelect = (props: IPropsSelect) => {
  const { label, name, styleParent, data, onChange, errors, touched, value, placeholder } = props;

  return (
    <StyledSelect style={styleParent} isError={!!(errors && touched)}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <fieldset name={name} key={name}>
        <Select onChange={onChange} value={value} placeholder={placeholder}>
          {data.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.label}
              </Option>
            );
          })}
        </Select>
        {errors && touched && <StyledError visible={true}>{errors || "errors"}</StyledError>}
      </fieldset>
    </StyledSelect>
  );
};

const StyledSelect = styled.div<{ isError?: boolean }>`
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 8px;
    display: block;
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
      font-size: 14px;
      margin-bottom: 6px;
    }
  }
  .ant-select-selection {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    height: 48px;
    font-size: 16px;
    color: black;
    display: flex;
    align-items: center;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-selection__rendered {
    max-width: 100%;
  }
  ${(p) => {
    if (p.isError)
      return `
        .ant-select-selection {
                border-width: 1px;
                border-color: ${p.theme.colors.danger}!important;
                &:focus-within {
                    border-color: ${p.theme.colors.danger}!important;
                }
            }
        `;
  }}
`;

interface ICheckBox {
  name: string;
  value: boolean;
  onChange: ((e: CheckboxChangeEvent) => void) | undefined;
  label?: string | JSX.Element;
  errors?: string | undefined;
  touched?: boolean | undefined;
}

export const SharedCheckBox = (props: ICheckBox) => {
  const { name, value, onChange, label, errors, touched } = props;
  return (
    <>
      <Checkbox name={name} value={value} onChange={onChange}>
        {label}
      </Checkbox>
      {errors && touched && <StyledError visible={true}>{errors}</StyledError>}
    </>
  );
};
