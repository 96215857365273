import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import styled, { CSSProperties } from "styled-components";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import { SharedButton, StyledPreview } from "@components";
import { selectAuth, selectMerchant } from "@redux";
import { IFreePoint } from "@interfaces";
import { BLANK_IMAGE_URL } from "@configs";
import { numberFormatter } from "@utils";

interface IProps {
    freePoint: IFreePoint;
    style?: CSSProperties;
    handleToggleConfirm: () => void;
    handleChangeImage: (swiper: SwiperCore) => void;
    currentImage: number;
}

SwiperCore.use([Navigation, Pagination]);

export const ComponentFreePointDetail = (props: IProps) => {
    //page props
    const { freePoint, style, handleToggleConfirm, currentImage, handleChangeImage } = props;
    //page Hooks
    const { t } = useTranslation();
    const { themeColor, subColor } = useSelector(selectMerchant);
    const { showConfirm } = useSelector(selectAuth);

    //page variable
    const dateFormFormat = moment(freePoint?.startDate).format("yyyy/MM/DD HH:mm");
    const dateToFormat = moment(freePoint?.endDate).format("yyyy/MM/DD HH:mm");

    return (
        <StyledPreview
            mainColor={themeColor}
            subColor={subColor}
            style={style}
            showConfirm={showConfirm}
        >
            <div className="page_body">
                <div className="body_freePoint">
                    <div className="text_name">
                        <div className="name">{freePoint?.name || t("page.name")}</div>
                        <div className="date_text">
                            {dateFormFormat} - {dateToFormat}
                        </div>
                    </div>
                </div>
                <div className="code">
                    <div className="coupon_code">{t("page.point_given")}:</div>
                    <div className="number">{numberFormatter(freePoint?.point || 0)}</div>
                </div>
                <div className="content">
                    <Swiper
                        slidesPerView={1}
                        pagination={true}
                        onActiveIndexChange={handleChangeImage}
                    >
                        {freePoint?.imageUrl?.length ? (
                            <div className="pagination_coupon">
                                {currentImage}/{freePoint?.imageUrl?.length}
                            </div>
                        ) : (
                            <></>
                        )}
                        {freePoint?.imageUrl?.length ? (
                            freePoint?.imageUrl.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <img className="image" src={item} />
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <img className="image" src={BLANK_IMAGE_URL} />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className="btn-main">
                    <StyledButton
                        size="large"
                        bg_color={themeColor}
                        type="submit"
                        onClick={handleToggleConfirm}
                    >
                        {t("page.collect_free_points")}
                    </StyledButton>
                </div>
            </div>
        </StyledPreview>
    );
};

const StyledButton = styled(SharedButton)`
    margin-top: 16px;
    /* height: 48px; */
`;
