import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { ComponentPassword } from "@components";
import { IResetPassword } from "@interfaces";
import { useYup } from "@validations";
import { setLoading } from "@redux";
import { authApi } from "@api";
import { useNotify } from "@utils";
import { PATH_SIGN_IN } from "@configs";

interface IProps {
    email: string;
    resetPasswordId: string;
}

export const ModuleResetPassword = (props: IProps) => {
    //page props
    const { email, resetPasswordId } = props;
    //page hook
    const { t } = useTranslation();
    const { YubResetPassword } = useYup();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();

    //page variable
    const initialValues: IResetPassword = {
        password: "",
        conformPassword: "",
    };
    const registerSchema = Yup.object().shape(YubResetPassword);

    const handleRegister = async (
        values: IResetPassword,
        { setSubmitting }: FormikHelpers<IResetPassword>
    ) => {
        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const res = await authApi.resetPassword({
                password: values.password,
                email,
                resetPasswordId,
            });
            if (res.data.success) {
                success(t("message.reset_pass.success"));
                history.push(PATH_SIGN_IN);
            }
        } catch (errors: any) {
            const errorsResponse = errors.response.data.errors;
            if (errorsResponse) {
                error(errorsResponse);
            } else {
                error(t("message.reset_pass.fail"));
            }
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
        //
    };

    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validationSchema: registerSchema,
            onSubmit: handleRegister,
        });

    return (
        <ComponentPassword
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
        />
    );
};
