import { selectMerchant } from "@redux";
import { useSelector } from "react-redux";
import { ComponentSingleOrderHistory } from "src/Components/orderHistory/single";
import { StyledSingleOrderHistory } from "src/Components/styles/SingleOrderHistory";

export const PageSingleOrderHistory = () => {
  const { themeColor } = useSelector(selectMerchant);

  return (
    <StyledSingleOrderHistory themeColor={themeColor}>
      <ComponentSingleOrderHistory />
    </StyledSingleOrderHistory>
  );
};
