// import { useSelector } from "react-redux";
import { Result } from "antd";
import { useTranslation } from "react-i18next";

// import { selectAuth } from "@redux";

export const PageError404 = () => {
    //page hook
    const { t } = useTranslation();
    // const { isLoading } = useSelector(selectAuth);
    // return <>{isLoading && <Result status="404" title="404" subTitle={t("message.page_404")} />}</>;
    return <>{<Result status="404" title="404" subTitle={t("message.page_404")} />}</>;
};
