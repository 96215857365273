import styled from "styled-components";

export const StyledCards = styled.div<{ mainColor: string }>`
  .wholePage {
    // background: #f7f7f8;
    padding: 20px;
    // height: 100vh;
  }
  // top Cards Tab
  .topCardTabs .topNav .nav-link {
    text-align: center;
    padding: 15px 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #989898;
    background: transparent;
    cursor: pointer;
    position: relative;
    font-style: normal;
    border: none;
  }
  .topCardTabs .topNav li {
    padding: 0 !important;
  }
  .topCardTabs .topNav .nav-link.active {
    color: #000000;
    font-weight: 600;
    &::after {
      content: "";
      position: absolute;
      bottom: 8px;
      left: 50%;
      width: 30%;
      height: 2.5px;
      border-radius: 10px;
      background-color: #000;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .topCardTabs .nav-tabs {
    gap: 20px;
  }
  .nav-tabs {
    border: none;
  }
  .topTabs .topTabs {
    padding: 20px;
  }
  .topTabs .topTabs p {
    font-size: 14px;
    color: #919191;
    margin-bottom: 0;
  }
  .topTabs hr {
    margin: 0.5rem 0;
    border-top: 1px solid #e1e1e1;
  }
  // top Cards Tab
  // top Navs from MyBooking Style
  .topTabs .nav-bar {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 10px;
    justify-content: flex-start;
    .item {
      /* width: 25%; */
      text-align: center;
      padding: 8px 0 15px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #989898;
      background: transparent;
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      font-style: normal;
    }
    .under {
      color: #000000;
      font-weight: 600;
      &::after {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 45%;
        width: 50%;
        height: 2px;
        background-color: #f22f46;
        transform: translateX(-50%);
      }
    }
  }
  .topTabs .topTabs .nav-tabs {
    border: none;
    place-content: baseline;
  }
  .topTabs .nav-tabs .nav-link.active {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
  }
  .topTabs .nav-tabs .nav-link {
    border: 1px solid #707070;
    background: #fff;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
  }
  .topTabs .all {
    width: 60px;
  }
  .topTabs .lastItem {
    margin-right: 0 !important;
  }
  .topTabs {
    margin-top: 10px;
  }
  // top Navs from MyBooking Style
  .wholeGray {
    background: #f7f7f8;
    padding: 20px;
    // height: 100vh;
  }
  .cardInside {
    display: flex;
    background: #fc5064;
    width: 100%;
    height: 225px;
    border-radius: 15px;
    place-content: space-between;
    overflow: hidden;
    cursor: pointer;
  }
  .leftSide h1 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  .leftSide h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
  }
  .leftSide {
    padding: 20px;
    position: relative;
    width: 60%;
    min-width: 60%;
  }
  .leftSide hr {
    border-top: 0.5px solid #ffffff73;
  }
  .leftSide p {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
  }
  .validityStick p {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 6px;
  }
  .rightSide img {
    // width: 100%;
    height: 100%;
    border-radius: 60px 0 0 60px / 180px 0 0 180px;
    transform: scale(1.05);
  }
  .singleCard {
    margin-bottom: 20px;
  }
  .fixBotMargin {
    margin-bottom: 0 !important;
  }
  // Cards Basket
  .bookingCard {
    display: flex;
    place-content: space-between;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    place-items: baseline;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .wholeGrayPage {
    background: #f7f7f8;
    padding: 20px;
  }
  .bookingCard .topLeft h1 {
    font-size: 20px;
    font-weight: 600;
  }
  .bookingCard .topLeft h2 {
    font-size: 16px;
    color: #646464;
  }
  .bookingCard .topRightValidity p {
    color: #a5a5a5;
    font-size: 14px;
    line-height: 30px;
  }
  .topSection {
    display: flex;
    place-content: space-between;
    width: 100%;
  }
  .wholeGrayPage hr {
    border-top: 1px solid #e1e1e1;
    width: 100%;
  }
  .tableDetail {
    width: 100%;
    h3 {
      font-size: 16px;
      color: #646464;
      margin-bottom: 1rem;
      padding: 0;
      line-height: 16px;
    }
    p {
      font-size: 16px;
      color: #646464;
      margin-bottom: 1rem;
      padding: 0;
      line-height: 16px;
    }
  }
  .myCardDetails {
    display: flex;
    width: 90%;
    place-content: space-between;
  }
  .myCardDetails h3 {
    font-size: 16px;
    color: #646464;
    margin-bottom: 1rem;
    padding: 0;
    line-height: 16px;
  }
  .myCardDetails p {
    font-size: 16px;
    color: #646464;
    margin-bottom: 1rem;
    padding: 0;
    line-height: 16px;
  }
  .botCardDetails {
    margin-top: 10px;
    display: flex;
    place-content: space-between;
    width: 100%;
  }
  .botCardDetails .totalPrice p {
    font-size: 20px;
    color: #f22f46;
    font-weight: 600;
  }
  .botCardDetails .totalPrice p span {
    font-size: 20px;
    color: #f22f46;
    font-weight: 600;
    margin-left: 10px;
  }
  .noteButton button {
    background: #fff;
    border-radius: 50px;
    border: 1px solid #cfcfcf;
    color: #919191;
    /* width: 55px; */
    padding: 5px 20px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .rightDetails {
    width: 70%;
  }
  // Cards Basket
  // Walkin Codes
  .threeTab .tab {
    background: #fff;
    border-radius: 3px;
    padding: 18px 20px;
    display: flex;
    place-content: space-between;
    place-items: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .threeTab .tab h2 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .threeTab .arrowIcon {
    font-weight: 600;
    font-size: 18px;
  }
  .threeTab .btn {
    background: #fff;
  }
  // Walkin Codes
  // Shop Choosing
  .shopDiv {
    cursor: pointer;
  }
  .shopDiv .leftShopDetail {
    width: 65%;
  }
  .shopDiv .leftShopDetail h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .shopDiv .leftShopDetail p {
    font-size: 14px;
    font-weight: 400;
  }
  .shopDiv .leftShopDetail .small {
    font-size: 12px;
    color: #a5a5a5;
  }
  .rightShopDetail {
    height: 100%;
    border-left: 1px solid #a5a5a5;
    display: flex;
    padding: 20px;
  }
  .rightShopDetail p {
    margin: 0;
    font-weight: 600;
  }
  // Shop Choosing
`;
