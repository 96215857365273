import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";

import {
    ComponentGmailButton,
    ComponentHeaderAuth,
    ComponentPhoneButton,
    ComponentSocialMedia,
    SharedSocialLine,
    StyledContainerAuthSign,
    StyledContainerSignIn,
} from "@components";
import {
    addConfigClient,
    lineLogin,
    lineSignup,
    logout,
    selectAuth,
    selectConfigClient,
    selectMerchant,
    setConfigClient,
    setExternalLogin,
} from "@redux";
import {
    ModuleEmailSignIn,
    ModuleFaceBook,
    ModuleGoogle,
    ModuleLine,
    ModuleTelSignIn,
} from "@modules";
import {
    enumExternalMethod,
    PATH_RESET_PASSWORD_EMAIL,
    enumExternalAuthType,
    PATH_SIGN_UP,
    LINE_AUTH_URL,
    PATH_HOME,
    PATH_SIGN_UP_FORM,
} from "@configs";
import { IExternalAuthState, ILineLogin, ILineSignup, ILineState } from "@interfaces";
import { useNotify } from "@utils";
import { LineApi } from "@api";

export const PageSignIn = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const { error, success } = useNotify();
    const dispatch = useDispatch();
    //redux states
    const merchant = useSelector(selectMerchant);
    const { externalLogin, line } = useSelector(selectAuth);
    //
    const [loginMethod, setLoginMethod] = useState<enumExternalMethod>(enumExternalMethod.EMAIL);
    const [signinMethods, setSigninMethods] = useState<JSX.Element[]>([]);
    const [remember, setRemember] = useState<boolean>(false);
    const { _id } = useSelector(selectMerchant);
    const configClient = useSelector(selectConfigClient);
    const { auth } = useSelector(selectAuth);
    //page variable
    const state: IExternalAuthState = {
        type: enumExternalAuthType.LOGIN,
    };
    const errorHandler = (errors: any) => {
        //handle Error
        if (errors.response) {
            const { data, status } = errors.response;
            if (data?.message && status !== 500) {
                error(data?.message);
            } else {
                error(t("page.sign_in_failed"));
            }
            // dispatch(logout());
        }
    };

    useEffect(() => {
        setSigninMethods([
            <ModuleLine state={state} />,
            <ModuleFaceBook state={state} />,
            <ModuleGoogle errorHandler={errorHandler} state={state} />,
            <ComponentPhoneButton onClick={handleSwitchPhone} />,
        ]);
        if (externalLogin === enumExternalMethod.LINE && !auth?.accessToken) {
            externalLine();
        }
    }, []);

    const handleSignUp = () => {
        history.push(PATH_SIGN_UP);
    };

    const handleForgotPassword = useCallback(() => {
        history.push(PATH_RESET_PASSWORD_EMAIL);
    }, []);

    const handleToggleRemember = useCallback(() => {
        setRemember(!remember);
    }, [remember]);

    const handleSwitchEmail = useCallback(() => {
        setLoginMethod(enumExternalMethod.EMAIL);
        setSigninMethods([
            <ModuleLine state={state} />,
            <ModuleFaceBook state={state} />,
            <ModuleGoogle errorHandler={errorHandler} state={state} />,
            <ComponentPhoneButton onClick={handleSwitchPhone} />,
        ]);
    }, [signinMethods]);

    const handleSwitchPhone = useCallback(() => {
        setLoginMethod(enumExternalMethod.TEL);
        setSigninMethods([
            <ModuleLine state={state} />,
            <ModuleFaceBook state={state} />,
            <ModuleGoogle errorHandler={errorHandler} state={state} />,
            <ComponentGmailButton onClick={handleSwitchEmail} />,
        ]);
    }, [signinMethods]);

    const externalLine = async () => {
        const lineLoginApi = LineApi(LINE_AUTH_URL);
        try {
            if (line?.code) {
                const lineState = JSON.parse(line?.state) as ILineState;
                if (lineState.type === enumExternalAuthType.LOGIN) {
                    const lineAccessToken = await lineLoginApi.login(line?.code);
                    if (!lineAccessToken.data) {
                        dispatch(logout());
                    }
                    const lineLoginData: ILineLogin = {
                        token: lineAccessToken.data.access_token,
                        refreshToken: lineAccessToken.data.refresh_token,
                        tokenId: lineAccessToken.data.id_token,
                    };
                    const res = await dispatch(lineLogin(lineLoginData));
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(res);
                    //@ts-ignore
                    if (!res.payload.accessToken) {
                        error(t("page.email_password_correct"));
                    } else {
                        //@ts-ignore
                        const authData = res.payload;
                        const index = configClient.findIndex((item) => item.merchantId === _id);
                        if (index >= 1) {
                            dispatch(
                                setConfigClient({
                                    index,
                                    configClient: {
                                        merchantId: _id || "",
                                        token: authData,
                                    },
                                })
                            );
                        } else {
                            dispatch(
                                addConfigClient({
                                    merchantId: _id || "",
                                    token: authData,
                                })
                            );
                        }
                        success(t("page.login_successfully"));
                        history.push(PATH_HOME);
                    }
                } else if (lineState.type === enumExternalAuthType.SIGNUP) {
                    const lineAccessToken = await lineLoginApi.login(line?.code);
                    if (!lineAccessToken.data) {
                        dispatch(logout());
                    }
                    dispatch(
                        setExternalLogin({
                            type: enumExternalMethod.LINE,
                            isSignUp: true,
                        })
                    );

                    const lineSignupData: ILineSignup = {
                        token: lineAccessToken.data.access_token,
                        refreshToken: lineAccessToken.data.refresh_token,
                        tokenId: lineAccessToken.data.id_token,
                        inviteId: lineState?.inviteId,
                    };

                    const res = await dispatch(lineSignup(lineSignupData));
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(res);
                    //@ts-ignore
                    if (!res.payload.accessToken) {
                        error(t("page.email_password_correct"));
                    } else {
                        //@ts-ignore
                        const authData = res.payload;
                        const index = configClient.findIndex((item) => item.merchantId === _id);
                        if (index >= 1) {
                            dispatch(
                                setConfigClient({
                                    index,
                                    configClient: {
                                        merchantId: _id || "",
                                        token: authData,
                                    },
                                })
                            );
                        } else {
                            dispatch(
                                addConfigClient({
                                    merchantId: _id || "",
                                    token: authData,
                                })
                            );
                        }
                        success(t("page.login_successfully"));
                        history.push(PATH_SIGN_UP_FORM);
                    }
                } else {
                    dispatch(logout());
                }
            } else {
                dispatch(logout());
            }
        } catch (errors) {
            errorHandler(errors);
            dispatch(
                setExternalLogin({
                    type: enumExternalMethod.LINE,
                    isSignUp: false,
                })
            );
        }
    };

    return (
        <StyledContainerSignIn color={merchant.themeColor}>
            <ComponentHeaderAuth>
                <>
                    <StyledContainerAuthSign bg_color={merchant.themeColor}>
                        <div className="title_auth">
                            {t("page.sign_in_to")} <br /> {merchant.name}
                        </div>
                        <div className="choose_auth">{t("page.choose_sign_in_method")}</div>
                        <ComponentSocialMedia socials={signinMethods} />
                        <SharedSocialLine />
                        {loginMethod === enumExternalMethod.EMAIL ? (
                            <ModuleEmailSignIn remember={remember} />
                        ) : (
                            <ModuleTelSignIn remember={remember} />
                        )}

                        {/* <ModuleEmailSignIn /> */}
                        {loginMethod !== enumExternalMethod.TEL && (
                            <div className="forgot">
                                {t("page.forgot")}
                                <span onClick={handleForgotPassword}>{t("page.password")}</span>
                            </div>
                        )}
                        <div className="remember">
                            <Checkbox value={remember} onClick={handleToggleRemember}>
                                {t("page.remember_me")}
                            </Checkbox>
                        </div>
                    </StyledContainerAuthSign>
                    {/* <ModuleEmailSignIn handleForgotPassword={handleForgotPassword} /> */}
                    <div className="sign_in">
                        {t("page.dont_have_an_account")}
                        <span onClick={handleSignUp}>{t("page.sign_up")}</span>
                    </div>
                </>
            </ComponentHeaderAuth>
        </StyledContainerSignIn>
    );
};
