export const InformationIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99977 4.74998C6.39426 4.74998 6.71406 4.43019 6.71406 4.0357C6.71406 3.64121 6.39426 3.32141 5.99977 3.32141C5.60528 3.32141 5.28548 3.64121 5.28548 4.0357C5.28548 4.43019 5.60528 4.74998 5.99977 4.74998Z"
      fill="#919191"
    />
    <path
      d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
      stroke="#919191"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.67855V5.82141"
      stroke="#919191"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
