interface IProps {
  color?: string;
}
export const DiscountIcon = (props: IProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill={props.color || "#F22F46"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5064 6.15721L17.0116 9.91275C17.2289 10.4549 17.189 11.0124 16.897 11.5182L13.1083 18.0799C12.8629 18.5049 12.4613 18.8115 11.9873 18.9378C11.5167 19.0631 11.0155 18.9977 10.5936 18.7542L5.08314 15.5727C4.65741 15.3269 4.35433 14.9287 4.22711 14.4541C4.10028 13.981 4.16447 13.4821 4.40945 13.0578L8.19775 6.49567C8.49006 5.98933 8.95251 5.67683 9.53118 5.59386L13.5363 5.01965C13.6273 5.00662 13.7191 5 13.811 5C14.5705 5 15.2242 5.45332 15.5064 6.15721ZM13.8769 8.34034C14.1756 8.26023 14.4248 8.06911 14.5795 7.80129C14.898 7.24992 14.7073 6.53955 14.156 6.22111C13.6044 5.90249 12.8941 6.09289 12.5757 6.64454C12.2573 7.19619 12.4476 7.90621 12.9991 8.22472C13.1749 8.3262 13.3726 8.38025 13.5754 8.38025C13.6772 8.38025 13.7786 8.36669 13.8769 8.34034ZM19.8365 16.4635V9.31292C19.8365 8.76524 19.608 8.29481 19.1773 7.95643L16.1748 5.59744C16.019 5.47501 15.8446 5.37883 15.6571 5.31454L15.437 5.23912L15.5807 5.42204C15.7177 5.59629 15.828 5.78948 15.9104 5.99513L17.4157 9.75085C17.6849 10.4226 17.636 11.1091 17.2741 11.7358L13.5487 18.1885H18.1116C19.0626 18.1885 19.8365 17.4145 19.8365 16.4635ZM7.82663 13.5268C7.539 13.6929 7.44045 14.0606 7.60651 14.3483C7.77258 14.6359 8.14037 14.7345 8.428 14.5684L12.9416 11.9624C13.2293 11.7964 13.3278 11.4286 13.1618 11.141C12.9957 10.8533 12.6279 10.7548 12.3403 10.9208L7.82663 13.5268ZM12.0068 14.8011C11.9208 15.1219 11.5911 15.3123 11.2702 15.2263C10.9494 15.1404 10.759 14.8106 10.845 14.4898C10.931 14.169 11.2607 13.9786 11.5815 14.0646C11.9023 14.1505 12.0927 14.4803 12.0068 14.8011ZM9.03375 11.2552C9.35456 11.3412 9.68432 11.1508 9.77028 10.83C9.85624 10.5092 9.66585 10.1794 9.34504 10.0935C9.02423 10.0075 8.69447 10.1979 8.60851 10.5187C8.52255 10.8395 8.71294 11.1693 9.03375 11.2552Z"
      fill="white"
    />
  </svg>
);
