import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant } from "@redux";
import { Row } from "antd";
import { StyledSubmitButton } from "../shared";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNotify } from "@utils";
import { AddOrderGrayButton } from "../icon";

interface IProps {
  dynamicFreebieDrawer: boolean;
  handleClose: () => void;
  listFreebieAddon?: any;
  listPromoDynamicFreebie?: any;
  addListToBasket?: any;
  addProductsFreebieAddAddon?: any;
  openProductFreebieAddon?: any;
}

export const ComponentDynamicFreebieDrawer = (props: IProps) => {
  const {
    dynamicFreebieDrawer,
    handleClose,
    listPromoDynamicFreebie,
    listFreebieAddon,
    addListToBasket,
    addProductsFreebieAddAddon,
    openProductFreebieAddon,
  } = props;
  const { t } = useTranslation();
  const { error } = useNotify();
  const { themeColor } = useSelector(selectMerchant);
  const [index, setIndex] = useState(1);
  const [selectProductDynamicFreebie, setSelectProductDynamicFreebie] = useState<any[]>([]);

  const freebieHandler = () => {
    let listSelectDynamicFreebie: any = [];
    selectProductDynamicFreebie.map((promo) => {
      promo.map((e: any) => {
        for (let i = 0; i < e.original_free_qty; i++) {
          listSelectDynamicFreebie.push(e);
        }
      });
    });
    let listProductNonAddon: any = listSelectDynamicFreebie.filter(
      (p: any) => !p.addon_option_list || p.addon_option_list.length === 0
    );
    let listProductAddon: any = listSelectDynamicFreebie.filter(
      (p: any) => p.addon_option_list && p.addon_option_list.length > 0
    );
    let resultProductAddon: any = [];
    if (listProductNonAddon && listProductNonAddon.length > 0) {
      addListToBasket(listProductNonAddon);
    }
    if (listProductAddon && listProductAddon.length > 0) {
      listProductAddon.map((e: any) => resultProductAddon.push(e));
    }
    if (listFreebieAddon && listFreebieAddon.length > 0) {
      listFreebieAddon.map((e: any) => resultProductAddon.push(e));
    }
    if (resultProductAddon && resultProductAddon.length > 0) {
      addProductsFreebieAddAddon(resultProductAddon);
      openProductFreebieAddon();
    } else {
      handleClose();
    }
  };

  return (
    <>
      <StyledFreebieHeaderDrawer
        title={
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <p className="h3" style={{ margin: 0 }}>
              {t("page.select_freebie_add_on")}{" "}
              <span style={{ fontSize: 16, fontWeight: 600, color: themeColor, display: "unset" }}>
                {selectProductDynamicFreebie[index - 1]
                  ? selectProductDynamicFreebie[index - 1].length
                  : 0}
                /
                {listPromoDynamicFreebie &&
                  listPromoDynamicFreebie[index - 1].dynamic_freebie_amount}
              </span>
            </p>
            <div></div>
          </div>
        }
        placement={enumDrawerPlacement.BOTTOM}
        onClose={() => null}
        visible={dynamicFreebieDrawer}
        width={"100%"}
        closable={false}
        height={"80%"}
      >
        <StyledBasketDrawer themeColor={themeColor}>
          <div className="freebieForm">
            {listPromoDynamicFreebie[index - 1].result_free_item.length > 0
              ? listPromoDynamicFreebie[index - 1].result_free_item.map((item: any, i: number) => (
                  <div
                    className="allServices"
                    onClick={() => {
                      if (!selectProductDynamicFreebie[index - 1]) {
                        selectProductDynamicFreebie[index - 1] = [];
                        setSelectProductDynamicFreebie(selectProductDynamicFreebie);
                      }
                      if (
                        selectProductDynamicFreebie[index - 1].length <
                        listPromoDynamicFreebie[index - 1].dynamic_freebie_amount
                      ) {
                        let list: any[] = [...selectProductDynamicFreebie];
                        list[index - 1] = [
                          ...list[index - 1],
                          {
                            ...item,
                            pos_promotion_id: listPromoDynamicFreebie[index - 1].pos_promotion_id,
                          },
                        ];
                        setSelectProductDynamicFreebie(list);
                      } else {
                        return;
                      }
                    }}
                    key={i}
                  >
                    <div className="coverImage">
                      <img src={item.image_url || "/images/default_service.png"} />
                    </div>
                    <div className="serviceInfo">
                      <h1>{item.name}</h1>
                      <div className="priceAndArrow">
                        <AddOrderGrayButton />
                      </div>
                    </div>
                  </div>
                ))
              : null}
            <div className="addItemRow">
              <div className="btn-layout">
                <form>
                  <StyledSubmitButton
                    type="red"
                    text={
                      <Row className="addToOrderRow">
                        <span className="button1" style={{ color: "#fff" }}>
                          {`${t("page.next")} ${index}/${listPromoDynamicFreebie.length}`}
                        </span>
                      </Row>
                    }
                    htmlType="button"
                    onClick={() => {
                      if (
                        !selectProductDynamicFreebie[index - 1] ||
                        listPromoDynamicFreebie[index - 1].dynamic_freebie_amount >
                          selectProductDynamicFreebie[index - 1].length
                      ) {
                        error(
                          `Please Select Freebie ${
                            listPromoDynamicFreebie[index - 1].dynamic_freebie_amount
                          } Item`
                        );
                        return;
                      }
                      if (index < listPromoDynamicFreebie.length) {
                        setIndex(index + 1);
                      } else {
                        freebieHandler();
                      }
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </StyledBasketDrawer>
      </StyledFreebieHeaderDrawer>
    </>
  );
};
const StyledFreebieHeaderDrawer = styled(SharedComponentDrawer)`
  .totalQueueBadge {
    border-radius: 48px;
    padding: 2px 12px 2px 2px;
    display: flex;
    gap: 4px;
    place-items: center;
    span {
      margin: 0;
    }
    .totalQueueIcon {
      border-radius: 50%;
      padding: 4px 8px;
    }
  }
  .ant-drawer-body {
    margin-top: 4.2rem;
  }
  .ant-drawer-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  .coverImage img {
    border-radius: 8px;
    width: 110px;
    height: 110px;
    object-fit: cover;
  }
  .allServices {
    /* margin-top: 20px; */
    margin-bottom: 20px;
    display: flex;
  }
  .serviceInfo h1 {
    font-size: 18px;
    margin-bottom: 0.3rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .serviceInfo p {
    font-size: 16px;
    color: #919191;
    margin-bottom: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
  }
  .serviceInfo {
    margin-left: 20px;
    width: 100%;
    position: relative;
    .staff-position {
      padding-bottom: 25px;
    }
  }
  .serviceInfo .priceAndArrow {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: 5px;
    padding-right: 0;
    svg {
      margin-top: 5px;
    }
  }
`;

const StyledBasketDrawer = styled.div<{ themeColor: string }>`
  .freebieForm {
    padding-bottom: 10rem;
  }
  .greenBox {
    border-radius: 8px;
    background: ${(color) => color.themeColor}10;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSide {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 12px;
      }
    }
  }
  .addItemRow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px;
    align-items: center;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
    .separator {
      position: absolute;
      top: -10px;
      margin: 0;
      left: 0;
      right: 0;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .addToOrderRow {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    width: 100%;
    button {
      margin: 0;
      width: 100%;
      height: 55px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  input[type="radio"]:checked {
    background-color: red;
  }
  .ant-radio-inner::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.7);
    width: 50%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 0.3s ease;
    background: transparent;
    border-radius: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    position: relative;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(color) => color.themeColor};
    border: 2px solid ${(color) => color.themeColor};
    transition: background-color 0.3s ease;
  }
  .ant-radio {
    width: 17px;
    height: 17px;
  }
  span.ant-radio + * {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }
  .ant-radio-checked::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${(color) => color.themeColor};
  }
  .addonsTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    span {
      color: #919191;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(color) => color.themeColor};
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(color) => color.themeColor};
  }
  .checkBoxColumn {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
  }
  .checkBoxColumn {
    margin-bottom: 12px;
  }
  .checkBoxRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    place-items: center;
  }
  .optionPrice {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #000;
  }
  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${(color) => color.themeColor};
    color: #fff;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid ${(color) => color.themeColor};
    z-index: 0;
  }
  .ant-radio-button-wrapper {
    height: auto;
    width: 115px;
    background: #f7f7f8;
    border-radius: 8px;
    border: 2px solid #f7f7f8;
    text-align: center;
    color: #b4b4b9;
  }
  .variantsGroup {
    flex-direction: row;
    gap: 10px;
    flex-flow: wrap;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #f7f7f8;
  }
  .ant-radio-button-wrapper-checked span {
    margin-top: 2px;
  }
  .ant-radio-button-wrapper span {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .toppingLeftSide {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox {
    top: 0.45em;
    height: fit-content;
  }
  .toppingRightSide {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }
  }
`;
