import { useParams } from "react-router";

import { ComponentHeaderAuth } from "@components";
import { ModuleResetPassword } from "@modules";

export const PageResetPassword = () => {
    //page hook
    const { resetPasswordId } = useParams<{ resetPasswordId: string }>();
    //page variable
    const href = window.location.href;
    const url = new URL(href);
    const email = url.searchParams.get("email");

    return (
        <ComponentHeaderAuth>
            <ModuleResetPassword email={email || ""} resetPasswordId={resetPasswordId || ""} />
        </ComponentHeaderAuth>
    );
};
