interface IProps {
  color?: string;
}
export const OrderHistoryQueueIcon = (props: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
      <path
        d="M7.58334 2.01152V1.1341C7.83121 0.98959 8 0.723809 8 0.41666V0.20834C8 0.0931836 7.90682 0 7.79166 0L0.70834 0C0.593184 0 0.5 0.0931836 0.5 0.20834V0.41668C0.5 0.723809 0.668789 0.98959 0.91666 1.13412L0.91666 2.01152C0.91666 2.83713 1.26863 3.62732 1.88225 4.17949L2.79391 5L1.88225 5.82051C1.26863 6.37268 0.91666 7.16287 0.91666 7.98848V8.86588C0.668789 9.01041 0.5 9.27619 0.5 9.58334V9.79168C0.5 9.90682 0.593184 10 0.70834 10H7.79168C7.90684 10 8.00002 9.90682 8.00002 9.79166V9.58332C8.00002 9.27619 7.83123 9.01041 7.58336 8.86588L7.58336 7.98848C7.58336 7.16287 7.23139 6.37268 6.61777 5.82051L5.70609 5L6.61775 4.17949C7.23137 3.62732 7.58334 2.83711 7.58334 2.01152ZM6.06031 3.56018L4.80461 4.69035C4.71672 4.7693 4.66666 4.8818 4.66666 5C4.66666 5.1182 4.71672 5.23072 4.80461 5.30965L6.06031 6.43982C6.49854 6.83432 6.75 7.39889 6.75 7.98848L6.75 8.75H6.22906L4.41662 6.33342C4.33809 6.22844 4.16189 6.22844 4.08336 6.33342L2.27094 8.75H1.75V7.98848C1.75 7.39887 2.00146 6.8343 2.43971 6.4398L3.69541 5.30963C3.7833 5.23068 3.83336 5.11818 3.83336 4.99998C3.83336 4.88178 3.7833 4.76926 3.69541 4.69033L2.43971 3.56016C2.00146 3.16568 1.75 2.60111 1.75 2.01152L1.75 1.25L6.75 1.25V2.01152C6.75 2.60111 6.49854 3.16568 6.06031 3.56018Z"
        fill={props.color || "#006100"}
      />
      <path
        d="M5.68589 2.91666H2.81378C2.73138 2.91666 2.65671 2.96529 2.62335 3.04056C2.58999 3.11605 2.60401 3.20394 2.65956 3.26498L4.11016 4.60328C4.14983 4.63929 4.19989 4.65718 4.24993 4.65718C4.29997 4.65718 4.35003 4.63927 4.3897 4.60328L5.84009 3.26498C5.89563 3.20394 5.90968 3.11605 5.8763 3.04056C5.84296 2.96529 5.76829 2.91666 5.68589 2.91666Z"
        fill={props.color || "#006100"}
      />
    </svg>
  );
};
