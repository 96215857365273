import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { IResultFreeItem } from "@interfaces";
import { selectMerchant, setPromotionIDList, store } from "@redux";
import { Checkbox, Skeleton, Tabs } from "antd";
import { productAPI } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotify } from "@utils";
import { IconClose } from "../icon/FreebieDrawer/IconClose";
import {
  IGetAllPosPromotionMatchExample,
  IGetAllPosPromotionMatchNotConflictExample,
  ISelectPosPromotionByPriorityManualExample,
} from "src/Interfaces/posPromotion";
import { posPromotionApi } from "src/API/posPromotion";
import { IconPromotionDrawerHeader } from "../icon/BasketDrawer/IconPromotionDrawerHeader";

interface IProps {
  promotionsDrawer: boolean;
  handleClose: () => void;
  isApplyPromotionAuto?: boolean;
  setIsApplyPromotionAuto?: any;
  setPaymentDetails?: any;
  getPromotions?: any;
  setSelectedFreebies?: any;
  selectedFreebies?: any;
  productAddonList?: any;
  promoCode?: any;
}

export const ComponentPromotionsDrawer = (props: IProps) => {
  const {
    promotionsDrawer,
    handleClose,
    isApplyPromotionAuto,
    setIsApplyPromotionAuto,
    setPaymentDetails,
    getPromotions,
    setSelectedFreebies,
    selectedFreebies,
    productAddonList,
    promoCode,
  } = props;
  const { t } = useTranslation();
  const { error } = useNotify();
  const org = useSelector((state: any) => state.merchant);
  const cart = useSelector((state: any) => state.cart.cart);
  const promotionIDListRedux = useSelector((state: any) => state.cart.promotionIDList);
  const userInfo = store.getState().auth.userInfo;
  const dispatch = useDispatch();
  const { themeColor } = useSelector(selectMerchant);
  const { TabPane } = Tabs;
  const [tab, setTab] = useState<string>("0");
  const [posPromotionMatch, setPosPromotionMatch] = useState<any[]>([]);
  const [isLoading, setIsloading] = useState(true);
  const [posNotConflictPromotionMatch, setPosNotConflictPromotionMatch] = useState<
    IGetAllPosPromotionMatchNotConflictExample[]
  >([]);
  const [promotionRemainAll, setPromotionRemainAll] = useState<
    ISelectPosPromotionByPriorityManualExample[]
  >([]);

  const tabHandler = (key: string) => {
    setTab(key);
  };

  const getAllPosPromotionMatch = async () => {
    try {
      const get = await posPromotionApi.getAllPosPromotionMatch({
        organization_id: org._id,
        store_id: org.store_id,
        sale_channel_id: "",
        profile_id: userInfo._id || "",
        promo_code_list: promoCode || "",
        order_list: cart.concat(selectedFreebies),
      });
      if (get.data.error === "0") {
        // if (tab === "0") {
        //   setPosPromotionMatch(get.data.result2.filter((e: any) => e.group_type !== 'not_dup'));
        // } else {
        //   setPosNotConflictPromotionMatch(get.data.result2.filter((e: any) => e.group_type === 'not_dup'));
        // }
        setPosPromotionMatch(get.data.result2);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  useEffect(() => {
    getAllPosPromotionMatch();
  }, [tab]);
  useEffect(() => {
    getPromotionByPriorityManual();
  }, []);

  const generateResultPromotion = (items: any) => {
    let str = "Result: ";
    items.map((item: IResultFreeItem) => {
      if (item.name && item.free_qty) {
        str = str + `${item.name} (x${item.free_qty}) `;
      }
    });
    return str;
  };

  const getPromotionByPriorityManual = async (promotionIDList?: any) => {
    let params = formatIOrderListManual(promotionIDList);
    try {
      const get = await posPromotionApi.selectPosPromotionByPriorityManual(params);
      if (get.data.error === "0") {
        setPromotionRemainAll(get.data.result.promotion_remain_all);
        if (promotionIDList && promotionIDList.length !== 0) {
          setIsApplyPromotionAuto(false);
        }
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const formatIOrderListManual = (promotionIDList: any) => {
    let orderList: any = [...cart.filter((x: any) => !x.is_freebie)];
    selectedFreebies.map((item: any) => {
      const obj = {
        variant_id: item.variant_id ? item.variant_id.toString() : item.variant_id,
        qty: item.qty,
        note: "",
        addon_list: [],
      };
      orderList.push(obj);
    });
    const params = {
      organization_id: org._id,
      store_id: org.store_id,
      sale_channel_id: "",
      profile_id: "",
      promo_code_list: "",
      pos_promotion_id_list: promotionIDList || promotionIDListRedux,
      order_list: orderList,
    };
    return params;
  };

  const checkBoxHandler = async (
    e: any,
    promotion: string,
    isPromotionNoConflict: boolean,
    promotionDetails?: any
  ) => {
    let temp: any = [...selectedFreebies];
    await setIsApplyPromotionAuto(false);
    if (!e.target.checked) {
      dispatch(setPromotionIDList(promotionIDListRedux.filter((x: any) => x !== promotion)));
      await getPromotionByPriorityManual(promotionIDListRedux.filter((x: any) => x !== promotion));
    } else {
      dispatch(setPromotionIDList([...promotionIDListRedux, promotion]));
      await Promise.all([getPromotionByPriorityManual([...promotionIDListRedux, promotion])]);
    }
  };
  const allowedPromotionIDs = posPromotionMatch
    .filter((promotion) =>
      promotionRemainAll.some(
        (remained: any) => remained.pos_promotion_id === promotion.pos_promotion_id
      )
    )
    .map((promotion) => promotion.pos_promotion_id);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  return (
    <StyledPromotionsDrawer
      themeColor={themeColor}
      title={
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <IconPromotionDrawerHeader color={themeColor} />
              <p className="h3" style={{ margin: 0 }}>
                {t("page.promotion")}{" "}
              </p>
            </div>
            <div onClick={handleClose}>
              <IconClose />
            </div>
          </div>
          <div className="promotions">
          <div className="checkBoxCol">
            <Checkbox
              onChange={() => {
                if (!isApplyPromotionAuto) {
                  dispatch(setPromotionIDList([]));
                  getPromotionByPriorityManual();
                }
                setIsApplyPromotionAuto(!isApplyPromotionAuto);
              }}
              checked={isApplyPromotionAuto}
            >
              <p className="body1" style={{ margin: 0 }}>
                {t("page.apply_auto")}{" "}
              </p>
            </Checkbox>
          </div>
          </div>
          {/* <Tabs defaultActiveKey={"0"} onChange={tabHandler}>
            <TabPane tab={t("page.conflict")} key="0"></TabPane>
            <TabPane tab={t("page.no_conflict")} key="1"></TabPane>
          </Tabs> */}
        </>
      }
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={promotionsDrawer}
      width={"100%"}
      closable={false}
      height={"85%"}
    >
      <StyledPromotionsBodyDrawer themeColor={themeColor}>
        <div className="promotionsCol">
          {/* {tab === "0"
            ? posPromotionMatch && */}
          {posPromotionMatch &&
            posPromotionMatch.map((groupPromotion, i) => (
              <>
                {groupPromotion.pos_promotion_list.map((promotion: any, n: number) => (
                  <div className="promotions" key={i + n}>
                    <div className="checkBoxCol">
                      <Skeleton loading={isLoading} active avatar title={true} paragraph={{ rows: 4, width: [170, 170, 170, 170] }}>
                        <Checkbox
                          onChange={(e) => {
                            checkBoxHandler(e, promotion.pos_promotion_id, false, promotion);
                            console.log(promotionIDListRedux);
                          }}
                          checked={
                            promotionIDListRedux &&
                            promotionIDListRedux.some((x: any) => x === promotion.pos_promotion_id)
                          }
                          disabled={
                            promotionIDListRedux.length > 0 &&
                            promotionIDListRedux.findIndex(
                              (e: any) => e === promotion.pos_promotion_id
                            ) < 0 &&
                            promotionRemainAll.findIndex((e: any) => e.pos_promotion_id === promotion.pos_promotion_id) < 0 ? true : false
                            // !allowedPromotionIDs.includes(promotion.pos_promotion_id)
                          }
                        >
                          <div className="radioContent">
                            <p className="body1" style={{ margin: 0 }}>
                              {promotion.pos_promotion_name}
                            </p>
                            <p className="body3" style={{ margin: 0, color: "#919191" }}>
                              {t("page.condition")} : {promotion.condition_str}
                            </p>
                            <p className="body3" style={{ margin: 0, color: "#919191" }}>
                              {promotion.promotion_type === "freebie"
                                ? generateResultPromotion(promotion.result_free_item)
                                : `${t("page.result")}: Discount ${promotion.result_discount} THB`}
                            </p>
                            {promotionIDListRedux.length > 0 &&
                            promotionIDListRedux.findIndex(
                              (e: any) => e === promotion.pos_promotion_id
                            ) < 0 &&
                            promotionRemainAll.findIndex((e: any) => e.pos_promotion_id === promotion.pos_promotion_id) < 0 ? 
                              <p className="body3" style={{ margin: 0, color: "#F22F46" }}>
                                {`${t("page.disable_promotion")}`}
                              </p>
                              : null
                            }
                          </div>
                          <div className="radioImage">
                            {promotion.result_free_item.map((freeItem: any, j: any) => (
                              <img src={freeItem.image_url} key={j} />
                            ))}
                          </div>
                        </Checkbox>
                      </Skeleton>
                    </div>
                  </div>
                ))}

              </>
            ))}
        </div>
      </StyledPromotionsBodyDrawer>
    </StyledPromotionsDrawer>
  );
};
const StyledPromotionsDrawer = styled(SharedComponentDrawer) <{ themeColor: string }>`
  .ant-drawer-body {
    padding-top: 0;
    background: #fbfbfb;
    padding: 0;
  }
  .ant-tabs-bar {
    border: none;
    margin: 0;
  }
  .ant-drawer-header {
    padding-bottom: 6px;
  }
  .ant-tabs-nav {
    margin: 0 20px 0 0;
    .ant-tabs-tab-active {
      color: ${(color) => color.themeColor} !important;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0;
    color: #919191;
    font-weight: 500;
  }
  .ant-tabs-ink-bar {
    background-color: ${(color) => color.themeColor};
    height: 4px;
    border-radius: 10px;
  }
  .promotions {
    padding-top: 12px;
    .radioContent {
      text-align: left;
      margin-right: 10px;
    }
    .checkBoxCol {
      display: flex !important;
      .ant-skeleton {
        padding-right: 100px;
        position: relative;
        .ant-skeleton-header {
          position: absolute;
          right: 0;
          padding-right: 0;
          .ant-skeleton-avatar {
            width: 71px;
            height: 100px;
            border-radius: 6px;
          }
        }
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin: 0;
            height: 14px;
            border-radius: 6px;
            width: 100%!important;
          }
          .ant-skeleton-paragraph {
            margin-top:10px;
            li {
              margin-top:0;
              margin-bottom: 6px;
              height: 14px;
              border-radius: 6px;
            }
          }
        }
      }
      .ant-radio-checked::after,
      .ant-checkbox-checked::after {
        border: 1px solid ${(color) => color.themeColor};
      } 
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(color) => color.themeColor};
        border-color: ${(color) => color.themeColor};
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${(color) => color.themeColor};
      }
      .ant-checkbox-wrapper {
        margin-left: 0;
        line-height: 30px;
        display: flex;
        flex-direction: row;
      }
    }
  }
`;

const StyledPromotionsBodyDrawer = styled.div<{ themeColor: string }>`
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    width: 100%;
    button {
      margin: 0;
      width: 100%;
      height: 55px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  input[type="radio"]:checked {
    background-color: red;
  }
  .ant-radio-inner::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.7);
    width: 50%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 0.3s ease;
    background: transparent;
    border-radius: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    position: relative;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(color) => color.themeColor};
    border: 2px solid ${(color) => color.themeColor};
    transition: background-color 0.3s ease;
  }
  .ant-radio {
    width: 17px;
    height: 17px;
  }
  span.ant-radio + * {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    color: #000000;
    display: flex;
    align-self: flex-start;
  }
  .ant-radio-checked::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${(color) => color.themeColor};
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(color) => color.themeColor};
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
  }
  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${(color) => color.themeColor};
    color: #fff;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid ${(color) => color.themeColor};
    z-index: 0;
  }
  .ant-radio-button-wrapper {
    height: auto;
    width: 115px;
    background: #f7f7f8;
    border-radius: 8px;
    border: 2px solid #f7f7f8;
    text-align: center;
    color: #b4b4b9;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #f7f7f8;
  }
  .ant-radio-button-wrapper-checked span {
    margin-top: 2px;
  }
  .ant-radio-button-wrapper span {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .ant-checkbox {
    top: 0.45em;
    height: fit-content;
  }
  .selectPromotion {
    margin: 0;
    color: #919191;
    padding: 8px 24px;
  }
  .promotionsCol {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .promotions {
    background: #fff;
    padding: 24px;
    .radioContent {
      text-align: left;
      margin-right: 10px;
    }
    .checkBoxCol {
      display: flex !important;
      .ant-skeleton {
        padding-right: 100px;
        position: relative;
        .ant-skeleton-header {
          position: absolute;
          right: 0;
          padding-right: 0;
          .ant-skeleton-avatar {
            width: 71px;
            height: 100px;
            border-radius: 6px;
          }
        }
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin: 0;
            height: 14px;
            border-radius: 6px;
            width: 100%!important;
          }
          .ant-skeleton-paragraph {
            margin-top:10px;
            li {
              margin-top:0;
              margin-bottom: 6px;
              height: 14px;
              border-radius: 6px;
            }
          }
        }
      }
    }
    .radioImage {
      img {
        width: 70px;
        height: 100px;
        object-fit: cover;
      }
    }
  }
  .ant-radio-wrapper {
    margin-right: 0;
  }
`;
