import styled from "styled-components";

export const StyledOrderHistory = styled.div`
  .wholeGrayBack {
    background: #323232;
    padding-top: 16px;
    h1 {
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      margin: 0;
      margin-bottom: 23px;
    }
    .drawerStyleSection {
      border-radius: 8px 8px 0px 0px;
      background: #fff;
      height: 88vh;
    }
    .drawerHeader {
      padding: 16px;
      border-bottom: 0.5px solid #e1e1e1;
      h2 {
        font-weight: 600;
        font-size: 16px;
        color: #000;
        margin: 0;
      }
    }
    .loadMoreButton {
      display: flex;
      place-content: center;
    }
    .drawerBody {
      padding: 24px 16px 16px 16px;
      padding-bottom: 80px;
      .eachOrderRow {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .titlePriceRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
            color: #000;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            color: #000;
          }
          .orderTime {
            color: #919191;
          }
        }
        .picturesStatus {
          display: flex;
          justify-content: space-between;
          img {
            border-radius: 4px;
            width: 42px;
            height: 42px;
          }
          .discountStatus {
            display: flex;
            flex-direction: column;
            place-items: flex-end;
            small {
              padding: 0 0 4px 0;
              font-weight: 300;
              font-size: 10px;
              line-height: 16px;
              text-align: center;
              text-decoration-line: line-through;
              color: #9b9b9b;
            }
            .statusBadge {
              padding: 4px 8px;
              border-radius: 60px;
              font-weight: 400;
              font-size: 10px;
              margin: 0;
              white-space: nowrap;
            }
            .completeBadge {
              background: rgba(43, 184, 0, 0.1);
              color: #2bb800;
            }
            .pendingBadge {
              background: rgba(255, 153, 0, 0.1);
              color: #f90;
            }
            .cancelledBadge {
              background: rgba(239, 40, 28, 0.1);
              color: #ef281c;
            }
            .badgesRow {
              display: flex;
              gap: 5px;
              align-items: flex-end;
            }
            .queueBadge {
              padding: 4px 8px;
              border-radius: 60px;
              font-weight: 400;
              font-size: 10px;
              margin: 0;
              background: rgba(148, 96, 55, 0.1);
              display: flex;
              gap: 5px;
              white-space: nowrap;
              align-items: center;
              span {
                font-weight: 700;
              }
              .queueCircleIcon {
                border-radius: 50%;
                background: #946037;
                padding: 1px 3px;
                width:20px;
                height: 20px;
                svg {
                  width: 15px;
                }
              }
            }
          }
          .orderImagesRow {
            display: flex;
            align-items: center;
            gap: 8px;
            width: calc(100% - 90px);
            overflow-x: scroll;
          }
        }
      }
    }
  }
`;
