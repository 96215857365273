export const light = {
  colors: {
    background: "#ffffff",
    home_bg: "#FAFAFA",
    color: "#000000",
    normal: "#919191",
    main: "#f4394f",
    primary: "#007bff",
    info: "#1890ff",
    success: "#52c41a",
    green: "#05C755",
    processing: "#1890ff",
    error: "#f5222d",
    highlight: "#f5222d",
    warning: "#faad14",
    dark: "rgb(51,51,51)",
    text: "#000000",
    textSecondary: "rgba(145, 145, 145)",
    borderBase: "hsv(0, 0, 85%)",
    borderSplit: "rgba(0, 0, 0, 0.05)",
    red: "red",
    danger: "#f5222d",
    dark_gray: "#f1f1f1",
    card: "#ffffff",
    cutter: "#4a515c",
    white: "white",
    black: "black",
  },
};
export const dark = {
  colors: {
    background: "#000000",
    home_bg: "#000000",
    color: "#fff",
    normal: "#f1f1f1",
    main: "#f4394f",
    primary: "#007bff",
    info: "#1890ff",
    success: "#52c41a",
    green: "#05C755",
    processing: "#1890ff",
    error: "#f5222d",
    highlight: "#f5222d",
    warning: "#faad14",
    dark: "rgb(51,51,51)",
    text: "#000000",
    textSecondary: "rgba(145, 145, 145)",
    borderBase: "hsv(0, 0, 85%)",
    borderSplit: "rgba(0, 0, 0, 0.05)",
    red: "red",
    danger: "#f5222d",
    dark_gray: "#464D53",
    card: "#303437",
    cutter: "#000",
    white: "white",
    black: "black",
  },
};
export const theme = {
  colors: {
    main: "#f22f46",
    primary: "#007bff",
    info: "#c6152a",
    success: "#52c41a",
    green: "#05C755",
    processing: "#c6152a",
    error: "#f5222d",
    highlight: "#f5222d",
    warning: "#faad14",
    normal: "#d9d9d9",
    background: "#f7f7f9",
    dark: "rgb(51,51,51)",
    text: "rgba(0, 0, 0, .65)",
    textSecondary: "rgba(0,0,0,.45)",
    borderBase: "hsv(0, 0, 85%)",
    borderSplit: "rgba(0, 0, 0, 0.05)",
    red: "red",
    colorThemes: [
      {
        main: "#862929",
        sub: "#933131",
      },
      {
        main: "#854C2F",
        sub: "#985A3A",
      },
      {
        main: "#346524",
        sub: "#3C712B",
      },
      {
        main: "#DAD35E",
        sub: "#E5DD67",
      },
      {
        main: "#442334",
        sub: "#583246",
      },
      {
        main: "#16A086",
        sub: "#22AA90",
      },
      {
        main: "#2D3E50",
        sub: "#34495E",
      },
      {
        main: "#D25418",
        sub: "#DB632A",
      },
      {
        main: "#8F44AD",
        sub: "#964DB4",
      },
      {
        main: "#2A80B9",
        sub: "#358BC4",
      },
      {
        main: "#7E8C8D",
        sub: "#869696",
      },
      {
        main: "#F39C26",
        sub: "#F8AA40",
      },
      {
        main: "#CF4670",
        sub: "#D84C77",
      },
      {
        main: "#2F346C",
        sub: "#363C77",
      },
      {
        main: "#0A0909",
        sub: "#1F1F1F",
      },
    ],
  },
  heights: {
    input: "48px",
    button: "49px",
  },
  borderRadius: {
    base: "4px",
    sm: "4px",
  },
  breakPoints: {
    breakSmall: "320px",
    breakMobile: "375px",
    breakMobileLarge: "414px",
    breakMobileMax: "480px",
    breakMobileMedium: "575px",
    breakTablet: "767px",
  },
  widths: {
    wide_650: "650px",
    wide_700: "700px",
    wide_900: "900px",
    wide_1110: "1110px",
  },
  family: {
    font: `"Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    code: `"Anonymous Pro", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace`,
  },
  menu: {
    inlineTopLevelItemHeight: "36px",
    itemHeight: "36px",
  },
  header: {
    background: "#ffffff",
    height: "60px",
  },
  footer: {
    height: "70px",
  },
  card: {
    paddingBase: "16px",
    paddingWider: "16px",
  },
};
