import { FormikErrors, FormikTouched } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import moment from "moment";

import { IHandleBlur, IUserSetting, IHandleChange } from "@interfaces";
import { SharedButton, SharedInput } from "@components";
import { enumUseSetting, enumValidation } from "@configs";
import { ModulesTelInput } from "@modules";
import { theme } from "@utils";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";

interface IProps {
    values: IUserSetting;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<IUserSetting>;
    touched: FormikTouched<IUserSetting>;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    title: enumUseSetting;
    setFieldTouched: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IUserSetting>>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IUserSetting>>;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
    errorPhone: string;
    handleChangeDate: (date: moment.Moment | null, dateString: string) => void;
}

export const ComponentUserSettingSave = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    //page props
    const {
        values,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        title,
        setFieldTouched,
        errorPhone,
        handleChangeDate,
        setFieldValue,
        setError,
    } = props;
    //redux state
    const { userInfo } = useSelector(selectAuth);

    const render = () => {
        switch (title) {
            case enumUseSetting.FULL_NAME:
                return t("page.name");

            case enumUseSetting.PHONE_NUMBER:
                return t("page.phone_number");

            case enumUseSetting.EMAIL:
                return t("page.email");

            case enumUseSetting.DATE_OF_BIRTH:
                return t("page.date_of_birth");

            default:
                return <></>;
        }
    };

    const renderName = () => {
        switch (title) {
            case enumUseSetting.FULL_NAME:
                return enumUseSetting.FULL_NAME;

            case enumUseSetting.PHONE_NUMBER:
                return enumUseSetting.PHONE_NUMBER;

            case enumUseSetting.EMAIL:
                return enumUseSetting.EMAIL;

            default:
                return "";
        }
    };

    const disableDate = (current: moment.Moment | null | undefined) => {
        const maxDate = moment(new Date(Date.now()));
        if (current) {
            return current.isAfter(maxDate);
        }
        return false;
    };

    return (
        <StyledSettingSave>
            {title === enumUseSetting.FULL_NAME && (
                <div className="length">
                    {values[title]?.length}/{enumValidation.MAX_LENGTH_INPUT}
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="title">{render()}</div>
                {title !== enumUseSetting.DATE_OF_BIRTH &&
                    (title === enumUseSetting.PHONE_NUMBER ? (
                        <ModulesTelInput
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values[title] || ""}
                            errorPhone={errorPhone}
                            setError={setError}
                        />
                    ) : (
                        <StyledSharedInput
                            name={renderName()}
                            value={values[title]}
                            onBlur={handleBlur}
                            onChange={(e: React.ChangeEvent<any>) => {
                                const value: string = e.target.value;
                                if (value[value.length - 2] && value.trim()) {
                                    setFieldValue(renderName(), value);
                                } else {
                                    setFieldValue(renderName(), value.trim());
                                }
                            }}
                            errors={errors[title]}
                            touched={touched[title]}
                        />
                    ))}

                {title === enumUseSetting.DATE_OF_BIRTH && (
                    <DatePicker
                        value={moment(values[title] || undefined)}
                        onChange={handleChangeDate}
                        disabledDate={disableDate}
                    />
                )}

                <StyledSharedButton
                    size="large"
                    bg_color={theme.colors.green}
                    disabled={isSubmitting}
                    type="submit"
                >
                    {title === enumUseSetting.EMAIL ? (
                        userInfo?.emailVerified ? (
                            <>{t("page.save")}</>
                        ) : (
                            <>{t("page.verify_email")}</>
                        )
                    ) : title === enumUseSetting.PHONE_NUMBER ? (
                        userInfo?.phoneVerified ? (
                            <>{t("page.save")}</>
                        ) : (
                            <>{t("page.verify_phone")}</>
                        )
                    ) : (
                        <>{t("page.save")}</>
                    )}
                </StyledSharedButton>
            </form>
        </StyledSettingSave>
    );
};

const StyledSharedInput = styled(SharedInput)`
    width: 90%;
    margin: 0 auto;
`;

const StyledSettingSave = styled.div`
    text-align: center;
    padding-top: 24px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #707070;
        margin-bottom: 12px;
    }
    .allow-dropdown.intl-tel-input {
        width: 90%;
    }
    .intl-tel-input.allow-dropdown input[type="text"],
    .intl-tel-input.allow-dropdown input[type="tel"] {
        height: 48px !important;
        outline: none;
        border: none;
        background-color: #fff;
        width: 100% !important;
        font-size: 16px;
        color: black;
        box-shadow: none !important;
        font-weight: 500;
    }
    .ant-calendar-picker {
        width: 90%;
        input {
            box-shadow: none !important;
            border-color: transparent !important;
        }
        input:focus {
            border-color: transparent !important;
        }
    }
    .ant-calendar-picker-input.ant-input {
        height: 48px;
        font-size: 16px;
    }
    .ant-input:focus {
        border-color: initial !important;
        box-shadow: initial;
    }
    .custom-error {
        margin-left: 5%;
    }
    .selected-dial-code {
        padding-left: 0 !important;
    }
    .selected-flag {
        display: flex !important;
    }
    .intl-tel-input .country-list {
        width: 100%;
    }
    .intl-tel-input input {
        border-radius: 6px;
    }

    .length {
        position: absolute;
        right: 5%;
        top: 24px;
    }
    .ant-input {
        background-color: #fff !important;
    }
`;

const StyledSharedButton = styled(SharedButton)`
    width: 90%;
    position: absolute;
    bottom: 48px;
    left: 5%;
    font-size: 16px;
    font-weight: 600;
`;
