import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { BLANK_IMAGE_URL } from "@configs";
import { IMembershipTier, IReward } from "@interfaces";
import { IconStarSmall } from "@components";
import { selectAuth, selectMerchant } from "@redux";

interface IProps {
    data: IReward;
    handleClick: (value: IReward, check: boolean) => void;
    lastItem: boolean;
}

export const RewardItem = (props: IProps) => {
    //hook
    const { t } = useTranslation();
    //page props
    const { data, lastItem, handleClick } = props;
    //redux state
    const { themeColor, membershipTiers } = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    //page variable
    const currentMemberShiptTier = userInfo?.memberTier;

    const checkIfNotIncludeRank = () => {
        const arr = getArrayMemberTier();
        if (arr.length) {
            const index = arr.findIndex((item) => item._id === currentMemberShiptTier?._id);
            if (index === -1) {
                return arr.reduce(
                    (pre, item, index) =>
                        index < arr.length - 1
                            ? `${pre} ${item.membershipName}, `
                            : `${pre} ${item.membershipName}`,
                    ""
                );
            } else return false;
        } else return false;
    };

    const getArrayMemberTier = () => {
        const arr: IMembershipTier[] = [];
        membershipTiers?.forEach((memberTier) => {
            if (data.levelIds?.includes(memberTier._id)) arr.push(memberTier);
        });
        return arr;
    };

    return (
        <StyledContainer slot="container-end" lastItem={lastItem}>
            <div className="item-layout">
                <div className="image">
                    <div className="start">
                        <IconStarSmall color={themeColor} />
                        <span>{data.point}</span>
                    </div>
                    <img
                        src={data.imageUrl[0] || BLANK_IMAGE_URL}
                        onClick={() => handleClick(data, Boolean(checkIfNotIncludeRank()))}
                    />

                    {checkIfNotIncludeRank() ? (
                        <div className="silver_member">
                            <div className="line"></div>
                            <div className="text">
                                {t("page.MEMBER", { text: checkIfNotIncludeRank() })}
                            </div>

                            <div className="line"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    className="desc"
                    onClick={() => handleClick(data, Boolean(checkIfNotIncludeRank()))}
                >
                    <p>{data.name}</p>
                </div>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{ lastItem: boolean }>`
    padding-right: ${(props) => (props.lastItem ? "12px" : "unset")};
    width: ${(props) => (props.lastItem ? "calc(209px + 12px)" : "209px")};
    height: 100%;
    /* margin: 0 24px 28px 0; */

    /* box-shadow: rgb(233 233 233) 0 0 14px 2px; */

    .item-layout {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        background-color: #fff;
    }
    .starSmall {
        position: absolute;
        top: 12px;
        left: 12px;
        padding: 4px 6px;
        background-color: #fff;
        z-index: 10;
        border-radius: 16px;
        span {
            display: inline-block;
            margin: 0 6px;
            color: #000000;

            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        }
    }
    .image {
        width: 100%;
        height: 209px;
        position: relative;
        .start {
            position: absolute;
            display: flex;
            align-items: center;
            top: 12px;
            left: 12px;
            padding: 0 10px 0;
            background-color: #fff;
            border-radius: 16px;
            z-index: 10;
            min-width: 45px;
            span {
                display: inline-block;
                margin-left: 4px;

                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: #000000;
                top: 1px;
                position: relative;
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            cursor: pointer;
        }
        .silver_member {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: none;
            .line {
                width: 23px;
                height: 1px;
                background-color: #fff;
            }
            .text {
                padding: 0 12px;
                flex: 1;
                color: #fff;
                text-transform: uppercase;
                max-width: 70%;
                word-break: break-all;

                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                text-align: center;
            }
        }
    }

    .desc {
        flex: 1;
        position: relative;
        p {
            font-size: 14px;
            margin-bottom: 16px;
            line-height: 1.3em;
            color: black;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 10px 8px 0 8px;
            cursor: pointer;
            font-style: normal;
            font-weight: 100;
            white-space: pre-wrap;
            max-height: 47px;
            height: 100%;
            word-break: break-word;
        }
    }
`;
