import { IPagination, IReceive } from "@interfaces";
import axiosClient from "./axiosClient";

export const benefitApi = {
    // getListBenefit: (params: IPagination) => {
    //     const url = "/histories?type=BENEFIT";
    //     return axiosClient.get(url, { params });
    // },
    getListBenefit: (params: IPagination) => {
        const url = "/benefits/client";
        return axiosClient.get(url, { params });
    },
    getDetailBenefitById: (id: string) => {
        const url = `/histories/${id}?type=BENEFIT`;
        return axiosClient.get(url);
    },
    // redeemed: (values: IReceive) => {
    //     const url = "/histories/redeemed";
    //     return axiosClient.post(url, values);
    // },
    redeemed: (values: IReceive) => {
        const url = "/benefits/redeemed";
        return axiosClient.post(url, values);
    },
};
