import { IGetCategorys, IGetPartnerByOrganizationId, IGetProductCategorys } from "src/Interfaces/organization";
import axiosClient from "./axiosClientStore";

export const organizationAPI = {
  getCategorys: (params: IGetCategorys) => {
    const url = "organization/getCategorys";
    return axiosClient.get(url, { params });
  },
  getProductCategorys: (params: IGetProductCategorys) => {
    const url = "organization/getProductCategorys";
    return axiosClient.get(url, { params });
  },
  getPartnerByOrganizationId: (params: IGetPartnerByOrganizationId) => {
    const url = "organization/getPartnerByOrganizationId";
    return axiosClient.get(url, { params });
  },
};
