interface IProps {
    color: string;
}

export const IConCircle = (props: IProps) => {
    const { color = "#970000" } = props;
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 14.25C11.2279 14.25 14.25 11.2279 14.25 7.5C14.25 3.77208 11.2279 0.75 7.5 0.75C3.77208 0.75 0.75 3.77208 0.75 7.5C0.75 11.2279 3.77208 14.25 7.5 14.25Z"
                stroke={color}
                strokeWidth="1.5"
            />
        </svg>
    );
};
