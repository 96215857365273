import axios from "axios";
import querystring from "querystring";

import { store } from "@redux";
import { LINE_REDIRECT_URI } from "@configs";

const lineHeader = (baseURL: string) => ({
    baseURL,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    paramsSerializer: (params: any) => querystring.stringify(params),
});

export const LineApi = (baseUrl: string) => {
    //hook state
    const axiosClient = axios.create(lineHeader(baseUrl));
    const lineIntegration = store.getState().merchant.lineIntegration;

    return {
        login: (code: string) => {
            const url = "/token";
            //WHAT: login body
            const grantType = "authorization_code";
            // const clientId = "1656727135";
            const clientId = lineIntegration?.lineLoginApi?.channelID || "";
            // const clientSecret = "18752f7b4229f30c61b29dba473aa20e";
            const clientSecret = lineIntegration?.lineLoginApi?.channelSecret || "";
            //WHAT: setup xxx-form-data body
            const accessTokenBody = new URLSearchParams();
            accessTokenBody.append("grant_type", grantType);
            accessTokenBody.append("code", code);
            accessTokenBody.append("redirect_uri", LINE_REDIRECT_URI);
            accessTokenBody.append("client_id", clientId);
            accessTokenBody.append("client_secret", clientSecret);
            return axiosClient.post(url, accessTokenBody);
        },
    };
};
