export default {
    delete: {
        success: "ลบสำเร็จ",
        fail: "ลบไม่สำเร็จ",
    },
    duplicate: {
        success: "คัดลอกสำเร็จ",
        fail: "คัดลอกไม่สำเร็จ",
    },
    create: {
        success: "สร้างสำเร็จ",
        fail: "สร้างไม่สำเร็จ",
    },
    update: {
        success: "อัปเดทสำเร็จ",
        fail: "อัปเดทไม่สำเร็จ",
    },
    form_validate: {
        warning: "ข้อมูลในบางช่องไม่ถูกต้อง กรุณาตรวจสอบ!",
    },
    reset_pass: {
        fail: "รีเซ็ทรหัสผ่านไม่สำเร็จ!",
        success: "รีเซ็ทรหัสผ่านสำเร็จ!",
    },
    sign_up: {
        success: "สมัครสมาชิกสำเร็จ!",
        fail: "สมัครสมาชิกไม่สำเร็จ!",
    },
    upload_image: {
        success: "อัปโหลดรูปภาพสำเร็จ!",
        fail: "อัปโหลดรูปภาพไม่สำเร็จ!",
    },
    login: {
        success: "ลงชื่อเข้าใช้งานสำเร็จ!",
        fail: "ลงชื่อเข้าใช้งานไม่สำเร็จ!",
    },
    merge: {
        success: "Merge {{number}} สำเร็จ! ",
    },
    use_coupon: {
        success: "ใช้ coupon สำเร็จ!",
        fail: "ใช้ coupon ไม่สำเร็จ!",
    },
    received: {
        success: "รับ coupon สำเร็จ!",
        fail: "รับ coupon ไม่สำเร็จ!",
    },
    image: {
        max_size: "ไฟล์รูปภาพไม่ควรเกิน 10MB",
    },
    verify_email: {
        success: "ยืนยันอีเมลสำเร็จ!",
        fail: "ยืนยันอีเมลไม่สำเร็จ!",
    },
    verify_phone: {
        success: "ยืนยันเบอร์โทรสำเร็จ!",
        fail: "ยืนยันเบอร์โทรไม่สำเร็จ!",
    },
    email_send: "ส่งอีเมล",
    phone_send: "ส่งเบอร์โทร",
    email_correct: "อีเมลถูกต้อง",
    phone_correct: "เบอร์โทรถูกต้อง",
    upload_image_big_size: "ขนาดรูปภาพใหญ่เกินไป",
    delete_confirm: "คุณแน่ใจว่าจะลบ {{name}}?",
    exist_email: "อีเมลนี้ถูกใช้งานแล้ว!",
    not_found: "รายการ {{name}} ไม่สามารถค้นหาได้",
    delete_title: "คุณแน่ใจใช่ไหม?",
    try_again_text: "มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง",
    invalid: "หน้าไม่ถูกต้อง",
    not_permission: "คุณไม่มีสิทธิ์เข้าถึงการใช้งาน",
    error: "มีข้อผิดพลาดเกิดขึ้นที่ระบุไม่ได้ กรุณาลองใหม่อีกครั้ง",
    you_need_login: "คุณต้องลงชื่อเข้าใช้งานเพื่อดำเนินการต่อไป",
    forbidden: "คุณไม่มีสิทธิ์เข้าถึงการใช้งาน",
    copied: "คัดลอกสำเร็จ",
    export_file_fail: "นำไฟล์ออกไม่สำเร็จ",
    sign_failed: "สัญญานขัดข้อง",
    page_404: "ขออภัย หน้าที่คุณเปิดอยู่ไม่สามารถใช้งานได้ฃ",
    update_link_line_success: "อัปเดทลิงค์ Line สำเร็จ!",
};
