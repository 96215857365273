import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ComponentHeaderAuth, IConClose, StyledContainerSocial, StyledSignUp } from "@components";
import { selectAuth, selectMerchant } from "@redux";
import { ModuleEmail, ModuleSocial, ModuleTel } from "@modules";
import { enumExternalMethod } from "@configs";

export const PageSignUpFrom = () => {
    //page hooks

    const { t } = useTranslation();

    //redux states
    const { externalLogin } = useSelector(selectAuth);

    const { name } = useSelector(selectMerchant);
    //states
    const [isTerm, setIsTerm] = useState<boolean>(false);

    // useEffect(() => {
    //     if (!externalLogin) {
    //         // history.push(PATH_SIGN_UP);
    //         console.log("go here");
    //     } else console.log("tai day");
    // }, []);

    const handleChangeTerm = (e: boolean) => {
        setIsTerm(e);
    };

    const render = () => {
        switch (externalLogin) {
            case enumExternalMethod.EMAIL:
                return <ModuleEmail handleChangeTerm={handleChangeTerm} showTerm={isTerm} />;

            case enumExternalMethod.FACEBOOK:
                return <ModuleSocial handleChangeTerm={handleChangeTerm} showTerm={isTerm} />;

            case enumExternalMethod.GOOGLE:
                return <ModuleSocial handleChangeTerm={handleChangeTerm} showTerm={isTerm} />;

            case enumExternalMethod.LINE:
                return <ModuleSocial handleChangeTerm={handleChangeTerm} showTerm={isTerm} />;

            case enumExternalMethod.TEL:
                return <ModuleTel handleChangeTerm={handleChangeTerm} showTerm={isTerm} />;

            default:
                break;
        }
    };

    return (
        <ComponentHeaderAuth>
            <StyledSignUp>
                <StyledContainerSocial>
                    <div className="title">
                        {isTerm ? (
                            <>
                                <div>{name + t("page.term_and_conditions_shop")}</div>
                                <div className="btn-close" onClick={() => handleChangeTerm(false)}>
                                    <IConClose width={18} />
                                </div>
                            </>
                        ) : (
                            <div>
                                {" "}
                                {t(
                                    "page.fill_in_the_information_and_receive_special_benefits"
                                )}{" "}
                            </div>
                        )}
                    </div>
                </StyledContainerSocial>
                {render()}
            </StyledSignUp>
        </ComponentHeaderAuth>
    );
};
