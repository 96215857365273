import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IMembershipTier } from "@interfaces";
import { IConBecomeStar, IConCircle, IConClose } from "@components";
import { selectMerchant } from "@redux";

interface IProps {
    membershipTier: IMembershipTier;
    handleClose: () => void;
}

export const ComponentCurrentPoint = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //page props
    const { membershipTier, handleClose } = props;
    const { themeColor } = useSelector(selectMerchant);

    return (
        <StyledCurrentPoint borderBottom={!!membershipTier?.benefits?.length}>
            <div className="content_currentPoint">
                <div className="close" onClick={handleClose}>
                    <IConClose width={18} />
                </div>
                <div className="title">
                    <div className="image">
                        <IConBecomeStar size={24} color={themeColor} />
                    </div>
                    <div className="member">
                        {membershipTier.membershipName} {t("page._member")}
                    </div>
                </div>
                <div className="baht_spent">
                    {membershipTier.bahtSpent}฿ {t("page._spent")} = {membershipTier.points}{" "}
                    {t("page._point")}
                </div>
                {membershipTier?.benefits?.length !== 0 && (
                    <div className="benefits">
                        {membershipTier?.benefits?.map((benefit) => {
                            return (
                                <>
                                    <div className="circle">
                                        <div className="icon-">
                                            <IConCircle color={themeColor} />
                                        </div>
                                        <span>{benefit}</span>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                )}
            </div>
        </StyledCurrentPoint>
    );
};

const StyledCurrentPoint = styled.div<{ borderBottom: boolean }>`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    background: rgba(0, 0, 0, 0.5);
    .content_currentPoint {
        background-color: #fff;
        padding: 28px;
        border-radius: 8px;
        width: calc(100% - 80px);
        max-height: 90%;
        overflow: auto;
        position: relative;
        ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .title {
        display: flex;
        align-items: center;
        .image {
            width: 28px;
            margin-right: 8px;
        }
        .member {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #000000;
        }
    }
    .baht_spent {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #7e7e7e;
        padding: 8px 0 16px 0;
        ${(p) => {
            if (p.borderBottom) return "border-bottom: 1px solid rgba(112, 112, 112, 0.31);";
        }}
    }
    .benefits {
        margin-top: 16px;
        .circle {
            display: flex;

            margin-top: 20px;

            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5rem;
            color: #000000;
            span {
                display: inline-block;
                margin-left: 12px;
                line-height: 20px;
            }
        }
    }
`;
