import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useTranslation } from "react-i18next";
import React from "react";

import { RewardItem } from "./Item";
import { IDataReward, IReward } from "@interfaces";
// import { useWindowDimensions } from "@utils";

SwiperCore.use([Navigation]);

interface IProps {
    data: IDataReward[];
    handleClick: (value: IReward, check: boolean) => void;
}

export const ComponentRewardList = (props: IProps) => {
    //hook
    const { t } = useTranslation();
    //page props
    const { data, handleClick } = props;

    return (
        <StyledContainer>
            {data.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="wrapper">
                            <div className="money">
                                {item.point} {item.point > 1 ? t("page._points") : t("page._point")}
                            </div>
                            <Swiper slidesPerView={"auto"} spaceBetween={16}>
                                {item.reward.map((values, index) => (
                                    <SwiperSlide key={index}>
                                        <RewardItem
                                            data={values}
                                            handleClick={handleClick}
                                            lastItem={index === item.reward.length - 1}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </React.Fragment>
                );
            })}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    padding: 25px 0;
    position: relative;
    .wrapper {
        margin: 0 0 24px 12px;
    }
    .money {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.5rem;
        color: #a7a7a7;
        margin-bottom: 8px;
        word-spacing: -1px;
    }
    .list-reward {
        display: flex;
    }
    .swiper-slide {
        background: none;
        height: unset;
        width: auto !important;
    }
`;
