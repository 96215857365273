import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import OtpInput from "react-otp-input";

import { IConReSend, SharedButton, StyledSignUp } from "@components";
import { selectMerchant } from "@redux";
import { TIME_RESEND } from "@configs";
import { useWindowDimensions } from "@utils";

interface IProps {
    handleChangeOtp: (values: string) => void;
    handleVerifyOtp: () => void;
    otp: string;
    handleResend: () => void;
    timeResend: number;
}

export const ComponentSignUpOTP = (props: IProps) => {
    //page props
    const { handleChangeOtp, otp, handleVerifyOtp, handleResend, timeResend } = props;
    //page hook
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    //page variable
    const merchant = useSelector(selectMerchant);
    const margin = width <= 380 ? (width <= 340 ? "0.1rem" : "0.3rem") : "0.5rem";

    const checkWidth = width < 380 ? (width < 350 ? "2.3rem" : "2.5rem") : "3rem";
    return (
        <StyledSignUp>
            <StyledContainer>
                <div className="title">{t("page.please_enter_OTP")}</div>
                <OtpInput
                    numInputs={6}
                    inputStyle={{
                        width: checkWidth,
                        height: "3rem",
                        margin: `1rem ${margin}`,
                        fontSize: "2rem",
                        borderRadius: "4px",
                        border: "none",
                        background: "#f8f8f8",
                    }}
                    containerStyle={{
                        justifyContent: "center",
                        flexWrap: "wrap",
                        margin: "5rem 0 5rem 0",
                    }}
                    isInputNum
                    onChange={handleChangeOtp}
                    value={otp}
                />
                <div className="resend">
                    {timeResend === TIME_RESEND ? <IConReSend /> : timeResend}
                    <span onClick={handleResend}>{t("page.resend_OTP")}</span>
                </div>
                <StyledButton size="large" bg_color={merchant.themeColor} onClick={handleVerifyOtp}>
                    {t("page.submit")}
                </StyledButton>
            </StyledContainer>
        </StyledSignUp>
    );
};

const StyledButton = styled(SharedButton)`
    margin-top: 16px;
`;

const StyledContainer = styled.div`
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 46px;
    }
    .resend {
        cursor: pointer;
        span {
            display: inline-block;
            margin-left: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #676767;
            position: relative;
            top: 2px;
        }
    }
`;
