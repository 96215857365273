import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useNotify } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant, store } from "@redux";
import { StyledSubmitButton } from "../shared";
import "../styles/Swiper.css";
import { Checkbox, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { couponApi, posPromotionApi } from "@api";

interface IProps {
  applyCouponDrawer: boolean;
  handleClose: () => void;
  setPromoCode?: any;
  promoCode?: string;
}

export const ComponentApplyCouponDrawer = (props: IProps) => {
  const {
    applyCouponDrawer,
    handleClose,
    setPromoCode,
    promoCode,
  } = props;
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const dispatch = useDispatch();
  const org = useSelector((state: any) => state.merchant);
  const cart = useSelector((state: any) => state.cart.cart);
  const userInfo = store.getState().auth.userInfo;
  const { themeColor } = useSelector(selectMerchant);
  const [listClientPosCoupon, setListClientPosCoupon] = useState<any[]>([]);
  const [listAllPosPromotionMatch, setListAllPosPromotionMatch] = useState<any[]>([]);
  const [selectCoupon, setSelectCoupon] = useState('');
  const [isLoading, setIsloading] = useState(true);


  useEffect(() => {
    getClientPosCoupon();
    getAllPosPromotionMatchTypePromoCodeOnly();
    setSelectCoupon(promoCode || '');
  }, []);

  const getClientPosCoupon = async () => {
    try {
      if (userInfo) {
        const params = {
          merchantId: userInfo.merchantId,
          userId: userInfo.contact.userId
        };
        const response = await couponApi.getClientPosCoupon(params);
        if (response.data && response.data.length > 0) {
          setListClientPosCoupon(response.data);
        }
      } else {
        error('user info is empty.');
      }
    } catch (error: any) {
      error(error.message);
    }
  };

  const getAllPosPromotionMatchTypePromoCodeOnly = async () => {
    try {
      if (userInfo) {
        const orderList: any = [...cart.filter((x: any) => !x.is_freebie)];
        const params = {
          organization_id: org._id,
          store_id: org.store_id,
          profile_id: userInfo._id,
          order_list: orderList,
          partner_id: null
        };
        const response = await posPromotionApi.getAllPosPromotionMatchTypePromoCodeOnly(params);
        if (response.data.error === '0') {
          setListAllPosPromotionMatch(response.data.result);
        } else {
          error(response.data.msg);
        }

      } else {
        error('user info is empty.');
      }
    } catch (error: any) {
      error(error.message);
    }
  };

  const handleApplyGiftCard = () => {
    setPromoCode(selectCoupon);
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  const loadingsComponent = (
    <StyledSkeleton>
      <Skeleton loading={true} active avatar title={true} paragraph={{ rows: 1, width: [130] }}></Skeleton>
      <Skeleton loading={true} active avatar title={true} paragraph={{ rows: 1, width: [130] }}></Skeleton>
      <Skeleton loading={true} active avatar title={true} paragraph={{ rows: 1, width: [130] }}></Skeleton>
    </StyledSkeleton>
  );

  return (
    <StyledDrawer
      title={t("page.use_coupon")}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={applyCouponDrawer}
      width={"100%"}
      closable={true}
      height={"80%"}
      className={"drawer"}
    >
      <StyledApplyCard themeColor={themeColor}>
        <div className="wrapper-result-promotion">
          {isLoading === false ?
            <>
              {listClientPosCoupon && listClientPosCoupon.map((coupon: any, index: number) => (
                <>
                  {listAllPosPromotionMatch.length > 0 && listAllPosPromotionMatch.findIndex(promo => promo.pos_promotion_id === coupon.alienList.promotion_pos_id) > -1 ?
                    <div className="result-promotion" key={index}>
                      <div className="result-img">
                        <img src={coupon.imageUrl && coupon.imageUrl.length > 0 ? coupon.imageUrl[0] : ''} alt="" />
                      </div>
                      <div className="result-detail">
                        <h2 style={{ marginBottom: 0 }}>{coupon.name || ''}</h2>
                        <p style={{ color: '#919191', fontSize: 10, marginBottom: 0 }}>{coupon.guideline || '-'}</p>
                      </div>
                      <div className="result-checklist">
                        <Checkbox
                          onChange={() => {
                            setSelectCoupon(coupon.alienList.alienCode);
                          }}
                          checked={coupon.alienList && coupon.alienList.alienCode ? coupon.alienList.alienCode === selectCoupon : false}
                        />
                      </div>
                    </div>
                    :
                    <div className="result-promotion" key={index}>
                      <div className="result-img">
                        <img src={coupon.imageUrl && coupon.imageUrl.length > 0 ? coupon.imageUrl[0] : ''} alt="" />
                      </div>
                      <div className="result-detail">
                        <h2 style={{ marginBottom: 0, color: '#A5A5A5' }}>{coupon.name || ''}</h2>
                        <p style={{ color: '#919191', fontSize: 10, marginBottom: 0 }}>{coupon.guideline || '-'}</p>
                      </div>
                      <div className="result-checklist">
                        <Checkbox
                          onChange={() => {
                            // setSelectCoupon(coupon.alienList.alienCode);
                          }}
                          checked={false}
                          disabled={true}
                        />
                      </div>
                    </div>
                  }
                </>
              ))}
            </>
            :
            loadingsComponent
          }

        </div>
        <StyledSubmitButton
          type="red"
          text={<span>{t("page.confirm_payment")}</span>}
          className="subBtn"
          onClick={() => {
            handleApplyGiftCard();
          }}
        />
      </StyledApplyCard>
    </StyledDrawer>
  );
};

const StyledSkeleton = styled.div`
  .ant-skeleton {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    .ant-skeleton-header {
      .ant-skeleton-avatar {
        width: 42px;
        height: 42px;
        border-radius: 6px;
      }
    }
    .ant-skeleton-content {
      .ant-skeleton-title {
        margin: 0;
        margin-bottom: 5px;
        height: 14px;
        border-radius: 6px;
        width: 130px!important;
        margin-top:5px;
      }
      .ant-skeleton-paragraph {
        margin-top: 0;
        li {
          height: 14px;
          border-radius: 6px;
        }
      }
    }
}
`;

const StyledDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    height: auto !important;
    margin-bottom: 0 !important;
  }
`;

const StyledApplyCard = styled.div<{ themeColor: string }>`
  .swiper-container {
    padding-bottom: 45px;
  }
  .cardBox {
    padding: 20px;
  }
  .slide {
    border-radius: 12px;
    background: #fc5064;
    position: relative;
    display: flex;
    flex-direction: column;
    place-items: baseline;
    img {
      position: absolute;
      right: 0;
      width: 150px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      top: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: ${(color) => color.themeColor};
  }
  .cardTitle {
    border-radius: 30px;
    background: rgba(242, 47, 70, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
  }
  .amount {
    margin-top: 40px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
    width: fit-content;
  }
  .amountInput {
    height: 60px;
  }
  .subBtn {
    width: 100%;
    border-radius: 74px;
    background: ${(color) => color.themeColor};
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    width: -webkit-fill-available;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .selectAmountSection {
    .selectAmountTitle {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .selectAmountSubtitle {
      color: #919191;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .amountCardRows {
    margin-top: 10px;
    .available svg .main {
      fill: #f22f46;
      opacity: 1;
    }
    .used {
      span {
        transform: rotate(-15deg);
      }
    }
    .couponIcon {
      position: relative;
      display: flex;
      place-content: center;
      place-items: center;
      span {
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .mainContainer {
    margin-bottom: 40px;
  }
  .wrapper-result-promotion {
    padding-top: 30px;
    position: relative;
    
    &:after {
      content: '';
      position:absolute;
      top:0;
      left:-20px;
      width: calc(100% + 40px);
      height:10px;
      background-color: #FAFAFA;
    }
    .ant-collapse {
      border:none;
      background-color: #fff;
      .ant-collapse-item {
        border:none;
        .ant-collapse-header {
          padding:0;
          .ant-collapse-arrow {
            display:none;
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
      .ant-collapse-content-box {
        padding:0;
      }
      .ant-input {
        background-color: #fafafa;
      }
    }
    .result-promotion {
      position: relative;
      padding-left: 50px;
      margin-bottom: 20px;
      padding-right: 70px;
      .result-checklist {
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        right: 5px;
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: ${(color) => color.themeColor};
          }
        }
        .ant-checkbox-inner {
          border-radius: 100%;
          border-color: ${(color) => color.themeColor};
        }
      }
      .result-img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 42px;
        height: 42px;
        overflow: hidden;
        img {
          width:100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .result-detail {
        h2 {
          font-size:16px;
          line-height: normal;
          font-weight: 600;
          color: #000;
          span {
            margin-left: 5px;
            font-weight: 600;
          }
        }
        h3 {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: #919191;
        }
      }
    }
  }
`;
