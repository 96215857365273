import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant, store } from "@redux";
import { SharedInput, StyledSubmitButton } from "../shared";
import { Swiper, SwiperSlide } from "swiper/react";
import "../styles/Swiper.css";
import { Col, Row, Skeleton } from "antd";
import { CouponIcon } from "../icon/CardDrawer";
import { SelectedCouponIcon } from "../icon/CardDrawer/SelectedCouponIcon";
import { useEffect, useState } from "react";
import { cardAPI } from "@api";
import { ICreateProductOrderPOSExample } from "@interfaces";
import { IconFreeaeCard } from "../icon/BasketDrawer/IconLock";

interface IProps {
  applyCardDrawer: boolean;
  handleClose: () => void;
  paymentDetails?: ICreateProductOrderPOSExample;
  getPaymentDetails: () => void;
  setDiscountGiftCard?: any;
  setDiscountGiftCardPromotion?: any;
  setGiftCardDetail?: any;
  setGiftCardPromotionId?: any;
}

export const ComponentApplyCardDrawer = (props: IProps) => {
  const history = useHistory();
  const {
    applyCardDrawer,
    handleClose,
    paymentDetails,
    setDiscountGiftCard,
    setDiscountGiftCardPromotion,
    setGiftCardDetail,
    getPaymentDetails,
    setGiftCardPromotionId
  } = props;
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const dispatch = useDispatch();
  const orgID = store.getState().auth.userInfo?.merchant?._id;
  const userInfo = store.getState().auth.userInfo;
  const { themeColor } = useSelector(selectMerchant);
  const [selected, setSelected] = useState<number[]>([]);
  const [listGiftCard, setListGiftCard] = useState<any[]>([]);
  const [selectGiftCard, setSelectGiftCard] = useState<any>({});
  const [txtInputAmountCard, setTxtInputAmountCard] = useState<string>("");
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getMemberCardUser();
  }, []);

  const getMemberCardUser = async () => {
    try {
      if (userInfo && userInfo._id) {
        const response = await cardAPI.getMemberCardUser({ userId: userInfo._id });
        if (response.data && response.data.length > 0) {
          const giftCards = response.data.filter(
            (card: any) => !card.isAllmember && !card.isPhysical
          );
          // giftCards[1].isUse = false; // for test
          setListGiftCard(giftCards);
          setSelectGiftCard(giftCards.length > 0 ? giftCards[0] : {});
          setTxtInputAmountCard(giftCards.length > 0 && giftCards[0].fixUsePertime ? giftCards[0].fixUsePertime : "");
        }
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const handleApplyGiftCard = () => {
    let discountGiftCardPromotion = 0;
    let giftCardPromotionId = "";
    if (
      selectGiftCard.isPromotion === true &&
      selectGiftCard.promotion &&
      selectGiftCard.promotion.length > 0 &&
      selectGiftCard.promotion[0].isUse === true
    ) {
      if (selectGiftCard.promotion[0].unit === "percent") {
        const grandTotal = paymentDetails?.grand_total || 0;
        const maxSpend = Number(selectGiftCard.promotion[0].maxSpend) || 0;
        const discountPromo =
          (grandTotal * (Number(selectGiftCard.promotion[0].promotionBenefit) || 0)) / 100;
        discountGiftCardPromotion = discountPromo > maxSpend ? maxSpend : discountPromo;
      } else {
        discountGiftCardPromotion = Number(selectGiftCard.promotion[0].promotionBenefit) || 0;
      }
      giftCardPromotionId = selectGiftCard.promotion[0]._id || "";
    }
    if (txtInputAmountCard === "" || Number(txtInputAmountCard) === 0) {
      alert("Error, Used card is empty.");
      return;
    }
    if (selectGiftCard.cardExp && new Date(selectGiftCard.cardExp) < new Date()) {
      alert("Error, Card expired.");
      return;
    }
    if (Number(selectGiftCard.cardCash) < Number(txtInputAmountCard)) {
      setTxtInputAmountCard(selectGiftCard.cardCash);
      alert("Error, Used card is more than card balance.");
      return;
    }
    if (
      selectGiftCard.maxUsePertime &&
      Number(selectGiftCard.maxUsePertime) > 0 &&
      Number(selectGiftCard.maxUsePertime) < Number(txtInputAmountCard)
    ) {
      setTxtInputAmountCard(selectGiftCard.maxUsePertime);
      alert("Error, Used card is more than max usage.");
      return;
    }
    if (
      Number(paymentDetails?.grand_total) <
      Number(txtInputAmountCard) + Number(discountGiftCardPromotion)
    ) {
      setTxtInputAmountCard(
        (Number(paymentDetails?.grand_total) - Number(discountGiftCardPromotion)).toFixed(0)
      );
      alert("Error, Used card is more than net total.");
      return;
    }
    setGiftCardDetail(selectGiftCard);
    setDiscountGiftCard(txtInputAmountCard);
    setDiscountGiftCardPromotion(discountGiftCardPromotion);
    setGiftCardPromotionId(giftCardPromotionId);
    handleClose();
  };

  const generateCouponIcon = () => {
    let elements = [];
    const initialAmount = Number(selectGiftCard.initialAmount);
    const fixUsePertime = Number(selectGiftCard.fixUsePertime);
    const cardCash = Number(selectGiftCard.cardCash);
    const lengthCoupon = cardCash / fixUsePertime;
    const lengthCouponUse = (initialAmount - cardCash) / fixUsePertime;
    let i = 0;
    for (let index = 0; index < lengthCoupon; index++) {
      elements.push(
        <Col span={8} key={i}>
          <div className="couponIcon available">
            <span>{`${fixUsePertime} THB`}</span>
            <CouponIcon />
          </div>
        </Col>
      );
      i++;
    }
    for (let index = 0; index < lengthCouponUse; index++) {
      elements.push(
        <Col span={8} key={i}>
          <div className="couponIcon used">
            <span>Used</span>
            <CouponIcon />
          </div>
        </Col>
      );
      i++;
    }
    return elements;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  return (
    <StyledDrawer
      title={t("page.use_card")}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={applyCardDrawer}
      width={"100%"}
      closable={true}
      height={"80%"}
      className={"drawer"}
    >
      <StyledApplyCard themeColor={themeColor}>
        <div className="card-skeleton">
          <Skeleton loading={isLoading} active title={true} paragraph={false}>
            <Swiper
              autoplay={true}
              slidesPerView={1.1}
              spaceBetween={20}
              pagination={true}
              grabCursor={true}
              className="mySwiper"
              onSlideChange={(index: any) => {
                const i = index.activeIndex as number;
                setSelectGiftCard(listGiftCard[i]);
                setTxtInputAmountCard(
                  listGiftCard[i].fixUsePertime ? listGiftCard[i].fixUsePertime : ""
                );
              }}
            >

              {listGiftCard &&
                listGiftCard.map((card, index) => (
                  <SwiperSlide className="slide" key={index}>
                    <div className="cardBox">
                      <img src={card.imageUrl[0]} alt="test" />
                      <p className="cardTitle">{card.cardName}</p>
                      <p className="amount">{`${card.cardCash ? card.cardCash : "0"} THB`}</p>
                      <p className="cardTitle">
                        {card.cardExp && card.cardExp !== ""
                          ? `EXP : ${new Date(card.cardExp).getDate()}/${new Date(card.cardExp).getMonth() + 1
                          }/${new Date(card.cardExp).getFullYear()}`
                          : "EXP : NA"}
                      </p>
                    </div>
                    {(card.isFreeze || !card.isUse) && <div className="freezeMembarCard">
                      <IconFreeaeCard/>
                      <p className="textFreezeMemberCard">
                        {card.isFreeze ? 'Your card has been freeze.' : 'Your card is inactive.'}
                      </p>
                    </div>}
                  </SwiperSlide>
                ))}
            </Swiper>
          </Skeleton>
        </div>
        <div className="content-skeleton">


          <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 9, width: 74 }}>
            {selectGiftCard && selectGiftCard._id && (
              <div className="mainContainer">
                {!selectGiftCard.isTopup &&
                  selectGiftCard.fixUsePertime &&
                  Number(selectGiftCard.fixUsePertime) > 0 ? (
                  <div className="selectAmountSection">
                    <p className="selectAmountTitle">{t("page.please_select_amount_to_use")}</p>
                    <Row className="amountCardRows">{generateCouponIcon()}</Row>
                  </div>
                ) : (
                  <div className="enterAmountSection">
                    <SharedInput
                      label={t("page.amount_apply_card")}
                      className="amountInput"
                      suffixIcon={"THB"}
                      value={txtInputAmountCard}
                      onChange={(value: any) => {
                        if (selectGiftCard.fixUsePertime && Number(selectGiftCard.fixUsePertime) > 0) {
                          setTxtInputAmountCard(selectGiftCard.fixUsePertime);
                        } else {
                          if (
                            selectGiftCard.maxUsePertime &&
                            Number(selectGiftCard.maxUsePertime) > 0
                          ) {
                            setTxtInputAmountCard(
                              Number(value.target.value.replace(/[^0-9]/g, "0")) >
                                Number(selectGiftCard.maxUsePertime)
                                ? selectGiftCard.maxUsePertime
                                : value.target.value.replace(/[^0-9]/g, "")
                            );
                          } else {
                            setTxtInputAmountCard(value.target.value.replace(/[^0-9]/g, ""));
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Skeleton>
        </div>
        <StyledSubmitButton
          type="red"
          style={(selectGiftCard.isFreeze || !selectGiftCard.isUse) ? {backgroundColor: "#E1E1E1"} : {}}
          text={<span>{t("page.confirm_payment")}</span>}
          className="subBtn"
          onClick={() => {
            if (selectGiftCard.isFreeze || !selectGiftCard.isUse) {
              return;
            }
            handleApplyGiftCard();
          }}
          disable={selectGiftCard.isFreeze || !selectGiftCard.isUse}
        />
      </StyledApplyCard>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    height: auto !important;
    margin-bottom: 0 !important;
  }
`;

const StyledApplyCard = styled.div<{ themeColor: string }>`
  .card-skeleton {
    margin-bottom: 50px;
    .ant-skeleton {
      .ant-skeleton-title {
        marign-top:0;
        margin-bottom:0;
        height: 170px;
        border-radius: 16px;
      }
    }
  }
  .content-skeleton {
    .ant-skeleton {
      .ant-skeleton-paragraph {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        li {
          height: 74px;
          border-radius: 100%;
          width: 74px;
          margin: 0 auto;
        }
      }
    }
  }
  .swiper-container {
    padding-bottom: 45px;
  }
  .cardBox {
    padding: 20px;
  }
  .slide {
    border-radius: 12px;
    background: #fc5064;
    position: relative;
    display: flex;
    flex-direction: column;
    place-items: baseline;
    img {
      position: absolute;
      right: 0;
      width: 100%;
      // border-top-left-radius: 50%;
      // border-bottom-left-radius: 50%;
      // border-top-right-radius: 12px;
      // border-bottom-right-radius: 12px;
      border-radius: 12px;
      opacity: 0.6;
      top: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: ${(color) => color.themeColor};
  }
  .cardTitle {
    border-radius: 30px;
    background: ${(color) => color.themeColor};
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
  }
  .amount {
    margin-top: 40px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
    width: fit-content;
  }
  .amountInput {
    height: 60px;
  }
  .subBtn {
    width: 100%;
    border-radius: 74px;
    background: ${(color) => color.themeColor};
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    width: -webkit-fill-available;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .selectAmountSection {
    .selectAmountTitle {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .selectAmountSubtitle {
      color: #919191;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .amountCardRows {
    margin-top: 10px;
    .available svg .main {
      fill: #f22f46;
      opacity: 1;
    }
    .used {
      span {
        transform: rotate(-15deg);
      }
    }
    .couponIcon {
      position: relative;
      display: flex;
      place-content: center;
      place-items: center;
      span {
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .mainContainer {
    margin-bottom: 40px;
  }
  .freezeMembarCard {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.74);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .textFreezeMemberCard {  
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    margin-bottom: 0;
  }
`;
