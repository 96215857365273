import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router";

import {
    ComponentHeaderAuth,
    ComponentCards,
    ComponentQRcode,
    StyledContainerSHome,
    StyledCards,
} from "@components";
import { selectAuth, selectMerchant, selectNotSaveLocal, setNavigationType } from "@redux";
import { ModuleHistory, ModulesBenefit, ModulesCoupon, ModulesReward } from "@modules";
import { enumNavigation, PATH_TIER_DETAIL, PATH_USER_SETTING } from "@configs";

export const PageCards = () => {
    //page hook
    const history = useHistory();
    const dispatch = useDispatch();
    //page redux
    const { userInfo } = useSelector(selectAuth);
    const { themeColor } = useSelector(selectMerchant);
    const { navigationType } = useSelector(selectNotSaveLocal);

    //page state
    const [showQrCode, setShowQrCode] = useState<boolean>(false);

    const handleToggleUnder = (values: enumNavigation) => {
        dispatch(setNavigationType(values));
    };

    const handleToggleQrCode = () => {
        setShowQrCode(!showQrCode);
    };

    const handleSwitchUserSetting = () => {
        history.push(PATH_USER_SETTING);
    };

    const handleSwitchTier = () => {
        history.push(PATH_TIER_DETAIL);
    };

    const handleInitalQrCode = () => {
        setShowQrCode(false);
    };
    // const handleCoupon = () => {
    //     dispatch(setNavigationType(enumNavigation.COUPON_MERCHANT));
    // };

    const render = () => {
        switch (navigationType) {
            case enumNavigation.USER_POINT:
                return <ModulesReward />;
            case enumNavigation.BENEFIT:
                return <ModulesBenefit />;
            case enumNavigation.COUPON:
                return <ModulesCoupon under={navigationType} />;
            case enumNavigation.HISTORY:
                return <ModuleHistory />;
            case enumNavigation.COUPON_MERCHANT:
                return <ModulesCoupon under={navigationType} />;
            default:
                return <></>;
        }
    };

    return (
        // <ComponentHeaderAuth handleInitalQrCode={handleInitalQrCode}>
        <StyledCards mainColor={themeColor}>
            {!showQrCode && (
                <>
                    <ComponentCards
                        under={navigationType}
                        handleToggleUnder={handleToggleUnder}
                        userInfo={userInfo}
                        themeColor={themeColor}
                        handleToggleQrCode={handleToggleQrCode}
                        handleSwitchUserSetting={handleSwitchUserSetting}
                        handleSwitchTier={handleSwitchTier}
                        // handleCoupon={handleCoupon}
                    />
                    {/* <StyledContainerSHome>{render()}</StyledContainerSHome> */}
                </>
            )}
            {showQrCode && <ComponentQRcode handleToggleQrCode={handleToggleQrCode} />}
        </StyledCards>
        // </ComponentHeaderAuth>
    );
};
