import styled from "styled-components";

//prettier-ignore
export const StyledPreview = styled.div<{mainColor?: string;subColor?: string; showConfirm?: boolean;}>`
    /* min-height: 811px; */
    background-color: #f7f7f8;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    ${(p) => {
        if (p.showConfirm) return "height:98vh; overflow:hidden;";
    }}
    @media (min-width: ${(p) => p.theme.breakPoints.breakMaxBig}) {
        max-height: 667px;
        right: 30px;
        bottom: 1.5rem;
        position: absolute;
        overflow: hidden;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakMaxBig}) {
        margin-top: 32px;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobileSmallPhone}) {
        width: 340px;
    }
    /* @media (max-width: ${(p) => p.theme.breakPoints.breakSmall}) {
        width: 250px;
    } */
    .header {
        padding: 24px;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        span {
            display: inline-block;
            margin-left: 12px;
            font-weight: 600;
            font-size: 25px;
            line-height: 33px;
        }
    }
    .page_body {
        position: relative;
        .pagination {
            z-index: 2;
            background-color: white;
            position: absolute;
            top: 1rem;
            right: 1rem;
            border-radius: 1rem;
            padding: 0 10px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMaxBig}) {
            height: 619px;
            overflow-y: auto;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
            ::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background: ${(p) => p.theme.colors.fadedText};
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb:hover {
                cursor: grab;
            }
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMaxBig}) {
            padding-bottom: 32px;
        }
    }
    .body,
    .body_freePoint {
        height: 150px;
        /* position: relative;
        display: flex;
        justify-content: center; */
        overflow: hidden;
        min-height: 150px;
        background-color: ${(p) => p.mainColor};
        position: relative;
        max-width: calc(100% - 24px);
        overflow: hidden;
        margin: 24px auto 0 auto;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .text_name {
            position: absolute;
            top: 22px;
            left: 25px;
            .name {
                font-weight: bold;
                font-size: 18px;
                line-height: 1.8rem;
                color: #ffffff;
                max-width: 290px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
     
            }
            .date_text {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5rem;
                color: #b5b5b5;
                margin-top: 30px;
             
                
                
            }
            .nick_name {
                padding: 4px 18px;
                font-weight: normal;
                font-size: 13px;
                color: #ffffff;
                background: rgba(0, 0, 0, 0.22);
                border-radius: 17px;
                margin-top: 10px;
                text-align: center;
                min-width: 120px;
                width: fit-content;
                cursor: pointer;
              
            }
        }
    }
    .body_freePoint {
        height: 125px;
        min-height: 125px;
    }
    .code {
        display: flex;
        padding: 18px 25px;
        background-color: #ffffff;
        align-items: center;
        width: calc(100% - 24px);;
        margin: 0 auto 16px auto;
        /* max-width: 100%; */
      
        .coupon_code {
            min-width: fit-content;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #717171;
            margin-right: 12px;
        }
        .number {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            width: 170px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
    }
    .content {
        width: calc(100% - 24px);;
        margin: 0 auto;
        border-radius: 8px;
        padding: 28px 20px;
        background-color: #ffffff;
        position: relative;
        .pagination_coupon {
            z-index: 2;
            background-color: white;
            position: absolute;
            top: 1rem;
            right: 1rem;
            border-radius: 1rem;
            padding: 0 10px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        .image {
            width: 100%;
            height: calc(100vw - 64px);
            max-height: 416px;
            object-fit: cover;
            border-radius: 4px;
            /* width: 100%;
            height: 100vh;
            max-height: 392px;
            object-fit: cover;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobileLarge}) {
                max-height: 329px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                max-height: 298px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakSmall}) {
                max-height: 185px;
            } */
        }
        .guideline {
         
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5rem;
            /* color: #464646; */
            margin-top: 24px;
            word-break: break-word;
            * {
                font-size: 14px;
                line-height: 1.5rem;
            }
        }
    }
    .btn-main {
        width: calc(100% - 24px);;
        margin: 0 auto;
    }
    //update
    .img-field {
        .img-wrap {
            background-color: white;
            overflow: hidden;
            max-height: 400px;
            display: flex;
            justify-content: center;
            img {
                object-fit: cover;
                width: 100%;
                object-position: center;
                height: 400px;
            }
            // .empty-img {
            //     width: 80%;
            //     padding: 30px;
            // }
        }
    }
    .name-field {
        background-color: white;
        padding: 14px 12px;
        color: #a8a8a8;
    

        p {
            color: ${(p) => p.theme.colors.fadedColor};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
    .quantity-field {
        padding: 12px;
        margin: 12px;
        background-color: white;
        border-radius: 4px;
        .count-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                margin: 0 8px;
                color: black;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
           
            }
            .item-left {
                .item-left-top {
                    display: flex;
                    align-items: center;
                }
            }
            .item-right {
                display: flex;
                align-items: center;
                .btn-minus,.btn-add {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border-width: 0px;
                    box-shadow:  4px 3px 5px 0px rgb(0 0 0 / 15%), 3px 1px 20px 20px rgb(239 230 230 / 20%);
                    cursor: pointer;
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /* border: 1px solid black; */
                }
                div:active {
                    transform: scale(0.95);
                }
            }
        }
    }
    .select-field {
        cursor: pointer;
        padding: 12px;
        background-color: white;
        margin: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select-opts {
            width: 100%;
            margin-top: 6px;
            div {
                border-color: transparent !important;
            }
        }
        .arrowRight {
            cursor: pointer;
        }
        .variant {
      
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 1.8rem;
            color: #000000;
        }
    }
    .description-field {
        word-break: break-word;
        background-color: white;
       
        flex: 1;
        padding: 16px;
        max-width: 100%;
        word-break: break-all;
        p,
        div {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: black;
            line-height: 1.5rem;
        }
        h5 {
            font-size: 20px;
            font-weight: 700;
        }
        .use_point{
           width: 100%; 
        }
    }
    .text-small {
        font-size: 10px;
        font-weight: 600;
        color: #9d9d9d;
        font-style: normal;
        font-weight: normal;
      
        margin-top: 4px;
    }
    h5 {
        word-break: break-word;
        color: black;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: normal;
        font-size: 27px;
        line-height: 36px;
    
    }
    .use_point {
        text-align: center;
        margin-right: 12px;
    }
    .ant-select-selection__rendered {
        color: #000000;
        font-size: 20px;
   
    }
    .select_type {
        color: #929292;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
     
    }
    .variant {
        color: #000000;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
      
    }
    .swiper-slide.swiper-slide-active {
        overflow: hidden;
        /* border-radius: 6px; */
        width: 100%;
    }
    /* .swiper-slideswiper-slide-active */
    .swiper-pagination-bullet {
        opacity: 0.6;
    }
    .swiper-pagination-bullet-active {
        background-color: black;
        opacity: 1;
    }
    .swiper-container-horizontal>.swiper-scrollbar{
        display: none;
    }
`;
