import { Col, Row } from "antd";
import styled from "styled-components";

interface IProps {
    socials: JSX.Element[];
}

export const ComponentSocialMedia = (props: IProps) => {
    //page props
    const { socials } = props;

    return (
        <StyledContainer>
            <Row gutter={8}>
                {socials.map((item, index) => {
                    return (
                        <Col span={24 / socials.length} key={index}>
                            {item}
                        </Col>
                    );
                })}
            </Row>
        </StyledContainer>
    );
};

const StyledContainer = styled.div``;
