import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FormikErrors, FormikTouched } from "formik";

import { StyledSignUp, SharedInput, SharedButton, IConEmail } from "..";
import { selectMerchant } from "@redux";
import { IEmail, IHandleBlur, IHandleChange } from "@interfaces";

interface IProps {
    values: IEmail;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<IEmail>;
    touched: FormikTouched<IEmail>;
    isSubmitting: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const ComponentPasswordEmail = (props: IProps) => {
    //page props
    const { values, handleBlur, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    //page hook
    const { t } = useTranslation();
    //page variable
    const merchant = useSelector(selectMerchant);

    return (
        <StyledSignUp>
            <form onSubmit={handleSubmit}>
                <StyledContainer bg_color={merchant.themeColor}>
                    <div className="title">{t("page.reset_your_password")}</div>
                    <StyledSharedInput
                        prefixIcon={<IConEmail />}
                        placeholder={t("page.email")}
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.email}
                        touched={touched.email}
                    />
                    <StyledButton
                        size="large"
                        bg_color={merchant.themeColor}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {t("page.email_recovery_link")}
                    </StyledButton>
                </StyledContainer>
            </form>
        </StyledSignUp>
    );
};

const StyledContainer = styled.div<{ bg_color: string }>`
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 46px;
    }
    .choose {
        font-size: 16px;
        line-height: 21px;
        color: #676767;
        margin-bottom: 42px;
    }
    .site-form-item-icon {
        font-size: 20px;
    }
    .forgot {
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
        color: #646464;
        text-align: start;
        margin-top: 16px;
        span {
            color: ${(p) => p.bg_color};
        }
    }
    .remember {
        text-align: start;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        margin-top: 8px;
        color: #646464;
    }
`;

const StyledButton = styled(SharedButton)`
    margin-top: 7rem;
`;

const StyledSharedInput = styled(SharedInput)`
    margin-top: 4rem;
`;
