import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { SharedComponentDrawer } from "../shared/Drawer";
import { MinusIcon } from "../icon/MinusIcon";
import { PlusIcon } from "../icon/PlusIcon";
import styled from "styled-components";
import { ICreateProductOrderPOSExample, IFreeItemByPromotion, IGetStoreExample } from "@interfaces";
import { selectMerchant } from "@redux";
import { Checkbox, Col, Divider, Radio, Row, Spin } from "antd";
import { SharedInput, StyledSubmitButton } from "../shared";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotify } from "@utils";
import { IconBack } from "../icon/FreebieDrawer/IconBack";
import { IconClose } from "../icon/FreebieDrawer/IconClose";
import { IAddonOptionList } from "src/Interfaces/posPromotion";

interface IProps {
  freebieDrawer: boolean;
  handleClose: () => void;
  storeDetails?: IGetStoreExample;
  paymentDetails?: ICreateProductOrderPOSExample;
  getPaymentDetails: () => void;
  productAddonList: any;
  setProductAddonList?: any;
  setSelectedFreebies?: any;
  selectedFreebies?: any;
  addListToBasket?: any;
}

export const ComponentFreebieDrawer = (props: IProps) => {
  const {
    freebieDrawer,
    handleClose,
    paymentDetails,
    productAddonList,
    setSelectedFreebies,
    selectedFreebies,
    addListToBasket,
  } = props;
  const { t } = useTranslation();
  const { error } = useNotify();
  const org = useSelector((state: any) => state.merchant);
  const cart = useSelector((state: any) => state.cart.cart);
  const dispatch = useDispatch();
  const { themeColor } = useSelector(selectMerchant);
  const [notation, setNotation] = useState<string>("");
  const [addonList, setAddonList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [basketItems, setBasketItem] = useState<any[]>([]);

  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const handleNextItem = () => {
    setLoading(true);
    if (currentItemIndex < productAddonList.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
      setAddonList([]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const currentItem = productAddonList[currentItemIndex];

  const addToBasketHandler = () => {
    let product = { ...productAddonList[currentItemIndex] };
    let tempAddon: any = [];
    const addonRequire = product.addon_option_list.filter((e: any) => e.is_require);
    let isNotSelectRequire = false;
    addonRequire.map((e: any) => {
      if (addonList.findIndex((a: any) => a.addon_id === e.addon_id) < 0) {
        isNotSelectRequire = true;
      }
    });
    if (isNotSelectRequire) {
      const missingAddons = [];
      if (product.addon_option_list && product.addon_option_list.length !== 0) {
        for (let i = 0; i < product.addon_option_list.length; i++) {
          const addon = product.addon_option_list[i];

          if (addon.is_require) {
            const isAddonInList = addonList.some(
              (addonItem) => addonItem.addon_id === addon.addon_id
            );

            if (!isAddonInList) {
              missingAddons.push(addon.addon_name);
            }
          }
        }
      }
      error(`Required addons missing: ${missingAddons.join(", ")}`);
    } else {
      for (let i = 0; i < addonList.length; i++) {
        if (
          addonList[i].is_multi_choice &&
          addonList[i].option_selected.findIndex((item: any) => item.is_selected === true) > -1
        ) {
          tempAddon.push(addonList[i]);
          if (addonList[i].option_selected.some((x: any) => x.is_selected)) {
            for (let s = 0; s < product.addon_option_list.length; s++) {
              product.addon_option_list[s].option_selected = addonList[i].option_selected.filter(
                (x: any) => x.is_selected === true
              );
            }
          }
        }
        if (!addonList[i].is_multi_choice) {
          tempAddon.push(addonList[i]);

          if (product.addon_option_list.length > 1) {
            product.addon_option_list = product.addon_option_list.filter(
              (x: any) => x.option_selected !== undefined
            );
          }
        }
      }
      // Deleting undefined object if you select only RadioButtons
      for (let i = 0; i < tempAddon.length; i++) {
        if (tempAddon[i].option_selected.some((x: any) => x === undefined)) {
          tempAddon[i].option_selected = tempAddon[i].option_selected.filter(
            (x: any) => x !== undefined
          );
        }
      }
      // Calculating the Price
      let subTotalTemp = 0;
      for (let i = 0; i < tempAddon.length; i++) {
        for (let j = 0; j < tempAddon[i].option_selected.length; j++) {
          if (tempAddon[i].option_selected[j] !== undefined) {
            subTotalTemp = subTotalTemp + tempAddon[i].option_selected[j].price;
          }
        }
      }
      // Calculating the Price
      // const findInd = addonList.findIndex(x => x.is_require === true);

      product = {
        addon_list: tempAddon.filter((x: any) => x.option_selected !== undefined),
        product_image_url: product.image_url,
        qty: 1,
        is_freebie: true,
        note: "",
        product_name: product.name,
        variant_id: product.variant_id,
        variant_name: product.name,
        pos_promotion_id: product.pos_promotion_id,
      };
      let newBasket = basketItems;
      newBasket.push(product);
      setBasketItem(newBasket);
      if (currentItemIndex < productAddonList.length - 1) {
        handleNextItem();
      } else {
        const listFreebie = [...selectedFreebies, ...basketItems];
        setSelectedFreebies(listFreebie);
        handleClose();
      }
    }
  };

  const checkboxChange = (e: any, option: any, item: any) => {
    const updatedList = [...addonList];
    const addonIndex = updatedList.findIndex(
      (addon) => addon.addon_id === item.addon_id && addon.addonIndex === currentItemIndex
    );
    const maxSelected = item.option_limit;

    if (e.target.checked) {
      const selectedCount = addonIndex !== -1 ? updatedList[addonIndex].option_selected.length : 0;
      let sumQtyOption = 0;
      updatedList.map((addon: any) => {
        if (addon.addon_id === item.addon_id) {
          addon.option_selected.map(
            (option: any) => (sumQtyOption = sumQtyOption + option.option_qty)
          );
        }
      });

      if (sumQtyOption === item.max_option_limit_qty) {
        return;
      }

      if (selectedCount < maxSelected) {
        const addonIndex = updatedList.findIndex(
          (addon) => addon.addon_id === item.addon_id && addon.addonIndex === currentItemIndex
        );
        if (addonIndex !== -1) {
          updatedList[addonIndex].option_selected.push({
            option_id: option.option_id,
            option_name: option.option_name,
            price: option.price,
            is_selected: true,
            option_qty: 1,
          });
        } else {
          updatedList.push({
            addonIndex: currentItemIndex,
            addon_id: item.addon_id,
            addon_name: item.addon_name,
            option_selected: [
              {
                option_id: option.option_id,
                option_name: option.option_name,
                price: option.price,
                is_selected: true,
                option_qty: 1,
              },
            ],
          });
        }
        setAddonList(updatedList);
      }
    } else {
      const addonIndex = updatedList.findIndex((addon) => addon.addon_id === item.addon_id);

      if (addonIndex !== -1) {
        updatedList[addonIndex].option_selected = updatedList[addonIndex].option_selected.filter(
          (selected: any) => selected.option_id !== option.option_id
        );
        // make the addon empty if we unchecked all the addons
        if (updatedList[addonIndex].option_selected.length === 0) {
          updatedList.splice(addonIndex, 1);
        }
      }
      setAddonList(updatedList);
    }
  };
  const radioChange = (option: any, item: any) => {
    if (addonList.some((x: any) => x.addon_id === item.addon_id) === false) {
      setAddonList([
        ...addonList,
        {
          addon_id: item.addon_id,
          addon_name: item.addon_name,
          is_require: item.is_require,
          option_selected: [
            {
              option_id: option.target.value.option_id,
              option_name: option.target.value.option_name,
              price: option.target.value.price,
              option_qty: 1,
            },
          ],
        },
      ]);
    } else {
      let findIndex = addonList.findIndex((x: any) => x.addon_id === item.addon_id);
      let updatedRadio = [...addonList];
      updatedRadio[findIndex].option_selected = [
        {
          option_id: option.target.value.option_id,
          option_name: option.target.value.option_name,
          price: option.target.value.price,
          option_qty: 1,
        },
      ];
      setAddonList(updatedRadio);
    }
  };
  const radioStyle = {
    display: "flex",
    height: "30px",
    lineHeight: "30px",
    placeContent: "flex-start",
  };

  const optionPlusQtyHandler = (option: any, item: any) => {
    const updatedList = [...addonList];
    let sumQtyOption = 0;
    updatedList.map((addon: any) => {
      if (addon.addon_id === item.addon_id) {
        addon.option_selected.map(
          (option: any) => (sumQtyOption = sumQtyOption + option.option_qty)
        );
      }
    });

    if (sumQtyOption === item.max_option_limit_qty) {
      return;
    }

    const addonIndex = updatedList.findIndex((x) => x.addon_id === item.addon_id);
    const optionIndex = updatedList[addonIndex].option_selected.findIndex(
      (x: any) => x.option_id === option.option_id
    );
    let updatedAddonOption = updatedList[addonIndex].option_selected[optionIndex];

    if (updatedAddonOption) {
      if (typeof updatedAddonOption.option_qty === "number") {
        updatedAddonOption.option_qty += 1;
      } else {
        updatedAddonOption.option_qty = 1;
      }
    }
    setAddonList(updatedList);
  };
  const optionMinusQtyHandler = (option: any, item: any) => {
    const updatedList = [...addonList];

    const addonIndex = updatedList.findIndex((x) => x.addon_id === item.addon_id);
    const optionIndex = updatedList[addonIndex].option_selected.findIndex(
      (x: any) => x.option_id === option.option_id
    );
    let updatedAddonOption = updatedList[addonIndex].option_selected[optionIndex];

    if (updatedAddonOption) {
      if (typeof updatedAddonOption.option_qty === "number") {
        updatedAddonOption.option_qty -= 1;
      } else {
        updatedAddonOption.option_qty = 1;
      }
    }
    setAddonList(updatedList);
  };

  const handleBackItem = () => {
    if (currentItemIndex < productAddonList.length && currentItemIndex > 0) {
      setLoading(true);
      if (currentItemIndex < productAddonList.length - 1) {
        setCurrentItemIndex(currentItemIndex - 1);
        let temp: any = [...basketItems];
        setBasketItem(temp.slice(0, selectedFreebies.length - 1));
        setAddonList([]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  return (
    <>
      {currentItem &&
      currentItem.addon_option_list &&
      currentItem.addon_option_list.length === 0 ? (
        setCurrentItemIndex(currentItemIndex + 1)
      ) : (
        <StyledFreebieHeaderDrawer
          title={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div onClick={handleBackItem}>
                <IconBack />
              </div>
              <p className="h3" style={{ margin: 0 }}>
                {t("page.select_freebie_add_on")}{" "}
                <span
                  style={{ fontSize: 16, fontWeight: 600, color: themeColor, display: "unset" }}
                >
                  {currentItemIndex && currentItemIndex === 0 ? 1 : currentItemIndex + 1}/
                  {productAddonList.filter((x: any) => x.addon_option_list.length !== 0).length}
                </span>
              </p>
              <div></div>
            </div>
          }
          placement={enumDrawerPlacement.BOTTOM}
          // onClose={handleClose}
          onClose={() => null}
          visible={freebieDrawer}
          width={"100%"}
          closable={false}
          height={"80%"}
          // key={i}
        >
          <StyledBasketDrawer themeColor={themeColor}>
            <div className="freebieForm">
              <div className="greenBox">
                <div className="leftSide">
                  <span className="body2">x{currentItem && currentItem.free_qty}</span>
                  <img src={currentItem && currentItem.image_url} />
                  <p className="body2" style={{ margin: 0 }}>
                    {currentItem && currentItem.name}
                  </p>
                </div>
                <div className="rightSide">
                  <p className="subtitle3" style={{ margin: 0, color: themeColor }}>
                    {t("page.free")}
                  </p>
                </div>
              </div>
              <Divider />
              <div>
                {!loading ? (
                  <div className="checkBoxColumn">
                    {currentItem &&
                      currentItem.addon_option_list.map((addon: IAddonOptionList, k: number) => (
                        <div key={k}>
                          {addon.is_multi_choice ? (
                            <>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label className="addonsTitle">
                                  {addon.addon_name}{" "}
                                  {addon.is_require && <span style={{ color: "#F22F46" }}>*</span>}
                                  <span>(Max: {addon.option_limit})</span>
                                </label>
                                <label className="addonsTitle">
                                  <span>(Max: {addon.max_option_limit_qty} total)</span>
                                </label>
                              </div>
                              <div key={k}>
                                {addon.product_option_list.map((option, l) => {
                                  const optionIsSelected = addonList.some((addon) =>
                                    addon.option_selected.some(
                                      (selected: any) =>
                                        selected.option_id === option.option_id &&
                                        addon.addonIndex === currentItemIndex
                                    )
                                  );
                                  const addonIndex = addonList.findIndex(
                                    (x) => x.addon_id === addon.addon_id
                                  );

                                  const optionIndex =
                                    addonList[addonIndex] &&
                                    addonList[addonIndex].option_selected &&
                                    addonList[addonIndex].option_selected.findIndex(
                                      (x: any) => x.option_id === option.option_id
                                    );
                                  const optionQty =
                                    addonList[addonIndex] &&
                                    addonList[addonIndex].option_selected &&
                                    addonList[addonIndex].option_selected[optionIndex] &&
                                    addonList[addonIndex].option_selected[optionIndex].option_qty;
                                  return (
                                    <div key={l} className="checkBoxRow">
                                      <Checkbox
                                        onChange={(e) => {
                                          checkboxChange(e, option, addon);
                                        }}
                                        checked={optionIsSelected}
                                      >
                                        <div className="toppingLeftSide">
                                          {option.option_name}
                                          <span className="optionPrice">฿{option.price}</span>
                                        </div>
                                      </Checkbox>
                                      <div className="toppingRightSide">
                                        <div
                                          style={{
                                            opacity:
                                              optionQty === option.option_qty_limit &&
                                              optionQty === 1
                                                ? 0.6
                                                : 1,
                                          }}
                                          onClick={() => {
                                            if (optionQty && optionQty > 1) {
                                              optionMinusQtyHandler(option, addon);
                                            }
                                          }}
                                        >
                                          <MinusIcon size={20} />
                                        </div>
                                        <span>{optionQty || 1}</span>
                                        <div
                                          style={{
                                            opacity:
                                              optionQty === option.option_qty_limit ? 0.6 : 1,
                                          }}
                                          onClick={() => {
                                            if (optionQty && optionQty < option.option_qty_limit) {
                                              optionPlusQtyHandler(option, addon);
                                            }
                                          }}
                                        >
                                          <PlusIcon size={20} />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <Radio.Group onChange={(e) => radioChange(e, addon)} key={k}>
                              <label className="addonsTitle">
                                {addon.addon_name}{" "}
                                {addon.is_require && <span style={{ color: "#F22F46" }}>*</span>}
                                <span>{`(Select 1)`}</span>
                              </label>
                              {addon.product_option_list.map((option, l) => (
                                <Radio
                                  style={radioStyle}
                                  value={option}
                                  key={l}
                                  className="checkBoxRow"
                                >
                                  {option.option_name}
                                  <span className="optionPrice">฿{option.price}</span>
                                </Radio>
                              ))}
                            </Radio.Group>
                          )}
                        </div>
                      ))}
                  </div>
                ) : (
                  <Spin />
                )}
              </div>
              <Divider />
              <Row>
                <Col span={24}>
                  <SharedInput
                    label={t("page.notation")}
                    type={"text"}
                    className="mb-6"
                    placeholder={t("page.ex_a_little_sweet")}
                    value={notation}
                    onChange={(value: any) => {
                      setNotation(value.target.value);
                    }}
                  />
                </Col>
              </Row>
              {freebieDrawer && (
                <div className="addItemRow">
                  <div className="btn-layout">
                    <form>
                      <StyledSubmitButton
                        type="red"
                        text={
                          <Row className="addToOrderRow">
                            <span className="button1" style={{ color: "#fff" }}>
                              {t("page.next")}
                            </span>
                          </Row>
                        }
                        htmlType="button"
                        onClick={() => {
                          addToBasketHandler();
                          // if (currentItemIndex < productAddonList.length - 1) {
                          //   handleNextItem();
                          // } else {
                          //   freebieHandler(item);
                          // }
                        }}
                      />
                    </form>
                  </div>
                </div>
              )}
            </div>
          </StyledBasketDrawer>
        </StyledFreebieHeaderDrawer>
      )}
    </>
  );
};
const StyledFreebieHeaderDrawer = styled(SharedComponentDrawer)`
  .totalQueueBadge {
    border-radius: 48px;
    padding: 2px 12px 2px 2px;
    display: flex;
    gap: 4px;
    place-items: center;
    span {
      margin: 0;
    }
    .totalQueueIcon {
      border-radius: 50%;
      padding: 4px 8px;
    }
  }
  .ant-drawer-body {
    margin-top: 4.2rem;
  }
  .ant-drawer-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }
`;

const StyledBasketDrawer = styled.div<{ themeColor: string }>`
  .freebieForm {
    padding-bottom: 10rem;
  }
  .greenBox {
    border-radius: 8px;
    background: ${(color) => color.themeColor}10;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSide {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 12px;
      }
    }
  }
  .addItemRow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px;
    align-items: center;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
    .separator {
      position: absolute;
      top: -10px;
      margin: 0;
      left: 0;
      right: 0;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .addToOrderRow {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    width: 100%;
    button {
      margin: 0;
      width: 100%;
      height: 55px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  input[type="radio"]:checked {
    background-color: red;
  }
  .ant-radio-inner::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.7);
    width: 50%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 0.3s ease;
    background: transparent;
    border-radius: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    position: relative;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(color) => color.themeColor};
    border: 2px solid ${(color) => color.themeColor};
    transition: background-color 0.3s ease;
  }
  .ant-radio {
    width: 17px;
    height: 17px;
  }
  span.ant-radio + * {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }
  .ant-radio-checked::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${(color) => color.themeColor};
  }
  .addonsTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    span {
      color: #919191;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(color) => color.themeColor};
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(color) => color.themeColor};
  }
  .checkBoxColumn {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
  }
  .checkBoxColumn {
    margin-bottom: 12px;
  }
  .checkBoxRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    place-items: center;
  }
  .optionPrice {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #000;
  }
  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${(color) => color.themeColor};
    color: #fff;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid ${(color) => color.themeColor};
    z-index: 0;
  }
  .ant-radio-button-wrapper {
    height: auto;
    width: 115px;
    background: #f7f7f8;
    border-radius: 8px;
    border: 2px solid #f7f7f8;
    text-align: center;
    color: #b4b4b9;
  }
  .variantsGroup {
    flex-direction: row;
    gap: 10px;
    flex-flow: wrap;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #f7f7f8;
  }
  .ant-radio-button-wrapper-checked span {
    margin-top: 2px;
  }
  .ant-radio-button-wrapper span {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .toppingLeftSide {
    display: flex;
    gap: 5px;
  }
  .ant-checkbox {
    top: 0.45em;
    height: fit-content;
  }
  .toppingRightSide {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }
  }
`;
