

export const IconPromotionCode = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5375_2751)">
        <path d="M23.794 0.205975C23.5194 -0.0686178 23.0742 -0.0686178 22.7996 0.205975L20.7516 2.25394C20.6257 2.19394 20.4929 2.14561 20.3542 2.11097L13.9899 0.538647C13.2753 0.360006 12.5076 0.572584 11.9867 1.09351L0.616876 12.4446C-0.205544 13.2671 -0.205544 14.6054 0.616876 15.4279L8.57216 23.3831C9.39462 24.2056 10.7329 24.2056 11.5554 23.3831L22.9066 12.0132C23.4275 11.4923 23.6401 10.7247 23.4614 10.01L21.8891 3.6458C21.8544 3.50714 21.8061 3.37425 21.7461 3.24834L23.7941 1.20038C24.0686 0.925787 24.0686 0.480569 23.794 0.205975ZM22.097 10.3512C22.1566 10.5894 22.0857 10.8452 21.9121 11.0189L10.5609 22.3887C10.2867 22.6629 9.84064 22.6629 9.56647 22.3887L1.61123 14.4334C1.33706 14.1593 1.33706 13.7132 1.61123 13.439L12.9811 2.08791C13.1141 1.95493 13.2953 1.88222 13.4799 1.88222C13.5364 1.88222 13.5931 1.88902 13.6489 1.90294L19.6268 3.3787L17.9335 5.07206C17.1495 4.69865 16.1811 4.83562 15.533 5.48372C14.7105 6.30623 14.7105 7.64446 15.533 8.46698C15.9442 8.87821 16.4844 9.08385 17.0246 9.08385C17.5647 9.08385 18.105 8.87826 18.5162 8.46698C19.1644 7.81879 19.3013 6.85045 18.9278 6.06646L20.6212 4.37311L22.097 10.3512ZM17.5218 7.47262C17.2477 7.74674 16.8016 7.74679 16.5274 7.47262C16.2533 7.19849 16.2533 6.75234 16.5274 6.47821C16.6645 6.3411 16.8445 6.27262 17.0246 6.27262C17.2046 6.27262 17.3847 6.34115 17.5217 6.47817L17.5217 6.47821L17.5218 6.47826C17.796 6.75238 17.7959 7.19849 17.5218 7.47262Z" fill="black" />
        <path d="M11.5553 8.46701C10.7329 7.64454 9.39459 7.64454 8.57208 8.46701C7.74966 9.28948 7.74966 10.6278 8.57208 11.4503C9.39454 12.2727 10.7329 12.2727 11.5553 11.4503C12.3779 10.6278 12.3779 9.28952 11.5553 8.46701ZM10.561 10.4558C10.2868 10.73 9.8407 10.73 9.56657 10.4558C9.29245 10.1816 9.29236 9.73554 9.56653 9.46141C9.8407 9.18729 10.2868 9.18724 10.561 9.46141C10.8351 9.73554 10.8351 10.1816 10.561 10.4558Z" fill="black" />
        <path d="M12.5497 15.4278C11.7254 14.6034 10.3911 14.6034 9.56653 15.4278C8.74402 16.2504 8.74402 17.5886 9.56653 18.4111C10.391 19.2355 11.7253 19.2356 12.5497 18.4111C13.3723 17.5886 13.3723 16.2504 12.5497 15.4278ZM11.5553 17.4167C11.2806 17.6915 10.8357 17.6915 10.5609 17.4167C10.2868 17.1426 10.2868 16.6964 10.5609 16.4223C10.8357 16.1475 11.2806 16.1475 11.5553 16.4223C11.8295 16.6964 11.8295 17.1425 11.5553 17.4167Z" fill="black" />
        <path d="M15.7346 12.8642C15.6917 12.4782 15.344 12.2001 14.9581 12.243L6.00847 13.2374C5.6225 13.2803 5.34435 13.6279 5.38724 14.0139C5.42722 14.3735 5.73163 14.6395 6.08525 14.6395C6.11117 14.6395 6.13742 14.6381 6.16377 14.6351L15.1135 13.6407C15.4994 13.5978 15.7776 13.2502 15.7346 12.8642Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5375_2751">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
