import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useNotify } from "@utils";
import { SharedComponentDrawer } from "../shared/Drawer";
import { SharedInput, StyledSubmitButton } from "../shared";
import { MinusIcon } from "../icon/MinusIcon";
import { PlusIcon } from "../icon/PlusIcon";
import styled from "styled-components";
import {
  IAddonList,
  IAddonListUpdateOrder,
  IBasketItems,
  IGetProductOrderOnTableByQrOpenTableExample,
  IOptionSelected,
  IOrderList,
  IProduct,
  IProductAddonList,
  IProductOptionList,
  IVariantList,
} from "@interfaces";
import { Swiper, SwiperSlide } from "swiper/react";
import "../styles/Swiper.css";
import { useEffect, useState } from "react";
import { Checkbox, Col, Radio, Row, Skeleton } from "antd";
import { productAPI } from "@api";
import { selectCart, selectMerchant, setCart, store } from "@redux";
import { useDispatch, useSelector } from "react-redux";
interface IProps {
  showModal: boolean;
  handleClose: () => void;
  product?: IProduct;
  orderList?: IGetProductOrderOnTableByQrOpenTableExample;
}

export const ComponentProductDrawer = (props: IProps) => {
  const { showModal, handleClose, product, orderList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { success, error } = useNotify();
  const cart = useSelector((state: any) => state.cart.cart);
  const orgInfo = useSelector((state: any) => state.merchant);
  const [qty, setQTY] = useState<number>(1);
  const [notation, setNotation] = useState<string>("");
  const [addonList, setAddonList] = useState<IAddonListUpdateOrder[]>([]);
  const [variantList, setVariantList] = useState<IVariantList>();
  const { themeColor } = useSelector(selectMerchant);
  const [isLoading, setIsloading] = useState(true);
  const [listVariantClose, setListVariantClose] = useState<any>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<any>([]);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
      // active stock
      const listClose = product && product.variant_list.filter(variant => variant.store_list && variant.store_list.some((store:any) => store.store_id === orgInfo.store_id && store.is_enable_pos === false));
      setListVariantClose(listClose && listClose.length > 0 ? listClose : []);
    }, 500);
  }, []);

  const orderHandler = async () => {
    try {
      let temp: any = [];
      let subTotalTemp: any = 0;
      let exist = true;
      let tempVariant: any = {};
      const result: number = product?.variant_list.findIndex(function (object) {
        let found_count = 0;
        if (object.dimension_list) {
          for (let i = 0; i < object.dimension_list.length; i++) {
            for (let j = 0; j < selectedDimensions.length; j++) {
              if (
                object.dimension_list[i].dimension_name ===
                  selectedDimensions[j].dimension_name &&
                object.dimension_list[i].dimension_option_name ===
                  selectedDimensions[j].dimension_option_name
              ) {
                found_count = found_count + 1;
              }
            }
          }
        }
        // zeroQTY = object.store.qty > 0 || object.store.qty === null;
        if (found_count === selectedDimensions.length) {
          return object;
        }
      }) || 0;
      tempVariant = product?.variant_list[result];
      setVariantList(tempVariant);
      if (product?.is_variant === false) {
        if (addonList) {
          for (let i = 0; i < addonList.length; i++) {
            for (let j = 0; j < addonList[i].option_selected.length; j++) {
              if (addonList[i].option_selected[j].option_qty) {
                const qtyOption = addonList[i].option_selected[j].option_qty || 1;
                subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * qtyOption);
              } else {
                subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * 1);
              }

              if (
                !cart.some((cartItem: any) =>
                  cartItem.addon_list?.some((addonItem: any) =>
                    addonItem.option_selected?.some(
                      (cartOption: any) =>
                        cartOption.option_id === addonList[i].option_selected[j].option_id
                    )
                  )
                )
              ) {
                exist = false;
                break;
              }
            }
          }
        }
        if (cart.some((cart: any) => cart.product_id === product?._id && exist === true)) {
          const newState: any = cart.map((obj: IBasketItems) => {
            if (obj.product_id === product?._id) {
              return {
                ...obj,
                qty: qty === 1 ? obj.qty + 1 : obj.qty + qty,
                price_unit: obj.price_unit + ((product.variant_list[0].price + subTotalTemp) * qty),
              };
            }
            return obj;
          });
          dispatch(setCart(newState));
          handleClose();
        } else {
          const missingAddons = [];
          if (product.product_addon_list && product.product_addon_list.length !== 0) {
            for (let i = 0; i < product.product_addon_list.length; i++) {
              const addon = product.product_addon_list[i];

              if (addon.is_require) {
                const isAddonInList = addonList.some(
                  (addonItem) => addonItem.addon_id === addon.addon_id
                );

                if (!isAddonInList) {
                  missingAddons.push(addon.addon_name);
                }
              }
            }
          }
          if (missingAddons.length > 0) {
            error(`Required addons missing: ${missingAddons.join(", ")}`);
            // Handle the error or take appropriate action
          } else {
            temp.push({
              addon_list: addonList,
              note: notation,
              net: (product.variant_list[0].price + subTotalTemp) * qty,
              price_unit: (product.variant_list[0].price + subTotalTemp) * qty,
              product_code: product.product_code,
              product_id: product._id,
              product_name: product.name,
              qty: qty,
              product_image_url: product.image_url_list[0],
              variant_code: product.variant_list[0].variant_code,
              variant_name: product.variant_list[0].variant_name,
              variant_id: product.variant_list[0]._id,
              discount: product.discount_in_baht,
            });
            if (cart.length === 0) {
              dispatch(setCart(temp));
            } else {
              dispatch(setCart(cart.concat(temp)));
            }
            success("Added to the basket successfully");
            handleClose();
            setAddonList([]);
            setQTY(1);
          }
        }
      } else {
        if (tempVariant) {
          if (
            cart.some(
              (cart: any) => cart.variant_id === tempVariant._id && cart.addon_list === addonList
            )
          ) {
            const newState: any = cart.map((obj: IBasketItems) => {
              if (obj.variant_id === tempVariant._id) {
                for (let i = 0; i < addonList.length; i++) {
                  for (let j = 0; j < addonList[i].option_selected.length; j++) {
                    if (addonList[i].option_selected[j].option_qty) {
                      const qtyOption = addonList[i].option_selected[j].option_qty || 1;
                      subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * qtyOption);
                    } else {
                      subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * 1);
                    }
                  }
                }
                return {
                  ...obj,
                  addon_list: addonList,
                  qty: qty === 1 ? obj.qty + 1 : obj.qty + qty,
                  price_unit:
                    qty === 1
                      ? obj.price_unit * 2 + subTotalTemp
                      : obj.price_unit + obj.price_unit * qty + subTotalTemp,
                };
              }
              return obj;
            });
            dispatch(setCart(newState));
            handleClose();
          } else {
            if (addonList) {
              for (let i = 0; i < addonList.length; i++) {
                for (let j = 0; j < addonList[i].option_selected.length; j++) {
                  if (addonList[i].option_selected[j].option_qty) {
                    const qtyOption = addonList[i].option_selected[j].option_qty || 1;
                    subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * qtyOption);
                  } else {
                    subTotalTemp = subTotalTemp + (addonList[i].option_selected[j].price * 1);
                  }
                }
              }
            }
            temp.push({
              addon_list: addonList,
              note: notation,
              net: tempVariant?.price,
              price_unit: tempVariant?.price + subTotalTemp,
              product_code: product?.product_code,
              product_id: product?._id,
              product_name: product?.name,
              qty: qty,
              product_image_url: product?.image_url_list[0],
              variant_code: tempVariant?.variant_code,
              variant_name: tempVariant?.variant_name,
              variant_id: tempVariant?._id,
              discount: product?.discount_in_baht,
            });
            if (cart.length === 0) {
              dispatch(setCart(temp));
            } else {
              dispatch(setCart(cart.concat(temp)));
            }
            success("Added to the basket successfully");
            handleClose();
            setAddonList([]);
            setQTY(1);
          }
        } else {
          error(`Required variant missing`);
        }
      }
    } catch (error) {
      await console.log(error);
    }
  };
  const radioStyle = {
    display: "flex",
    height: "30px",
    lineHeight: "30px",
    placeContent: "flex-start",
  };

  const radioChange = (option: any, item: IProductAddonList) => {
    if (addonList.some((x: any) => x.addon_id === item.addon_id) === false) {
      setAddonList([
        ...addonList,
        {
          addon_id: item.addon_id,
          addon_name: item.addon_name,
          is_require: item.is_require,
          option_selected: [
            {
              option_id: option.target.value.option_id,
              option_name: option.target.value.option_name,
              price: option.target.value.price,
              option_qty: 1,
            },
          ],
        },
      ]);
    } else {
      let findIndex = addonList.findIndex((x: any) => x.addon_id === item.addon_id);
      let updatedRadio = [...addonList];
      updatedRadio[findIndex].option_selected = [
        {
          option_id: option.target.value.option_id,
          option_name: option.target.value.option_name,
          price: option.target.value.price,
          option_qty: 1,
        },
      ];
      setAddonList(updatedRadio);
    }
  };

  const checkboxChange = (e: any, option: any, item: IProductAddonList) => {
    let updatedList: any[] = [];
    if (addonList && addonList.length !== 0) {
      updatedList = [...addonList];
    } else {
      // check if selected product is exist in the cart
      // const findIndex = cart.findIndex((item: any) => item.product_id === product?._id);
      // if (findIndex !== -1) {
      //   updatedList = [...cart[findIndex].addon_list];
      // } else {
      updatedList = [];
      // }
    }
    const addonIndex = updatedList.findIndex((addon) => addon.addon_id === item.addon_id);
    const maxSelected = item.option_limit;

    if (e.target.checked) {
      const selectedCount = addonIndex !== -1 ? updatedList[addonIndex].option_selected.length : 0;
      let sumQtyOption = 0;
      updatedList.map((addon: any) => {
        if (addon.addon_id === item.addon_id) {
          addon.option_selected.map(
            (option: any) => (sumQtyOption = sumQtyOption + option.option_qty)
          );
        }
      });

      if (sumQtyOption === item.max_option_limit_qty) {
        return;
      }

      if (selectedCount < maxSelected) {
        const addonIndex = updatedList.findIndex((addon) => addon.addon_id === item.addon_id);
        if (addonIndex !== -1) {
          updatedList[addonIndex] = {
            ...updatedList[addonIndex],
            option_selected: [
              ...updatedList[addonIndex].option_selected,
              {
                option_id: option.option_id,
                option_name: option.option_name,
                price: option.price,
                is_selected: true,
                option_qty: 1,
              },
            ],
          };
        } else {
          updatedList.push({
            addon_id: item.addon_id,
            addon_name: item.addon_name,
            is_require: item.is_require,
            option_selected: [
              {
                option_id: option.option_id,
                option_name: option.option_name,
                price: option.price,
                is_selected: true,
                option_qty: 1,
              },
            ],
          });
        }
        setAddonList(updatedList);
      }
    } else {
      const addonIndex = updatedList.findIndex((addon) => addon.addon_id === item.addon_id);

      if (addonIndex !== -1) {
        updatedList[addonIndex].option_selected = updatedList[addonIndex].option_selected.filter(
          (selected: any) => selected.option_id !== option.option_id
        );
        // make the addon empty if we unchecked all the addons
        if (updatedList[addonIndex].option_selected.length === 0) {
          updatedList.splice(addonIndex, 1);
        }
      }
      setAddonList(updatedList);
    }
  };

  const variantChange = (variant: any) => {
    setVariantList(variant.target.value);
  };
  const optionPlusQtyHandler = (option: IProductOptionList, item: IProductAddonList) => {
    const updatedList = [...addonList];
    let sumQtyOption = 0;
    updatedList.map((addon: any) => {
      if (addon.addon_id === item.addon_id) {
        addon.option_selected.map(
          (option: any) => (sumQtyOption = sumQtyOption + option.option_qty)
        );
      }
    });

    if (sumQtyOption === item.max_option_limit_qty) {
      return;
    }

    const addonIndex = updatedList.findIndex((x) => x.addon_id === item.addon_id);
    const optionIndex = updatedList[addonIndex].option_selected.findIndex(
      (x) => x.option_id === option.option_id
    );
    let updatedAddonOption = updatedList[addonIndex].option_selected[optionIndex];

    if (updatedAddonOption) {
      if (typeof updatedAddonOption.option_qty === "number") {
        updatedAddonOption.option_qty += 1;
      } else {
        updatedAddonOption.option_qty = 1;
      }
    }
    setAddonList(updatedList);
  };
  const optionMinusQtyHandler = (option: IProductOptionList, item: IProductAddonList) => {
    const updatedList = [...addonList];

    const addonIndex = updatedList.findIndex((x) => x.addon_id === item.addon_id);
    const optionIndex = updatedList[addonIndex].option_selected.findIndex(
      (x) => x.option_id === option.option_id
    );
    let updatedAddonOption = updatedList[addonIndex].option_selected[optionIndex];

    if (updatedAddonOption) {
      if (typeof updatedAddonOption.option_qty === "number") {
        updatedAddonOption.option_qty -= 1;
      } else {
        updatedAddonOption.option_qty = 1;
      }
    }
    setAddonList(updatedList);
  };

  const addonPrice = addonList.reduce((accumulator, item) => {
    return (
      accumulator +
      (item.option_selected?.reduce((optionAccumulator, option) => {
        return optionAccumulator + (option.option_qty ?? 0) * (option.price ?? 0);
      }, 0) ?? 0)
    );
  }, 0);

  return (
    <StyledProductHeaderDrawer
      title={t("page.details_menu")}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={() => {
        handleClose();
        setQTY(1);
      }}
      visible={showModal}
      width={"100%"}
      closable={true}
      height={"90%"}
    >
      <StyledProductDrawer themeColor={themeColor}>
        <div className="imgProduct">
          <Skeleton loading={isLoading} active title={true} paragraph={false}>
            <Swiper
              autoplay={true}
              slidesPerView={1}
              spaceBetween={20}
              pagination={true}
              grabCursor={true}
              className="mySwiper"
            >
              {product?.image_url_list.length === 0 ? (
                <SwiperSlide>
                  <img src={"/images/default_service.png"} />
                </SwiperSlide>
              ) : (
                product?.image_url_list.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img src={item || "/images/default_service.png"} />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </Skeleton>
        </div>
        <div className="titleDescSection">
          <Skeleton loading={isLoading} active title={true} paragraph={{ rows: 3 }}>
            <h1 className="productTitle">{product?.name}</h1>
            <p className="productDesc">
              {product?.description.replaceAll(/<\/?[^>]+(>|$)/gi, "") || ""}
            </p>
          </Skeleton>
        </div>
        <div className="pricesSection">
          <Skeleton
            loading={isLoading}
            active
            title={true}
            paragraph={{ rows: 2, width: [39, 39] }}
          >
            <p className="finalPrice">฿{product?.standard_price}</p>
          </Skeleton>
        </div>
        {product?.is_variant && (
          <div style={{ marginBottom: 40 }}>
            <div className="separator"></div>
            {product.variant_dimensions.map((item, i) => (
              <div key={i}>
                <label className="addonsTitle">{item.dimension_name}</label>
                <Radio.Group
                  buttonStyle="solid"
                  // onChange={(e) => variantChange(e)}
                  onChange={(e) => {
                    setSelectedDimensions([
                      ...selectedDimensions,
                      {
                        _id: item._id,
                        dimension_name: item.dimension_name,
                        dimension_option_name: e.target.value,
                      },
                    ]);
                    if (selectedDimensions.some((x:any) => x._id === item._id,)
                    ) {
                      const newState = selectedDimensions.map((obj: any) => {
                          if (obj._id === item._id) {
                            return {
                              ...obj,
                              dimension_option_name:
                              e.target.value,
                            };
                          }
                          return obj;
                        },
                      );
                      setSelectedDimensions(newState);
                    }
                  }}
                  className="variantsGroup"
                >
                  {/* active stock */}
                  {/* {product.variant_list.map((variant, j) => ( */}
                    {/* <Radio.Button value={variant} key={j} disabled={!(variant.store_list.filter((store:any) => store.store_id === orgInfo.store_id)[0].is_enable)} style={!(variant.store_list.filter((store:any) => store.store_id === orgInfo.store_id).length > 0 && variant.store_list.filter((store:any) => store.store_id === orgInfo.store_id)[0].is_enable) ? {backgroundColor: '#000', opacity: 0.4} : {}}>
                      {variant.variant_name}
                    </Radio.Button> */}
                  {/* ))} */}
                  {item.dimension_option_name.map((name: any, j: number) => (
                    <Radio.Button value={name} key={j}
                      disabled={listVariantClose.length > 0 && listVariantClose.some((variant:any) => variant.dimension_list.some((d:any) => d.dimension_option_name === name && d.dimension_name === item.dimension_name))}
                    >
                      {name}
                      {/* {variant.variant_name?.substring(0, 8) + "..."} */}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
            ))}
          </div>
        )}
        {product?.product_addon_list && product?.product_addon_list.length !== 0 && (
          <div className="separator"></div>
        )}
        {product?.product_addon_list &&
          product?.product_addon_list.map((item, i) => (
            <div key={i}>
              {item.is_multi_choice ? (
                <div className="checkBoxColumn">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label className="addonsTitle">
                      {item.addon_name}{" "}
                      {item.is_require && <span style={{ color: "#F22F46" }}>*</span>}
                      <span>(Max: {item.option_limit})</span>{" "}
                    </label>
                    <label className="addonsTitle">
                      <span>(Max: {item.max_option_limit_qty} total)</span>
                    </label>
                  </div>
                  {item.product_option_list.map((option, j) => {
                    const findIndex = cart.findIndex(
                      (item: any) => item.product_id === product?._id
                    );
                    const cartIndex = cart.findIndex((z: any) => z.variant_id === variantList?._id);
                    const optionIsSelected =
                      addonList && addonList.length !== 0
                        ? addonList.some(
                            (addon) =>
                              addon.addon_id === item.addon_id &&
                              addon.option_selected.some(
                                (selected) => selected.option_id === option.option_id
                              )
                          )
                        : // it checks variant to if you've selected on the last order, shows the item as checked
                          // variantList &&
                          //   cartIndex !== -1 &&
                          //   cart[cartIndex].variant_id === variantList._id
                          // ? cart[findIndex].addon_list.some(
                          //     (addon: any) =>
                          //       addon.addon_id === item.addon_id &&
                          //       addon.option_selected.some(
                          //         (selected: any) => selected.option_id === option.option_id
                          //       )
                          //   ) :
                          false;
                    const addonIndex = addonList.findIndex((x) => x.addon_id === item.addon_id);

                    const optionIndex =
                      addonList[addonIndex] &&
                      addonList[addonIndex].option_selected &&
                      addonList[addonIndex].option_selected.findIndex(
                        (x) => x.option_id === option.option_id
                      );
                    const optionQty =
                      addonList[addonIndex] &&
                      addonList[addonIndex].option_selected &&
                      addonList[addonIndex].option_selected[optionIndex] &&
                      addonList[addonIndex].option_selected[optionIndex].option_qty;

                    return (
                      <div className="checkBoxRow" key={j}>
                        <Checkbox
                          key={j}
                          onChange={(e) => {
                            checkboxChange(e, option, item);
                          }}
                          checked={optionIsSelected}
                          disabled={option.this_store.is_enable === false ? true : false}
                        >
                          <div className="toppingLeftSide">
                            {option.option_name}
                            <span className="optionPrice">฿{option.price}</span>
                          </div>
                        </Checkbox>
                        <div className="toppingRightSide">
                          <div
                            style={{
                              opacity:
                                optionQty === option.option_qty_limit && optionQty === 1 ? 0.6 : 1,
                            }}
                            onClick={() => {
                              if (optionQty && optionQty > 1) {
                                optionMinusQtyHandler(option, item);
                              }
                            }}
                          >
                            <MinusIcon size={20} />
                          </div>
                          <span>{optionQty || 1}</span>
                          <div
                            style={{
                              opacity: optionQty === option.option_qty_limit ? 0.6 : 1,
                            }}
                            onClick={() => {
                              if (optionQty && optionQty < (option.option_qty_limit ?? 0)) {
                                optionPlusQtyHandler(option, item);
                              }
                            }}
                          >
                            <PlusIcon size={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Radio.Group onChange={(e) => radioChange(e, item)} key={i}>
                  <label className="addonsTitle">
                    {item.addon_name}{" "}
                    {item.is_require && <span style={{ color: "#F22F46" }}>*</span>}
                    <span>{`(Select 1)`}</span>
                  </label>
                  {item.product_option_list.map((option, j) => (
                    <Radio style={radioStyle} value={option} key={j} className={option.this_store.is_enable === false ? "checkBoxRow disabledCheckBox" : "checkBoxRow"} disabled={option.this_store.is_enable === false ? true : false}>
                      {option.option_name}
                      <span className="optionPrice">฿{option.price}</span>
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </div>
          ))}
        <div className="separator"></div>
        <Row>
          <Col span={24}>
            <SharedInput
              label={t("page.notation")}
              type={"text"}
              className="mb-6"
              placeholder={t("page.ex_a_little_sweet")}
              value={notation}
              onChange={(value: any) => {
                setNotation(value.target.value);
              }}
            />
          </Col>
        </Row>
        <EmptyFooterSpace />
        {showModal && (
          <div>
            <div className="addItemRow">
              <div className="qtySection">
                <div
                  className="minusDiv"
                  onClick={() => {
                    if (qty > 1) {
                      setQTY(qty - 1);
                    }
                  }}
                >
                  <MinusIcon />
                </div>
                <span>{qty}</span>
                <div className="plusDiv" onClick={() => setQTY(qty + 1)}>
                  <PlusIcon />
                </div>
              </div>
              <div className="btn-layout">
                <StyledSubmitButton
                  type="red"
                  text={
                    <Row className="addToOrderRow">
                      <span>{t("page.add")}</span>
                      <span>
                        ฿
                        {((variantList === undefined ? product?.standard_price ?? 0 : 0) +
                          addonPrice +
                          (variantList?.price ?? 0) || 0) * qty}
                      </span>
                    </Row>
                  }
                  htmlType="button"
                  onClick={() => {
                    orderHandler();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </StyledProductDrawer>
    </StyledProductHeaderDrawer>
  );
};

const StyledProductHeaderDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    margin-top: 4.2rem;
  }
  .ant-drawer-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  .ant-drawer-title {
    font-weight: 700;
  }
`;

const EmptyFooterSpace = styled.div`
  height: 150px;
`;
const StyledProductDrawer = styled.div<{ themeColor: string }>`
  .imgProduct {
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 14px;
    }
    .ant-skeleton {
      .ant-skeleton-title {
        height: 250px;
        border-radius: 16px;
        margin: 0;
      }
    }
  }
  .titleDescSection {
    .ant-skeleton {
      .ant-skeleton-title {
        height: 14px;
        border-radius: 16px;
      }
      .ant-skeleton-paragraph {
        li {
          height: 14px;
          border-radius: 16px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .pricesSection {
    .ant-skeleton {
      .ant-skeleton-title {
        height: 14px;
        border-radius: 16px;
        margin: 0;
        width: 62px !important;
      }
      .ant-skeleton-paragraph {
        display: flex;
        margin: 5px 0;
        li {
          height: 14px;
          border-radius: 16px;
          margin: 0 5px 10px 0;
        }
      }
    }
  }
  .addItemRow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px;
    align-items: center;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
    .separator {
      position: absolute;
      top: -10px;
      margin: 0;
      left: 0;
      right: 0;
    }
  }
  .qtySection {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: end;
    span {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #000000;
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    button {
      margin: 0;
      width: 206px;
      height: 60px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  .swiper-slide img {
    height: 250px;
  }
  .productTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }
  .productDesc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #a5a5a5;
  }
  .titleDescSection {
    padding: 16px 0;
  }
  .pricesSection {
    margin-top: 15px;
  }
  .finalPrice {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin: 0;
  }
  .discountRow {
    display: flex;
    gap: 7px;
    align-items: center;
  }
  .discountedPrice {
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: line-through;
    color: #a0a4ab;
    margin: 0;
  }
  .discountBadge {
    background: #49c549;
    border-radius: 34px;
    padding: 0 4px;
    font-weight: 300;
    font-size: 8px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    min-width: 28px;
    place-content: center;
  }
  .separator {
    margin: 16px auto;
    background: #fbfbfb;
    width: 100%;
    height: 10px;
  }
  .addToOrderRow {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
  input[type="radio"]:checked {
    background-color: red;
  }
  .ant-radio-inner::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.7);
    width: 50%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 0.3s ease;
    background: transparent;
    border-radius: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    position: relative;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(color) => color.themeColor};
    border: 2px solid ${(color) => color.themeColor};
    transition: background-color 0.3s ease;
  }
  .ant-radio {
    width: 17px;
    height: 17px;
  }
  span.ant-radio + * {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #000000;
    display: flex;
    gap: 5px;
  }
  .ant-radio-checked::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${(color) => color.themeColor};
  }
  .addonsTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    span {
      color: #919191;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(color) => color.themeColor};
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(color) => color.themeColor};
  }
  .checkBoxColumn {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
  }
  .checkBoxColumn {
    margin-bottom: 12px;
  }
  .checkBoxRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    place-items: center;
  }
  .disabledCheckBox {
    span.ant-radio-inner {
      background-color: #bfbfbf;
      border-color: gray;
    }
  } 
  .optionPrice {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #000;
  }
  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${(color) => color.themeColor};
    color: #fff;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid ${(color) => color.themeColor};
    z-index: 0;
  }
  .ant-radio-button-wrapper {
    height: auto;
    width: 115px;
    background: #f7f7f8;
    border-radius: 8px;
    border: 2px solid #f7f7f8;
    text-align: center;
    color: #b4b4b9;
  }
  .ant-radio-button-wrapper-disabled {
    background-color: #b4b4b9;
    color: #f7f7f8;
  }
  .variantsGroup {
    flex-direction: row;
    gap: 10px;
    flex-flow: wrap;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #f7f7f8;
  }
  .ant-radio-button-wrapper-checked span {
    margin-top: 2px;
  }
  .ant-radio-button-wrapper span {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .toppingLeftSide {
    display: flex;
    gap: 5px;
  }
  .ant-checkbox {
    top: 0.45em;
    height: fit-content;
  }
  .toppingRightSide {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }
  }
`;
