import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import QRCode from "react-qr-code";

import { SharedButton } from "@components";
import { selectAuth, selectMerchant } from "@redux";
import { IConClose } from "./icon";

interface IProps {
    handleToggleQrCode: () => void;
}

export const ComponentQRcode = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //redux states
    const { userInfo } = useSelector(selectAuth);
    const { themeColor } = useSelector(selectMerchant);
    //props
    const { handleToggleQrCode } = props;

    return (
        <StyledContainer>
            {" "}
            <div className="room" onClick={handleToggleQrCode}>
                <IConClose width={18} color="black" />
            </div>
            <div className="content">
                <div className="my_qrCode">
                    <span className="english">{t("page.qrCode")}</span>
                    <span className="thai">{t("page.qrCode_thai")}</span>
                </div>
                <QRCode value={userInfo?._id || ""} size={200} />
                <div className="merchant_id">
                    <p className="title">{t("page.member_id")}</p>
                    <p className="code">{userInfo?._id}</p>
                </div>
                <StyledButton
                    size="large"
                    bg_color={themeColor}
                    type="submit"
                    onClick={handleToggleQrCode}
                >
                    {t("page.done")}
                </StyledButton>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    /* position: fixed; */
    top: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    background: #fff;
    padding: 70px 0;
    border-radius: 16px;
    z-index: 100;
    .content {
        width: 70%;
        margin: 0 auto;
        text-align: center;
    }
    .room {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }
    .my_qrCode {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5rem;
        text-align: center;
        color: #000000;
        margin-bottom: 36px;
        .english {
            margin-right: 10px;
        }
        .thai {
        }
    }
    .merchant_id {
        margin-top: 36px;
        padding: 16px 24px;
        /* background: #f2f2f2; */
        p {
            margin: initial;
        }
        .title {
            font-weight: 400;
            color: black;
            font-size: 15px;
        }
        .code {
            color: #000000;
            font-weight: 400;
            font-size: 20px;
            margin-top: 4px;
            word-break: break-word;
            line-height: 1.5rem;
        }
    }
`;

const StyledButton = styled(SharedButton)`
    margin-top: 12px;
    font-weight: 400;
    width: auto;
    padding: 10px 37px;
    border-radius: 24px;
`;
