interface IProps {
    color?: string;
    width?: number;
    className?: string;
}

export const IConEmail = (props: IProps) => {
    const { color = "#9B9B9B", width = 20, className } = props;
    const height = Math.floor((width / 20) * 17);
    return (
        <>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M11.3961 11.4601C11.0371 11.6561 10.6346 11.7588 10.2256 11.7588C9.81658 11.7588 9.41411 11.6561 9.0551 11.4601L0.454102 6.76709V14.2011C0.454102 14.849 0.711488 15.4704 1.16964 15.9286C1.62779 16.3867 2.24918 16.6441 2.8971 16.6441H17.5541C18.202 16.6441 18.8234 16.3867 19.2816 15.9286C19.7397 15.4704 19.9971 14.849 19.9971 14.2011V6.76709L11.3961 11.4601Z"
                    fill={color}
                />
                <path
                    d="M10.2271 10.129C10.0911 10.1286 9.95738 10.0943 9.8381 10.029L0.879102 5.14296C0.750517 5.073 0.643176 4.96967 0.568381 4.84384C0.493587 4.71801 0.454108 4.57434 0.454102 4.42796L0.454102 2.79896C0.454102 2.15103 0.711488 1.52965 1.16964 1.0715C1.62779 0.613344 2.24918 0.355957 2.8971 0.355957H17.5541C18.202 0.355957 18.8234 0.613344 19.2816 1.0715C19.7397 1.52965 19.9971 2.15103 19.9971 2.79896V4.42796C19.9971 4.57434 19.9576 4.71801 19.8828 4.84384C19.808 4.96967 19.7007 5.073 19.5721 5.14296L10.6141 10.029C10.4954 10.0939 10.3624 10.1283 10.2271 10.129Z"
                    fill={color}
                />
            </svg>
        </>
    );
};
