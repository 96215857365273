import styled from "styled-components";

export const StyledWelcome = styled.div<{ themeColor: string }>`
    text-align: center;

    background-color: white;
    .box_top {
        /* background: url("/images/bgWelcome.png"); */
        background-image: url("/images/welcome_bg.svg");
        background-size: cover;
        background-position: bottom;
        padding-bottom: 80px;
        .welcome {
            font-size: 25px;
            line-height: 33px;
            color: ${(p) => p.themeColor};
            padding-top: 26px;
            font-weight: 700;
        }
        .desc {
            width: 80%;
            margin: 16px auto 44px auto;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            color: #000000;
        }
    }

    .group_images {
        transform: translateY(-50px);

        background-color: transparent;
        padding: 12px 0;
        img {
            width: 90vw;
            max-width: 435px;
            margin-top: 12px;
            height: 90vw;
            max-height: 435px;
            object-fit: cover;
            border-radius: 8px;
        }
    }
`;
