import styled from "styled-components";

export const StyledMyBooking = styled.div<{ mainColor: string }>`
  .wholePage {
    background: #f7f7f8;
    padding: 20px;
    min-height: 90vh;
    // height: 100vh;
  }
  // Nav Tabs
  .topTabs {
    padding: 20px;
  }
  .topTabs p {
    font-size: 14px;
    color: #919191;
    margin-bottom: 0;
  }
  hr {
    margin: 0.5rem 0;
    border-top: 1px solid #e1e1e1;
  }
  .nav-bar {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 10px;
    justify-content: flex-start;
    .item {
      /* width: 25%; */
      text-align: center;
      padding: 8px 0 15px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #989898;
      background: transparent;
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      font-style: normal;
    }
    .under {
      color: #000000;
      font-weight: 600;
      &::after {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 45%;
        width: 50%;
        height: 2px;
        background-color: #f22f46;
        transform: translateX(-50%);
      }
    }
  }
  .topTabs .nav-tabs {
    border: none;
    place-content: baseline;
  }
  .nav-tabs .nav-link.active {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
  }
  .nav-tabs .nav-link {
    border: 1px solid #707070;
    background: #fff;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
  }
  .all {
    width: 60px;
  }
  .lastItem {
    margin-right: 0 !important;
  }
  // Nav Tabs
  .bookingCard {
    display: flex;
    place-content: space-between;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    place-items: baseline;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .bookingCard .leftSide h1 {
    font-size: 20px;
  }
  .bookingCard .leftSide h2 {
    font-size: 16px;
  }
  .smallImages {
    display: flex;
    gap: 8px;
  }
  .smallImages img {
    width: 35px;
  }
  .rightSide {
    display: flex;
    place-items: end;
    flex-direction: column;
  }
  .rightSide label {
    color: #000;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
  }
  .rightSide p {
    color: #9b9b9b;
    text-align: right;
    font-size: 12px;
  }
  .rightSide h3 {
    color: #000;
    text-align: right;
    font-size: 20px;
  }
  .noteButton {
    display: flex;
    place-content: end;
    width: 100%;
    padding: 20px 0;
    gap: 12px;
  }
  .noteButton button {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #919191;
    color: #919191;
    padding: 5px 15px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
  }
  .noteButton button:active {
    outline: none;
  }
  .cardInside {
    display: flex;
    place-content: space-between;
    width: 100%;
  }
  .noteButton .black {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
  }
  .noMarginBottom {
    margin-bottom: 0;
  }
  // Booking Inside Css
  .topSection h1 {
    font-size: 20px;
    font-weight: bold;
  }
  .awards {
    display: flex;
    place-content: space-evenly;
    margin: 2.5rem auto 2rem auto;
  }
  .middleOne {
    width: 1px;
    background: #e1e1e1;
  }
  .leftOneDetail h1 {
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
  }
  .leftOneDetail p {
    font-size: 18px;
    margin-bottom: 0;
    color: #000;
    font-weight: bold;
  }
  .leftOne {
    display: flex;
    gap: 20px;
    place-items: end;
  }
  .leftOneDetail {
    text-align: left;
  }
  .rightOne {
    display: flex;
    gap: 20px;
    place-items: end;
  }
  .rightOneDetail h1 {
    font-size: 10px;
    color: #000;
  }
  .rightOneDetail p {
    font-size: 15px;
    margin-bottom: 0;
    color: #000;
  }
  .basketSummary {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
  }
  .SummaryTitle {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .firstHrBasket {
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e1e180;
  }
  // My Booking Basket Codes
  .basketItems {
    display: flex;
    place-content: space-between;
    margin-bottom: 20px;
    .serviceImage {
      width: 20%;
    }
  }
  .serviceImage img {
    width: 100%;
  }
  .serviceDetails {
    margin-left: 20px;
  }
  .serviceDetails h1 {
    font-size: 16px;
    font-weight: bold;
  }
  .serviceDetails ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 5px;
  }
  .serviceDetails ul li:before {
    content: "-";
    margin-right: 5px;
  }
  .serviceDetails ul li {
    font-size: 14px;
    font-weight: 600;
  }
  .serviceDetails p {
    font-size: 10px;
    display: flex;
    place-items: center;
  }
  .serviceDetails p svg {
    margin-right: 5px;
  }
  .basketSummary .noteButton button {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #919191;
    color: #919191;
    width: auto;
    cursor: pointer;
    outline: none;
    font-size: 12px;
  }
  .basketSummary .noteButton button:active {
    outline: none;
  }
  .priceQuantity h3 {
    font-size: 15px;
    font-weight: bold;
  }
  .priceQuantity h4 {
    font-size: 14px;
    text-align: right;
    font-weight: bold;
  }
  .basketSummary .leftSide {
    display: flex;
    /* max-width: 70%; */
  }
  .basketSummary .rightSide {
    display: flex;
    /* gap: 20px; */
  }
  .priceQuantity {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    place-items: end;
  }
  .priceQuantity svg {
    margin-top: 27px;
    cursor: pointer;
  }
  .showMoreDiv {
    cursor: pointer;
    height: 30px;
  }
  .showMoreDiv button {
    font-size: 11px;
    font-weight: bold;
    border: none;
    background: transparent;
    outline: none;
    color: #000;
    cursor: pointer;
  }
  .showMoreDiv button .reverse svg {
    transform: rotate(180deg);
  }
  .totalPriceSection label {
    font-size: 16px;
    margin-right: 15px;
    font-weight: bold;
    color: black;
  }
  .innerTotalPrice .totalPrice {
    font-size: 20px;
    font-weight: bold;
  }
  .totalPriceSection {
    margin-top: 40px;
    display: flex;
    place-content: end;
    place-items: center;
  }
  // My Booking Basket Codes
  .confirmationDetails {
    margin-top: 20px;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
  }
  .confirmationDetails .SummaryTitle {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .qrCode {
    display: flex;
    place-content: center;
    flex-direction: column;
    place-items: center;
    margin-top: 4rem;
  }
  .qrCode h1 {
    font-size: 14px;
    margin-top: 10px;
  }
  .confirmDetails {
    margin-top: 30px;
    p {
      /* color: black; */
      font-size: 14px;
      font-weight: bold;
    }
  }
  .confirmDetails svg {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .buttonSection {
    display: flex;
    place-content: end;
    padding: 0 20px 15px 0;
    margin-top: 0px;
    gap: 10px;
  }
  .buttonSection .redOne {
    background: #f22f46;
    border-radius: 30px;
    border: 1px solid #f22f46;
    color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    place-content: space-evenly;
    place-items: center;
    padding: 10px 40px;
    outline: none;
  }
  .buttonSection .borderOne {
    background: transparent;
    border-radius: 30px;
    border: 1px solid #707070;
    color: #707070;
    width: auto;
    cursor: pointer;
    font-size: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    place-content: space-evenly;
    place-items: center;
    padding: 10px 40px;
    outline: none;
  }
  // Cancellation Tab
  .topSection {
    margin: 10px auto 30px 0;
  }
  .basketItems {
    margin-top: 15px;
  }
  .SummaryTitle {
    margin-bottom: 10px;
  }
  .FixLeft {
    margin-left: 0;
  }
  .basketSummary svg {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .basketSummary p {
    font-size: 14px;
    font-weight: bold;
  }
  .topTitleFelx {
    display: flex;
    place-content: space-between;
  }
  .topTitleFelx .noteButton {
    width: auto;
    padding: 0;
  }
  .textAreaSection {
    display: flex;
  }
  .textAreaSection textarea {
    /* border: none; */
    border: 1px solid #e1e1e195;
    width: 100%;
    min-height: 130px;
    resize: none;
  }
  .textAreaSection textarea:focus {
    /* border: none; */
    outline: none !important;
    border: 1px solid #e1e1e1;
  }
  .textAreaSection .rightArea {
    width: 100%;
  }
  .buttonSection {
    display: flex;
    place-content: end;
    padding: 30px 20px 20px 20px;
    background: #f7f7f8;
  }
  .buttonSection button {
    background: #f22f46;
    border-radius: 30px;
    border: 1px solid #f22f46;
    color: #fff;
    width: 100%;
    height: 60px;
    cursor: pointer;
    font-weight: bold;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    place-content: space-evenly;
    place-items: center;
  }
  .buttonSection button span {
    font-size: 18px;
  }
  // Cancel Popup
  .topPopupRow {
    display: flex;
    place-content: space-between;
  }
  .wholeCancelPopup {
    height: 100vh;
    display: flex;
    flex-direction: column;
    place-content: center;
    background: #707070;
  }
  .GreenDoneIcon {
    text-align: center;
  }
  .wholeCancelPopup .buttonSection {
    margin-top: 0;
    background: #fff;
    padding: 2rem 20px 20px 20px;
  }
  .wholeCancelPopup .basketSummary {
    border-radius: 0;
  }
  .GreenDoneIcon h1 {
    font-size: 20px;
    margin-top: 2rem;
  }
  .GreenDoneIcon svg {
    cursor: pointer;
  }
  // Inside Modify
  .basketItems .noteButton {
    padding-top: 0 !important;
  }
  .blackModify {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    color: #fff !important;
  }
`;
