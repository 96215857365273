export const SummaryOrderIcon = () => (
  <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 23.2296H29.6255" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M12.4305 6.26406C6.44309 7.84772 2.74852 13.7505 3.80802 19.7408C3.90421 20.2847 4.45618 20.6034 4.99011 20.4622L25.9176 14.9269C26.4515 14.7857 26.7737 14.2357 26.5884 13.7154C24.5477 7.98461 18.4179 4.6804 12.4305 6.26406Z"
      stroke="white"
      strokeWidth="2"
    />
    <circle
      cx="11.7822"
      cy="3.8132"
      r="2.48917"
      transform="rotate(-14.8154 11.7822 3.8132)"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
