import queryString from "querystring";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
    enumExternalAuthType,
    enumExternalMethod,
    LINE_AUTH_URL,
    PATH_HOME,
    PATH_USER_SETTING,
} from "@configs";
import { logout, setExternalLogin } from "@redux";
import { ILineLogin, ILineState } from "@interfaces";
import { authApi, LineApi } from "@api";
import { useNotify } from "@utils";

export const PageLineLogin = () => {
    //page hook
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const { t } = useTranslation();
    //page variable
    const queryParams = queryString.parse(window.location.search.substring(1));

    useEffect(() => {
        if (queryParams.code) {
            const lineState = JSON.parse(queryParams?.state as string) as ILineState;
            if (lineState.type === enumExternalAuthType.LINK_LINE) {
                //console.log(queryParams);
                const lineLoginApi = LineApi(LINE_AUTH_URL);
                (async () => {
                    const lineAccessToken = await lineLoginApi.login(queryParams.code as string);
                    if (lineAccessToken.data) {
                        const lineLoginData: ILineLogin = {
                            token: lineAccessToken.data.access_token,
                            refreshToken: lineAccessToken.data.refresh_token,
                            tokenId: lineAccessToken.data.id_token,
                        };

                        try {
                            await authApi.updateLine(lineLoginData);
                            success(t("message.update_link_line_success"));
                        } catch (err: any) {
                            //console.log(err);
                            const { message } = err?.response?.data;
                            error(message);
                        } finally {
                            history.push(PATH_USER_SETTING);
                        }
                    } else {
                        error(t("message.try_again_text"));
                    }
                })();
                //
                return;
            }
            if (queryParams.state) {
                dispatch(
                    setExternalLogin({
                        type: enumExternalMethod.LINE,
                        isSignUp: false,
                        line: {
                            code: queryParams.code.toString(),
                            state: queryParams.state.toString(),
                        },
                    })
                );
                history.push(PATH_HOME);
            } else {
                dispatch(logout());
            }
        } else {
            dispatch(logout());
        }
    }, []);

    return <></>;
};
