import { Spin } from "antd";
import styled from "styled-components";
import { ReactElement } from "react";

import { IconLoading } from "@components";

interface IProps {
    children?: HTMLElement | HTMLElement[] | ReactElement<any, any>;
}

export const ComponentSpinPage = (props: IProps) => {
    return (
        <Spin spinning={true} indicator={<IconLoading size={60} />}>
            {props.children}
        </Spin>
    );
};

const StyledSpinPage = styled(Spin)`
    margin-top: 4rem;
    width: 100%;
    height: 100%;
`;
