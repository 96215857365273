import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectMerchant } from "@redux";
import { ArrowRight, IConBecomeStar } from "@components";

interface IProps {
    handleClickNext: (id: string) => void;
}

export const ComponentMembershipTier = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    //redux page
    const { membershipTiers, themeColor } = useSelector(selectMerchant);
    //page props
    const { handleClickNext } = props;

    return (
        <StyledMemberShipTier>
            <div className="title">{t("page.member_ShipTier")}</div>
            <div className="desc">{t("page.member_shipTier_desc")}</div>
            {membershipTiers?.map((membershipTier) => {
                return (
                    <>
                        {!membershipTier.isDefault && (
                            <div
                                className="membershipTiers"
                                key={membershipTier._id}
                                onClick={() => handleClickNext(membershipTier._id)}
                            >
                                <div className="membershipTiers__info">
                                    <div className="image">
                                        <IConBecomeStar size={32} color={themeColor} />
                                    </div>
                                    <div className="rank">
                                        <div className="name">{membershipTier.membershipName}</div>
                                        <div className="membershipTiers_desc">
                                            {t("page.earn_points_in_a_year", {
                                                point: membershipTier.pointThreshold,
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="arrow_right">
                                    <ArrowRight />
                                </div>
                            </div>
                        )}
                    </>
                );
            })}
        </StyledMemberShipTier>
    );
};

const StyledMemberShipTier = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto;
    padding: 0 24px 24px 24px;
    background-color: #fff;
    box-shadow: rgb(231 230 230) 0px 5px 14px 0px;
    border-radius: 12px;
    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        padding: 24px 0 18px 0;
    }
    .desc {
        font-style: normal;
        font-weight: normal;
        line-height: 1.5rem;
        font-size: 15px;
        color: #000000;
        margin-bottom: 1em;
    }
    .membershipTiers {
        display: flex;
        padding: 12px 12px 12px 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(115, 115, 115, 0.31);
        .membershipTiers__info {
            display: flex;
            align-items: flex-start;
        }
        .rank {
            margin-left: 16px;
            .name {
                word-break: break-word;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 1.5rem;
                color: #000000;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .membershipTiers_desc {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: #7b7b7b;
            }
        }
    }
    .membershipTiers:last-child {
        border-bottom: initial;
    }
    .arrow_right {
        cursor: pointer;
    }
    .image {
        width: 28px;
    }
`;
