import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
// import { useHistory } from "react-router";
import styled from "styled-components";
import { Empty } from "antd";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { benefitApi } from "@api";
import { ComponentBenefitItem, ComponentBenefitList, ComponentSpinPage } from "@components";
// import { IReward } from "@interfaces";
import { selectMerchant } from "@redux";
// import { REWARD } from "@configs";
import { IBenefit, IMembershipTier, IPagination } from "@interfaces";
import { INITIAL_LIST_PARAMS, PATH_BENEFIT } from "@configs";
import { RenderMeta } from "@utils";

export const ModulesBenefit = () => {
    //page hooks

    const history = useHistory();
    const { t } = useTranslation();
    //page state
    const [benefit, setBenefit] = useState<IBenefit[]>([]);
    const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
    const [param, setParam] = useState<IPagination>(INITIAL_LIST_PARAMS);
    const [spinning, setSpinning] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setSpinning(true);
            try {
                const res = await benefitApi.getListBenefit(param);
                const data = res.data.docs as IBenefit[];
                setBenefit(data);
            } finally {
                setSpinning(false);
            }
        })();
    }, []);

    const handleClick = (id: string) => {
        history.push(PATH_BENEFIT + "/" + id);
    };

    const handleLoadMore = async () => {
        const paramPanigation = {
            ...param,
            page: (param.page || 1) + 1,
        };
        setParam(paramPanigation);
        setLoadingPagination(true);
        const res = await benefitApi.getListBenefit(paramPanigation);
        const data = res.data.docs as IBenefit[];
        setBenefit([...benefit, ...data]);
        setLoadingPagination(false);
    };

    const loadingComponent = (
        <StyledLoading>
            <div className="card">
                <Skeleton animation="wave" variant="rectangular" className="img" />
                <div className="content">
                    <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                    <Skeleton className="content" animation="wave" height={20} />
                    <Skeleton className="content" animation="wave" height={20} />
                </div>
            </div>
            <div className="card">
                <Skeleton animation="wave" variant="rectangular" className="img" />
                <div className="content">
                    <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                    <Skeleton className="content" animation="wave" height={20} />
                    <Skeleton className="content" animation="wave" height={20} />
                </div>
            </div>
            <div className="card">
                <Skeleton animation="wave" variant="rectangular" className="img" />
                <div className="content">
                    <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                    <Skeleton className="content" animation="wave" height={20} />
                    <Skeleton className="content" animation="wave" height={20} />
                </div>
            </div>
        </StyledLoading>
    );

    return (
        <>
            <RenderMeta title={t("meta.benefit_list")} />
            {!spinning ? (
                benefit.length > 0 ? (
                    <ComponentBenefitList
                        data={benefit}
                        loadingPagination={loadingPagination}
                        handleLoadMore={handleLoadMore}
                        handleClick={handleClick}
                    />
                ) : (
                    <Empty />
                )
            ) : (
                <ComponentSpinPage children={loadingComponent} />
            )}
        </>
    );
};

interface IProps {
    data: IBenefit;
    handleClick: (id: string) => void;
}

export const ModulesBenefitItem = (props: IProps) => {
    //page props
    const { data, handleClick } = props;
    //redux state
    const { membershipTiers } = useSelector(selectMerchant);
    //page state
    const [dataRanks, setDataRanks] = useState<IMembershipTier[]>([]);
    //page variable
    const levelIds = data.levelIds;

    useEffect(() => {
        if (levelIds.length === 0) {
            setDataRanks(membershipTiers || []);
        } else {
            levelIds.forEach((item) => {
                //
                const check = membershipTiers?.findIndex((value) => value._id === item) || 0;
                if (check >= 0 && membershipTiers) {
                    dataRanks.push(membershipTiers[check]);
                }
            });
            setDataRanks([...dataRanks]);
        }
    }, []);

    return (
        <>
            <ComponentBenefitItem data={data} handleClick={handleClick} dataRanks={dataRanks} />
        </>
    );
};

const StyledLoading = styled.div`
    margin-top: 25px;
    padding: 0 24px;
    .card {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 1px 3px 6px rgba(0, 0, 0, 0.23);
        margin-bottom: 28px;
        background-color: white;
        display: flex;
        width: 100%;
        position: relative;
        .img {
            width: 170px;
            height: 170px;
            @media (max-width: 375px) {
                width: 130px;
                height: 130px;
            }
        }
        .content {
            flex: 1;
            .title {
                margin: 14px 20px 14px 20px;
            }
            .content {
                margin: 0 20px;
            }
        }
    }
`;
