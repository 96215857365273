import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

import { enumTypes } from "@configs";

interface IExternalType {
    type: enumTypes;
    id: string;
}
interface IPage {
    // navigationType: enumNavigation;
    showPopupHistory: boolean;
    trafficSourceId: string;
    externalType: IExternalType | null;
    merchantId: string | null;
}

const initialState: IPage = {
    // navigationType: enumNavigation.USER_POINT,
    showPopupHistory: false,
    trafficSourceId: "",
    externalType: null,
    merchantId: null,
};

const page = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPopupHistory: (state, action: PayloadAction<boolean>) => {
            state.showPopupHistory = action.payload;
        },
        setTrafficId: (state, action: PayloadAction<string>) => {
            state.trafficSourceId = action.payload;
        },
        setExternalTypes: (state, action: PayloadAction<IExternalType | null>) => {
            state.externalType = action.payload;
        },
        setInitMerchantId: (state, action: PayloadAction<string>) => {
            state.merchantId = action.payload;
        },
    },
});

export const selectPage = (state: RootState) => state.page;

export const {
    // setNavigationType,
    setPopupHistory,
    setTrafficId,
    setExternalTypes,
    setInitMerchantId,
} = page.actions;

export default page.reducer;
