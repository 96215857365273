import Parser from "html-react-parser";
import styled from "styled-components";

import { IBenefit, IMembershipTier } from "@interfaces";
import { BLANK_IMAGE_URL } from "@configs";

interface IProps {
    data: IBenefit;
    handleClick: (id: string) => void;
    dataRanks: IMembershipTier[];
}

export const ComponentBenefitItem = (props: IProps) => {
    //page props
    const { data, handleClick, dataRanks } = props;

    return (
        <StyledContainerBenefit>
            <div className="image">
                <img
                    src={data.imageUrl[0] || BLANK_IMAGE_URL}
                    onClick={() => handleClick(data._id || "")}
                />
            </div>
            <div className="content">
                <div className="name" onClick={() => handleClick(data._id || "")}>
                    {data.name}
                </div>
                <div className="ranks">
                    {/* <Swiper slidesPerView={2}> */}
                    {data.levelIds.length !== 0 &&
                        dataRanks.map((dataRank) => {
                            return (
                                <>
                                    {!dataRank.isDefault && (
                                        // <SwiperSlide key={dataRank._id}>
                                        <StyledRank color={dataRank.color}>
                                            {dataRank.iconUrl && <img src={dataRank.iconUrl} />}
                                            <div className="membershipName">
                                                {" "}
                                                {dataRank.membershipName}
                                            </div>
                                        </StyledRank>
                                        // </SwiperSlide>
                                    )}
                                </>
                            );
                        })}{" "}
                    {/* </Swiper> */}
                </div>
                <div className="desc">{Parser(data.description)}</div>
            </div>
        </StyledContainerBenefit>
    );
};

const StyledContainerBenefit = styled.div`
    display: flex;
    margin-bottom: 28px;
    height: 170px;
    position: relative;
    align-items: center;
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
        height: 150px;
    }
    .image {
        width: 170px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            width: 150px;
        }
        img {
            border-radius: 6px;
            width: 170px;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                width: 150px;
            }
        }
    }
    .content {
        padding: 16px;
        height: 98%;
        padding-left: calc(170px + 16px);
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            padding-left: calc(150px + 16px);
        }
        background-color: #fff;
        flex: 1;
        width: 100px;
        /* max-width: 100%; */
        .name {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            color: #000000;
            /*  white-space: nowrap; */

            text-overflow: ellipsis;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                -webkit-line-clamp: 1;
            }
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 100%;
            word-break: break-word;
        }
        .desc {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5rem;
            color: #8a8a8a;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-height: 65px;
            margin-top: 12px;
            word-break: break-word;
            p {
                word-break: break-word;
            }
            * {
                margin-bottom: 0;
                color: #8a8a8a;
                font-style: normal;
                font-weight: normal;
            }
        }
        .ranks {
            margin-top: 10px;
            /* border-radius: 12px; */
            ::-webkit-scrollbar {
                display: none;
            }
            display: flex;
            overflow-x: auto;
            > div {
                flex: 1;
                min-width: fit-content;
            }
        }
    }
`;

const StyledRank = styled.div<{ color: string }>`
    display: flex;
    padding: 3px 8px;
    min-height: 19px;
    background-color: ${(p) => p.color};
    border-radius: 16px;
    color: #fff;
    font-size: 8px;
    margin: 0px 6px 0 0;
    max-width: fit-content;
    line-height: 9px;
    align-items: center;
    img {
        width: 12px;
        height: 12px;
        object-fit: contain;
    }
    .membershipName {
        display: inline-block;
        font-size: 8px;
        margin-bottom: 2px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 6px;

        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        top: 1px;
    }
`;
