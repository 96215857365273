import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { IMemberTier } from "@interfaces";
import { selectAuth, selectMerchant } from "@redux";
import { ModuleMemberShipTier } from "@modules";
import { IConUser } from "@components";
import { RenderMeta } from "@utils";

interface IProps {
    avatar: string;
    fullName: string;
    memberTier: IMemberTier | undefined;
}

export const ComponentTIerDetail = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //page props
    const { avatar, fullName, memberTier } = props;
    //redux state
    const { membershipTiers, themeColor } = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    //page variable
    const accumulativePoints = userInfo?.memberTier?.accumulativePoints || 0;
    const membershipTier = membershipTiers?.find(
        (membershipTier) => membershipTier.pointThreshold > accumulativePoints
    );

    const present = Math.round(
        (+accumulativePoints / +(membershipTier?.pointThreshold || 1)) * 100
    );

    const membershipName =
        membershipTier?.membershipName ||
        (membershipTiers &&
            membershipTiers.length > 1 &&
            membershipTiers[membershipTiers?.length - 1].membershipName);

    return (
        <>
            <RenderMeta title={t("meta.membership_tier")} />
            <StyledTierDetail color={memberTier?.color || "#000"}>
                <div className="avatar">
                    <div className="image">
                        {avatar ? <img src={avatar} /> : <IConUser size={88} />}
                    </div>
                    <div className="info">
                        <div className="name">
                            {t("page.hello")} <span style={{ fontWeight: 700 }}>{fullName}</span>
                        </div>
                        <div className="rank">
                            {memberTier?.iconUrl && <img src={memberTier?.iconUrl} />}
                            <span> {memberTier?.tierName}</span>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="square">
                        <CircularProgressbar
                            value={present}
                            strokeWidth={10}
                            styles={buildStyles({
                                pathColor: themeColor,
                            })}
                        />
                        <div className="text">
                            {membershipTier ? (
                                <>
                                    <div className="point">
                                        {accumulativePoints} {t("page._of")}{" "}
                                        {membershipTier?.pointThreshold}
                                    </div>
                                    <div className="points">{t("page._points")}</div>
                                    <div className="to_level">
                                        {t("page._to_level", {
                                            rank: membershipName,
                                        })}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="point">{accumulativePoints}</div>
                                    <div className="points">{t("page._points")}</div>
                                    {membershipName && (
                                        <div className="to_level">
                                            {membershipName}
                                            {t("page._level")}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <ModuleMemberShipTier />
                </div>
            </StyledTierDetail>
        </>
    );
};

const StyledTierDetail = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .avatar {
        border-radius: 0 0 16px 16px;
        box-shadow: rgb(211 211 211) 2px 3px 8px 0px;
        margin-bottom: 8px;
        padding: 32px 0;
        background-color: #fff;
        display: flex;
        align-items: center;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            padding: 20px 0;
        }
        .image {
            margin: 0 16px;
            img {
                border-radius: 50%;
                width: 94px;
                height: 94px;
                object-fit: cover;
                @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                    width: 76px;
                    height: 76px;
                }
            }
        }
        .info {
            .name {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: #000000;
                margin-bottom: 6px;
            }
            .rank {
                align-items: center;
                justify-content: center;
                display: flex;
                min-width: 50px;
                border-radius: 16px;
                padding: 4px 6px;
                font-style: normal;
                font-weight: bold;
                font-size: 8px;
                background-color: ${(p) => p.color};
                width: fit-content;
                color: #fff;
                img {
                    width: 12px;
                    height: 12px;
                    object-fit: cover;
                    margin-right: 4px;
                }
                span {
                    display: inline-block;
                    margin-right: 4px;

                    font-weight: bold;
                    font-size: 8px;
                }
            }
        }
    }
    .content {
        flex: 1;
        background: #f7f7f8;
        padding-bottom: 46px;
        .square {
            position: relative;
            height: fit-content;
            text-align: center;
            padding: 50px 0;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                padding: 42px 0;
            }
            img {
                width: 100%;
            }
            .text {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 100%;
                .point {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    color: #000000;
                    margin: 6px 0;
                }
                .points {
                    margin-top: -4px;
                    font-weight: 700;
                    color: #949494;
                }
                .to_level {
                    font-style: normal;

                    font-size: 12px;
                    color: #9a9a9a;
                }
            }
        }
    }
    .CircularProgressbar {
        width: 60%;
    }
`;
