import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { FormikErrors, FormikTouched } from "formik";
import Parser from "html-react-parser";

import {
    IDate,
    IHandleBlur,
    IHandleChange,
    IHandleChangeSelect,
    ISelect,
    ISocialMedia,
    ISurveyQuestions,
} from "@interfaces";
import { SharedButton, SharedCheckBox, SharedInput, SharedSelect, StyledError } from "..";
import { selectAuth, selectMerchant } from "@redux";
import { ModulesTelInput } from "@modules";

interface IProps {
    days: ISelect[];
    onChangeDay: IHandleChangeSelect;
    onChangeMonth: IHandleChangeSelect;
    onChangeYear: IHandleChangeSelect;
    months: ISelect[];
    years: ISelect[];
    onChangeAdditionField: IHandleChangeSelect;
    values: ISocialMedia;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<ISocialMedia>;
    touched: FormikTouched<ISocialMedia>;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    date: IDate;
    surveyQuestions: ISurveyQuestions[];
    handleChangeAnswerByIndex: (answer: string | string[], index: number) => void;
    setFieldTouched: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<ISocialMedia>>;
    errorPhone: string;
    setFieldValue: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<ISocialMedia>>;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
    showTermCondition: () => void;
    termCondition: string;
    showTerm: boolean;
}

const { Option } = Select;

export const ComponentFromSocial = (props: IProps) => {
    const {
        days,
        months,
        years,
        onChangeDay,
        onChangeYear,
        onChangeMonth,
        // onChangeAdditionField,
        values,
        handleBlur,
        handleChange,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        date,
        surveyQuestions,
        handleChangeAnswerByIndex,
        setFieldTouched,
        errorPhone,
        setFieldValue,
        setError,
        showTermCondition,
        termCondition,
        showTerm,
    } = props;
    //page hook
    const { t } = useTranslation();
    //page variable
    const merchant = useSelector(selectMerchant);
    const fields = merchant.signUpSettings?.fields;
    const { userInfo } = useSelector(selectAuth);
    const contact = userInfo?.contact;

    return (
        <StyledContainer showTerm={showTerm}>
            <form onSubmit={handleSubmit}>
                <SharedInput
                    placeholder={t("page.name")}
                    value={values.fullName}
                    name="fullName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors.fullName}
                    touched={touched.fullName}
                />
                <ModulesTelInput
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    value={values.phoneNumber || ""}
                    errorPhone={errorPhone}
                    setError={setError}
                    disabled={!!contact?.phoneNumber}
                />
                <StyledSharedInput
                    placeholder={t("page.email")}
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors.email}
                    touched={touched.email}
                    disable={!!contact?.email}
                />
                <div className="day">
                    <div className="title">{t("page.birth_date")}</div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <SharedSelect value={date.day} data={days} onChange={onChangeDay} />
                        </Col>
                        <Col span={8}>
                            <SharedSelect
                                value={date.month}
                                data={months}
                                onChange={onChangeMonth}
                            />
                        </Col>
                        <Col span={8}>
                            <SharedSelect value={date.year} data={years} onChange={onChangeYear} />
                        </Col>
                    </Row>
                    {date.error && (
                        <StyledError visible={true}>{t("validation.is_required")} </StyledError>
                    )}
                </div>
                {surveyQuestions.length !== 0 &&
                    fields?.map((field, index) => {
                        return (
                            <>
                                <Select
                                    placeholder={field.fieldName}
                                    value={surveyQuestions[index].answers}
                                    onChange={(value: string[]) =>
                                        handleChangeAnswerByIndex(value, index)
                                    }
                                    mode={field.multiple ? "multiple" : "default"}
                                >
                                    {field.options.map((option) => {
                                        return (
                                            <Option
                                                key={option.optionName}
                                                value={option.optionName}
                                            >
                                                {option.optionName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </>
                        );
                    })}
                <div className="bottom">
                    <div className="term-field">
                        <SharedCheckBox
                            name="acceptTermsAndConditions"
                            value={values.acceptTermsAndConditions || false}
                            onChange={handleChange}
                            label={<span>{t("page.accept")} </span>}
                        />
                        <span
                            style={{ textDecoration: "underline", cursor: "pointer", marginTop: 2 }}
                            onClick={showTermCondition}
                        >
                            {t("page._terms_and_conditions")}
                        </span>
                    </div>
                    <div
                        className="accept-text-err"
                        style={
                            errors.acceptTermsAndConditions && touched.acceptTermsAndConditions
                                ? { color: "red", visibility: "visible" }
                                : {}
                        }
                    >
                        {errors.acceptTermsAndConditions || "---"}
                    </div>
                    <StyledButton
                        size="small"
                        bg_color={merchant.themeColor}
                        disabled={isSubmitting}
                        type="submit"
                    >
                        {t("page.sign_up")}
                    </StyledButton>
                </div>
            </form>
            <div className="term-condition">{Parser(termCondition)}</div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{ showTerm: boolean }>`
    position: relative;
    padding: 11px;
    background-color: #fff;
    color: #676767;
    text-align: start;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;

    .title {
        margin-bottom: 8px;
    }
    .day {
        background-color: #f7f7f8;
        margin-bottom: 27px;
        padding: 11px;
        border-radius: 6px;

        .ant-select-selection {
            background-color: #fff;
        }
    }
    .bottom {
        width: fit-content;
        margin: 0 auto;
        margin-top: 18px;
        text-align: center;
        span {
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #676767;
        }
        .ant-checkbox-wrapper {
            display: block;
        }
    }
    .ant-select-selection {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        height: 48px;
        font-size: 16px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f7f7f8;
        margin-bottom: 14px;
        border: none;
        border-radius: 6px;
    }
    .selected-dial-code {
        padding-left: 0 !important;
    }
    .selected-flag {
        display: flex !important;
    }
    .intl-tel-input .country-list {
        width: 100%;
    }
    .intl-tel-input input {
        border-radius: 6px;
    }
    .term-field {
        display: flex;
        justify-content: center;
    }
    .term-condition {
        display: ${(p) => (p.showTerm ? "unset;" : "none;")};
        width: 100%;
        height: 100%;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        overflow: auto;
        z-index: 100;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const StyledButton = styled(SharedButton)`
    margin-top: 24px;
`;

const StyledSharedInput = styled(SharedInput)`
    margin-top: 24px;
`;
