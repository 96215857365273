import { memo, useCallback } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ComponentGoogleButton } from "@components";
import {
    addConfigClient,
    ggLogin,
    ggSignup,
    logout,
    selectConfigClient,
    selectMerchant,
    setConfigClient,
    setExternalLogin,
    setLoading,
} from "@redux";
import { IExternalAuthState, IGGLogin, IGGSignup } from "@interfaces";
import { enumExternalAuthType, enumExternalMethod, PATH_HOME, PATH_SIGN_UP_FORM } from "@configs";
import { useNotify } from "@utils";

interface IProps {
    errorHandler: (error: any) => void;
    state: IExternalAuthState;
}

export const ModuleGoogle = memo((props: IProps) => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error } = useNotify();
    const history = useHistory();
    // redux states
    const { _id } = useSelector(selectMerchant);
    const configClient = useSelector(selectConfigClient);
    const handleGGSuccess = async (response: any) => {
        const accessToken = response?.accessToken;
        dispatch(setLoading(true));
        if (!accessToken) {
            if (props.state.type === enumExternalAuthType.LOGIN) {
                error("page.sign_in_failed");
            } else {
                error("page.sign_up_failed");
            }
            dispatch(logout());
        }

        try {
            let response;
            if (props.state.type === enumExternalAuthType.LOGIN) {
                const ggLoginData: IGGLogin = {
                    token: accessToken,
                };
                response = await dispatch(ggLogin(ggLoginData));
                //WHAT: wrap function in create async thunk
                //@ts-ignore
                unwrapResult(response);
            } else if (props.state.type === enumExternalAuthType.SIGNUP) {
                const ggSignupData: IGGSignup = {
                    token: accessToken,
                };

                response = await dispatch(ggSignup(ggSignupData));
                //WHAT: wrap function in create async thunk
                //@ts-ignore
                unwrapResult(response);
            }

            //@ts-ignore
            if (!response.payload.accessToken) {
                error(t("page.email_password_correct"));
            } else {
                //@ts-ignore
                const authData = response.payload;
                const index = configClient.findIndex((item) => item.merchantId === _id);

                if (index >= 1) {
                    dispatch(
                        setConfigClient({
                            index,
                            configClient: {
                                merchantId: _id || "",
                                token: authData,
                            },
                        })
                    );
                } else {
                    dispatch(
                        addConfigClient({
                            merchantId: _id || "",
                            token: authData,
                        })
                    );
                }
                props.state.type === enumExternalAuthType.LOGIN
                    ? history.push(PATH_HOME)
                    : history.push(PATH_SIGN_UP_FORM);
            }
        } catch (error) {
            props.errorHandler(error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleGGFail = () => {
        dispatch(logout());
        dispatch(setLoading(false));
    };

    const handleOnClick = useCallback(() => {
        dispatch(setLoading(true));

        dispatch(
            setExternalLogin({
                type: enumExternalMethod.GOOGLE,
                isSignUp: props.state.type === enumExternalAuthType.SIGNUP ? true : false,
            })
        );
    }, []);

    return (
        <GoogleLogin
            clientId={
                process.env.REACT_APP_GOOGLE_ID ||
                "918086977464-i1t2r5gpv27chedpe4jv9raihno46qgt.apps.googleusercontent.com"
            }
            render={(renderProps) => <ComponentGoogleButton onClick={renderProps.onClick} />}
            onSuccess={handleGGSuccess}
            onFailure={handleGGFail}
            cookiePolicy={"single_host_origin"}
            onRequest={handleOnClick}
        />
    );
});
