import page from "./page";
import message from "./message";
import validation from "./validation";
import meta from "./meta";

export const TRANSLATIONS_TH = {
    page,
    message,
    validation,
    meta,
};
