import styled from "styled-components";

export const StyedCardInside = styled.div<{ mainColor: string }>`
  .wholePageGray {
    padding: 20px;
    background: #f7f7f8;
  }
  // topFirst Image like Cards
  .cardInside {
    display: flex;
    background: #fc5064;
    width: 100%;
    height: 225px;
    border-radius: 15px;
    place-content: space-between;
    overflow: hidden;
    cursor: pointer;
  }
  .leftSide h1 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  .leftSide h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
  }
  .leftSide {
    padding: 20px;
    position: relative;
    width: 60%;
    min-width: 60%;
  }
  .leftSide hr {
    border-top: 0.5px solid #ffffff73;
  }
  .leftSide p {
    font-size: 12px;
    color: #fff;
  }
  .validityStick p {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 6px;
  }
  .rightSide img {
    // width: 100%;
    height: 100%;
    border-radius: 60px 0 0 60px / 180px 0 0 180px;
    transform: scale(1.05);
  }
  // topFirst Image like Cards
  .wholePage {
    padding: 0 20px 20px 20px;
  }
  // Swiper Slider
  .swiper-pagination-bullet-active {
    background: #000;
  }
  // Swiper Slider
  // Bot Section from Services Inside
  .botSection {
    padding: 18px 0;
    min-height: 250px;
  }
  .nav-link {
    text-align: center;
    padding: 15px 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #989898;
    background: transparent;
    cursor: pointer;
    position: relative;
    font-style: normal;
    margin-right: 15px;
  }
  li {
    padding: 0 !important;
  }
  .nav-link.active {
    color: #000000;
    font-weight: 600;
    border: none !important;
    background-color: transparent;
    &::after {
      content: "";
      position: absolute;
      bottom: 8px;
      left: 45%;
      width: 50%;
      height: 2.5px;
      border-radius: 10px;
      background-color: #000;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link:hover {
    border: 1px solid transparent;
  }
  .tab-content {
    margin-top: 10px;
  }
  .buttonSection {
    margin-top: 20px;
    display: flex;
    place-content: space-evenly;
  }
  .buttonSection .addService {
    background: #000;
    border-radius: 30px;
    border: 1px solid #000;
    color: #fff;
    width: 160px;
    height: 50px;
    cursor: pointer;
    font-weight: 600;
  }
  .buttonSection .Next {
    background: #f22f46;
    border-radius: 30px;
    border: 1px solid #f22f46;
    color: #fff;
    max-width: 460px;
    width: 100%;
    position: fixed;
    bottom: ${(props) => `${props.theme.footer.height}`};
    margin-bottom: 15px;
    font-size: 16px;
    height: 50px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    place-content: space-evenly;
    place-items: center;
  }
  .buttonSection .Next span {
    font-weight: 500;
  }
  button:focus {
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background: #000;
  }
  // Bot Section from Services Inside
  .singleImage {
    margin-top: 20px;
  }
  .singleImage img {
    width: 100%;
    border-radius: 15px;
  }
  .chatIcon {
    position: fixed;
    display: flex;
    width: 480px;
    bottom: 85px;
    place-content: end;
    z-index: 9;
    padding-right: 15px;
  }
  /* .chatIcon {
        cursor: pointer;
        position: -webkit-sticky;
        position: sticky;
        z-index: 999;
        bottom: 0px;
        display: flex;
        width: 50px;
        height: 50px;
        margin-left: auto;
        margin-right: 15px;
        border-radius: 50%;
    } */
`;
