import { CSSProperties } from "styled-components";

interface IProps {
    style: CSSProperties;
}

export const IConPassword = (props: IProps) => {
    const { style } = props;
    return (
        <>
            <svg
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={style}
            >
                <path
                    d="M8 0.333008C6.5869 0.337228 5.23288 0.900452 4.23366 1.89967C3.23444 2.89889 2.67122 4.25291 2.667 5.66601V7.66601C1.83047 8.42632 1.16135 9.35242 0.702196 10.3854C0.243041 11.4184 0.00390425 12.5356 0 13.666C0 15.7877 0.842855 17.8226 2.34315 19.3229C3.84344 20.8232 5.87827 21.666 8 21.666C10.1217 21.666 12.1566 20.8232 13.6569 19.3229C15.1571 17.8226 16 15.7877 16 13.666C15.9961 12.5356 15.757 11.4184 15.2978 10.3854C14.8386 9.35242 14.1695 8.42632 13.333 7.66601V5.66601C13.3288 4.25291 12.7656 2.89889 11.7663 1.89967C10.7671 0.900452 9.4131 0.337228 8 0.333008V0.333008ZM9.333 14.6V17.667H6.667V14.6C6.26183 14.3764 5.92423 14.048 5.68954 13.6492C5.45485 13.2504 5.3317 12.7958 5.333 12.333C5.34652 11.6348 5.6334 10.9697 6.13199 10.4806C6.63059 9.99159 7.30111 9.71765 7.9995 9.71765C8.69789 9.71765 9.36841 9.99159 9.86701 10.4806C10.3656 10.9697 10.6525 11.6348 10.666 12.333C10.6674 12.7957 10.5444 13.2502 10.3099 13.649C10.0754 14.0478 9.73798 14.3763 9.333 14.6ZM10.666 6.20001C9.83025 5.81928 8.91791 5.63654 8 5.66601C7.08183 5.63608 6.16914 5.81848 5.333 6.19901V5.66601C5.32613 5.31149 5.39003 4.95917 5.52095 4.62964C5.65187 4.30012 5.84719 4 6.09548 3.74686C6.34376 3.49372 6.64004 3.29263 6.96697 3.15535C7.2939 3.01807 7.64492 2.94737 7.9995 2.94737C8.35408 2.94737 8.7051 3.01807 9.03203 3.15535C9.35896 3.29263 9.65524 3.49372 9.90352 3.74686C10.1518 4 10.3471 4.30012 10.478 4.62964C10.609 4.95917 10.6729 5.31149 10.666 5.66601V6.20001Z"
                    fill="#9B9B9B"
                />
            </svg>
        </>
    );
};
