import { IGetStoreExample } from "@interfaces";
import { OpenningIcon, PhoneStoreDetailsIcon, PinDetailsIcon } from "../icon";

interface IProps {
  storeDetails?: IGetStoreExample;
  setShowStoreDetails: (type: any) => void;
}

export const SelectedStoreDetails = (props: IProps) => {
  const { storeDetails, setShowStoreDetails } = props;
  return (
    <div className="wholePage">
      <div className="topStoreInfo2">
        <div className="logoSide">
          <div className="circle">
            <h1>{storeDetails?.store_name}</h1>
          </div>
        </div>
        <div className="rightOfLogoSide">
          <h1>{storeDetails?.store_name}</h1>
          <h2>{storeDetails?.description}</h2>
        </div>
      </div>
      <div className="pinRow">
        <div className="pinSide">
          <PinDetailsIcon />
          <p>{storeDetails?.address}</p>
        </div>
        <div className="imgSide">
          <img src="https://i.ibb.co/gPxS57y/Image-136.png" />
        </div>
      </div>
      <hr className="customHR" />
      <div className="phoneRow">
        <div className="leftPhoneRow">
          <PhoneStoreDetailsIcon />
          <p>{storeDetails?.customer_service_tel}</p>
        </div>
        <div className="rightPhoneRow">&gt;</div>
      </div>
      <hr className="customHR" />
      <div className="openningRow">
        <div className="openningTime firstOpenning">
          <OpenningIcon />
          <div className="openningItems">
            <h1>Opening time :</h1>
            <p>Monday - Friday</p>
            <p>Saturday</p>
            <p>Sunday</p>
          </div>
        </div>
        <div className="openningTime">
          <h1>&nbsp;</h1>
          <p>{storeDetails?.business_hours?.monday?.open_hour}</p>
          <p>{storeDetails?.business_hours?.saturday?.open_hour}</p>
          <p>{storeDetails?.business_hours?.sunday?.open_hour}</p>
        </div>
      </div>
      <button
        className="btn btn-block mt-5"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowStoreDetails(false);
        }}
      >
        Click to back
      </button>
    </div>
  );
};
