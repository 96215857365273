import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IConfigClient } from "@interfaces";

import { RootState } from ".";
const initialState: IConfigClient[] = [];

const configClient = createSlice({
    name: "configClient",
    initialState,
    reducers: {
        addConfigClient: (state, action: PayloadAction<IConfigClient>) => {
            state.push(action.payload);
            return state;
        },
        setConfigClient: (
            state,
            action: PayloadAction<{ index: number; configClient: IConfigClient }>
        ) => {
            state[action.payload.index] = action.payload.configClient;
        },
        tokenConfigClient: (
            state,
            action: PayloadAction<{ index: number; accessToken: string }>
        ) => {
            state[action.payload.index].token = {
                ...state[action.payload.index].token,
                accessToken: action.payload.accessToken,
            };
        },
        resetConfigClient: (state) => {
            state = initialState;
            return state;
        },
        removeConfigClient: (state, action: PayloadAction<number>) => {
            state = state.splice(action.payload, 1);
        },
    },
});

export const selectConfigClient = (state: RootState) => state.configClient;

export const {
    addConfigClient,
    setConfigClient,
    resetConfigClient,
    tokenConfigClient,
    removeConfigClient,
} = configClient.actions;

export default configClient.reducer;
