import styled from "styled-components";

export const StyledContainerSocial = styled.div`
    .title {
        display: flex;
        div {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            max-width: 70%;
            margin: 0 auto 46px auto;
        }
        svg {
            cursor: pointer;
        }
        .btn-close {
            position: absolute;
            right: 25px;
            top: 20px;
        }
    }
`;
