import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ComponentUserVerify } from "@components";
import {
    enumUseSetting,
    enumValidation,
    PATH_HOME,
    PATH_USER_SETTING,
    TIME_RESEND,
} from "@configs";
import { setLoading } from "@redux";
import { verifyApi } from "@api";
import { useNotify } from "@utils";
import { useTranslation } from "react-i18next";

export const PageVerify = () => {
    //page hooks
    const { type } = useParams<{ type: enumUseSetting }>();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const { t } = useTranslation();
    const history = useHistory();
    //page props
    const [otp, setOtp] = useState<string>("");
    const [timeResend, setTimeResend] = useState<number>(TIME_RESEND);
    //page variable
    const email = window.sessionStorage.getItem("email") || "";
    const phoneNumber = window.sessionStorage.getItem("phoneNumber") || "";

    useEffect(() => {
        //WHAT: CHECK EMAIL OR PHONE
        if (!email && !phoneNumber) {
            history.push(PATH_HOME);
        }
    }, []);

    const handleChangeOtp = (otp: string) => {
        setOtp(otp);
    };

    const handleVerifyEmail = async () => {
        try {
            dispatch(setLoading(true));
            const res = await verifyApi.verifyEmailCode({
                code: otp,
                email,
            });
            const message = res.data.message;
            if (message) {
                success(t("message.verify_email.success"));
            }
            window.sessionStorage.removeItem("email");
            window.sessionStorage.removeItem("phoneNumber");
            history.push(PATH_USER_SETTING);
        } catch (errors: any) {
            const { message } = errors.response.data;
            if (message) {
                error(message);
            } else {
                error(t("message.verify_email.fail"));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleVerifyPhone = async () => {
        try {
            dispatch(setLoading(true));
            const res = await verifyApi.verifyPhoneCode({
                code: otp,
                phoneNumber,
            });
            const message = res.data.message;
            if (message) {
                success(t("message.verify_phone.success"));
            }
            window.sessionStorage.removeItem("email");
            window.sessionStorage.removeItem("phoneNumber");
            history.push(PATH_USER_SETTING);
        } catch (errors: any) {
            const { message } = errors.response.data;
            if (message) {
                error(message);
            } else {
                error(t("message.verify_phone.fail"));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSave = () => {
        if (otp.length < enumValidation.LENTH_OTP) return;
        if (type === enumUseSetting.EMAIL) {
            handleVerifyEmail();
        } else {
            handleVerifyPhone();
        }
    };

    const handleResend = async () => {
        if (timeResend !== TIME_RESEND) return;
        try {
            dispatch(setLoading(true));
            let res;
            if (type === enumUseSetting.EMAIL) {
                res = await verifyApi.resendEmailCode({
                    email,
                });
            } else {
                res = await verifyApi.resendPhoneCode({
                    phoneNumber,
                });
            }
            if (res.data.message) {
                success(
                    type === enumUseSetting.EMAIL
                        ? t("message.email_send")
                        : t("message.phone_send")
                );
                let currentTime = timeResend;
                const id = setInterval(() => {
                    currentTime = currentTime - 1;
                    setTimeResend(() => currentTime);
                    if (currentTime === 0) {
                        setTimeResend(TIME_RESEND);
                        clearInterval(id);
                    }
                }, 1000);
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ComponentUserVerify
            otp={otp}
            handleChangeOtp={handleChangeOtp}
            type={type}
            handleSave={handleSave}
            timeResend={timeResend}
            handleResend={handleResend}
        />
    );
};
