import { IGetStoreExample } from "@interfaces";
import { clearCart, selectMerchant, setMerchant, store } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotify } from "@utils";
import { BackIcon } from "../icon/CheckHostModal/BackIcon";
import { AllStoresPinIcon } from "../icon/AllStoresPinIcon";
import { AllStoresRightArrow } from "../icon/AllStoresRightArrow";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import styled from "styled-components";
import { SharedButtonSub } from "../shared";

interface IProps {
  allStoreDetails: IGetStoreExample[];
  setShowAllStores: (type: any) => void;
  isHostClosed: boolean;
  fetchMoreData: any;
  hasMore: boolean;
}

export const AllStoresTab = (props: IProps) => {
  const dispatch = useDispatch();
  const { error } = useNotify();
  const { t, i18n } = useTranslation();
  const merchant = store.getState().merchant;
  const { allStoreDetails, setShowAllStores, isHostClosed, fetchMoreData, hasMore } = props;
  const [location, setLocation] = useState<any>(null);
  const orgInfo = useSelector((state: any) => state.merchant);
  const { themeColor } = useSelector(selectMerchant);
  const [isLoading, setIsloading] = useState(true);

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Earth's radius in kilometers
    const lat1Rad = (lat1 * Math.PI) / 180;
    const lon1Rad = (lon1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;
    const lon2Rad = (lon2 * Math.PI) / 180;

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err: any) => {
          error("Error getting location:", err);
        }
      );
    } else {
      error("Geolocation is not supported in this browser.");
    }
  }, []);

  const handleCalculateDistance = (destLat: any, destLon: any) => {
    if (location) {
      const { latitude: mobileLat, longitude: mobileLon } = location;
      const calculatedDistance: any = calculateDistance(mobileLat, mobileLon, destLat, destLon);
      return calculatedDistance;
    }
    return null;
  };

  const distances = allStoreDetails
    .filter((item) => item.location?.latlon && item.is_enable && !item.is_delete && !item.is_hq_store && item.is_client_order)
    .map(
      (item) =>
        item.location?.latlon && {
          id: item._id,
          distance: handleCalculateDistance(item.location.latlon[1], item.location.latlon[0]),
        }
    );

  distances.sort((a: any, b: any) => a.distance - b.distance);

  const loadingComponent = (
    <StyledSkeleton>
      <Skeleton loading={true} active title={false} paragraph={{ rows: 5 }}></Skeleton>
    </StyledSkeleton>
  );

  return (
    <div className="wholeGrayPage">
      <div className="topTitleRow">
        {isHostClosed === false && orgInfo && orgInfo.store_id && (
          <div
            onClick={() => {
              setShowAllStores(false);
            }}
          >
            <BackIcon color={themeColor} />
          </div>
        )}
        <h1 style={{ color: themeColor }}>{t("page.near_store")}</h1>
      </div>

      {isLoading === false ? (
        <>
          {distances.map(({ id, distance }: any) => {
            const item: any = allStoreDetails.find((store) => store._id === id);
            return (
              <div
                className="threeTab"
                onClick={() => {
                  setShowAllStores(false);
                  dispatch(clearCart);
                  dispatch(
                    setMerchant({
                      ...merchant,
                      store_id: item._id,
                      store_code: item.store_code,
                      address: item.address,
                      store_name: item.store_name,
                      location: item.location,
                      business_hours: item.business_hours,
                    })
                  );
                }}
                key={id}
              >
                <div className="tab shopDiv">
                  <div className="leftShopDetail">
                    <AllStoresPinIcon />

                    <h3>
                      {item["store_name"]}{" "}
                      {distance === null ? (
                        <>
                          <span>Loading...</span>
                        </>
                      ) : (
                        <>
                          <span>{distance && distance.toFixed(1)} km Away</span>
                        </>
                      )}
                    </h3>
                    <div className="rightArrow">
                      <AllStoresRightArrow />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {hasMore ? (
            <div className="loadMoreStoreButton">
              <SharedButtonSub
                text={t("page.load_more")}
                onClick={fetchMoreData}
                type={"default"}
                style={{ background: themeColor }}
              />
            </div>
          ) : (
            <div style={{paddingBottom: '60px'}}/>
          )}
        </>
      ) : (
        loadingComponent
      )}

      {isHostClosed === false && orgInfo && orgInfo.store_id && (
        <button
          className="btn btn-block mt-5"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowAllStores(false);
          }}
        >
          Click to back
        </button>
      )}
    </div>
  );
};

const StyledSkeleton = styled.div`
  .ant-skeleton-paragraph {
    margin-top: 0 !important;
    li {
      height: 54px;
      border-radius: 16px;
      margin-bottom: 20px;
      width: 100% !important;
    }
  }
`;
