import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper";

import { freePointApi, merchantApi } from "@api";
import { ComponentFreePointDetail, ComponentPopUpConfirm } from "@components";
import {
    PATH_404,
    enumStatus,
    enumTypes,
    PATH_HOME,
    enumValidation,
    PATH_SIGN_UP,
    PATH_SIGN_UP_FORM,
} from "@configs";
import { IFreePoint } from "@interfaces";
import { selectAuth, selectConfigClient, setExternalTypes, setLoading } from "@redux";
import { useNotify } from "@utils";

export const PageFreePoint = () => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { error, success, warning } = useNotify();
    //page redux
    const { auth, userInfo } = useSelector(selectAuth);
    const configClient = useSelector(selectConfigClient);
    //page state
    const [freePoint, setFreePoint] = useState<IFreePoint>();
    const [currentImage, setCurrentImage] = useState<number>(enumValidation.NUMBER_ONE);
    const [showPopupConfirm, setShowPopupConfirm] = useState<boolean>(false);
    //page variable

    useEffect(() => {
        useFreePoint();
    }, []);

    const useFreePoint = async () => {
        try {
            dispatch(setLoading(true));
            if (id) {
                const res = await merchantApi.getFreePointId(id);
                const data = res.data as IFreePoint;
                setFreePoint(data);
                const index = configClient.findIndex((item) => item.merchantId === data.merchantId);
                if (index < 0 || userInfo?.status === enumStatus.INACTIVE) {
                    dispatch(setExternalTypes({ type: enumTypes.FREE_POINT, id }));
                } else {
                    dispatch(setExternalTypes(null));
                }
            } else {
                history.push(PATH_404);
            }
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                error(mess);
            }
            history.push(PATH_404);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const checkIsLogin = () => {
        if (!auth?.accessToken) {
            warning(t("page.please_sign_up_to_collect_free_point"));
            return history.push(PATH_SIGN_UP);
        } else if (userInfo?.status === enumStatus.INACTIVE) {
            history.push(PATH_SIGN_UP_FORM);
        }
    };

    const handleRedeemedFreePoint = async () => {
        try {
            dispatch(setLoading(true));
            const res = await freePointApi.redeemedFreePoint(
                {
                    objectId: freePoint?._id || "",
                    status: enumStatus.ACTIVE,
                    type: enumTypes.FREE_POINT,
                },
                id
            );
            if (res.data.message) {
                success(res.data.message);
            }
        } catch (err: any) {
            const { errors } = err.response.data;
            if (errors) {
                error(errors);
            } else {
                error(t("message.received.fail"));
            }
        } finally {
            dispatch(setLoading(false));
            history.push(PATH_HOME);
        }
    };

    const handleClosePopupConfirm = () => {
        setShowPopupConfirm(false);
    };
    const handleShowPopupConfirm = () => {
        checkIsLogin();
        setShowPopupConfirm(true);
    };

    const handleChangeImage = (swiper: SwiperCore) => {
        setCurrentImage(swiper.realIndex + 1);
    };

    return (
        <>
            {freePoint && (
                <ComponentFreePointDetail
                    freePoint={freePoint}
                    handleToggleConfirm={handleShowPopupConfirm}
                    handleChangeImage={handleChangeImage}
                    currentImage={currentImage}
                />
            )}

            {showPopupConfirm && freePoint && (
                <ComponentPopUpConfirm
                    handleCloseDetail={handleClosePopupConfirm}
                    data={freePoint as IFreePoint}
                    handleConfirm={handleRedeemedFreePoint}
                />
            )}
        </>
    );
};
