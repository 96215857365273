import { ICheckStoreReadyForOnlineOrder, IGetBillSetting } from "@interfaces";
import axiosClient from "./axiosClientStore";

export const posAPI = {
  getBillSetting: (params: IGetBillSetting) => {
    const url = "pos/getBillSetting";
    return axiosClient.get(url, { params });
  },
  checkStoreReadyForOnlineOrder: (params: ICheckStoreReadyForOnlineOrder) => {
    const url = "pos/checkStoreReadyForOnlineOrder";
    return axiosClient.get(url, { params });
  },
};
