import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant, store } from "@redux";
import { PointIcon } from "../icon";
import { Input, Skeleton } from "antd";
import { StyledCancelButton, StyledSubmitButton } from "../shared";
import { useEffect, useState } from "react";
import { ICreateProductOrderPOSExample } from "@interfaces";

interface IProps {
  usePointsDrawer: boolean;
  handleClose: () => void;
  paymentDetails?: ICreateProductOrderPOSExample;
  getPaymentDetails: (point: number | undefined) => void;
  pointsInput?: number;
  setPointsInput: any;
}

export const ComponentUsePointDrawer = (props: IProps) => {
  const {
    usePointsDrawer,
    handleClose,
    paymentDetails,
    getPaymentDetails,
    pointsInput,
    setPointsInput,
  } = props;
  const { t } = useTranslation();
  const userInfo = store.getState().auth.userInfo;
  const { themeColor } = useSelector(selectMerchant);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    if (usePointsDrawer) {
      setTimeout(() => {
        setIsloading(false);
      }, 1000);
    }
  }, [usePointsDrawer]);

  return (
    <SharedComponentDrawer
      title={t("page.use_points")}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={usePointsDrawer}
      width={"100%"}
      closable={true}
      height={"40%"}
    >
      <StyledUsePointsDrawer themeColor={themeColor}>
        <div className="pointBox">
          <div className="leftSide">
            <div className="rocketPointRow">
              <PointIcon />
              <div className="pointsTextColumn">
                <p>Rocket Point</p>
                <p className="pointsLine">
                  <Skeleton
                    loading={isLoading}
                    active
                    title={false}
                    paragraph={{ rows: 1, width: [102] }}
                  >
                    {paymentDetails?.current_member_tier?.discountUsePoints} {t("page.points")} ={" "}
                    {paymentDetails?.current_member_tier?.discountInBaht} ฿
                  </Skeleton>
                </p>
              </div>
            </div>
            <small>
              {t("page.your")} Rocket Point :{" "}
              <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 1 }}>
                <span>
                  {userInfo && userInfo.totalPoints} {t("page.points")}
                </span>
              </Skeleton>
            </small>
          </div>
          <div className="rightSide">
            <div className="pointInputDiv">
              <Input
                placeholder="0"
                type="number"
                className="pointInput"
                value={pointsInput}
                max={userInfo && userInfo.totalPoints}
                min={0}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (paymentDetails && value <= userInfo.totalPoints && value >= 0) {
                    setPointsInput(parseInt(e.target.value));
                  }
                }}
              />
              <label style={{ color: pointsInput ? "#F22F46" : "#919191" }}>
                {t("page.points")}
              </label>
            </div>
          </div>
        </div>
        {usePointsDrawer && (
          <div className="addItemRow">
            <div className="btn-layout">
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
              <StyledSubmitButton
                type="red"
                text={t("page.use_point")}
                htmlType="button"
                style={{ backgroundColor: pointsInput ? "#F22F46" : "#E1E1E1" }}
                onClick={() => {
                  getPaymentDetails(pointsInput);
                  handleClose();
                }}
              />
            </div>
          </div>
        )}
      </StyledUsePointsDrawer>
    </SharedComponentDrawer>
  );
};
const StyledUsePointsDrawer = styled.div<{ themeColor: string }>`
  .pointBox {
    border: 0.5px solid #e1e1e1;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }
  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 13px;
    p {
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
    .pointsLine {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
    .pointsTextColumn {
      display: flex;
      flex-direction: column;
      place-items: baseline;
      .ant-skeleton {
        .ant-skeleton-paragraph {
          li {
            height: 14px;
            border-radius: 14px;
          }
        }
      }
    }
    .rocketPointRow {
      display: flex;
      gap: 13px;
    }
    small {
      font-weight: 500;
      font-size: 10px;
      color: #000000;
      span {
        font-weight: 500;
        font-size: 10px;
        color: ${(color) => color.themeColor};
      }
      .ant-skeleton {
        .ant-skeleton-paragraph {
          li {
            height: 14px;
            border-radius: 14px;
          }
        }
      }
    }
  }
  .rightSide {
    .pointInput {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 59px;
      background: #f7f7f8;
      border-radius: 4px;
      text-align: center;
      padding-bottom: 15px;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: #919191;
    }
    .pointInputDiv {
      position: relative;
      label {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #919191;
        position: absolute;
        margin: 0;
        bottom: 2px;
        left: 0;
        right: 0;
      }
    }
  }
  .grayDiscountRow {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fafafa;
    padding: 8px 16px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-skeleton {
      .ant-skeleton-paragraph {
        margin: 0 0 0 5px;
        display: flex;
        justify-content: flex-end;
        li {
          height: 14px;
          border-radius: 14px;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      text-align: center;
      color: #000000;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
  }
  .addItemRow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px;
    align-items: center;
    background: #fff;
    place-content: center;
    .separator {
      position: absolute;
      top: -10px;
      margin: 0;
      left: 0;
      right: 0;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    width: 100%;
    display: flex;
    gap: 12px;
    button {
      margin: auto;
      width: 100%;
      height: 60px;
      border-radius: 74px;
      padding: 0;
    }
  }
`;
