import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import Styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";

import {
  IconQuantity,
  IconMinus,
  IconPlus,
  IconPosition,
  IconTime,
  IconVariant,
  IconGreater,
  IconAddToBasket,
} from "@components";
import { ICardUse, IInfo, IPrebookingBody, IStaffListSelect } from "@interfaces";
import { enumNavigation } from "@configs";
import { setCurrentPath, setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";

// MUI
import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";

const baseUrl = process.env.REACT_APP_API_STORE_ADMIN;

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

interface IDVP {
  duration: {
    value: number;
    unit: string;
  };
  price: number;
  variant: string;
  _id: string;
}

interface IServiceDetail {
  service_name: string;
  variant_list: string[];
  image_url_list: { image_url: string }[];
  duration_list: { value: string; unit: string }[];
  description: string;
  condition: string;
  service_code: string;
  standard_price: number;
  totalPrice: number;
  consumption: number;
  duration_backend: {
    unit: string;
    value: string;
  };
  is_client_choose_staff: boolean;
  duration_variant_price: IDVP[];
}

export const ComponentServiceInside = (props: IProps) => {
  const history = useHistory();

  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };

  const [value, setValue] = React.useState<Date | null>(new Date());
  let [ServiceDetails, setServiceDetails] = useState<IServiceDetail>({
    service_name: "",
    variant_list: [],
    image_url_list: [{ image_url: "" }],
    duration_list: [{ value: "", unit: "" }],
    description: "",
    condition: "",
    service_code: "",
    standard_price: 0,
    totalPrice: 0,
    consumption: 0,
    duration_backend: {
      unit: "",
      value: "",
    },
    is_client_choose_staff: true,
    duration_variant_price: [],
  });

  const [ServiceDurationList, setServiceDurationList] = useState();

  let [StaffDetails, setStaffDetails] = useState({
    name: "",
    image_url: "",
    store_service_list: [
      {
        store_code: "",
      },
    ],
  });
  const [total, setTotal] = useState<number>(0);
  let [quantity, setQuantity] = useState(1);
  let [Refresh, setRefresh] = useState(false);
  const [active, setActive] = useState("");
  let [ShowAllTab, setShowAllTab] = useState(true);
  let [StylistTab, setStylistTab] = useState(false);
  let [SelectionTab, setSelectionTab] = useState(false);
  let [DurationTab, setDurationTab] = useState(false);
  let [DatePicker, setDatePicker] = useState(false);
  let [TimeSlots, setTimeSlots] = useState([]);
  let [Type, setType] = useState("");
  let [Stylist, setStylist] = useState("");
  let [stylistCode, setStylistCode] = useState("");
  let [ImgUrl, setImgUrl] = useState("");
  let [durationValue, setdurationValue] = useState<number>(0);
  let [durationUnit, setdurationUnit] = useState("");
  let mydate = new Date("2022-04-17");
  let minDate = new Date(new Date().getTime());
  const [buyType, setBuyType] = useState<string>("money");

  let [StoreCode, setStoreCode] = useState("");

  // variant selection
  let variantName = "";
  let [storeVariantName, setStoreVariantName] = useState("");

  // Get Dates From Calendar
  let todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + 1);
  let [CalendarDate, setCalendarDate] = useState(todayDate.toISOString().split("T")[0]);
  // let outdate = [
  //   date.getFullYear(),
  //   ("0" + (date.getMonth() + 1)).slice(-2),
  //   ("0" + date.getDate()).slice(-2),
  // ].join("-");
  // let CalendarDate = todayDate.toISOString().split("T")[0];
  let lastURL = window.location.href.split("/").pop();

  let staffUrl = window.location.href.split("/");

  // Find Staff of Secvices
  // let [WTF, setWTF] = useState([]);
  // let [getStaffCode, setGetStaffCode] = useState("");

  let [staffCodes, setStaffCodes] = useState<IStaffListSelect[]>([
    // {
    //     staff_code: "",
    //     staff_name: "",
    //     store_service_list: [{ store_code: "" }],
    //     image_url: ""
    // },
  ]);
  let AllStaffs: any = [];
  useEffect(() => {
    localStorage.removeItem("path");
    if (staffUrl[4] !== "services") {
      axios
        .get(
          `https://api-store.rocket-tech.app/1.0.0/staffSelectionByQuery?organization_id=${organization_id}&staff_code=${staffUrl[4]}`
        )
        .then((data) => {
          let newService = data.data.result.itemx[0];
          setStaffDetails(newService);
          setTotal(newService?.starndard_price);
          // newService = StoreCode;
          newService = data.data.result.itemx[0].store_service_list.store_code;
          setStoreCode(localStorage.getItem("store_code") || "");
          setStylist(data.data.result.itemx[0].name);
          setImgUrl(data.data.result.itemx[0].image_url);

          localStorage.setItem("staffCode", staffUrl[4]);
        })
        .catch((err) => console.log(err, "\nSomething wrong, try again!"));
    } else {
      console.log("UE not select staff");
      let wtf = localStorage.getItem("store_code");
      axios
        .get(`https://api-store.rocket-tech.app/1.0.0/staffSelection`)
        .then((data) => {
          for (let i = 0; i < data.data.length; i++) {
            for (let w = 0; w < data.data[i].store_service_list.length; w++) {
              if (data.data[i].store_service_list[w].store_code === wtf) {
                for (let k = 0; k < data.data[i].store_service_list[w].service_list.length; k++) {
                  if (data.data[i].store_service_list[w].service_list[k].service_code === lastURL) {
                    AllStaffs.push({
                      staff_code: data.data[i].staff_code,
                      staff_name: data.data[i].name,
                      image_url: data.data[i].image_url,
                    });
                    // localStorage.setItem("staffCode", data.data[i].staff_code);
                  }
                }
              }
            }
            // AllStaffs.push({
            //     staff_code: data.data[i].staff_code,
            //     staff_name: data.data[i].name,
            // });

            // for (let k = 0; k < data.data[i].store_service_list.length; k++) {
            //     for (
            //         let j = 0;
            //         j < data.data[i].store_service_list[k].service_list.length;
            //         j++
            //     ) {
            //         if (
            //             data.data[i].store_service_list[k].service_list[
            //                 j
            //             ].service_code.indexOf(lastURL) === 0
            //         ) {
            //             // console.log(data.data[i].store_service_list);
            //             AllStaffs.push({
            //                 staff_code: data.data[i].staff_code,
            //                 staff_name: data.data[i].name,
            //             });
            //         }
            //     }
            // }
          }
          getStaffonService("");
          setStaffCodes(AllStaffs);
        })
        .catch((err) => console.log(err, "\nSomething wrong, try again!"));
      setTimeout(() => {
        axios
          .get(
            `https://api-store.rocket-tech.app/1.0.0/staffSelectionByQuery?organization_id=${organization_id}&staff_code=${AllStaffs[0]["staff_code"]}`
          )
          // .get(
          //     `https://api-store.rocket-tech.app/1.0.0/staffSelectionByQuery?organization_id=${organization_id}&staff_code=${localStorage.getItem(
          //         "staffCode"
          //     )}`
          // )
          .then((data) => {
            let newService = data.data.result.itemx[0];
            setStaffDetails(newService);
            // newService = StoreCode;
            newService = data.data.result?.itemx[0]?.store_service_list?.store_code;
            setStoreCode(localStorage.getItem("store_code") || "");
            // setStylist(data.data.result.itemx[0].name);
            // setImgUrl(data.data.result.itemx[0].image_url);
          })
          .catch((err) => console.log(err, "\nSomething wrong, try again!"));
      }, 700);
    }
    axios
      .get(
        `https://api-store.rocket-tech.app/1.0.0/serviceInsideDetails?organization_id=${organization_id}&service_code=${lastURL}`
      )
      .then((data) => {
        let newService = data.data.result;
        setServiceDetails(newService);
        setTotal(newService?.standard_price);
      })
      .catch((err) => console.log(err, "\nSomething wrong, try again!"));

    const beforeUnloadListener = () => {
      //Send something to back end
      localStorage.removeItem("staffCode");
      localStorage.removeItem("bookingDetails");
    };
    window.addEventListener("pagehide", beforeUnloadListener);
  }, []);

  //@ts-ignore
  const token = store.getState().auth.auth?.accessToken;
  const profile_id = store.getState().auth.userInfo._id;
  const organization_id = store.getState().auth.userInfo?.merchant?._id;
  const organization_code = store.getState().auth.userInfo?.merchant?.subCode;
  const username = store.getState().auth?.userInfo?.username;
  const cardUse: ICardUse = store.getState().cardUse;
  // TimePicker
  const headers = {
    "Content-Type": "application/json",
    "access-token": token,
  };
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState(null);

  const getQuotes = () => {
    // call api slot time
    let localStoreCode = localStorage.getItem("store_code");

    let body = {
      organization_id: organization_id,
      // profile_id: profile_id,
      username: username,
      store_code: localStoreCode,
      service_date: value?.toISOString().split("T")[0],
      // note: "testnote",
      booking_service: [
        {
          service_code: ServiceDetails.service_code,
          duration: { value: durationValue, unit: durationUnit },
          variant: storeVariantName,
          consumption: quantity,
          booking_staff: [],
        },
      ],
    };
    setLoading(true);
    axios
      .post(`${baseUrl}booking/getBookingSlotAvailable`, body, { headers: headers })
      .then((data) => {
        let timeSlots = data.data.result;
        let arr: any = [];
        for (let i = 0; i < timeSlots.length; i++) {
          arr.push({
            time_text: timeSlots[i].time_text,
            is_available: timeSlots[i].is_available,
          });
        }
        setTimeSlots(arr);
        setLoading(false);
        setQuotes(arr);
        // setRefresh(!Refresh);
      })
      .catch((err) => console.log(err, "\nSomething wrong on Time Slots, try again!"));
  };

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value);
    const end = endOfWeek(value);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);
    let [userPickedDate, setUserPickedDate] = useState("");

    return (
      <CustomPickersDay
        {...pickersDayProps}
        // disableMargin
        dayIsBetween={false}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  function filterWeekends(date: Date) {
    // Return false if Saturday or Sunday
    let dayOfWeek = date.getDay();
    let isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
    return dayOfWeek === null;
  }

  const [timePick, setTimePick] = useState("");

  const [basket, setBasket] = useState([]);
  const handleAddToBasket = () => {
    const addServiceToBasket = () => {
      let localBasket = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
      preBookingBody.booking_service[0].total_price = total;
      // preBookingBody.booking_service[0].image_url = ServiceDetails.image_url_list[0].image_url;
      if (Object.keys(localBasket).length === 0) {
        localBasket = preBookingBody;
      } else {
        localBasket.booking_service.push(preBookingBody.booking_service[0]);
      }
      localStorage.setItem("preBookingBody", JSON.stringify(localBasket));
      history.push("/s-home");
    };
    if (ServiceDetails?.variant_list?.length > 0) {
      if (Type !== "") {
        if (durationValue !== 0) {
          addServiceToBasket();
        } else {
          alert("please select duration");
        }
      } else {
        alert("please select Type");
      }
    } else {
      if (durationValue !== 0) {
        addServiceToBasket();
      } else {
        alert("please select duration");
      }
    }
    // let localBasket = JSON.parse(localStorage.getItem("preBookingBody") || "{}");

    // preBookingBody.booking_service[0].total_price = total;
    // console.log("prebookingBody", preBookingBody);
    // if (Object.keys(localBasket).length === 0) {
    //   localBasket = preBookingBody;
    // } else {
    //   localBasket.booking_service.push(preBookingBody.booking_service[0]);
    // }
    // localStorage.setItem("preBookingBody", JSON.stringify(localBasket));
    // history.push("/s-home");
  };
  const preBooking = () => {
    if (localStorage.getItem("preBookingBody")) {
      // let temp = [];
      let localBasket = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
      // temp.push(oldLocal);
      localBasket.booking_service.push(preBookingBody.booking_service[0]);
      localBasket.total_price = localBasket.total_price + ServiceDetails.standard_price;
      localStorage.setItem("preBookingBody", JSON.stringify(localBasket));
      // setTimeout(() => {
      history.push("/basket");
      // }, 1000);
    } else {
      axios
        .post(`${baseUrl}booking/preBooking`, preBookingBody, { headers: headers })
        .then((data) => {
          if (data.data.error) {
            setBasket(JSON.parse(localStorage.getItem("preBookingBody") || "{}"));
            localStorage.setItem("preBookingBody", JSON.stringify(preBookingBody));
            // setTimeout(() => {
            history.push("/basket");
            // }, 1000);
            // if (data.data.error === 0) {
            //     setBasket(data.data.result.booking_return);
            //     console.log(basket);
            // }
          } else {
            alert("Something is wrong with booking on backend and time");
          }
        })
        .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
    }
  };

  const preBookingBody: IPrebookingBody = {
    organization_id: organization_id,
    profile_id: profile_id,
    store_code: StoreCode,
    service_date: value?.toISOString().split("T")[0],
    service_time: timePick,
    total_price: ServiceDetails.standard_price,
    note: "testnote",
    contact_text: "",
    booking_service: [
      {
        service_code: lastURL || "",
        service_name: ServiceDetails.service_name,
        standard_price: ServiceDetails.standard_price,
        image_url_list: ServiceDetails.image_url_list,
        // image_url: "",
        total_price: 0,
        duration: {
          value: durationValue,
          unit: durationUnit,
        },
        variant: storeVariantName,
        stylist: Stylist,
        consumption: quantity,
        booking_staff: [
          {
            staff_code: stylistCode,
          },
        ],
      },
    ],
  };

  // Check for Modify
  let alreadyBooked: any[] = [];
  let [bookExist, setBookExist] = useState(false);
  const [modifyBody, setModifyBody] = useState({});

  useEffect(() => {
    // check book by card use
    if (
      localStorage.getItem("bookByServiceCodeCounter") &&
      lastURL !== localStorage.getItem("bookByServiceCodeCounter")
    ) {
      alert("Please choose your currect service!");
      history.push(`/S-Home/services/${localStorage.getItem("bookByServiceCodeCounter")}`);
      location.reload();
    } else {
      null;
    }

    // check book by card use
    if (localStorage.getItem("modifyBookingBody")) {
      const getBookingDetails = JSON.parse(localStorage.getItem("modifyBookingBody") || "{}");
      const getBookingDetails_booking_service = getBookingDetails.booking_service;
      for (let i = 0; i < getBookingDetails_booking_service.length; i++) {
        alreadyBooked.push(getBookingDetails_booking_service[0]);
      }
      if (alreadyBooked.find((o) => o.service_code === lastURL)) {
        setBookExist(true);
      } else {
        //
      }
      setModifyBody(getBookingDetails);
    }
  }, []);

  useEffect(() => {
    // for check card use
    if (cardUse?.current_path === "/cards" || cardUse?.current_path === "/cardsSV") {
      setBuyType("card");
      dispatch(setCurrentPath("/serviceCard"));
      if (cardUse?.card_type === "counter") {
        setType(cardUse.counter_service.variant);
        if (cardUse.counter_service.duration.value) {
          setdurationValue(cardUse.counter_service.duration.value);
          setdurationUnit(cardUse?.counter_service.duration.unit);
        }
      }
    }
  }, [cardUse]);

  useEffect(() => {
    if (ServiceDetails?.duration_variant_price?.length > 0) {
      let found = ServiceDetails.duration_variant_price.filter((obj: IDVP) => {
        if (obj?.variant === Type && obj?.duration.value === durationValue) {
          return obj;
        }
      });
      if (found?.length === 1) {
        setTotal(quantity * found[0].price);
        preBookingBody.booking_service[0].total_price = found[0].price;
      } else {
        setTotal(quantity * ServiceDetails?.standard_price);
        preBookingBody.booking_service[0].total_price = ServiceDetails?.standard_price;
      }
    } else {
      setTotal(quantity * ServiceDetails?.standard_price);
    }
  }, [quantity, Type, durationValue]);

  const handleBuyWithCard = () => {
    let bookByCard = JSON.parse(localStorage.getItem("bookByCard") || "{}");
    if (bookByCard?.store_value_amount < total) {
      alert("total is more cost that card amount");
    } else {
      // preBookingBody.booking_service[0].image_url = ServiceDetails.image_url_list[0].image_url;
      preBookingBody.booking_service[0].variant = Type;
      preBookingBody.booking_service[0].duration = {
        value: durationValue,
        unit: durationUnit,
      };
      preBookingBody.booking_service[0].booking_staff = [
        {
          staff_code: stylistCode || "",
        },
      ];
      localStorage.setItem("preBookingBody", JSON.stringify(preBookingBody));
      dispatch(setCurrentPath("/serviceInside"));
      history.push("/basket");
    }
  };

  const modifyBooking = () => {
    const bookingLocal = JSON.parse(localStorage.getItem("bookingDetails") || "{}");
    const bookingCode = bookingLocal.booking_code;
    const modifyBookingBody = {
      organization_id: organization_id,
      booking_code: bookingCode,
      profile_id: profile_id,
      store_code: StoreCode,
      service_date: value?.toISOString().split("T")[0],
      service_time: timePick,
      total_price: ServiceDetails.standard_price,
      note: "testnote",
      booking_service: [
        {
          service_code: lastURL,
          service_name: ServiceDetails.service_name,
          standard_price: ServiceDetails.standard_price,
          duration: {
            value: durationValue,
            unit: durationUnit,
          },
          variant: storeVariantName,
          stylist: Stylist,
          consumption: quantity,
          booking_staff: [
            // {
            //     staff_code: staffUrl[4],
            // },
          ],
        },
      ],
    };
    axios
      .patch(`${baseUrl}booking/updateBooking`, modifyBookingBody, { headers: headers })
      .then((data) => {
        if (data.data.error !== 1) {
          localStorage.removeItem("modifyBookingBody");
          localStorage.removeItem("bookingDetails");
          history.push("/mybooking");
        } else {
          console.log("Something Happened with date and time maybe!");
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
  };

  // Apple Booking Card
  const createBookingApplyCardFuncPre = () => {
    const createBookingcardBodyPre = {
      api_type: "pre",
      organization_id: organization_id,
      profile_id: profile_id,
      card_use_code: localStorage.getItem("cardUsesCode"),
      store_code: StoreCode,
      service_date: value?.toISOString().split("T")[0],
      service_time: timePick,
      note: "testnote",
      contact_text: "",
      booking_service: [
        {
          service_code: ServiceDetails.service_code,
          duration: {
            value: durationValue,
            unit: durationUnit,
          },
          variant: storeVariantName,
          consumption: quantity,
          booking_staff: [
            // {
            //     staff_code: localStorage.getItem("staffCode") || staffUrl[4],
            // },
          ],
        },
      ],
    };
    axios
      .post(`${baseUrl}booking/createBookingApplyCard`, createBookingcardBodyPre, {
        headers: headers,
      })
      .then((data) => {
        if (data.data.error === "0") {
          createBookingApplyCardFunc();
          localStorage.removeItem("bookByCard");
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
  };
  const createBookingApplyCardFunc = () => {
    const createBookingcardBody = {
      api_type: "do",
      organization_id: organization_id,
      profile_id: profile_id,
      card_use_code: localStorage.getItem("cardUsesCode"),
      store_code: StoreCode,
      service_date: value?.toISOString().split("T")[0],
      service_time: timePick,
      note: "testnote",
      contact_text: "",
      booking_service: [
        {
          service_code: ServiceDetails.service_code,
          duration: {
            value: durationValue,
            unit: durationUnit,
          },
          variant: storeVariantName,
          consumption: quantity,
          booking_staff: [
            // {
            //     staff_code: localStorage.getItem("staffCode") || staffUrl[4],
            // },
          ],
        },
      ],
    };
    axios
      .post(`${baseUrl}booking/createBookingApplyCard`, createBookingcardBody, {
        headers: headers,
      })
      .then((data) => {
        localStorage.removeItem("bookByServiceCodeCounter");
        localStorage.removeItem("cardUsesCode");
        setTimeout(() => {
          history.push("/mybooking");
          location.reload();
        }, 300);
      })
      .catch((err) => console.log(err, "\nSomething wrong on Pre-Booking, try again!"));
  };

  const getStaffonService = (item: any) => {
    let payload = {
      organization_code: organization_code,
      xpage: 1,
      xlimit: 10,
      service_code: lastURL,
      store_code: localStorage.getItem("store_code") || "",
      variant: item,
    };
    const option = `organization_code=${organization_code}&xpage=1&xlimit=10&service_code=${lastURL}&store_code=${localStorage.getItem(
      "store_code"
    )}&variant=${item}`;
    axios
      .get(`${baseUrl}staff/getStaffsCanDoService?${option}`, { headers: headers })
      .then((data) => {
        if (data?.data?.error === "0") {
          let allStaff: any = [];

          data?.data?.result.forEach((item: any) => {
            allStaff.push({
              staff_code: item?.staff_code,
              staff_name: item?.name,
              image_url: item?.image_url,
            });
          });
          setStaffCodes(allStaff);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong, try again!"));
  };

  return (
    <>
      <>
        {ShowAllTab && (
          <div className="wholePage">
            <div className="topImage">
              <Swiper
                autoplay={true}
                slidesPerView={1}
                spaceBetween={20}
                pagination={true}
                grabCursor={true}
                className="mySwiper"
              >
                {ServiceDetails?.image_url_list.length > 0 &&
                  ServiceDetails.image_url_list.map((item, index) => {
                    return (
                      <div key={index}>
                        <SwiperSlide>
                          {typeof item === "string" ? (
                            <img src={item || "/images/default_service.png"} />
                          ) : (
                            <img src={item.image_url || "/images/default_service.png"} />
                          )}
                        </SwiperSlide>
                      </div>
                    );
                  })}

                {/* {ServiceDetails
                  ? ServiceDetails.image_url_list.map((item: any, i) => {
                      console.log("item", item);

                      return <h1>{item}</h1>;
                    })
                  : null} */}
              </Swiper>
            </div>
            <div className="titleQuantity">
              <div className="topTitle">
                <h1>{ServiceDetails ? ServiceDetails.service_name : "Unknown"}</h1>
              </div>
              <div className="quantity">
                <div className="leftSide">
                  <StyledIconQuantity /> <h2>Quantity</h2>
                </div>
                <div className="rightSide">
                  <button
                    className="quantityButton"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    <StyledIconMinus />
                  </button>
                  <h2>{quantity}</h2>
                  <button
                    className="quantityButton"
                    onClick={() => {
                      setQuantity(quantity + 1);
                    }}
                  >
                    <StyledIconPlus />
                  </button>
                </div>
              </div>
            </div>
            <div className="staffInfo">
              {ServiceDetails?.variant_list.length > 0 && (
                <div className="position">
                  <div className="leftSide">
                    <div className="iconWidth">
                      <StyledIconVriant />
                    </div>
                    <h2>Variant</h2>
                    {/* <h2>{Type ? Type : "Unknown"}</h2> */}
                  </div>
                  <div className="rightSide">
                    {cardUse?.card_type !== "counter" ? (
                      <label
                        onClick={() => {
                          setShowAllTab(!ShowAllTab);
                          setSelectionTab(!SelectionTab);
                        }}
                      >
                        {Type ? Type : "Select Variant"}{" "}
                        <span>
                          <StyledIconGreater />
                        </span>
                      </label>
                    ) : (
                      <label>{Type}</label>
                    )}
                  </div>
                </div>
              )}

              {ServiceDetails?.is_client_choose_staff && (
                <div className="position">
                  <div className="leftSide">
                    <div className="iconWidth">
                      {/* <img src={staffCodes ? ImgUrl : "Not Found"} /> */}
                      <StyledIconPosition />
                    </div>
                    {/* <h2>{Stylist || "Unknown"}</h2> */}
                    <h2>Staff</h2>
                  </div>
                  <div className="rightSide">
                    {staffUrl[4] === "services" ? (
                      <label
                        onClick={() => {
                          setShowAllTab(!ShowAllTab);
                          setStylistTab(!StylistTab);
                        }}
                      >
                        {Stylist !== "" && (
                          <img
                            src={
                              staffCodes
                                ? ImgUrl || "/images/default_staff.png"
                                : "/images/default_staff.png"
                            }
                            className="staff-img"
                          />
                        )}
                        {Stylist || "Select staff"}{" "}
                        <span>
                          <StyledIconGreater />
                        </span>
                      </label>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="position rmBotBorder">
                <div className="leftSide timeIconDiv">
                  <div className="iconWidth">
                    <StyledIconTime />
                  </div>
                  <h2>
                    {/* {durationValue ? durationValue : "0"} {durationUnit ? durationUnit : "minutes"} */}
                    Duration
                  </h2>
                </div>
                <div className="rightSide">
                  {cardUse?.card_type !== "counter" ? (
                    <label
                      onClick={() => {
                        setShowAllTab(!ShowAllTab);
                        setDurationTab(!DurationTab);
                      }}
                    >
                      {durationValue ? `${durationValue} ${durationUnit}` : "Select duration"}
                      <span>
                        <StyledIconGreater />
                      </span>
                    </label>
                  ) : (
                    <label>
                      {durationValue} {durationUnit}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="botSection">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item noTopPadding">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Description
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    T&C
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <p
                    className="pane-text"
                    dangerouslySetInnerHTML={{
                      __html: ServiceDetails?.description,
                    }}
                  />
                </div>
                <div
                  className="tab-pane"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <p
                    className="pane-text"
                    dangerouslySetInnerHTML={{
                      __html: ServiceDetails?.condition,
                    }}
                  />
                </div>
              </div>
              <div className="buttonSection">
                {/* <button className="addService">Add services</button> */}
                <button
                  className="Next"
                  onClick={() => {
                    // if (localStorage.getItem("preBookingBody")) {
                    //     preBooking();
                    // } else {
                    //     setShowAllTab(!ShowAllTab);
                    //     setDatePicker(!DatePicker);
                    //     getQuotes();
                    // }
                    if (buyType === "money") {
                      handleAddToBasket();
                    } else {
                      handleBuyWithCard();
                    }
                  }}
                >
                  {buyType === "money" ? (
                    <span>
                      <StyledIconAddToBasket />
                      Add to basket
                    </span>
                  ) : (
                    "buy"
                  )}
                  {localStorage.getItem("bookByServiceCodeCounter") ? null : (
                    <span>
                      <span style={{ fontWeight: "normal" }}>฿</span>{" "}
                      {/* {ServiceDetails.standard_price * quantity} */}
                      {total}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {SelectionTab && (
          <div className="threeTab">
            {ServiceDetails
              ? ServiceDetails.variant_list.map((item, i) => (
                  <div
                    key={i}
                    className="tab"
                    onClick={() => {
                      setType(item);
                      setShowAllTab(!ShowAllTab);
                      setSelectionTab(!SelectionTab);
                      variantName = item;
                      setStoreVariantName(item);
                      getStaffonService(item);
                    }}
                  >
                    <h2>{item ? item : "Not Found"}</h2>
                    <div className="arrowIcon">
                      <StyledIconGreater />
                    </div>
                  </div>
                ))
              : null}
            <button
              className="btn btn-block mt-5"
              onClick={() => {
                setShowAllTab(!ShowAllTab);
                setSelectionTab(!SelectionTab);
              }}
              style={{ cursor: "pointer" }}
            >
              Click to back
            </button>
          </div>
        )}
        {StylistTab && (
          <div className="threeTab">
            {staffCodes
              ? staffCodes
                  .slice(0)
                  .reverse()
                  .map((item, i) => (
                    <div
                      key={i}
                      className="tab"
                      onClick={() => {
                        setStylist(item.staff_name);
                        setStylistCode(item.staff_code);
                        setImgUrl(item.image_url);
                        setShowAllTab(!ShowAllTab);
                        setStylistTab(!StylistTab);
                      }}
                    >
                      <h2>{item.staff_name ? item.staff_name : "Not Found"}</h2>
                      <div className="arrowIcon">
                        <StyledIconGreater />
                      </div>
                    </div>
                  ))
              : null}
            <button
              className="btn btn-block mt-5"
              onClick={() => {
                setShowAllTab(!ShowAllTab);
                setStylistTab(!StylistTab);
              }}
              style={{ cursor: "pointer" }}
            >
              Click to back
            </button>
          </div>
        )}
        {DurationTab && (
          <div className="threeTab">
            {ServiceDetails && ServiceDetails.duration_list?.length > 0 ? (
              ServiceDetails.duration_list.map((item, i) => (
                <div
                  className="tab"
                  key={i}
                  onClick={() => {
                    setdurationValue(parseInt(item.value));
                    setdurationUnit(item.unit);
                    setShowAllTab(!ShowAllTab);
                    setDurationTab(!DurationTab);
                  }}
                >
                  <h2>
                    {item ? item.value : "Not Found"} {item ? item.unit : "Not Found"}
                  </h2>
                  <div className="arrowIcon">
                    <StyledIconGreater />
                  </div>
                </div>
              ))
            ) : (
              <>
                <div
                  className="tab"
                  onClick={() => {
                    setdurationValue(parseInt(ServiceDetails.duration_backend?.value));
                    setdurationUnit(ServiceDetails.duration_backend?.unit);
                    setShowAllTab(!ShowAllTab);
                    setDurationTab(!DurationTab);
                  }}
                >
                  <h2>
                    {ServiceDetails.duration_backend?.value} {ServiceDetails.duration_backend?.unit}
                    {/* {item ? item.value : "Not Found"}{" "}
                      {item ? item.unit : "Not Found"} */}
                  </h2>
                  <div className="arrowIcon">
                    <StyledIconGreater />
                  </div>
                </div>
              </>
            )}
            <button
              className="btn btn-block mt-5"
              onClick={() => {
                setShowAllTab(!ShowAllTab);
                setDurationTab(!DurationTab);
              }}
              style={{ cursor: "pointer" }}
            >
              Click to back
            </button>
          </div>
        )}

        {DatePicker && (
          <div className="wholePage datePicker">
            <div className="dateTopDiv">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  label="Date picker"
                  views={["day"]}
                  value={value}
                  className="dateTimePicker"
                  onChange={(newValue) => {
                    setTimeSlots([]);
                    setValue(newValue);
                    // setQuotes(null);
                    getQuotes();
                    // setRefresh(!Refresh);
                  }}
                  // renderDay={renderWeekPickerDay}
                  renderInput={(params) => <TextField {...params} className="datepicker-boom" />}
                  inputFormat="'Week of' MMM d"
                  minDate={minDate}
                  shouldDisableDate={filterWeekends}
                />
              </LocalizationProvider>
              <div className="timeSection">
                <div className="timeTitle">
                  <h1>เวลาที่ต้องการใช้บริการ</h1>
                </div>
                <div className="times paddingBottom">
                  {loading ? (
                    <p style={{ marginTop: "10px" }}>Loading...</p>
                  ) : (
                    TimeSlots.map((item, i) => {
                      return (
                        <button
                          key={i}
                          className={
                            item["is_available"]
                              ? active !== i.toString()
                                ? "timeButtons"
                                : "timeButtons activeTime"
                              : "timeButtons disabledTime"
                          }
                          onClick={() => {
                            if (item["is_available"]) {
                              setTimePick(item["time_text"]);
                              setActive(i.toString());
                            }
                          }}
                        >
                          {item["time_text"]}
                        </button>
                      );
                      // if (item["is_available"] === true) {
                      //     return (
                      //         <button
                      //             key={i}
                      //             className={
                      //                 active !== i.toString()
                      //                     ? "timeButtons"
                      //                     : "timeButtons activeTime"
                      //             }
                      //             onClick={() => {
                      //                 setTimePick(item["time_text"]);
                      //                 setActive(i.toString());
                      //             }}
                      //         >
                      //             {item["time_text"]}
                      //         </button>
                      //     );
                      // }
                    })
                  )}
                </div>
              </div>
              <div className="buttonSection datePicketButSection">
                {/* <button className="addService">Add services</button> */}
                {/* <Link to={"/basket"}> */}
                <button
                  className="Next"
                  onClick={() => {
                    if (localStorage.getItem("modifyBookingBody")) {
                      modifyBooking();
                    }
                    // if (
                    //     localStorage.getItem("bookByServiceCodeCounter") ||
                    //     localStorage.getItem("cardUsesCode")
                    // ) {
                    //     createBookingApplyCardFuncPre();
                    // } else {
                    //     preBooking();
                    // }
                  }}
                >
                  {/* {localStorage.getItem("modifyBookingBody") && (
                        <span>Confirm modify</span>
                    )}
                    {localStorage.getItem("bookByCard") ||
                    localStorage.getItem("bookByServiceCodeCounter") ? (
                        <span>Confirm usage</span>
                    ) : (
                        <span>Next</span>
                    )} */}
                  Booking confirm
                  {localStorage.getItem("modifyBookingBody") ||
                  localStorage.getItem("bookByCard") ||
                  localStorage.getItem("bookByServiceCodeCounter") ? null : (
                    <span>
                      <span style={{ fontWeight: "normal" }}>฿</span>{" "}
                      {ServiceDetails.standard_price}
                    </span>
                  )}
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        )}
      </>
      <EmptyFooterSpace />
    </>
  );
};

const EmptyFooterSpace = Styled.div`
    height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledIconQuantity = Styled(IconQuantity)`
    cursor: pointer;
`;

const StyledIconMinus = Styled(IconMinus)`
    cursor: pointer;
`;

const StyledIconPlus = Styled(IconPlus)`
    cursor: pointer;
`;

const StyledIconPosition = Styled(IconPosition)`
    cursor: pointer;
`;

const StyledIconTime = Styled(IconTime)`
    cursor: pointer;
`;

const StyledIconVriant = Styled(IconVariant)`
  cursor: pointer;
`;

const StyledIconGreater = Styled(IconGreater)`
  cursor: pointer;
`;

const StyledIconAddToBasket = Styled(IconAddToBasket)`
  cursor: pointer;
`;
