export default {
    invalid_type: {
        email: "อีเมลของคุณไม่ถูกต้อง กรุณากอรกใหม่",
    },
    correct_url: "กรอก URL ให้ถูกต้อง",
    field_required: "จำเป็นต้องกรอกช่องนี้!",
    email_invalid: "อีเมลไม่ถูกต้อง",
    required: "{{name}} กรุณากรอกข้อมูลดังนี้",
    pass_not_confirm: "รหัสผ่านยังไม่ถูกยืนยัน กรุณากรอกรหัสผ่านยืนยัน",
    max_length: "{{name}} ควรมีความยาวที่น้อยกว่า 255 ตัวอักษร",
    max_length_number: "{{name}} จำเป็นต้องมีความยาวไม่เกิน {{number}} ตัวอักษร",
    min: "{{name}} จำเป็นต้องมีความยาวอย่างน้อย {{number}} ตัวอักษร ",
    password_must_match: "รหัสผ่านของคุณไม่ตรงกัน กรุณรากอรกใหม่",
    confirm_password_must_match: "ยืนยันรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่",
    max: "{{name}} ควรมีความยาวไม่เกิน {{number}} ตัวอักษร",
    max_number: "{{name}} ควรมีความยาวไม่เกินหรือเท่ากับ {{number}}",
    min_number: "{{name}} ควรมีความยาวอย่างน้อยหรือเท่ากับ {{number}}",
    must_number: "{{name}} ควรเป็นตัวเลข",
    date_before_now: "{{name}} ควรเกิดขึ้นก่อนวันนี้",
    date_now: "วันที่ ควรจะเป็นวันที่หลังจาก วันที่กรอกปัจจุบัน",
    date_from: "วันที่ ควรจะเป็นวันที่หลังจาก วันที่เริ่มต้น",
    is_Number: "จำเป็นต้องเป็นตัวเลข",
    must_be_a_valid_data_URL: "Url ของคุณไม่ถูกต้อง กรุณากรอกใหม่",
    please_enter_website: "กรุณาเข้าสู่หน้าเว็บไซต์",
    image: {
        wrong_type: "รูปต้องเป็นสกุลไฟล์ .png, .jpg, และ .jpeg เท่านั้น",
        empty: "ไม่ค้นพบรูปภาพ",
        max_size: "รูปภาพไม่ควรมีขนาดเกิน 10MB",
    },
    isNumber: "เบอร์โทรของคุณไม่ถูกต้อง กรุณากรอกใหม่",
    day: "วันที่",
    month: "เดือน",
    year: "ปี",
    is_required: "จำเป็นต้องกรอกช่องนี้",
    is_checked: "จำเป็นต้องติ๊กช่องนี้",
    acceptTermsAndConditions: "คุณต้องยอมรับข้อตกลงและเงื่อนไข",
};
