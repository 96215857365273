import { useTranslation } from "react-i18next";
import styled from "styled-components";
import OtpInput from "react-otp-input";

import { theme, useWindowDimensions } from "@utils";
import { IConReSend, SharedButton } from "@components";
import { enumUseSetting, TIME_RESEND } from "@configs";

interface IProps {
    otp: string;
    handleChangeOtp: (value: string) => void;
    type: enumUseSetting;
    handleSave: () => void;
    handleResend: () => void;
    timeResend: number;
}

export const ComponentUserVerify = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    //page props
    const { otp, handleChangeOtp, type, handleSave, timeResend, handleResend } = props;

    //page variable
    const margin = width <= 380 ? (width <= 340 ? "0.1rem" : "0.3rem") : "0.5rem";
    const checkWidth = width < 380 ? (width < 350 ? "2.3rem" : "2.5rem") : "3rem";

    return (
        <StyledUserVerify>
            <div className="title">
                {type === enumUseSetting.EMAIL ? (
                    <>{t("page.email_desc")}</>
                ) : (
                    <> {t("page.phone_desc")}</>
                )}
            </div>
            <div className="enter_it">{t("page.enter_it_here")}</div>
            <OtpInput
                numInputs={6}
                inputStyle={{
                    width: checkWidth,
                    height: "3rem",
                    margin: `1rem ${margin}`,
                    fontSize: "2rem",
                    borderRadius: "4px",
                    border: "none",
                    background: "#fff",
                }}
                containerStyle={{
                    justifyContent: "center",
                    flexWrap: "wrap",
                    margin: "0.5rem 0",
                }}
                isInputNum
                value={otp}
                onChange={handleChangeOtp}
            />
            <div className="resend" onClick={handleResend}>
                {timeResend === TIME_RESEND ? <IConReSend /> : timeResend}
                {type === enumUseSetting.EMAIL ? (
                    <> {t("page.resend_code")}</>
                ) : (
                    <> {t("page.resend_otp")}</>
                )}
            </div>
            <StyledSharedButton
                size="large"
                bg_color={theme.colors.green}
                type="submit"
                onClick={handleSave}
            >
                {t("page.save")}
            </StyledSharedButton>
        </StyledUserVerify>
    );
};

const StyledUserVerify = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    width: 100%;
    flex-direction: column;
    .title {
        font-style: normal;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
    .enter_it {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
    }
    .resend {
        font-style: normal;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: ${(p) => p.theme.colors.green};
        cursor: pointer;
        svg {
            position: relative;
            top: -1px;
        }
    }
    .allow-dropdown.intl-tel-input {
        width: 100% !important;
        background-color: #fff;
    }
    .intl-tel-input.allow-dropdown input[type="text"],
    .intl-tel-input.allow-dropdown input[type="tel"] {
        height: 48px !important;
        outline: none;
        border: none;
        background-color: #fff;
        width: 100% !important;
        font-size: 16px;
        color: black;
        box-shadow: none !important;
        font-weight: 500;
    }
`;

const StyledSharedButton = styled(SharedButton)`
    width: 90%;
    position: absolute;
    bottom: 48px;
    left: 5%;
`;
