import {
  ICreateProductOrderPOS,
  IGetClientPaySolutionLinkProduct,
  IGetProduct,
  IGetProductByStoreId,
  IGetProductOrder,
  IGetProductOrderById,
  IGetProductOrderOnTableByQrOpenTable,
  IGetProductOrders,
  IGetProducts,
  IGetProductsByPOS,
  IGetQueueOfMyProductOrderInStoreId,
  IGetTotalQueueTodayInStoreId,
  IUpdateProductOrderOnTableByClient,
} from "@interfaces";
import axiosClient from "./axiosClientStore";

export const productAPI = {
  getProducts: (params: IGetProducts) => {
    const url = "product/getProducts";
    return axiosClient.get(url, { params });
  },
  getProductsByPOS: (params: IGetProductsByPOS) => {
    const url = "product/getProductsByPOS";
    return axiosClient.get(url, { params });
  },
  getProduct: (params: IGetProduct) => {
    const url = "product/getProduct";
    return axiosClient.get(url, { params });
  },
  getProductByStoreId: (params: IGetProductByStoreId) => {
    const url = "product/getProductByStoreId";
    return axiosClient.get(url, { params });
  },
  updateProductOrderOnTableByClient: (params: IUpdateProductOrderOnTableByClient) => {
    const url = "client_product_order/updateProductOrderOnTableByClient";
    return axiosClient.post(url, params);
  },
  getProductOrderOnTableByQrOpenTable: (params: IGetProductOrderOnTableByQrOpenTable) => {
    const url = "client_product_order/getProductOrderOnTableByQrOpenTable";
    return axiosClient.get(url, { params });
  },
  getClientPaySolutionLinkProduct: (params: IGetClientPaySolutionLinkProduct) => {
    const url = "client_product_order/getClientPaySolutionLinkProduct";
    return axiosClient.get(url, { params });
  },
  getProductOrders: (params: IGetProductOrders) => {
    const url = "product/getProductOrders";
    return axiosClient.get(url, { params });
  },
  getProductOrder: (params: IGetProductOrder) => {
    const url = "product/getProductOrder";
    return axiosClient.get(url, { params });
  },
  getProductOrderById: (params: IGetProductOrderById) => {
    const url = "product/getProductOrderById";
    return axiosClient.get(url, { params });
  },
  createProductOrderPOS: (params: ICreateProductOrderPOS) => {
    const url = "product/createProductOrderPOS";
    return axiosClient.post(url, params);
  },
  getTotalQueueTodayInStoreId: (params: IGetTotalQueueTodayInStoreId) => {
    const url = "product/getTotalQueueTodayInStoreId";
    return axiosClient.get(url, { params });
  },
  getQueueOfMyProductOrderInStoreId: (params: IGetQueueOfMyProductOrderInStoreId) => {
    const url = "product/getQueueOfMyProductOrderInStoreId";
    return axiosClient.get(url, { params });
  },
};
