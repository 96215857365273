import { useFormik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ComponentSignInTel } from "@components";
import { IAuth, IPhone } from "@interfaces";
import { authApi } from "@api";
import { setExternalLogin, setLoading } from "@redux";
import { useNotify } from "@utils";
import { enumExternalMethod, PATH_OTP } from "@configs";

interface IProps {
    remember?: boolean;
}

export const ModuleTelSignIn = memo((props: IProps) => {
    //page hooks
    const dispatch = useDispatch();
    const { error } = useNotify();
    const { t } = useTranslation();
    const history = useHistory();
    //page state
    const [errorPhone, setError] = useState<string>();
    //page props
    const { remember } = props;
    //page variable
    const initialValues: IPhone = {
        phoneNumber: "",
    };

    const handleRegister = async (values: IPhone, { setSubmitting }: FormikHelpers<IPhone>) => {
        if (errorPhone) return;

        if (!values.phoneNumber) {
            setError(t("validation.isNumber"));
            return;
        }

        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const res = await authApi.loginPhone({
                phoneNumber: values.phone || "",
            });
            const data = res.data as IAuth;
            if (data) {
                dispatch(setExternalLogin({ type: enumExternalMethod.TEL, isSignUp: false }));
                window.sessionStorage.setItem("phoneNumber", values?.phone || "");
                window.sessionStorage.setItem("remember", (remember || "").toString());
                history.push(PATH_OTP);
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const { values, handleSubmit, isSubmitting, setFieldTouched, setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: handleRegister,
    });

    return (
        <ComponentSignInTel
            values={values}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            errorPhone={errorPhone || ""}
            setError={setError}
        />
    );
});
