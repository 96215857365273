import IntlTelInput, { CountryData } from "react-intl-tel-input";
import styled from "styled-components";

import { StyledError } from "@components";
import { FormikErrors } from "formik";
import { IEmailForm, IPhone, IUserSetting } from "@interfaces";

interface IProps {
    setFieldTouched: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IUserSetting | IEmailForm | IPhone>>;
    value: string;
    errorPhone: string;
    disabled?: boolean | undefined;
    country: string | undefined;
    handleChangePhoneNumber: (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string
    ) => void;
    handleSelectFlag: (
        currentNumber: string,
        selectedCountryData: CountryData,
        fullNumber: string
    ) => void;
}

export const SharedIntlTelInput = (props: IProps) => {
    //page props
    const {
        setFieldTouched,
        value,
        errorPhone,
        disabled,
        country,
        handleChangePhoneNumber,
        handleSelectFlag,
    } = props;

    return (
        <>
            <StyledIntlTelInput
                defaultCountry={country || "th"}
                preferredCountries={["th", "vn"]}
                fieldId="phoneNumber"
                fieldName="phoneNumber"
                onPhoneNumberChange={handleChangePhoneNumber}
                onPhoneNumberBlur={() => {
                    setFieldTouched(value || "", true);
                }}
                onSelectFlag={handleSelectFlag}
                value={value}
                disabled={disabled}
                autoFocus={true}
                formatOnInit={false}
                placeholder={"Phone number"}
            />
            {errorPhone && (
                <StyledError className="custom-error" visible={true}>
                    {errorPhone}
                </StyledError>
            )}
        </>
    );
};

const StyledIntlTelInput = styled(IntlTelInput)`
    input.allow-dropdown input[type="tel"] {
        border-radius: 6px;
    }
`;
