export const AllStoresPinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99984 2.5C6.78373 2.5 4.1665 5.04125 4.1665 8.16562C4.1665 12.605 9.4515 17.1887 9.67641 17.3812C9.7691 17.4606 9.88447 17.5 9.99984 17.5C10.1152 17.5 10.2306 17.4606 10.3233 17.3819C10.5482 17.1888 15.8332 12.605 15.8332 8.16562C15.8332 5.04125 13.2159 2.5 9.99984 2.5ZM9.99984 11.25C8.21289 11.25 6.7591 9.84812 6.7591 8.125C6.7591 6.40188 8.21289 5 9.99984 5C11.7868 5 13.2406 6.40188 13.2406 8.125C13.2406 9.84812 11.7868 11.25 9.99984 11.25Z"
        fill="#9DAAAE"
      />
    </svg>
  );
};
