import { useSelector } from "react-redux";

import { selectMerchant } from "@redux";

export const IConReSend = () => {
    const merchant = useSelector(selectMerchant);
    return (
        <>
            <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.09106 5.50011C1.31551 4.28116 1.94511 3.17375 2.87777 2.35744C3.81043 1.54113 4.99148 1.06377 6.22942 1.00276C7.46736 0.941757 8.68962 1.30068 9.69802 2.02134C10.7064 2.74199 11.4419 3.78213 11.7851 4.97311"
                    stroke={merchant.themeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 1V5L8.03101 4.507"
                    stroke={merchant.themeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.9091 7.5C11.6846 8.71895 11.055 9.82636 10.1224 10.6427C9.18972 11.459 8.00867 11.9363 6.77073 11.9973C5.53279 12.0584 4.31053 11.6994 3.30213 10.9788C2.29372 10.2581 1.5583 9.21798 1.21509 8.027"
                    stroke={merchant.themeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 12V8L4.969 8.493"
                    stroke={merchant.themeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
