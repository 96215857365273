import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { exchangedType } from "@configs";
import { IReward } from "@interfaces";

interface IProps {
    data: IReward;
    onClick: () => void;
}
export const ComponentHistoryReward = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    //page props
    const { data, onClick } = props;
    const date = moment(data.updatedAtHistory).format("DD MMM YYYY");

    return (
        <StyledContainerReward
            onClick={onClick}
            isRECEIVED={data.exchangedType === exchangedType.REDEEMED}
        >
            <div className="coupon">
                <div className="title">{t("page.reward")}</div>
                <div className="date">
                    <span className="status">
                        {" "}
                        {data.exchangedType === exchangedType.REDEEMED
                            ? t("page.earned")
                            : t("page.used")}
                    </span>
                    <span className="time">{date}</span>
                </div>
            </div>
            <div className="quality">
                {" "}
                {data.exchangedType === exchangedType.REDEEMED ? "+" : "-"}
                {data.quantityPoint}
            </div>
        </StyledContainerReward>
    );
};

const StyledContainerReward = styled.div<{ isRECEIVED: boolean }>`
    padding: 18px 14px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    background-color: #fff;
    margin-bottom: 16px;
    cursor: pointer;

    border-radius: 6px;
    overflow: hidden;

    .coupon {
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 4px;
        }
    }
    .date {
        font-weight: normal;
        font-size: 8px;
        line-height: 11px;
        color: #707070;
        .status {
            display: inline-block;
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid #d9d6d6;
        }
    }
    .quality {
        color: ${(p) => (p.isRECEIVED ? "#378A00" : "#970000")};
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
    }
`;
