import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { selectMerchant } from "@redux";
import { SharedButton, StyledWelcome } from "@components";
import { PATH_SIGN_UP } from "@configs";

export const PageWelcome = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    //redux state
    const { themeColor, signUpSettings, name } = useSelector(selectMerchant);

    const handleClick = () => {
        history.push(PATH_SIGN_UP);
    };

    return (
        <StyledWelcome themeColor={themeColor}>
            <div className="box_top">
                <div className="welcome">{t("page.welcome")}</div>
                <div className="desc">
                    {t("page.become_welcome")} {name}
                    {t("page.desc_welcome")}
                </div>
                <SharedButton size="large" bg_color={themeColor} width="90%" onClick={handleClick}>
                    {t("page.become")}
                </SharedButton>
            </div>
            <div className="group_images">
                {signUpSettings?.welcomeScreenImages?.map((item) => {
                    return <img key={item} src={item} />;
                })}
            </div>
        </StyledWelcome>
    );
};
