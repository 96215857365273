import { IConEmail, IConPhone, StyleSocialButton } from "@components";
import styled from "styled-components";
export interface ISocialButton {
    onClick?: () => void;
}

export const ComponentLineButton = (props: ISocialButton) => {
    const { onClick } = props;

    const LINE_COLOR = "#06C755";
    const LINE_ICON = "/images/icon/line-login-icon.png";

    return (
        <StyleSocialButton {...props} color={LINE_COLOR} onClick={onClick}>
            <img src={LINE_ICON} className="social-icon" />
        </StyleSocialButton>
    );
};

export const ComponentFaceBookButton = (props: ISocialButton) => {
    const FACEBOOK_COLOR = "#3B589E";
    const FACEBOOK_ICON = "/images/icon/fb-login-icon.png";

    return (
        <StyleSocialButton {...props} color={FACEBOOK_COLOR}>
            <img src={FACEBOOK_ICON} className="social-icon" />
        </StyleSocialButton>
    );
};

export const ComponentGoogleButton = (props: ISocialButton) => {
    const GOOGLE_COLOR = "#E94132";
    const GOOGLE_ICON = "/images/icon/gg-login-icon.png";

    return (
        <StyleSocialButton {...props} color={GOOGLE_COLOR}>
            <img src={GOOGLE_ICON} className="social-icon" />
        </StyleSocialButton>
    );
};

export const ComponentGmailButton = (props: ISocialButton) => {
    const GOOGLE_COLOR = "#9B9B9B";
    const { onClick } = props;

    return (
        <StyledIConEmail {...props} onClick={onClick} color={GOOGLE_COLOR}>
            <IConEmail color="#fff" className="social-icon" />
        </StyledIConEmail>
    );
};

const StyledIConEmail = styled(StyleSocialButton)`
    svg {
        transform: scale(1.5);
    }
`;

export const ComponentPhoneButton = (props: ISocialButton) => {
    const GOOGLE_COLOR = "#9B9B9B";
    const { onClick } = props;

    return (
        <StyledIConEmail {...props} onClick={onClick} color={GOOGLE_COLOR}>
            <IConPhone color="#fff" className="social-icon" />
        </StyledIConEmail>
    );
};
