import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CSSProperties } from "styled-components";
import { FormikErrors, FormikTouched } from "formik";

import { SharedInput, SharedButton, IConEmail, IConPassword } from "..";
import { selectMerchant } from "@redux";
import { ILogin, IHandleBlur, IHandleChange } from "@interfaces";
import { memo } from "react";

interface IProps {
    values: ILogin;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<ILogin>;
    touched: FormikTouched<ILogin>;
    isSubmitting: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const styledPassword: CSSProperties = {
    position: "relative",
    left: "3px",
};

export const ComponentSignInEmail = memo((props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //page props
    const { values, handleBlur, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    //page variable

    const merchant = useSelector(selectMerchant);

    return (
        <form onSubmit={handleSubmit}>
            <SharedInput
                prefixIcon={<IConEmail />}
                placeholder={t("page.email")}
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.email}
                touched={touched.email}
            />
            <SharedInput
                prefixIcon={<IConPassword style={styledPassword} />}
                placeholder={t("page._password")}
                name="password"
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.password}
                touched={touched.password}
            />
            <SharedButton
                size="large"
                bg_color={merchant.themeColor || ""}
                disabled={isSubmitting}
                type="submit"
            >
                {t("page.sign_in")}
            </SharedButton>
        </form>
    );
});
