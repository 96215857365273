import styled from "styled-components";

export const StyledContainerSignIn = styled.div<{ color?: string }>`
    .sign_in {
        position: relative;
        top: -85px;
        text-align: end;
        margin: 36px 36px 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 21px;
        span {
            color: ${(p) => p.color};
            cursor: pointer;
        }
    }
`;
