import styled from "styled-components";
import { useSelector } from "react-redux";

import { IVariant } from "@interfaces";
import { IConTick } from "@components";
import { selectMerchant } from "@redux";

export interface IProps {
    variants: IVariant[];
    variant: string;
    handleChangeVariant: (value: string) => void;
    handleToggleShowSelectType: () => void;
}

export const ComponentSelectType = (props: IProps) => {
    //page props
    const { variants, variant, handleChangeVariant } = props;
    const { themeColor } = useSelector(selectMerchant);

    return (
        <StyledSelectType>
            {/* <div className="header">
                <div className="btn-hidden">
                    <IConClose width={16} />
                </div>
                <div className="shop-name">{name}</div>
                <div className="btn-back" onClick={handleToggleShowSelectType}>
                    <IConClose width={16} color="black" /> 
                </div>
            </div> */}
            <div className="body">
                {variants.map((item) => {
                    return (
                        <div
                            className="item"
                            onClick={() => {
                                handleChangeVariant(item.name);
                            }}
                            key={item._id}
                        >
                            <div className="value">{item.name}</div>
                            {variant === item.name && (
                                <div className="tick">
                                    <IConTick color={themeColor} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </StyledSelectType>
    );
};

const StyledSelectType = styled.div`
    margin-left: 12px;
    .item {
        padding: 18px 26px 18px 0;
        width: 100%;
        border-bottom: 1px solid rgba(115, 115, 115, 0.31);

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.8rem;
        color: #000000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    .value {
        font-weight: 600;
        font-style: normal;
        line-height: 1.5rem;
    }
    .header {
        .btn-hidden {
            visibility: hidden;
        }
        .shop-name {
            font-size: 18px;
            font-weight: 600;
            color: black;
        }
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
`;
