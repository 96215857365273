import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import moment from "moment";

import {
  checkoutArrow,
  basketDetailsNote,
  basketTrashBin,
  checkoutClock,
  checkoutShop,
  checkoutDistance,
  checkoutCheck,
  checkoutCopy,
  checkoutPayment,
  cancelCloseIcon,
  cancelGreenDoneIcon,
} from "@components";
import { IInfo } from "@interfaces";
import { enumNavigation } from "@configs";
import { setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";
import QRCode from "react-qr-code";

const baseUrl = process.env.REACT_APP_API_STORE_ADMIN;

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

export const ComponentMyBooking = (props: IProps) => {
  const history = useHistory();

  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };
  let basketItem = [];
  const bookingDetails = JSON.parse(localStorage.getItem("bookingDetails") || "{}");
  if (bookingDetails.booking_service) {
    const getLocalStorage = bookingDetails.booking_service;
    // basketItem.push(getLocalStorage);

    for (let i = 0; i < getLocalStorage.length; i++) {
      basketItem.push(getLocalStorage);
    }
    // console.log(basketItem[0]);
  }

  const [all, setAll] = useState(true);
  const [upcoming, setUpcoming] = useState(false);
  const [PastTab, setPastTab] = useState(false);

  // const serviceDateConvert = moment(bookingDetails.service_date).format("Do MMMM YYYY");
  // const serviceTimeConvert = moment(bookingDetails.service_time, "hh:mm").format("h:mm a");

  const [myBookingsInside, setmyBookingsInside] = useState(true);

  // const [SingleBookingView, setSingleBookingView] = useState([]);
  const [showMore, setShowMore] = useState(true);

  // let [bookingCode, setBookingCode] = useState("");
  let [viewBooking, setViewBooking] = useState([]);

  //@ts-ignore
  const token = store.getState().auth.auth?.accessToken;
  const profile_id = store.getState().auth.userInfo?._id;
  const organization_code = store.getState().auth.userInfo?.merchant?.subCode;
  const organization_id = store.getState().auth.userInfo?.merchant?._id;
  const username = store.getState().auth?.userInfo?.username;

  const headers = {
    "Content-Type": "application/json",
    "access-token": token,
  };
  // View Booking Info
  // const viewBookingFunc = () => {
  //     axios
  //         .get(
  //             `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${bookingCode}`,
  //             { headers: headers }
  //         )
  //         .then((data) => {
  //             // console.log(data);
  //             setViewBooking(data.data.result);
  //             // localStorage.removeItem("bookingDetails");
  //             localStorage.setItem("bookingDetails", JSON.stringify(viewBooking));
  //         })
  //         .catch((err) => console.log(err, "\nSomething wrong on Get Bookings, try again!"));
  // };

  // All Filter
  useEffect(() => {
    AllFilterFunc();
    const beforeUnloadListener = () => {
      //Send something to back end
      localStorage.removeItem("staffCode");
      localStorage.removeItem("bookingDetails");
    };
    window.addEventListener("pagehide", beforeUnloadListener);
    console.log("bookingdetail", bookingDetails);
  }, []);
  let [AllFilter, setAllFilter] = useState([]);
  const AllFilterFunc = () => {
    axios
      .get(`https://api-store.rocket-tech.app/1.0.0/allFilter/${username}`)
      .then((data) => {
        // console.log(data);
        setAllFilter(data.data.result);
      })
      .catch((err) => console.log(err, "\nSomething wrong on Past Filter, try again!"));
  };

  // Upcoming Filter
  let [upcomingDetails, setUpComingDetails] = useState([]);
  const upComing = () => {
    axios
      .get(`https://api-store.rocket-tech.app/1.0.0/upcomingFilter/${username}`)
      .then((data) => {
        // console.log(data);
        setUpComingDetails(data.data.result);
      })
      .catch((err) => console.log(err, "\nSomething wrong on UpComing, try again!"));
  };

  // Past Filter
  let [PastDetails, setPastDetails] = useState([]);
  const Past = () => {
    axios
      .get(`https://api-store.rocket-tech.app/1.0.0/pastFilter/${username}`)
      .then((data) => {
        // console.log(data);
        setPastDetails(data.data.result);
      })
      .catch((err) => console.log(err, "\nSomething wrong on Past Filter, try again!"));
  };

  // Cancellation
  let [cancelTab, setCancelTab] = useState(false);
  let [HideInsideBooking, setHideInsideBooking] = useState(true);

  let [bookingCode, setBookingCode] = useState("");

  let cancelBody = {
    organization_code: organization_code,
    profile_id: profile_id,
    booking_code: bookingDetails.booking_code || bookingCode,
    username: username,
  };
  const confirmCancellation = () => {
    // console.log(bookingDetails.booking_code);
    axios
      .post(`${baseUrl}booking/clientCancelBooking`, cancelBody, { headers: headers })
      .then((data) => {
        localStorage.removeItem("bookingDetails");
      })
      .catch((err) => console.log(err, "\nSomething wrong on Cancelling, try again!"));
  };

  // Cancel Popup Tab
  let [ShowCancelPopUp, setShowCancelPopUp] = useState(false);

  // Modify
  const modifyFunc = () => {
    localStorage.setItem("preBookingBody ", JSON.stringify(bookingDetails.booking_service));
  };

  // check card use code startsWith
  let cardUseCodeStartWith = "";

  return (
    <>
      {myBookingsInside ? (
        <>
          <div className="topTabs">
            <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
              <li className="nav-item item">
                <a
                  className="nav-link all active"
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  aria-controls="all"
                  aria-selected="true"
                  onClick={() => {
                    setAll(true);
                    setUpcoming(false);
                    setPastTab(false);
                    // AllFilterFunc();
                  }}
                >
                  All
                </a>
              </li>
              <li className="nav-item item">
                <a
                  className="nav-link"
                  id="upcoming-tab"
                  data-toggle="tab"
                  href="#upcoming"
                  role="tab"
                  aria-controls="upcoming"
                  aria-selected="false"
                  onClick={() => {
                    setUpcoming(true);
                    setAll(false);
                    setPastTab(false);
                    upComing();
                  }}
                >
                  Upcoming
                </a>
              </li>
              <li className="nav-item item">
                <a
                  className="nav-link"
                  id="past-tab"
                  data-toggle="tab"
                  href="#past"
                  role="tab"
                  aria-controls="past"
                  aria-selected="false"
                  onClick={() => {
                    setPastTab(true);
                    setUpcoming(false);
                    setAll(false);
                    Past();
                  }}
                >
                  Past
                </a>
              </li>
            </ul>
          </div>
          <div className="wholePage">
            {all && (
              <>
                {bookingDetails ? (
                  <>
                    {all ? (
                      AllFilter.slice(0)
                        .reverse()
                        .map((item: any, i) => (
                          <div
                            className={"bookingCard"}
                            id="all"
                            role="tabpanel"
                            aria-labelledby="all-tab"
                            key={i}
                          >
                            <div className="cardInside">
                              <div className="leftSide">
                                <h1>{item["booking_code"]}</h1>
                                <h2>{item["store_name"]}</h2>
                                <div className="smallImages">
                                  <img src={item["booking_service"][0]["image_url"]} />
                                  {/* <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" />
                                  <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" /> */}
                                </div>
                              </div>
                              <div className="rightSide">
                                <label
                                  onClick={() => {
                                    axios
                                      .get(
                                        `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                        {
                                          headers: headers,
                                        }
                                      )
                                      .then((data) => {
                                        setViewBooking(data.data.result);
                                        console.log("view booking", data?.data?.result);
                                        localStorage.setItem(
                                          "bookingDetails",
                                          JSON.stringify(data.data.result)
                                        );
                                      })
                                      .catch((err) =>
                                        console.log(
                                          err,
                                          "\nSomething wrong on Get Bookings, try again!"
                                        )
                                      );
                                    setTimeout(() => {
                                      if (bookingDetails) {
                                        setmyBookingsInside(!myBookingsInside);
                                      }
                                    }, 1200);
                                  }}
                                >
                                  View booking &gt;
                                </label>
                                <p>
                                  {moment(item["service_date"]).format("Do MMMM YYYY")}
                                  <br />
                                  {moment(item["service_time"], "hh:mm").format("h:mm a")}
                                </p>
                                {item["card_use_code"].includes("CA") && (
                                  <h3>{item["booking_service"][0]["consumption"]} Count</h3>
                                )}
                                {item["card_use_code"].includes("VA") && <h3>Store Value Cost</h3>}
                                {item["card_use_code"].length === 0 && (
                                  <h3>฿{item["summary_net_price"]}</h3>
                                )}
                              </div>
                            </div>
                            {/* <div className="noteButton">
                              <button
                                onClick={() => {
                                  axios
                                    .get(
                                      `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                      {
                                        headers: headers,
                                      }
                                    )
                                    .then((data) => {
                                      setViewBooking(data.data.result);
                                      localStorage.setItem(
                                        "bookingDetails",
                                        JSON.stringify(data.data.result)
                                      );
                                    })
                                    .catch((err) =>
                                      console.log(
                                        err,
                                        "\nSomething wrong on Get Bookings, try again!"
                                      )
                                    );
                                  setTimeout(() => {
                                    if (bookingDetails) {
                                      setmyBookingsInside(!myBookingsInside);
                                    }
                                  }, 1200);
                                  setBookingCode(item["booking_code"]);
                                  setmyBookingsInside(false);
                                  setHideInsideBooking(false);
                                  setCancelTab(true);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="black"
                                onClick={() => {
                                  axios
                                    .get(
                                      `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                      {
                                        headers: headers,
                                      }
                                    )
                                    .then((data) => {
                                      setViewBooking(data.data.result);
                                      localStorage.setItem(
                                        "bookingDetails",
                                        JSON.stringify(data.data.result)
                                      );
                                    })
                                    .catch((err) =>
                                      console.log(
                                        err,
                                        "\nSomething wrong on Get Bookings, try again!"
                                      )
                                    );
                                  setTimeout(() => {
                                    if (bookingDetails) {
                                      setmyBookingsInside(!myBookingsInside);
                                    }
                                  }, 1200);
                                }}
                              >
                                Modify
                              </button>
                            </div> */}
                          </div>
                        ))
                    ) : (
                      <p>Nothing to show</p>
                    )}
                  </>
                ) : (
                  <p>Nothing to show</p>
                )}
              </>
            )}
            {upcoming && (
              <>
                {upcoming ? (
                  upcomingDetails
                    .slice(0)
                    .reverse()
                    .map((item: any, i) => (
                      <div
                        className={
                          upcoming ? "tab-pane bookingCard active" : "tab-pane bookingCard"
                        }
                        id="all"
                        role="upcoming"
                        aria-labelledby="all-tab"
                        key={i}
                      >
                        <React.Fragment key={i}>
                          <div className="cardInside">
                            <div className="leftSide">
                              <h1>{item["booking_code"]}</h1>
                              <h2>{item["store_name"]}</h2>
                              <div className="smallImages">
                                <img src={item["booking_service"][0]["image_url"]} />
                                {/* <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" />
                                <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" /> */}
                              </div>
                            </div>
                            <div className="rightSide">
                              <label
                                onClick={() => {
                                  axios
                                    .get(
                                      `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                      {
                                        headers: headers,
                                      }
                                    )
                                    .then((data) => {
                                      setViewBooking(data.data.result);
                                      localStorage.setItem(
                                        "bookingDetails",
                                        JSON.stringify(data.data.result)
                                      );
                                    })
                                    .catch((err) =>
                                      console.log(
                                        err,
                                        "\nSomething wrong on Get Bookings, try again!"
                                      )
                                    );
                                  setTimeout(() => {
                                    if (bookingDetails) {
                                      setmyBookingsInside(!myBookingsInside);
                                    }
                                  }, 1200);
                                }}
                              >
                                View booking &gt;
                              </label>
                              <p>
                                {moment(item["service_date"]).format("Do MMMM YYYY")}
                                <br />
                                {moment(item["service_time"], "hh:mm").format("h:mm a")}
                              </p>
                              {item["card_use_code"].includes("CA") && (
                                <h3>{item["booking_service"][0]["consumption"]} Count</h3>
                              )}
                              {item["card_use_code"].includes("VA") && <h3>Store Value Cost</h3>}
                              {item["card_use_code"].length === 0 && (
                                <h3>฿{item["summary_net_price"]}</h3>
                              )}
                            </div>
                          </div>
                          {/* <div className="noteButton">
                            <button
                              onClick={() => {
                                axios
                                  .get(
                                    `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                    {
                                      headers: headers,
                                    }
                                  )
                                  .then((data) => {
                                    setViewBooking(data.data.result);
                                    localStorage.setItem(
                                      "bookingDetails",
                                      JSON.stringify(data.data.result)
                                    );
                                  })
                                  .catch((err) =>
                                    console.log(
                                      err,
                                      "\nSomething wrong on Get Bookings, try again!"
                                    )
                                  );
                                setTimeout(() => {
                                  if (bookingDetails) {
                                    setmyBookingsInside(!myBookingsInside);
                                  }
                                }, 1200);
                                setBookingCode(item["booking_code"]);
                                setmyBookingsInside(false);
                                setHideInsideBooking(false);
                                setCancelTab(true);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="black"
                              onClick={() => {
                                axios
                                  .get(
                                    `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                    {
                                      headers: headers,
                                    }
                                  )
                                  .then((data) => {
                                    setViewBooking(data.data.result);
                                    localStorage.setItem(
                                      "bookingDetails",
                                      JSON.stringify(data.data.result)
                                    );
                                  })
                                  .catch((err) =>
                                    console.log(
                                      err,
                                      "\nSomething wrong on Get Bookings, try again!"
                                    )
                                  );
                                setTimeout(() => {
                                  if (bookingDetails) {
                                    setmyBookingsInside(!myBookingsInside);
                                  }
                                }, 1200);
                              }}
                            >
                              Modify
                            </button>
                          </div> */}
                        </React.Fragment>
                      </div>
                    ))
                ) : (
                  <p>Nothing to show</p>
                )}
              </>
            )}
            {PastTab && (
              <>
                {PastTab ? (
                  PastDetails.slice(0)
                    .reverse()
                    .map((item, i) => (
                      <div
                        className={
                          upcoming ? "tab-pane bookingCard active" : "tab-pane bookingCard"
                        }
                        id="all"
                        role="upcoming"
                        aria-labelledby="all-tab"
                        key={i}
                      >
                        <div className="cardInside">
                          <div className="leftSide">
                            <h1>{item["booking_code"]}</h1>
                            <h2>{item["store_name"]}</h2>
                            <div className="smallImages">
                              <img src={item["booking_service"][0]["image_url"]} />
                              {/* <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" />
                              <img src="https://i.ibb.co/Zxg7cgC/Image-102.jpg" /> */}
                            </div>
                          </div>
                          <div className="rightSide">
                            <label
                              onClick={() => {
                                axios
                                  .get(
                                    `${baseUrl}booking/getBooking?organization_code=${organization_code}&booking_code=${item["booking_code"]}`,
                                    {
                                      headers: headers,
                                    }
                                  )
                                  .then((data) => {
                                    setViewBooking(data.data.result);
                                    localStorage.setItem(
                                      "bookingDetails",
                                      JSON.stringify(data.data.result)
                                    );
                                  })
                                  .catch((err) =>
                                    console.log(
                                      err,
                                      "\nSomething wrong on Get Bookings, try again!"
                                    )
                                  );
                                setTimeout(() => {
                                  if (bookingDetails) {
                                    setmyBookingsInside(!myBookingsInside);
                                  }
                                }, 1200);
                              }}
                            >
                              View booking &gt;
                            </label>
                            <p>
                              {moment(item["service_date"]).format("Do MMMM YYYY")}
                              <br />
                              {moment(item["service_time"], "hh:mm").format("h:mm a")}
                            </p>
                            {item["card_use_code"] ? (
                              <h3>{item["booking_service"][0]["consumption"]} Count</h3>
                            ) : (
                              <h3>฿{item["summary_net_price"]}</h3>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <p>Nothing to show</p>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {HideInsideBooking && (
            <div className="wholePage">
              <div className="topSection">
                <h1>Booking</h1>
                <div className="awards">
                  <div className="leftOne">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <g transform="translate(-76 -188)">
                        <circle cx="24" cy="24" r="24" transform="translate(76 188)" fill="#fff" />
                        <g transform="translate(86.5 199.484)">
                          <path
                            d="M11-.75A20.089,20.089,0,0,1,19.089.782C21.45,1.855,22.75,3.353,22.75,5s-1.3,3.145-3.661,4.218A20.089,20.089,0,0,1,11,10.75,20.089,20.089,0,0,1,2.911,9.218C.55,8.145-.75,6.647-.75,5S.55,1.855,2.911.782A20.089,20.089,0,0,1,11-.75Zm0,10a18.577,18.577,0,0,0,7.468-1.4C20.236,7.049,21.25,6.009,21.25,5s-1.014-2.049-2.782-2.853A18.577,18.577,0,0,0,11,.75a18.577,18.577,0,0,0-7.468,1.4C1.764,2.951.75,3.991.75,5S1.764,7.049,3.532,7.853A18.577,18.577,0,0,0,11,9.25Z"
                            transform="translate(2.5 1.516)"
                          />
                          <path
                            d="M13.508,13.254a20.1,20.1,0,0,1-8.094-1.533C3.051,10.648,1.75,9.149,1.75,7.5v-1a.75.75,0,0,1,1.5,0v1c0,1.01,1.015,2.051,2.784,2.856a18.593,18.593,0,0,0,7.474,1.4,18.593,18.593,0,0,0,7.474-1.4c1.769-.8,2.784-1.845,2.784-2.856v-1a.75.75,0,0,1,1.5,0v1c0,1.648-1.3,3.147-3.663,4.221A20.1,20.1,0,0,1,13.508,13.254Z"
                            transform="translate(0 5.007)"
                          />
                          <path
                            d="M13.508,16.254a20.1,20.1,0,0,1-8.094-1.533C3.051,13.648,1.75,12.149,1.75,10.5v-1a.75.75,0,0,1,1.5,0v1c0,1.01,1.015,2.051,2.784,2.856a18.593,18.593,0,0,0,7.474,1.4,18.593,18.593,0,0,0,7.474-1.4c1.769-.8,2.784-1.845,2.784-2.856v-1a.75.75,0,0,1,1.5,0v1c0,1.648-1.3,3.147-3.663,4.221A20.1,20.1,0,0,1,13.508,16.254Z"
                            transform="translate(0 8.012)"
                          />
                        </g>
                      </g>
                    </svg>
                    <div className="leftOneDetail">
                      <h1>Points</h1>
                      <p>+100</p>
                    </div>
                  </div>
                  <div className="middleOne"></div>
                  <div className="rightOne">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <g transform="translate(-254 -188)">
                        <circle cx="24" cy="24" r="24" transform="translate(254 188)" fill="#fff" />
                        <g transform="translate(265 198)">
                          <path
                            d="M12.522,7.373-.347.665.347-.665,13.215,6.043Z"
                            transform="translate(7.137 4.682)"
                            fill="#212121"
                          />
                          <path
                            d="M14.143,16.41.665,9.671l.671-1.342,12.808,6.4L25.308,9.151l.671,1.342Z"
                            transform="translate(0 -1.25)"
                            fill="#212121"
                          />
                          <path
                            d="M.75,11H-.75V0H.75Z"
                            transform="translate(14 14)"
                            fill="#212121"
                          />
                          <path
                            d="M12.5,1.161,26.393,8.108V19.714l-12.25,6.125L.25,18.892V7.287ZM24.893,9.035,12.5,2.839,1.75,8.214v9.752l12.393,6.2,10.75-5.375Z"
                            fill="#212121"
                          />
                        </g>
                      </g>
                    </svg>
                    <div className="leftOneDetail">
                      <h1>Bookings</h1>
                      <p>+{bookingDetails.booking_service.length}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basketSummary">
                <h1 className="SummaryTitle">My bookings</h1>
                <hr className="firstHrBasket" />
                {bookingDetails.booking_service ? (
                  basketItem.slice(showMore ? -1 : 0).map((item, i) => (
                    <div className="basketItems" key={i}>
                      <div className="leftSide">
                        <div className="serviceImage">
                          <img src={item[i]["image_url"] || "/images/default_service.png"} />
                        </div>
                        <div className="serviceDetails">
                          <h1>{item[i]["service_name"]}</h1>
                          <ul>
                            <li>
                              {item[i]["duration"]["value"]} {item[i]["duration"]["unit"]}
                            </li>
                            <li>{item[i]["variant"]}</li>
                            <li>{item[i]["booking_staff"][0]["staff_name"]}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="rightSide">
                        <div className="priceQuantity">
                          <h3>฿{item[i]["net_price"]}</h3>
                          <h4>x{item[i]["consumption"]}</h4>
                        </div>
                        {/* <div className="noteButton">
                          <button
                            className="blackModify"
                            onClick={() => {
                              // modifyFunc();
                              // console.log(item[i]["service_code"]);
                              const preBookingBody = {
                                organization_id: organization_id,
                                profile_id: profile_id,
                                store_code: bookingDetails.store_code,
                                service_date: bookingDetails.service_date,
                                service_time: bookingDetails.service_time,
                                total_price: bookingDetails.summary_net_price,
                                note: "testnote",
                                booking_service: [
                                  {
                                    service_code: item[0]["service_code"],
                                    service_name: item[0]["service_name"],
                                    standard_price: item[0]["standard_price"],
                                    duration: {
                                      value: item[0]["duration"]["value"],
                                      unit: item[0]["duration"]["unit"],
                                    },
                                    variant: item[0]["variant"],
                                    stylist: item[0]["stylist"],
                                    consumption: item[0]["consumption"],
                                    booking_staff: [
                                      {
                                        staff_code: item[0]["booking_staff"][0]["staff_code"],
                                      },
                                    ],
                                  },
                                ],
                              };
                              localStorage.setItem(
                                "modifyBookingBody",
                                JSON.stringify(preBookingBody)
                              );

                              history.push(
                                `/S-Home/${item[i]["booking_staff"][0]["staff_code"]}/${item[i]["service_code"]}`
                              );
                              location.reload();
                            }}
                          >
                            Modify
                          </button>
                        </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nothing to show</p>
                )}
                {bookingDetails.booking_service && (
                  <>
                    {bookingDetails.booking_service.length > 1 && (
                      <div
                        className="showMoreDiv text-center"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        <button>
                          {showMore ? "Show more" : "Show less"}{" "}
                          <span className={showMore ? "" : "reverse"}>
                            <StyledArrow />
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                )}
                <hr />
                <div className="totalPriceSection">
                  <label className="total">Total :</label>
                  <div className="innerTotalPrice">
                    <h4 className="totalPrice">฿{bookingDetails.summary_net_price}</h4>
                  </div>
                </div>
              </div>
              <div className="confirmationDetails">
                <h1 className="SummaryTitle">Confirmation details</h1>
                <hr className="firstHrBasket" />
                {/* <div className="qrCode">
                  <QRCode size={140} value={bookingDetails.booking_code} />
                </div> */}
                <div className="confirmDetails">
                  <p>
                    <StyledClock />
                    {moment(bookingDetails.service_date).format("Do MMMM YYYY")}
                    {" at "}
                    {moment(bookingDetails.service_time, "hh:mm").format("h:mm a")}
                  </p>
                  <p>
                    <StyledClock /> Booking Code : {bookingDetails?.booking_code}
                  </p>
                  <p>
                    <StyledShop /> {bookingDetails?.store_name}
                  </p>
                  <p>
                    <StyledDistance /> {bookingDetails?.store_data?.address}
                    <br />
                  </p>
                  <p>
                    <StyledPayment /> payment
                  </p>
                </div>
              </div>

              <div className="buttonSection">
                <button
                  className="redOne"
                  onClick={() => {
                    setCancelTab(true);
                    setHideInsideBooking(false);
                  }}
                >
                  Cancel Booking
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {cancelTab && (
        <div className="wholePage">
          <div className="topStoreInfo topSection">
            <h1>Booking cancellation</h1>
          </div>
          <div className="basketSummary">
            <h1 className="SummaryTitle">Basket summary</h1>
            <hr />
            {bookingDetails.booking_service ? (
              basketItem.slice(showMore ? -1 : 1).map((item, i) => (
                <div className="basketItems" key={i}>
                  <div className="leftSide">
                    <div className="serviceDetails FixLeft">
                      <h1>{item[i]["service_name"]}</h1>
                      <ul>
                        <li>
                          {item[i]["duration"]["value"]} {item[i]["duration"]["unit"]}
                        </li>
                        <li>{item[i]["variant"]}</li>
                        <li>{item[i]["booking_staff"][0]["staff_name"]}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="priceQuantity">
                      <h3>฿{item[i]["standard_price"]}</h3>
                      <h4>x{item[i]["consumption"]}</h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Nothing to show</p>
            )}
            {bookingDetails.booking_service && (
              <>
                {bookingDetails.booking_service.length > 1 && (
                  <div
                    className="showMoreDiv text-center"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                  >
                    <button>
                      {showMore ? "Show more" : "Show less"}{" "}
                      <span className={showMore ? "" : "reverse"}>
                        <StyledArrow />
                      </span>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="basketSummary boxMargin">
            <h1 className="SummaryTitle">Booking details</h1>
            <hr />
            <p>
              <StyledClock /> {moment(bookingDetails.service_date).format("Do MMMM YYYY")}
              {" at "}
              {moment(bookingDetails.service_time, "hh:mm").format("h:mm a")}
            </p>
            <p>
              <StyledShop /> {bookingDetails.store_name}
            </p>
            <p>
              <StyledDistance /> {bookingDetails?.store_data?.address}
            </p>
          </div>
          <div className="basketSummary boxMargin">
            <div className="topTitleFelx">
              <h1 className="SummaryTitle">Notes to store</h1>
              {/* <div className="noteButton">
                <button>Notes</button>
              </div> */}
            </div>
            <hr />
            <div className="textAreaSection">
              <div className="leftArea">
                <StyledNote />
              </div>
              <div className="rightArea">
                <textarea name="cancelNote" id="cancelNote" defaultValue={""}></textarea>
              </div>
            </div>
          </div>
          <div className="buttonSection">
            <button
              className="Next"
              onClick={() => {
                confirmCancellation();
                // localStorage.removeItem("bookingDetails");
                setTimeout(() => {
                  setCancelTab(false);
                  setShowCancelPopUp(true);
                }, 1000);
              }}
            >
              <span>Confirm cancellation</span>
            </button>
          </div>
        </div>
      )}
      {ShowCancelPopUp && (
        <div className="wholePage wholeCancelPopup">
          <div className="basketSummary">
            <div className="topPopupRow">
              <h1 className="SummaryTitle">Confirmation slip</h1>
              <div className="closeIcon">
                <StyledClose />
              </div>
            </div>
            <hr className="firstHrBasket" />
            <div className="GreenDoneIcon">
              <StyledGreenDoneIcon />
              <h1>Cancellation successful!</h1>
            </div>
          </div>
          <div className="buttonSection">
            <button
              className="Next"
              onClick={() => {
                setHideInsideBooking(false);
                setShowCancelPopUp(false);
                setmyBookingsInside(true);
                // location.reload();
              }}
            >
              <span>My bookings</span>
            </button>
          </div>
        </div>
      )}
      <EmptyFooterSpace />
    </>
  );
};

const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledArrow = styled(checkoutArrow)`
  cursor: pointer;
`;
const StyledNote = styled(basketDetailsNote)`
  cursor: pointer;
`;
const StyledBin = styled(basketTrashBin)`
  cursor: pointer;
`;
const StyledClock = styled(checkoutClock)`
  cursor: pointer;
`;
const StyledShop = styled(checkoutShop)`
  cursor: pointer;
`;
const StyledDistance = styled(checkoutDistance)`
  cursor: pointer;
`;
const StyledCheck = styled(checkoutCheck)`
  cursor: pointer;
`;
const StyledPayment = styled(checkoutPayment)`
  cursor: pointer;
`;
const StyledClose = styled(cancelCloseIcon)`
  cursor: pointer;
`;
const StyledGreenDoneIcon = styled(cancelGreenDoneIcon)`
  cursor: pointer;
`;
