import { IGetAllPosPromotionMatchTypePromoCodeOnly, IGetPromotionListByPromoPartner, IGetVariantInEachPosPromotionItemTypeVariant, ISelectPosPromotionByPriorityAuto, ISelectPosPromotionByPriorityManual } from "@interfaces";
import axiosClient from "./axiosClientStore";

export const posPromotionApi = {
  selectPosPromotionByPriorityAuto: (params: ISelectPosPromotionByPriorityAuto) => {
    const url = "pos_promotion/selectPosPromotionByPriorityAuto";
    return axiosClient.post(url, params);
  },
  selectPosPromotionByPriorityManual: (params: ISelectPosPromotionByPriorityManual) => {
    const url = "pos_promotion/selectPosPromotionByPriorityManual";
    return axiosClient.post(url, params);
  },
  getAllPosPromotionMatch: (params: ISelectPosPromotionByPriorityAuto) => {
    const url = "pos_promotion/getAllPosPromotionMatch";
    return axiosClient.post(url, params);
  },
  getPromotionListByPromoPartner: (params: IGetPromotionListByPromoPartner) => {
    const url = "pos_promotion/getPromotionListByPromoPartner";
    return axiosClient.get(url, {params});
  },
  getVariantInEachPosPromotionItemTypeVariant: (params: IGetVariantInEachPosPromotionItemTypeVariant) => {
    const url = "pos_promotion/getVariantInEachPosPromotionItemTypeVariant";
    return axiosClient.get(url, {params});
  },
  getAllPosPromotionMatchTypePromoCodeOnly: (params: IGetAllPosPromotionMatchTypePromoCodeOnly) => {
    const url = "pos_promotion/getAllPosPromotionMatchTypePromoCodeOnly";
    return axiosClient.post(url, params);
  },
};
