interface IProps {
    color?: string;
    size?: number;
}

export const IConBecomeStar = (props: IProps) => {
    const { color = "#CE0000", size } = props;

    return (
        <svg
            width={size || "26"}
            height={size || "26"}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.172 7.88297C19.304 7.68297 17.325 6.66797 16.518 3.78297C16.3455 3.29824 16.09 2.84721 15.763 2.44999C15.4535 1.99061 15.0333 1.61653 14.5411 1.36225C14.049 1.10797 13.5008 0.981659 12.947 0.994972C12.0899 1.01746 11.2655 1.32919 10.6079 1.87946C9.95035 2.42974 9.49824 3.18622 9.32502 4.02598C9.05155 5.16423 8.38282 6.16842 7.43799 6.8596C6.49317 7.55077 5.33364 7.884 4.16602 7.79996C2.47302 7.82996 0.909983 8.32198 0.250983 10.091C-0.481017 12.057 0.468977 13.54 1.96998 14.671C2.97285 15.2846 3.722 16.2381 4.081 17.3577C4.44 18.4773 4.38498 19.6885 3.92597 20.771C3.36697 22.419 3.41299 24.165 5.09199 25.325C5.76331 25.7997 6.57532 26.0337 7.39631 25.989C8.21729 25.9444 8.99912 25.6237 9.615 25.079C11.946 23.302 14.087 23.479 16.394 25.079C17.0146 25.6151 17.7963 25.9288 18.6154 25.9706C19.4344 26.0125 20.244 25.78 20.916 25.31C21.5799 24.8226 22.0542 24.12 22.2581 23.3221C22.4619 22.5241 22.3828 21.6801 22.034 20.934C21.043 18.05 21.983 16.026 24.285 14.324C24.688 14.0647 25.0346 13.7267 25.3038 13.3303C25.5731 12.9339 25.7595 12.4872 25.852 12.017C26.223 9.71398 25.013 8.08297 22.172 7.88297ZM22.312 13C22.087 13.2908 21.8239 13.5501 21.53 13.771C19.059 15.371 18.599 17.559 19.523 20.223C19.712 20.8567 19.8156 21.5129 19.831 22.174C19.901 23.259 19.341 23.702 18.331 23.332C17.1343 23.0259 15.9968 22.5231 14.965 21.844C13.414 20.544 12.121 20.867 10.652 21.822C9.78589 22.3724 8.87579 22.8504 7.93098 23.251C6.75798 23.761 6.09198 23.365 6.11298 21.515C8.12598 17.781 6.37498 14.809 2.97498 12.262C2.09498 11.603 2.32299 10.683 3.51899 10.317C3.72779 10.2456 3.94637 10.2068 4.167 10.202C8.067 10.264 10.494 8.49197 11.719 4.70197C12.288 2.94397 13.356 3.02797 14.278 4.61497C14.3982 4.79989 14.4939 4.99956 14.563 5.20899C15.493 8.64399 17.787 10.174 21.284 10.149C22.045 10.144 23.038 10.074 23.384 11.007C23.733 11.968 22.826 12.423 22.312 13.005V13Z"
                fill="#050505"
            />
            <path
                d="M12.894 18.885C12.2554 18.8988 11.6236 18.7524 11.0562 18.4591C10.4888 18.1658 10.004 17.7349 9.64602 17.206C9.53265 17.0833 9.44489 16.9393 9.3879 16.7823C9.33091 16.6253 9.30582 16.4586 9.31411 16.2918C9.3224 16.125 9.3639 15.9615 9.43618 15.8109C9.50845 15.6604 9.61004 15.5258 9.73501 15.415C9.8436 15.3131 9.9721 15.2348 10.1124 15.1849C10.2527 15.135 10.4017 15.1146 10.5503 15.125C10.6988 15.1355 10.8435 15.1765 10.9755 15.2455C11.1074 15.3144 11.2237 15.4099 11.317 15.526C12.488 16.716 13.575 16.655 14.717 15.482C14.8125 15.3677 14.932 15.2758 15.067 15.2127C15.2019 15.1496 15.349 15.1169 15.498 15.1169C15.647 15.1169 15.7941 15.1496 15.929 15.2127C16.064 15.2758 16.1835 15.3677 16.279 15.482C16.4926 15.6906 16.6218 15.9707 16.6419 16.2686C16.662 16.5666 16.5717 16.8615 16.388 17.097C16.014 17.6757 15.4941 18.1456 14.8808 18.4595C14.2674 18.7734 13.5821 18.9201 12.894 18.885Z"
                fill={color}
            />
        </svg>
    );
};

export const IConBecomeCoupon = (props: IProps) => {
    const { color = "#CE0000" } = props;

    return (
        <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.774 13.457C25.097 12.1382 24.3242 10.8708 23.4619 9.66502C22.7419 8.72002 21.82 9.85501 21.038 9.73301C20.6691 9.7631 20.2998 9.67873 19.9806 9.49137C19.6613 9.30401 19.4076 9.02279 19.254 8.68601C19.0333 8.32183 18.9566 7.88833 19.0392 7.47056C19.1217 7.05279 19.3574 6.68094 19.7 6.42802C20.969 5.55302 20.829 4.67601 20.051 3.59501C19.6013 2.96473 19.2091 2.29538 18.879 1.59501C18.279 0.331009 17.525 0.195016 16.328 0.915016C12.054 3.48302 7.72796 5.96301 3.43296 8.49601C-0.759044 10.969 -0.57801 9.94403 2.06699 14.649C2.09599 14.7 2.13296 14.749 2.16697 14.797C2.58997 15.446 3.06698 15.859 3.93698 15.521C4.36934 15.3022 4.86899 15.2568 5.33371 15.3941C5.79844 15.5315 6.19311 15.8413 6.43698 16.26C6.58106 16.4719 6.68137 16.7105 6.73203 16.9617C6.78269 17.2129 6.7827 17.4717 6.73203 17.7229C6.68136 17.9741 6.581 18.2126 6.43692 18.4246C6.29285 18.6365 6.10794 18.8175 5.89298 18.957C5.74441 19.0611 5.62016 19.1962 5.52884 19.353C5.43752 19.5098 5.38129 19.6845 5.36399 19.8651C5.34669 20.0457 5.36878 20.2279 5.42868 20.3991C5.48859 20.5704 5.58487 20.7266 5.71097 20.857C6.66197 22.075 6.81097 23.931 8.55497 24.499C8.77997 24.572 9.12999 24.386 9.37699 24.242C14.607 21.192 19.8307 18.1317 25.048 15.061C25.609 14.721 26.193 14.225 25.774 13.457ZM22.326 14.243C18.286 16.6217 14.2393 18.9884 10.186 21.343C9.68601 21.63 9.10596 22.343 8.50296 21.501C8.23974 21.2244 8.06937 20.8726 8.0156 20.4945C7.96182 20.1165 8.02733 19.7311 8.20298 19.392C9.73898 16.181 8.215 13.579 4.642 13.273C4.25725 13.2708 3.88356 13.1438 3.57706 12.9112C3.27056 12.6786 3.04774 12.3529 2.94199 11.983C2.59999 11.057 3.44199 10.908 3.96299 10.602C7.94899 8.24869 11.9406 5.90502 15.938 3.57102C16.238 3.39302 16.553 3.22303 16.776 3.09803C17.688 3.09803 18.2679 4.42701 17.8499 5.29801C15.9589 9.23601 17.218 11.398 21.696 11.757C22.637 11.833 22.885 12.447 23.142 13.088C23.427 13.808 22.759 13.992 22.326 14.247V14.243Z"
                fill="#050505"
            />
            <path
                d="M12.52 16.068C12.569 16.79 12.38 17.144 11.953 17.326C11.763 17.424 11.5433 17.4475 11.3369 17.392C11.1305 17.3365 10.9522 17.206 10.837 17.026C9.74969 15.256 8.69001 13.469 7.65801 11.665C7.56186 11.4743 7.54057 11.2545 7.59831 11.0489C7.65605 10.8433 7.78866 10.6667 7.97002 10.554C8.33402 10.263 8.80297 10.163 9.10197 10.525C10.3667 12.2923 11.5088 14.1443 12.52 16.068Z"
                fill={color}
            />
        </svg>
    );
};

export const IConBecomeBenefit = (props: IProps) => {
    const { color = "#CE0000" } = props;

    return (
        <svg
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.04299 11.246C11.4007 11.246 13.312 8.95235 13.312 6.123C13.312 3.29365 11.4007 1 9.04299 1C6.68528 1 4.77399 3.29365 4.77399 6.123C4.77399 8.95235 6.68528 11.246 9.04299 11.246Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.728 19.8581H1.35901C1.35901 18.2729 1.98873 16.7526 3.10963 15.6317C4.23054 14.5108 5.75081 13.8811 7.33601 13.8811H10.751C12.3362 13.8811 13.8565 14.5108 14.9774 15.6317C16.0983 16.7526 16.728 18.2729 16.728 19.8581Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
