import { IReceive } from "@interfaces";
import axiosClient from "./axiosClient";

export const freePointApi = {
    getPreviewFreePoint: (id: string) => {
        const url = `/free-points/${id}/qrcode`;
        return axiosClient.get(url);
    },
    // redeemedFreePoint: (values: IReceive, id : string) => {
    //     const url = `/histories/${id}/redeemed`;
    //     return axiosClient.post(url, values);
    // },
    redeemedFreePoint: (values: IReceive, id: string) => {
        const url = `/free-points/${id}/redeemed`;
        return axiosClient.post(url, values);
    },
};
