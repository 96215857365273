import styled from "styled-components";

export const StyledCheckout = styled.div<{ mainColor: string }>`
    .wholePage {
        background: #f7f7f8;
        padding: 20px;
        // height: 100vh;
    }
    .topStoreInfo {
        margin-bottom: 20px;
    }
    .topStoreInfo h1 {
        font-size: 16px;
        color: #9b9b9b;
    }
    .topStoreInfo .baht {
        font-size: 18px;
        font-weight: bold;
    }
    .topStoreInfo p {
        font-size: 35px;
        font-weight: bold;
        color: #000000;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
    .basketSummary {
        background: #fff;
        border-radius: 5px;
        padding: 20px;
    }
    .SummaryTitle {
        font-size: 16px;
        font-weight: bold;
    }
    // Basket Codes
    .basketItems {
        display: flex;
        place-content: space-between;
        margin-bottom: 20px;
    }
    .serviceImage img {
        width: 100%;
    }
    .serviceDetails {
        margin-left: 20px;
    }
    .buyCard.serviceDetails {
        margin-left: 0;
    }
    .serviceDetails h1 {
        font-size: 16px;
        // font-weight: bold;
    }
    .serviceDetails ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 5px;
    }
    .serviceDetails ul li:before {
        content: "-";
        margin-right: 5px;
    }
    .serviceDetails ul li {
        font-size: 10px;
    }
    .serviceDetails p {
        font-size: 10px;
        display: flex;
        place-items: center;
    }
    .serviceDetails p svg {
        margin-right: 5px;
    }
    .noteButton button {
        background: #fff;
        border-radius: 20px;
        border: 1px solid #919191;
        color: #919191;
        width: 55px;
        cursor: pointer;
        outline: none;
        font-size: 12px;
    }
    .noteButton button:active {
        outline: none;
    }
    .priceQuantity h3 {
        font-size: 15px;
    }
    .priceQuantity h4 {
        font-size: 10px;
        text-align: right;
    }
    .leftSide {
        display: flex;
    }
    .rightSide {
        display: flex;
        gap: 20px;
    }
    .priceQuantity {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        place-items: end;
    }
    .priceQuantity svg {
        margin-top: 27px;
        cursor: pointer;
    }
    // Basket Codes
    .showMoreDiv {
        cursor: pointer;
        height: 30px;
    }
    .showMoreDiv button {
        font-size: 11px;
        font-weight: bold;
        border: none;
        background: transparent;
        outline: none;
        color: #000;
        cursor: pointer;
    }
    .showMoreDiv button .reverse svg {
        transform: rotate(180deg);
    }
    .boxMargin {
        margin-top: 20px;
    }
    .basketSummary p svg {
        margin-right: 5px;
    }
    .basketSummary p {
        margin-bottom: 1.5rem;
    }
    .radioItem label {
        font-size: 16px;
        margin-bottom: 0;
    }
    .radioItem {
        display: flex;
        place-content: space-between;
        place-items: center;
        margin-bottom: 20px;
    }
    .checked {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid #a5a5a5;
    }
    .checked svg {
        width: 18px;
        position: relative;
        bottom: 3px;
    }
    .radio-custom:checked + .checked {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #000000;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid transparent;
    }
    input[type="radio"] {
        display: none;
    }
    .cardImages {
        margin-right: 5px;
    }
    .rabbit {
        margin-left: -10px;
    }
    .BankLabel {
        display: flex;
    }
    .BankLabel ul {
        margin-bottom: 0;
        padding-bottom: 0;
        list-style: none;
        padding-left: 10px;
    }
    .BankLabel ul li {
        font-size: 10px;
        color: #919191;
    }
    .copyIcon {
        margin-left: -25px;
        position: absolute;
    }
    .attachSlip {
        width: 100% !important;
        height: 50px;
        border-radius: 24px !important;
        font-size: 14px !important;
        color: #000 !important
        cursor: pointer;
    }
    .buttonSection {
        display: flex;
        place-content: end;
        padding: 4rem 20px 20px 20px;
        background: #f7f7f8;
    }
    .buttonSection button {
        background: #f22f46;
        border-radius: 30px;
        border: 1px solid #f22f46;
        color: #fff;
        width: 100%;
        height: 60px;
        cursor: pointer;
        font-weight: bold;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        place-content: space-evenly;
        place-items: center;
    }
    .buttonSection button span {
        font-size: 18px;
    }
    .QRPage {
        padding: 20px;
        background-color:#FBFBFB;
        .top-section {
            padding-top: 70px;
            width: 80%;
            margin: auto;
            text-align: center;
            h2 {
                font-size: 20px;
                font-weight: bold;
                span {
                    color: red;
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .desc {
                margin-top: 15px;
                font-size: 16px;
                /* color: black; */
            }
            .qr-section {
                .header-section {
                    background-color:#0E3D67;
                    border-radius: 8px 8px 0 0;
                    padding: 14px;
                    text-align:center;
                    .box-section {
                        display: flex; 
                        width: 150px;  
                        margin: auto;
                    }
                    
                    .qr-icon{
                        width: 45px;
                    }
                    p {
                        color: white;
                        font-weight: bold;
                        margin: 0;
                        line-height: 18px;
                    }
                    .header-box{
                        width: 50%;
                    }
                }
                .body-section {
                    background: white;
                    margin-bottom: 50px;
                    padding-bottom: 50px;
                    .promptpay-icon {
                        width: 51%;
                    }
                    .qr-img {
                        width: 70%;
                        margin-bottom: 35px;
                    }
                    .acc-text {
                        color: black;
                        font-size: 20px;
                        font-weight: bold;
                        margin: 0px;
                    }
                    .price {
                        color: red;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .action {
                        display: flex;
                        justify-content: center;
                        p {
                            margin: 0px 15px;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: bold;
                            color: black;
                        }
                        
                    }
                }
            }
        }
        .bottom-section {
            width: 80%;
            margin: auto;
            h2 {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 15px;
                border-bottom: 1px solid #E1E1E1;
            }
            p {
                font-size: 14px;
                font-weight: bold;
                color: black;
                span {
                    color: red;
                    font-weight: bold;
                }
            }
        }
    
    }
`;
