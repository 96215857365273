import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Parser from "html-react-parser";

import { selectMerchant } from "@redux";
import { ICoupon } from "@interfaces";
import { enumUnit } from "@configs";
import { IConMoney } from "@components";

interface IProps {
    data: ICoupon;
    handleClickItem: (id: string) => void;
}

export const CouponItem = (props: IProps) => {
    //page props
    const { data, handleClickItem } = props;
    //page hook
    const { t } = useTranslation();
    //redux page
    const { themeColor } = useSelector(selectMerchant);

    const dateFormat = moment(data?.endDate).format("DD/MM/yyyy");

    return (
        <StyledContainer>
            <StyledContainerTop
                themeColor={themeColor}
                onClick={() => handleClickItem(data.qrToken || "")}
            >
                <div className="right">
                    <div className="name">{data.name}</div>
                    <div className="desc">{Parser(data.guideline || "")}</div>
                </div>
                <div className="money">
                    <IConMoney />
                </div>
                <div className="left">
                    <div className="money_right">
                        {data.unit === enumUnit.PERCENTAGE
                            ? (data.benefit || 0) + "%"
                            : (data.benefit || 0) + "฿"}
                    </div>
                </div>
            </StyledContainerTop>
            <StyledContainerBottom
                themeColor={themeColor}
                onClick={() => handleClickItem(data.qrToken || "")}
            >
                <div className="date">
                    {t("page.expiration_date")}: {dateFormat}
                </div>
                <div className="recceive_coupon">{t("page.use_coupon")} &gt;</div>
            </StyledContainerBottom>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    /* box-shadow: rgb(199 188 188) -3px 1px 2px 1px; */
    margin-right: 1px;
    border-radius: 6px;
    margin-bottom: 16px;
`;

const StyledContainerTop = styled.div<{ themeColor: string }>`
    background-color: #fff;
    padding: 0 24px 29px 24px;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-radius: 6px;
    border-bottom: 1px dashed #f2f2f2;
    min-height: 7rem;
    .right {
        flex: 1;
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileLarge}) {
            max-width: 45%;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            max-width: 42%;
        }
        * {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .name {
            max-width: 95%;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            padding-top: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .desc {
            max-width: 95%;
            font-weight: normal;
            font-size: 12px;
            line-height: 1.5rem;
            color: #8a8a8a;
            margin-top: 4px;
            padding-top: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 20px;
            * {
                margin-bottom: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 1.2rem;
            }
        }
    }
    .money {
        width: fit-content;
        position: absolute;
        top: 0;
        left: 50%;
        svg {
            opacity: 0.5;
        }
    }
    .left {
        width: fit-content;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: fit-content;
        /* width: 23%; */
        z-index: 1;
        .money_right {
            width: 100%;
            font-weight: 500;
            font-size: 26px;
            color: ${(p) => p.themeColor};
            text-align: end;
            padding-top: 18px;
            word-break: break-all;
        }
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f7f7f8;
        transform: translate(-50%, 50%);
        /* box-shadow: inset rgb(199 188 188) 1px 1px 1px 0px; */
    }
    &::before {
        content: "";
        /* box-shadow: inset rgb(199 188 188) 1px 1px 1px 0px; */
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f7f7f8;
        transform: translate(50%, 50%);
    }
`;

const StyledContainerBottom = styled.div<{ themeColor: string }>`
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 14px 24px 18px 24px;
    align-items: flex-end;
    border-radius: 6px;

    .date {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #8a8a8a;
    }
    .recceive_coupon {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: ${(p) => p.themeColor};
        cursor: pointer;
    }
`;
