import { IGetStore, IGetStoreById, IGetStores } from "src/Interfaces/store";
import axiosClient from "./axiosClientStore";

export const storeAPI = {
  getStores: (params: IGetStores) => {
    const url = "store/getStores";
    return axiosClient.get(url, { params });
  },
  getStore: (params: IGetStore) => {
    const url = "store/getStore";
    return axiosClient.get(url, { params });
  },
  getStoreById: (params: IGetStoreById) => {
    const url = "store/getStoreById";
    return axiosClient.get(url, { params });
  },
};
