import { IPagination } from "@interfaces";
import axiosClient from "./axiosClient";

export const historyApi = {
    getListHistory: (params: IPagination) => {
        const url = "/histories/all";
        return axiosClient.get(url, { params });
    },
    getListHistoryDetailById: (id: string) => {
        const url = `/histories/${id}/history`;
        return axiosClient.get(url);
    },

    getHistoryPublic: (id: string, params: { merchantId: string }) => {
        const config = {
            headers: {
                "merchant-id": params.merchantId,
            },
        };
        const url = "histories/public/" + id;
        return axiosClient.get(url, config);
    },
};
