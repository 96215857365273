import { enumValidation } from "@configs";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const YubPhoneSignUp = () => {
    //page hook
    const { t } = useTranslation();
    return {
        phoneNumber: Yup.string().matches(phoneRegExp, t("validation.isNumber")),
    };
};

export const YubEmailTelUpForm = () => {
    //page hook
    const { t } = useTranslation();
    return {
        fullName: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.name"),
                })
            )
            .max(
                enumValidation.MAX_LENGTH_INPUT,
                t("validation.max_length_number", {
                    returnObjects: true,
                    name: t("page.name"),
                    number: enumValidation.MAX_LENGTH_INPUT,
                })
            ),
        acceptTermsAndConditions: Yup.boolean().oneOf(
            [true],
            t("validation.acceptTermsAndConditions")
        ),
    };
};
