export const IconSaveQR = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6602 15.726C12.3017 15.726 12.0107 15.4351 12.0107 15.0766V4.64947C12.0107 4.29096 12.3017 4 12.6602 4C13.0187 4 13.3097 4.29096 13.3097 4.64947V15.0766C13.3097 15.4351 13.0187 15.726 12.6602 15.726Z"
                fill="#333B45"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6604 15.726C12.4881 15.726 12.3218 15.6576 12.2006 15.5346L9.67543 12.9999C9.42257 12.7454 9.42344 12.334 9.67717 12.0812C9.93176 11.8283 10.3422 11.8283 10.5951 12.0829L12.6604 14.1569L14.7257 12.0829C14.9786 11.8283 15.3891 11.8283 15.6437 12.0812C15.8974 12.334 15.8983 12.7454 15.6454 12.9999L13.1202 15.5346C12.999 15.6576 12.8327 15.726 12.6604 15.726Z"
                fill="#333B45"
            />
            <mask
                id="mask0_1736_81044"
                // style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="4"
                y="8"
                width="18"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 8.09816H21.3191V20H4V8.09816Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1736_81044)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4874 20H7.84055C5.72327 20 4 18.2776 4 16.1594V11.9292C4 9.81624 5.71894 8.09816 7.83276 8.09816H8.64763C9.00614 8.09816 9.29711 8.38913 9.29711 8.74764C9.29711 9.10615 9.00614 9.39711 8.64763 9.39711H7.83276C6.43509 9.39711 5.29895 10.5324 5.29895 11.9292V16.1594C5.29895 17.5614 6.43856 18.701 7.84055 18.701H17.4874C18.8833 18.701 20.0203 17.564 20.0203 16.1681V11.9387C20.0203 10.5367 18.8799 9.39711 17.4796 9.39711H16.6725C16.314 9.39711 16.0231 9.10615 16.0231 8.74764C16.0231 8.38913 16.314 8.09816 16.6725 8.09816H17.4796C19.5969 8.09816 21.3193 9.82143 21.3193 11.9387V16.1681C21.3193 18.281 19.5995 20 17.4874 20Z"
                    fill="#333B45"
                />
            </g>
        </svg>
    );
};
