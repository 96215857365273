import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { ComponentPasswordEmail } from "@components";
import { IEmail } from "@interfaces";
import { useYup } from "@validations";
import { authApi } from "@api";
import { setLoading } from "@redux";
import { useNotify } from "@utils";
import { PATH_SIGN_IN } from "@configs";

export const ModuleEmailResetPassword = () => {
    //page hooks
    const { YubEmailSignUp } = useYup();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const history = useHistory();

    //page variable
    const initialValues: IEmail = {
        email: "",
    };
    const registerSchema = Yup.object().shape(YubEmailSignUp);

    const handleRegister = async (values: IEmail, { setSubmitting }: FormikHelpers<IEmail>) => {
        //
        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const res = await authApi.sendResetPassword(values.email);
            const data = res.data;
            if (data.success) {
                success(data.message);
                history.push(PATH_SIGN_IN);
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validationSchema: registerSchema,
            onSubmit: handleRegister,
        });

    return (
        <ComponentPasswordEmail
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
        />
    );
};
