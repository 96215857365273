import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import Parser from "html-react-parser";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { BLANK_IMAGE_URL } from "@configs";
import { ArrowRight, IconStarSub, SharedButton, StyledPreview } from "@components";
import { IReward, IVariant } from "@interfaces";
import { selectAuth, selectMerchant } from "@redux";
import { numberFormatter } from "@utils";

interface IProps {
    data: IReward;
    count: number;
    handleToggleConfirm: () => void;
    handleAddCount: () => void;
    handleMinusCount: () => void;
    handleChangeImage: (swiper: SwiperCore) => void;
    currentImage: number;
    handleToggleShowSelectType: () => void;
    variant: string;
    variants: IVariant[];
    pointLeft: number;
}

SwiperCore.use([Navigation, Pagination]);

export const ComponentRewardDetail = (props: IProps) => {
    //hook
    const { t } = useTranslation();
    // props
    const {
        data,
        handleToggleConfirm,
        count,
        handleAddCount,
        handleMinusCount,
        currentImage,
        handleChangeImage,
        handleToggleShowSelectType,
        variant,
        variants,
        pointLeft,
    } = props;
    //redux states
    const { themeColor } = useSelector(selectMerchant);
    const { showConfirm, userInfo } = useSelector(selectAuth);

    return (
        <StyledPreview showConfirm={showConfirm}>
            <div className="page_body">
                {data.imageUrl.length ? (
                    <div className="pagination">
                        {currentImage}/{data.imageUrl.length}
                    </div>
                ) : (
                    <></>
                )}
                <div className="img-field">
                    <Swiper
                        slidesPerView={1}
                        pagination={true}
                        onActiveIndexChange={handleChangeImage}
                    >
                        {data.imageUrl.length ? (
                            data.imageUrl.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="img-wrap">
                                        <img src={item} />
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <div className="img-wrap">
                                    <img className="empty-img" src={BLANK_IMAGE_URL} />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className="name-field">
                    {data.quantity ? (
                        <p>
                            {t("page.quantity_available", {
                                returnObjects: true,
                                number: numberFormatter(data.quantity),
                            })}
                        </p>
                    ) : (
                        <></>
                    )}
                    <h5>{data.name}</h5>
                </div>
                <div className="quantity-field">
                    <div className="count-item">
                        <div className="item-left">
                            <div className="item-left-top">
                                <IconStarSub size={21} color={themeColor} />
                                <p>
                                    {data.point} x {count}
                                </p>
                            </div>
                            <div className="text-small">
                                {userInfo?.totalPoints && !!pointLeft
                                    ? t("page.have_points_left", {
                                          number: pointLeft,
                                      })
                                    : t("page.do_not_have_enough_points", {
                                          number: pointLeft,
                                      })}
                            </div>
                        </div>
                        <div className="item-right">
                            <div onClick={handleMinusCount} className=" btn-minus">
                                <div>-</div>
                            </div>
                            <p>{count}</p>
                            <div onClick={handleAddCount} className=" btn-add">
                                <div>+</div>
                            </div>
                        </div>
                    </div>
                </div>
                {variants.length !== 0 && (
                    <div className="select-field" onClick={handleToggleShowSelectType}>
                        <div className="text-small">
                            <div className="select_type">{t("page.select_type")}</div>
                            {<div className="variant">{variant || t("page.select")}</div>}
                        </div>
                        <div className="arrowRight">
                            <ArrowRight />
                        </div>
                    </div>
                )}
                <div className="description-field">
                    {data.description && (
                        <>
                            <h5>{t("page.description")}</h5>
                            <div>{Parser(data.description)}</div>
                        </>
                    )}
                    <div className="use_point">
                        <StyledButton
                            size="large"
                            bg_color={themeColor}
                            type="submit"
                            onClick={handleToggleConfirm}
                            disabled={pointLeft >= 0 ? false : true}
                        >
                            {t("page.redeem_reward")}
                        </StyledButton>
                    </div>
                </div>
            </div>
        </StyledPreview>
    );
};

const StyledButton = styled(SharedButton)`
    margin: 24px auto;
    font-weight: bold;
`;
