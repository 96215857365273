import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Empty } from "antd";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { rewardApi } from "@api";
import { ComponentRewardList, ComponentSpinPage } from "@components";
import { IReward, IDataReward } from "@interfaces";
import { REWARD } from "@configs";
import { RenderMeta } from "@utils";

export const ModulesReward = () => {
    //props
    const history = useHistory();
    const { t } = useTranslation();
    //page states
    const [reward, setReward] = useState<IDataReward[]>([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    useEffect(() => {
        handleGetReward();
    }, []);

    const handleGetReward = async () => {
        try {
            setSpinning(true);
            const res = await rewardApi.getListReward();
            const data = res.data.docs as IReward[];
            //WHAT: xu ly data thanh IData
            const points = data.map((item) => item.point) as number[];
            const pointsSet = Array.from(new Set(points));
            const result = pointsSet.map((item) => {
                const res = data.filter((values) => values.point === item);
                return {
                    point: item,
                    reward: res,
                };
            });
            result.sort((firstEl, secondEl) => {
                if (secondEl.point > firstEl.point) {
                    return -1;
                } else {
                    return 0;
                }
            });
            setReward(result);
        } finally {
            setSpinning(false);
        }
    };

    const handleClick = (value: IReward, check: boolean) => {
        if (check) return;
        history.push(REWARD + "/" + value._id);
        //
    };

    const loadingComponent = (
        <StyledLoading>
            <Skeleton animation="wave" width={"30%"} className="title" height={30} />
            <div className="row">
                <div className="card">
                    <Skeleton animation="wave" variant="rectangular" width={"100%"} height={210} />
                    <div className="card-bottom">
                        <Skeleton animation="wave" width={"100% "} height={20} />
                        <Skeleton animation="wave" width={"100% "} height={20} />
                    </div>
                </div>
                <div className="card">
                    <Skeleton animation="wave" variant="rectangular" width={"100%"} height={210} />
                    <div className="card-bottom">
                        <Skeleton animation="wave" width={"100% "} height={20} />
                        <Skeleton animation="wave" width={"100% "} height={20} />
                    </div>
                </div>
            </div>
            <Skeleton animation="wave" width={"30%"} className="title" height={30} />
            <div className="row">
                <div className="card">
                    <Skeleton animation="wave" variant="rectangular" width={"100%"} height={210} />
                    <div className="card-bottom">
                        <Skeleton animation="wave" width={"100% "} height={20} />
                        <Skeleton animation="wave" width={"100% "} height={20} />
                    </div>
                </div>
                <div className="card">
                    <Skeleton animation="wave" variant="rectangular" width={"100%"} height={210} />
                    <div className="card-bottom">
                        <Skeleton animation="wave" width={"100% "} height={20} />
                        <Skeleton animation="wave" width={"100% "} height={20} />
                    </div>
                </div>
            </div>
        </StyledLoading>
    );

    return (
        <>
            <RenderMeta title={t("meta.home")} />
            {!spinning ? (
                reward?.length > 0 ? (
                    <ComponentRewardList data={reward} handleClick={handleClick} />
                ) : (
                    <Empty />
                )
            ) : (
                <ComponentSpinPage children={loadingComponent} />
            )}
        </>
    );
};

const StyledLoading = styled.div`
    margin: 24px 0;
    padding: 24px;
    .card {
        background-color: white;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 1px 3px 6px rgba(0, 0, 0, 0.23);
        width: 240px;
        min-width: 209px;
        margin: 0 24px 28px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
        span {
            width: 100%;
        }
        .card-bottom {
            width: 100%;
            padding: 10px 20px;
        }
    }
    .row {
        display: flex;
        margin-bottom: 1.5rem;
        overflow: hidden;
    }
    .title {
        width: 30%;
        margin-bottom: 30px;
    }
`;
