import querystring from "querystring";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import { ComponentLineButton } from "@components";
import { LINE_ACCESS_URL, LINE_REDIRECT_URI, LINE_SCOPE } from "@configs";
import { IExternalAuthState } from "@interfaces";
import { selectMerchant } from "@redux";

interface IProps {
    state: IExternalAuthState;
}

export const ModuleLine = memo((props: IProps) => {
    //props
    const { state } = props;
    //redux page
    const { lineIntegration } = useSelector(selectMerchant);

    const handleLineLogin = useCallback(() => {
        // Build query string.

        const query = querystring.stringify({
            response_type: "code",
            client_id: lineIntegration?.lineLoginApi?.channelID || "",
            state: JSON.stringify(state),
            redirect_uri: LINE_REDIRECT_URI,
        });

        // Build the Line authorize URL.
        const lineUrl = `${LINE_ACCESS_URL}/authorize?&bot_prompt=normal&scope=${LINE_SCOPE}&${query}`;

        window.location.href = lineUrl;
        // Redirect to external URL.
    }, []);

    return <ComponentLineButton onClick={handleLineLogin} />;
});
