import { useSelector } from "react-redux";
import { useState } from "react";

import { ComponentHome, ComponentQRcode, StyledSHome } from "@components";
import { selectMerchant } from "@redux";

export const PageHome = () => {
  const { themeColor } = useSelector(selectMerchant);
  const [showQrCode, setShowQrCode] = useState<boolean>(false);

  const handleToggleQrCode = () => {
    setShowQrCode(!showQrCode);
  };

  return (
    <StyledSHome mainColor={themeColor}>
      {!showQrCode && (
        <>
          <ComponentHome />
        </>
      )}
      {showQrCode && <ComponentQRcode handleToggleQrCode={handleToggleQrCode} />}
    </StyledSHome>
  );
};
