import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

import { ICoupon, ICouponList } from "@interfaces";
import { CouponItem } from "./Item";
import { IconLoadMore } from "@components";
import { enumNavigation } from "@configs";

interface IProps {
    data: ICouponList[] | ICoupon[];
    handleClickItem: (id: string) => void;
    handleLoadMore: () => void;
    loadingPagination: boolean;
    under: enumNavigation;
}

export const ComponentCouponList = (props: IProps) => {
    //page props
    const { data, handleClickItem, handleLoadMore, loadingPagination, under } = props;

    return (
        <StyledContainerCouponList>
            <InfiniteScroll
                dataLength={data.length}
                next={handleLoadMore}
                hasMore={true}
                loader={
                    loadingPagination && (
                        <div className="example">
                            <Spin
                                size="large"
                                spinning={loadingPagination}
                                indicator={<IconLoadMore />}
                            />
                        </div>
                    )
                }
            >
                {data.map((item, index) => {
                    if (under === enumNavigation.COUPON_MERCHANT) {
                        const dataCoupon = item as ICouponList;
                        return (
                            <CouponItem
                                handleClickItem={handleClickItem}
                                data={{ ...dataCoupon.qrtable, exchangedType: item.exchangedType }}
                                key={index}
                            />
                        );
                    } else {
                        const dataCoupon = item as ICoupon;
                        return (
                            <CouponItem
                                handleClickItem={handleClickItem}
                                data={{ ...dataCoupon }}
                                key={index}
                            />
                        );
                    }
                })}
            </InfiniteScroll>
        </StyledContainerCouponList>
    );
};

const StyledContainerCouponList = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
    .example {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
    .infinite-scroll-component {
        overflow: hidden !important;
        border-radius: 6px;
    }
`;
