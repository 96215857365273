import { ICounterService } from "./../../Interfaces/cardUse";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICardUse, ISetCardDetail, ISetCardRedux } from "@interfaces";

import { RootState } from ".";

const initialState: ICardUse = {
  card_code: "",
  card_name: "",
  card_type: "",
  card_use_code: "",
  current_path: "",
  channel: "",
  counter_service: {
    duration: {
      value: 0,
      unit: "minutes",
    },
    service_balance: 0,
    service_code: "",
    service_id: "",
    variant: "",
  },
};

const cardUse = createSlice({
  name: "cardUse",
  initialState,
  reducers: {
    setCardDetail: (state, action: PayloadAction<ISetCardDetail>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCard: (state, action: PayloadAction<ISetCardRedux>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCurrentPath: (state, action: PayloadAction<string>) => {
      state.current_path = action.payload;
    },
    setChannel: (state, action: PayloadAction<string>) => {
      state.channel = action.payload;
    },
    setCouterService: (state, action: PayloadAction<ICounterService>) => {
      state.counter_service = action.payload;
    },
    resetCardUse: (state) => {
      return {
        ...initialState,
        // current_path: state.current_path,
      };
    },
  },
});

export const selectCardUse = (state: RootState) => state.cardUse;
export const {
  setCardDetail,
  setCard,
  setCurrentPath,
  setChannel,
  setCouterService,
  resetCardUse,
} = cardUse.actions;
export default cardUse.reducer;
