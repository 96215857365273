import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "antd";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  ComponentHistoryBenefit,
  ComponentHistoryCoupon,
  ComponentHistoryFreePoint,
  ComponentHistoryReward,
  IConClose,
} from "@components";
import { historyApi, merchantApi } from "@api";
import { enumTypes, PATH_404, PATH_HOME, PATH_SIGN_IN } from "@configs";
import { IBenefit, ICoupon, IFreePoint, IMerchant, IReward } from "@interfaces";
import { useNotify } from "@utils";

import {
  selectAuth,
  selectMerchant,
  setMainColor,
  setMerchant,
  setMerchantID,
  setPopupHistory,
} from "@redux";

interface IProps {
  merchantId: string | null;
  historyId: string | null;
}

export const PageHistoryPublic = (props: IProps) => {
  const { merchantId, historyId } = props;
  //hooks
  const history = useHistory();
  const { error } = useNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // redux states
  const { name, themeColor } = useSelector(selectMerchant);
  const { auth } = useSelector(selectAuth);
  //states
  const [data, setData] = useState<IBenefit | IReward | ICoupon | IFreePoint>();

  useEffect(() => {
    merchantConfig();
  }, []);

  const merchantConfig = async () => {
    if (merchantId && historyId) {
      try {
        dispatch(setMerchantID(merchantId));
        const merchantConfig = await merchantApi.getMerchantConfig();
        const merchant = merchantConfig.data.merchant as IMerchant;
        dispatch(setMerchant(merchant));
        dispatch(setMainColor(merchant.themeColor));
        await fetchHistory(historyId, merchantId);
      } catch (err: any) {
        if (err.response) {
          const mess = Array.isArray(err.response.data.errors)
            ? err.response.data.errors[0]
            : err.response.data.errors;
          error(mess);
        } else error(t("message.error"));
        dispatch(setPopupHistory(false));
        history.push(PATH_HOME);
      }
    } else {
      history.push(PATH_404);
      dispatch(setPopupHistory(false));
    }
  };

  const fetchHistory = async (id: string, merchantId: string) => {
    try {
      const res = await historyApi.getHistoryPublic(id, {
        merchantId: merchantId,
      });
      setData(res.data);
    } catch (err: any) {
      if (err.response) {
        const mess = Array.isArray(err.response.data.errors)
          ? err.response.data.errors[0]
          : err.response.data.errors;
        error(mess);
      } else error(t("message.error"));
      history.push(PATH_HOME);
      dispatch(setPopupHistory(false));
    }
  };
  const gotoHome = () => {
    dispatch(setPopupHistory(false));
    if (auth?.accessToken) history.push(PATH_HOME);
    else history.push(PATH_SIGN_IN);
  };

  const showHistory = () => {
    switch (data?.type) {
      case enumTypes.BENEFIT:
        return <ComponentHistoryBenefit data={data as IBenefit} />;
      case enumTypes.COUPON:
        return <ComponentHistoryCoupon data={data as ICoupon} />;
      case enumTypes.FREE_POINT:
        return <ComponentHistoryFreePoint data={data as IFreePoint} />;
      case enumTypes.REWARD:
        return <ComponentHistoryReward data={data as IReward} />;
    }
  };

  const loadingComponent = (
    <div>
      <div className="h-p-loading-item">
        <Skeleton height={40} width={"40%"} />
        <Skeleton height={30} />
      </div>
      <div className="h-p-loading-item">
        <Skeleton height={40} width={"40%"} />
        <Skeleton height={30} />
      </div>
      <div className="h-p-loading-item">
        <Skeleton height={40} width={"40%"} />
        <Skeleton height={30} />
      </div>
    </div>
  );

  return (
    <StyledContainer className="h-p-container">
      <div className="h-p-body">
        <div className="header">
          <div className="title">{t("page.confirmation")}</div>
          <div className="close" onClick={gotoHome}>
            <IConClose />
          </div>
        </div>
        <div className="h-p-shop-name">{name}</div>
        {data ? showHistory() : loadingComponent}
        <BackButton
          style={{ background: themeColor }}
          className="h-p-button"
          type="primary"
          onClick={gotoHome}
        >
          {t("page.back")}
        </BackButton>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .header {
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(115, 115, 115, 0.31);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-right: 1px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
`;

const BackButton = styled(Button)`
  height: 48px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
`;
