import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FormikErrors } from "formik";
import "react-intl-tel-input/dist/main.css";

import { SharedButton } from "@components";
import { selectMerchant } from "@redux";
import { IPhone } from "@interfaces";
import { ModulesTelInput } from "@modules";
interface IProps {
    values: IPhone;
    errorPhone: string;
    isSubmitting: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    setFieldTouched: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IPhone>>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IPhone>>;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ComponentSignUpTel = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //page props
    const {
        values,
        isSubmitting,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        errorPhone,
        setError,
    } = props;
    //page variable
    const merchant = useSelector(selectMerchant);

    return (
        <form onSubmit={handleSubmit}>
            <ModulesTelInput
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.phoneNumber || ""}
                errorPhone={errorPhone}
                setError={setError}
            />
            <StyledButton
                size="large"
                bg_color={merchant.themeColor}
                disabled={isSubmitting}
                type="submit"
            >
                {t("page.sign_up")}
            </StyledButton>
        </form>
    );
};

const StyledButton = styled(SharedButton)`
    margin-top: 50px;
`;
