/* eslint-disable no-case-declarations */
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

import { IBenefit, IReward, ICoupon, IFreePoint } from "@interfaces";
import { enumTypes, exchangedType } from "@configs";
import { ComponentHistoryCoupon } from "./Coupon";
import { ComponentHistoryBenefit } from "./Benefit";
import { ComponentHistoryReward } from "./Reward";
import { ComponentHistoryFreePoint, ComponentHistoryMerchantFreePoint } from "./FreePoint";
import { ComponentPopUpDone } from "@components";
import { IconLoadMore } from "../icon";
// import { CouponItem } from "./Item";

interface IProps {
    data: (IBenefit | IReward | ICoupon | IFreePoint)[];
    currenHistory?: IBenefit | IReward | ICoupon | IFreePoint;
    handleClick: (data: IBenefit | IReward | ICoupon | IFreePoint) => void;
    loadingPagination: boolean;
    handleLoadMore: () => void;
    handleCloseDetail: () => void;
}

export const ComponentHistoryList = (props: IProps) => {
    //page props
    const {
        data,
        loadingPagination,
        handleLoadMore,
        currenHistory,
        handleClick,
        handleCloseDetail,
    } = props;

    const render = (dataItem: IBenefit | IReward | ICoupon | IFreePoint, index: number) => {
        switch (dataItem.type) {
            case enumTypes.COUPON:
                return (
                    <ComponentHistoryCoupon
                        onClick={() => {
                            handleClick(dataItem);
                        }}
                        data={dataItem as ICoupon}
                        key={index}
                    />
                );
            case enumTypes.BENEFIT:
                return (
                    <ComponentHistoryBenefit
                        onClick={() => {
                            handleClick(dataItem);
                        }}
                        data={dataItem as IBenefit}
                        key={index}
                    />
                );
            case enumTypes.REWARD:
                const data = dataItem as IReward;
                //@ts-ignore
                const dataFreePoint: IFreePoint = {
                    updatedAtHistory: data.updatedAtHistory,
                    exchangedType: exchangedType.RECEIVED,
                    name: data.name,
                    //@ts-ignore
                    point: data?.point * data?.quantityPoint || 0,
                    type: enumTypes.REWARD,
                };
                return (
                    <>
                        {" "}
                        <ComponentHistoryReward
                            onClick={() => {
                                handleClick(data);
                            }}
                            data={data}
                            key={index}
                        />
                        <ComponentHistoryFreePoint
                            onClick={() => {
                                handleClick(dataFreePoint);
                            }}
                            data={dataFreePoint}
                            key={index}
                        />
                    </>
                );
            case enumTypes.FREE_POINT:
                return (
                    <ComponentHistoryFreePoint
                        onClick={() => {
                            handleClick(dataItem);
                        }}
                        data={dataItem as IFreePoint}
                        key={index}
                    />
                );
            case enumTypes.MERCHANT_UPDATE_POINT:
                return (
                    <ComponentHistoryMerchantFreePoint
                        onClick={() => {
                            handleClick(dataItem);
                        }}
                        data={dataItem as IFreePoint}
                        key={index}
                    />
                );
            default:
                return <>hi</>;
        }
    };

    const handleCalculatePoint = () => {
        return currenHistory?.quantityPoint || currenHistory?.point;
    };

    return (
        <StyledContainerHistoryList>
            <InfiniteScroll
                dataLength={data.length}
                next={handleLoadMore}
                hasMore={true}
                loader={
                    loadingPagination && (
                        <div className="example">
                            <Spin
                                size="large"
                                spinning={loadingPagination}
                                indicator={<IconLoadMore />}
                            />
                        </div>
                    )
                }
            >
                {data.map((item, index) => {
                    return render(item, index);
                })}
            </InfiniteScroll>
            {currenHistory && (
                <ComponentPopUpDone
                    isMerchantFreePoint={
                        currenHistory.type === enumTypes.MERCHANT_UPDATE_POINT ? true : undefined
                    }
                    handleCloseDetail={handleCloseDetail}
                    data={currenHistory}
                    isConfirm={true}
                    isHistory={true}
                    handleCalculatePoint={handleCalculatePoint}
                />
            )}
        </StyledContainerHistoryList>
    );
};

const StyledContainerHistoryList = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
    .example {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
`;
