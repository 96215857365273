import {  IVerify } from "@interfaces";
import axiosClient from "./axiosClient";

export const verifyApi = {
    updateEmail: (values: IVerify) => {
        const url = "/users/update-email";
        return axiosClient.put(url, values);
    },
    verifyEmailCode: (values: IVerify) => {
        const url = "/users/verify-email-code";
        return axiosClient.post(url, values);
    },
    updatePhone: (values: IVerify) => {
        const url = "/users/update-phone";
        return axiosClient.put(url, values);
    },
    verifyPhoneCode: (values: IVerify) => {
        const url = "/users/verify-phone-code";
        return axiosClient.post(url, values);
    },
    resendPhoneCode: (values: IVerify) => {
        const url = "/users/resend-phone-code";
        return axiosClient.post(url, values);
    },
    resendEmailCode: (values: IVerify) => {
        const url = "/users/resend-email-code";
        return axiosClient.post(url, values);
    },
};
