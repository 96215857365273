export const AllStoresRightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45446 19.5455C7.01518 19.1062 7.01518 18.3938 7.45446 17.9545L14.159 11.25L7.45446 4.54552C7.01518 4.10617 7.01518 3.39382 7.45446 2.95447C7.89381 2.5152 8.60616 2.5152 9.04551 2.95447L16.5455 10.4545C16.9848 10.8938 16.9848 11.6062 16.5455 12.0455L9.04551 19.5455C8.60616 19.9848 7.89381 19.9848 7.45446 19.5455Z"
        fill="#9DAAAE"
      />
    </svg>
  );
};
