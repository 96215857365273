
interface IProps {
  color?: string;
}
export const IconFreeaeCard = (props: IProps) => {
  return (
    <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5007 11H18.6118V7.5C18.6118 3.6394 15.4225 0.5 11.5007 0.5C7.57878 0.5 4.38954 3.6394 4.38954 7.5V11H3.50065C2.03103 11 0.833984 12.1771 0.833984 13.625V25.875C0.833984 27.3229 2.03103 28.5 3.50065 28.5H19.5007C20.9703 28.5 22.1673 27.3229 22.1673 25.875V13.625C22.1673 12.1771 20.9703 11 19.5007 11ZM6.75998 7.5C6.75998 4.92627 8.88607 2.8334 11.5007 2.8334C14.1152 2.8334 16.2413 4.92627 16.2413 7.5V11H6.75998V7.5Z" fill="white"/>
    </svg>
  );
};
