import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import querystring from "querystring";

import { authApi, merchantApi, verifyApi } from "@api";
import { ComponentUserSetting, ComponentUserSettingSave } from "@components";
import { selectAuth, selectMerchant, setLoading } from "@redux";
import { RenderMeta, useNotify } from "@utils";
import { IExternalAuthState, IUserSetting, IVerify } from "@interfaces";
import { useYup } from "@validations";
import {
    enumExternalAuthType,
    enumUseSetting,
    LINE_ACCESS_URL,
    LINE_REDIRECT_URI,
    LINE_SCOPE,
    PATH_USER_VERIFY,
    _10MB,
} from "@configs";

export const PageUserSetting = () => {
    //page hooks

    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const { YubSUserSetting } = useYup();
    const { t } = useTranslation();
    const history = useHistory();
    //redux state
    const { userInfo } = useSelector(selectAuth);
    const { lineIntegration } = useSelector(selectMerchant);

    //page state
    const [title, setTitle] = useState<enumUseSetting | "">("");
    const [errorPhone, setError] = useState<string>();

    //page variable
    const state: IExternalAuthState = {
        type: enumExternalAuthType.LINK_LINE,
    };
    const contact = userInfo?.contact;
    const initialValue: IUserSetting = {
        avatar: userInfo?.avatar || "",
        fullName: contact?.fullName || "",
        dateOfBirth: contact?.dateOfBirth || new Date(Date.now()),
        phoneNumber: contact?.phoneNumber || "",
        phone: contact?.phoneNumber || "",
        email: contact?.email || "",
    };
    const registerSchema = Yup.object().shape(YubSUserSetting);

    const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        dispatch(setLoading(true));
        //@ts-ignore
        const values = (e?.target.files[0] as File) || "";
        if (values.size >= _10MB) {
            error(t("validation.image.max_size"));
            dispatch(setLoading(false));
            return;
        }
        const formData = new FormData();
        formData.append("file", values);
        try {
            const res = await merchantApi.uploadImage(formData);
            await authApi.updateProfile({
                avatar: res.data.publicUrl,
            });
            setFieldValue("avatar", res.data.publicUrl);
        } catch (err: any) {
            const data = err.response.data;
            const mess = Array.isArray(data.errors) ? data.errors[0] : data.message;
            error(mess);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleVerilyEmail = async (values: IVerify) => {
        if (userInfo?.emailVerified && (values.email || "").trim() === contact?.email) {
            setTitle("");
            return;
        }
        try {
            dispatch(setLoading(true));
            const res = await verifyApi.updateEmail(values);
            if (res) {
                window.sessionStorage.setItem("email", values.email || "");
                history.push(PATH_USER_VERIFY + "/" + enumUseSetting.EMAIL);
                success(t("message.email_send"));
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            if (message) {
                error(message);
            } else {
                error(t("message.email_correct"));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleVerilyPhone = async (values: IVerify) => {
        if (userInfo?.phoneVerified && (values.phoneNumber || "").trim() === contact?.phoneNumber) {
            setTitle("");
            return;
        }
        try {
            dispatch(setLoading(true));
            const res = await verifyApi.updatePhone(values);
            if (res) {
                window.sessionStorage.setItem("phoneNumber", values.phoneNumber || "");
                history.push(PATH_USER_VERIFY + "/" + enumUseSetting.PHONE_NUMBER);
                success(t("message.phone_send"));
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            if (message) {
                error(message);
            } else {
                error(t("message.phone_correct"));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSaveSetting = async (
        values: IUserSetting,
        { setSubmitting }: FormikHelpers<IUserSetting>
    ) => {
        if (errorPhone) return;
        if (!values.phoneNumber) {
            setError(t("validation.isNumber"));
            return;
        }
        if (title === enumUseSetting.EMAIL) {
            handleVerilyEmail({
                email: values.email || "",
            });
            return;
        }
        if (title === enumUseSetting.PHONE_NUMBER) {
            handleVerilyPhone({
                phoneNumber: values.phone,
            });
            return;
        }
        if (!values.dateOfBirth) return;

        try {
            setSubmitting(true);
            dispatch(setLoading(true));
            const res = await authApi.updateProfile({
                ...values,
                phoneNumber: values.phone,
                avatar: values.avatar || undefined,
            });
            if (res.data.message) {
                success(res.data.message);
            } else {
                success(t("message.update.success"));
            }
            setFieldValue("phoneNumber", values.phone || "");
        } catch (errors: any) {
            const errorsResponse = errors.response.data.errors;
            if (Array.isArray(errorsResponse) && errorsResponse.length) {
                error(errorsResponse[0]);
            } else {
                error(t("message.update.fail"));
            }
        } finally {
            setTitle("");
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const handleChangeTitle = (title: enumUseSetting) => {
        setTitle(title);
    };

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: initialValue,
        validationSchema: registerSchema,
        onSubmit: handleSaveSetting,
        enableReinitialize: true,
    });

    const handleChangeDate = (date: moment.Moment | null, dateString: string) => {
        if (dateString) {
            setFieldValue("dateOfBirth", dateString);
        } else {
            setFieldValue("dateOfBirth", new Date(Date.now()));
        }
    };

    const handleLinkLine = () => {
        //
        const query = querystring.stringify({
            response_type: "code",
            client_id: lineIntegration?.lineLoginApi?.channelID || "",
            state: JSON.stringify(state),
            redirect_uri: LINE_REDIRECT_URI,
        });
        // Build the Line authorize URL.
        const lineUrl = `${LINE_ACCESS_URL}/authorize?scope=${LINE_SCOPE}&${query}`;
        window.location.href = lineUrl;
    };

    return (
        <>
            <RenderMeta
                title={t("meta.user_setting", { name: values.fullName || t("meta.user") })}
            />
            {title ? (
                <ComponentUserSettingSave
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    errors={errors}
                    touched={touched}
                    title={title}
                    setFieldTouched={setFieldTouched}
                    errorPhone={errorPhone || ""}
                    handleChangeDate={handleChangeDate}
                    setFieldValue={setFieldValue}
                    setError={setError}
                />
            ) : (
                <ComponentUserSetting
                    handleUploadImage={handleUploadImage}
                    values={values}
                    handleChangeTitle={handleChangeTitle}
                    handleLinkLine={handleLinkLine}
                />
            )}
        </>
    );
};
