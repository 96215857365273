import { IReceive } from "@interfaces";
import axiosClient from "./axiosClient";

export const rewardApi = {
    // getListReward: () => {
    //     const url = "/histories?type=REWARD";
    //     return axiosClient.get(url);
    // },
    getListReward: () => {
        const url = "/rewards/client";
        return axiosClient.get(url);
    },
    getDetailById: (id: string) => {
        const url = `/histories/${id}?type=REWARD`;
        return axiosClient.get(url);
    },
    //     redeemed:(values:IReceive)=> {
    //         const url = "/histories/redeemed";
    //        return axiosClient.post(url, values);

    //    },
    redeemed: (values: IReceive) => {
        const url = "/rewards/redeemed";
        return axiosClient.post(url, values);
    },
};
