import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectAuth, selectMerchant } from "@redux";
import { IConBecomeBenefit, IConBecomeCoupon, IConBecomeStar, StyledSquare } from "@components";
import { ModuleMemberShipTier } from "@modules";
import { RenderMeta } from "@utils";

export const ComponentUserDetail = () => {
    //hooks
    const { t } = useTranslation();
    //redux page
    const { name, themeColor, welcomeImageUrls } = useSelector(selectMerchant);
    const { userInfo } = useSelector(selectAuth);
    //page variable
    const memberTier = userInfo?.memberTier;

    return (
        <>
            <RenderMeta title={t("meta.membership_detail")} />
            <StyledUserDetail>
                <StyledCart>
                    <div className="become">{t("page.become_member")}</div>
                    <div className="desc">
                        {t("page.fill_in_your_information")}
                        {name} {t("page._and_receive")}
                    </div>
                    <div className="group">
                        <div className="item">
                            <div className="svg">
                                <IConBecomeStar color={themeColor} />
                            </div>
                            <span>{t("page.star_desc")}</span>
                        </div>
                        <div className="item">
                            <div className="svg">
                                <IConBecomeCoupon color={themeColor} />
                            </div>
                            <span>{t("page.coupon_desc")}</span>
                        </div>
                        <div className="item">
                            <div className="svg">
                                <IConBecomeBenefit color={themeColor} />
                            </div>
                            <span>{t("page.benefit_desc")}</span>
                        </div>
                        <div className="item border-none">
                            <div className="svg">
                                <IConBecomeStar color={themeColor} />
                            </div>
                            <span>{t("page.draws_desc")}</span>
                        </div>
                    </div>
                </StyledCart>
                <StyledCart>
                    <div className="text-content">
                        <div className="title_rule">{t("page.rules_of_points")}</div>
                        <div className="points">
                            <div className="left">
                                <StyledButtonPoint themeColor={themeColor}>
                                    {memberTier?.bahtSpent || 0}
                                </StyledButtonPoint>
                            </div>
                            <div className="equal">=</div>
                            <div className="right">
                                <StyledButtonPoint themeColor={themeColor}>
                                    {memberTier?.points || 0}
                                </StyledButtonPoint>
                            </div>
                        </div>
                        <div className="points">
                            <div className="baht_spent">{t("page._baht_spent")}</div>
                            <div className="baht_spent">{t("page._points")}!</div>
                        </div>
                    </div>
                </StyledCart>
                <ModuleMemberShipTier />
                <div className="group_image">
                    {welcomeImageUrls?.map((welcomeImageUrl) => {
                        return (
                            <StyledSquare>
                                <div className="content">
                                    <img className="image" src={welcomeImageUrl} />
                                </div>
                            </StyledSquare>
                        );
                    })}
                </div>
            </StyledUserDetail>
        </>
    );
};

const StyledCart = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto;
    padding: 0 24px 20px 24px;
    background-color: #fff;
    box-shadow: rgb(215 215 215) 0px 0px 4px 0px;
    border-radius: 12px;
    margin-bottom: 20px;
    .become {
        text-align: center;
        padding-top: 30px;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        margin-bottom: 24px;
    }
    .desc {
        text-align: center;
        font-size: 16px;
        color: #7e7e7e;
        line-height: 1.5rem;

        font-style: normal;
        font-weight: normal;
        margin-bottom: 6px;
    }
    .group {
        .item {
            padding: 14px 0;
            border-bottom: 1px solid rgba(115, 115, 115, 0.31);
            display: flex;
            align-items: end;
            .svg {
                text-align: center;
                width: 28px;
            }
            span {
                display: inline-block;
                margin-left: 20px;

                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #000000;
                font-weight: 400;
            }
        }
    }
    .border-none {
        border-bottom: initial !important;
    }
    .title_rule {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        margin-bottom: 32px;
    }
    .text-content {
        text-align: center;
        padding: 44px 0;
        .points {
            width: calc(80%);
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .left,
            .right {
                flex: 1;
                text-align: center;
                margin-bottom: 6px;
            }

            .baht_spent {
                font-size: 16px;
                color: #000000;
                font-weight: 400;
                text-align: center;
                display: inline-block;
                position: relative;
                left: -5px;
                color: black;
            }
        }
    }
`;

const StyledButtonPoint = styled.div<{ themeColor: string }>`
    width: fit-content;
    padding: 6px 24px;
    background: #f5f5f5;
    color: ${(p) => p.themeColor};
    border-radius: 4px;
    margin-bottom: 6px;
    font-weight: 700;
    margin: 0 auto;
`;

const StyledUserDetail = styled.div`
    background: transparent;
    padding-bottom: 50px;
    .group_image {
        text-align: center;
    }
`;
