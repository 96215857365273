import styled from "styled-components";

export const StyledHome = styled.div<{ mainColor: string }>`
  width: 100%;
  /* position: relative;
    top: -65px; */
  /* top: -85px;
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
        top: -50px;
    } */

  position: absolute;
  top: 120px;
  .background {
    width: 100%;
    height: 143px;
    position: absolute;
    top: 88px;
    background: #fff;
  }
  .preview {
    width: calc(100% - 24px);
    position: relative;
    background-color: #fff;
    /* background-image: url("/images/logoAuth.png"); */
    background-repeat: no-repeat;
    background-position: 12px 12px;
    margin: 0 auto;
    /* box-shadow: rgb(231 230 220) 1px 5px 14px 3px; */
    box-shadow: rgb(196 196 196 / 25%) 0px 0px 11px 2px;
    padding: 16px 22px;
    border-radius: 8px;
    .ant-avatar {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .circle-avatar-user {
      img {
        border-radius: 50%;
      }
    }
    .profile-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 10px;
      .avatar_name {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
        color: black;
        .name {
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        .hello {
          font-weight: normal;
        }
      }
      .user-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 8px;
        .star {
          cursor: pointer;
          margin-right: 10px;
        }
        .rank-layout {
        }
      }
    }
    .good {
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 8px;
      line-height: 11px;
      padding: 4px 8px;
      background: #816b00;
      display: inline-block;
      border-radius: 12px;
      position: relative;
      margin-top: 12px;
      top: -8px;
      span {
        display: inline-block;
        margin: 0 4px;
        font-size: 11px;
      }
    }
    .user-progress {
      .progress-info {
        display: flex;
        justify-content: space-between;
        &:first-child {
          color: #919191;
        }
        p {
          // margin-top: 0.5em;
          color: #a5a5a5;
          font-size: 11px;
        }
      }
      .progress-spend {
        color: #000 !important;
      }
      .progress-spend span {
        color: ${(color) => color.mainColor};
      }
    }
    .ant-progress-bg {
      background: ${(color) => color.mainColor} !important;
    }
    .star_coupans {
      display: flex;
      align-items: center;
      flex-flow: row;
      place-content: center;
      margin-top: 15px;
      margin-bottom: 12px;
      .point {
        color: #000;
        display: initial !important;
        font-weight: 700;
      }
      .coupon {
        font-size: 16px;
        color: black;
        display: initial !important;
      }
      .star_point {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 27%;
        margin-right: 10px;
        // box-shadow: rgb(196 196 197 / 14%) 0px 0px 4px 3px;
        padding: 3%;
        span {
          font-weight: 400;
          display: block;
          cursor: pointer;
          margin-top: 10px;
        }
        .point {
          font-size: 16px;
        }
        .coupon {
          font-weight: 400;
        }
      }
      .line {
        height: 40px;
        width: 1px;
        background-color: #707070;
        opacity: 0.5;
      }
      .desc {
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        color: #989898;
        position: relative;
        left: 2px;
        font-weight: 100 !important;
        cursor: pointer;
      }
    }
    .desc_end {
      font-weight: normal;
      font-size: 8px;
      line-height: 11px;

      width: 50%;
      text-align: center;
      color: #989898;
    }
  }

  .imageBannerSection {
    margin: 20px 0;
    text-align: center;
  }
  .imageBannerSection img {
    width: calc(100% - 24px);
    // height: 150px;
  }
  .view-booking {
    display: flex;
    place-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .viewBookingtext {
    font-size: 10pt;
    font-weight: normal;
    color: #000;
  }
  .previewPoints {
    display: flex;
    place-content: space-between;
    flex-direction: column;
    height: 140px;
    margin-top: 20px;
  }
  .botPoints {
    display: flex;
    place-content: space-between;
    align-items: center;
  }
  .botPoints p {
    margin-left: 20px;
    margin: 0;
    padding: 0;
  }
  .botPoints p span {
    margin-right: 10px;
  }
  .topPoints {
    display: flex;
    align-items: center;
  }
  .topPoints p {
    margin-left: 10px !important;
    margin: 0;
    padding: 0;
  }
  .ant-btn-danger {
    width: 110px;
    height: 40px;
    border-radius: 20px;
    background: ${(color) => color.mainColor};
  }
  .couponText {
    margin-top: 20px;
  }
  .couponText svg {
    margin-right: 10px;
  }
  .couponText p {
    margin-bottom: 0;
  }
  .couponText p span {
    position: relative;
    top: 1.5px;
  }

  .nav-bar {
    padding: 3px 16px;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      /* width: 25%; */
      text-align: center;
      padding: 15px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #989898;
      cursor: pointer;
      position: relative;

      font-style: normal;
    }
    .under {
      color: #000000;
      font-weight: 400;
      &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 45%;
        width: 56%;
        height: 2px;
        background-color: ${(p) => p.mainColor};
        transform: translateX(-50%);
      }
    }
  }
`;

export const StyledContainerHome = styled.div`
  /* min-height: 80vh; */
  background-color: transparent;
  /* display: grid; */
  .ant-empty {
    margin-top: 25px;
  }
`;
