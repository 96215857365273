import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BLANK_IMAGE_URL, PATH_ORDER_HISTORY } from "@configs";
import { DiscountIcon, InformationIcon, PinDetailsIcon } from "../icon";
import { posAPI, productAPI } from "@api";
import { selectMerchant, store } from "@redux";
import { useEffect, useState } from "react";
import {
  IBasketItems,
  IGetQueueOfMyProductOrderInStoreIdExample,
  IMerchant,
  IProductOrderClientExample,
  IProductOrderExample,
} from "@interfaces";
import { Button, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { OrderHistoryQueueIcon } from "../icon/orderHistory/OrderHistoryQueueIcon";

export const ComponentSingleOrderHistory = () => {
  const { product_order_id } = useParams<{ product_order_id: string }>();
  const history = useHistory();
  const orgInfo = useSelector((state: any) => state.merchant);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [order, setOrder] = useState<IProductOrderExample>();
  const [orderClient, setOrderClient] = useState<IProductOrderClientExample>();
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? order?.basket_items.length : 2;
  const [queueOfMyProductOrder, setQueueOfMyProductOrder] =
    useState<IGetQueueOfMyProductOrderInStoreIdExample>();
  const { themeColor } = useSelector(selectMerchant);

  const getOrder = async () => {
    try {
      const get = await productAPI.getProductOrderById({
        organization_id: orgInfo._id,
        product_order_id: product_order_id,
      });
      if (get.data.error === "1") {
        history.push(PATH_ORDER_HISTORY);
      } else {
        setOrder(get.data.result.product_order);
        console.log(get.data.result);
        setOrderClient(get.data.result.client);
        const getQueue = await productAPI.getQueueOfMyProductOrderInStoreId({
          organization_id: orgInfo._id,
          store_id: orgInfo.store_id,
          product_order_id: get.data.result.product_order._id,
        });
        if (getQueue.data.error === "0") {
          setQueueOfMyProductOrder({
            my_queue: getQueue.data.result.my_queue,
            total_queue: getQueue.data.result.total_queue,
            waiting_queue: getQueue.data.result.waiting_queue,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <div className="container">
      <div className="basketOrderSummary">
        <div className="basketHeader">
          <div className="basketOrderSummaryTitleRow">
            <h1>{t("page.basket_order_summary")}</h1>
            <span>
              {t("page.order_id")}: {order?.order_code}
            </span>
          </div>
          <div className="queueStatusBadge">
            <div className="leftSide">
              <p className="body3">{t("page.your_queue_number")}</p>
              <p className="h1">{queueOfMyProductOrder?.my_queue}</p>
            </div>
            <div className="rightSide">
              <div className="iconRow">
                <OrderHistoryQueueIcon color={themeColor} />{" "}
                <p className="body3">{t("page.wait_queue")}</p>
              </div>
              <p className="h1">{queueOfMyProductOrder?.waiting_queue}</p>
              <p className="body5">
                {t("page.total_queue")} : {queueOfMyProductOrder?.total_queue}
              </p>
            </div>
          </div>
        </div>
        {order?.pos_promotion_match_list &&
          order?.pos_promotion_match_list.length !== 0 &&
          order?.pos_promotion_match_list
            .sort((a: any, b: any) => {
              if (a.promotion_type === "freebie" && b.promotion_type !== "freebie") {
                return -1;
              } else {
                return 1;
              }
            })
            .map((promotion: any, i: number) => (
              <div key={i}>
                <div className="promotionRow" key={i}>
                  <div className="leftProSide">
                    <DiscountIcon color={themeColor} />
                    <p>{promotion.pos_promotion_name}</p>
                  </div>
                  {promotion.promotion_type !== "freebie" && (
                    <div className="rightProSide">
                      <span>฿{promotion.result_discount}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        {order?.basket_items.some((x) => x.is_freebie) && (
          <div className="promotionRow">
            <div className="leftProSide">
              <DiscountIcon color={themeColor} />
              <p>{t("page.freebie")}</p>
            </div>
          </div>
        )}
        {order?.basket_items
          .filter((x) => x.is_freebie)
          .map(
            (
              order,
              i //
            ) => (
              <div
                className="eachItemsRow"
                style={{ marginBottom: order.is_freebie ? 5 : 0 }}
                key={i}
              >
                <div className="leftSide">
                  <img src={order.product_image_url || BLANK_IMAGE_URL} />
                  <div className="itemTitleDesc">
                    <h2>
                      {order.product_name}
                      {order.product_name !== order.variant_name && `(${order.variant_name})`}
                    </h2>
                    {order.addon_list?.length !== 0 &&
                      order.addon_list?.map((addon: any, i) => (
                        <p key={i}>{addon.option_selected[0].option_name}</p>
                      ))}
                  </div>
                </div>
                <div className="rightSide">
                  <div className="itemTitleDesc">
                    {!order.is_freebie && (
                      <p>
                        {/* <span>{parseInt(order.price_all + order.discount).toFixed(2)}</span> */}
                        {order.price_all}฿
                      </p>
                    )}
                    <small>x{order.qty}</small>
                  </div>
                </div>
              </div>
            )
          )}
        <div className="promotionRow">
          <div className="leftProSide">
            <DiscountIcon color={themeColor} />
            <p>{t("page.basket")}</p>
          </div>
        </div>
        {order?.basket_items
          .filter((x) => !x.is_freebie)
          .slice(0, itemsToShow)
          .map((order: IBasketItems, i) => (
            <div
              className="eachItemsRow"
              style={{ marginBottom: order.is_freebie ? 5 : 0 }}
              key={i}
            >
              <div className="leftSide">
                <img src={order.product_image_url || BLANK_IMAGE_URL} />
                <div className="itemTitleDesc">
                  <h2>
                    {order.product_name}
                    {order.product_name !== order.variant_name && `(${order.variant_name})`}
                  </h2>
                  {order.addon_list?.length !== 0 &&
                    order.addon_list?.map((addon: any, i) => (
                      <p key={i}>{addon.option_selected[0].option_name}</p>
                    ))}
                </div>
              </div>
              <div className="rightSide">
                <div className="itemTitleDesc">
                  {!order.is_freebie && (
                    <p>
                      {/* <span>{parseInt(order.price_all + order.discount).toFixed(2)}</span> */}
                      {order.price_all}฿
                    </p>
                  )}
                  <small>x{order.qty}</small>
                </div>
              </div>
            </div>
          ))}
        {order && order.basket_items.filter((x) => !x.is_freebie).length > 2 && !showAll && (
          <Button className="showMoreBtn" onClick={toggleShowMore}>
            {t("page.show_more")} <DownOutlined />
          </Button>
        )}
        <hr />
        <div className="orderDetailsRow">
          <p>
            {t("page.subtotal")} ({order?.basket_items.length} {t("page.items")})
          </p>
          <p>{order?.total_before_discount.toFixed(2)}฿</p>
        </div>
        <div className="orderDetailsRow">
          <p>
            {t("page.points_discount")} <InformationIcon />
          </p>
          <p style={{color: 'red'}}>-{order?.discount_in_baht_by_point.toFixed(2)}฿</p>
        </div>
        <div className="orderDetailsRow">
          <p>{t("page.promotion_discount")}</p>
          <p style={{color: 'red'}}>-{order?.discount_in_baht_by_promotion.toFixed(2)}฿</p>
        </div>
        <div className="orderDetailsRow">
          <p>{t("page.gift_card_usage")}</p>
          <p style={{color: 'red'}}>-{order?.discount_in_baht_by_loyalty_card.toFixed(2)}฿</p>
        </div>
        {order?.service_charge !== 0 && (
          <div className="orderDetailsRow">
            <p>{t("page.service_charge")}</p>
            <p>{order?.service_charge.toFixed(2)}฿</p>
          </div>
        )}
        {order?.discount_in_baht_promo_by_loyalty_card !== 0 && (
          <div className="orderDetailsRow">
            <p>{t("page.member_card_usage_promotion")}</p>
            <p style={{color: 'red'}}>-{order?.discount_in_baht_promo_by_loyalty_card.toFixed(2)}฿</p>
          </div>
        )}
        {order?.discount_in_baht_promo_by_loyalty_member_card !== 0 && (
          <div className="orderDetailsRow">
            <p>{t("page.gift_card_usage_promotion")}</p>
            <p style={{color: 'red'}}>-{order?.discount_in_baht_promo_by_loyalty_member_card.toFixed(2)}฿</p>
          </div>
        )}
        <div className="orderDetailsRow">
          <p>{t("page.vat")}</p>
          <p>{order?.vat_total.toFixed(2)}฿</p>
        </div>
        <div className="orderDetailsRow">
          <p>
            <b>Total</b>
          </p>
          <p>
            <b>{order?.grand_total.toFixed(2)}฿</b>
          </p>
        </div>
      </div>
      <div
        className="branchDetails"
        onClick={() => {
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${orgInfo.location.latlon[1]},${orgInfo.location.latlon[0]}`
          );
        }}
      >
        <PinDetailsIcon />
        <div className="branchNameDesc">
          <p>
            {orgInfo.name} ({orgInfo.store_name})
          </p>
          <p className="desc">{orgInfo.address}</p>
        </div>
      </div>
      <div className="customerDetails">
        <h1>{t("page.details_customer")}</h1>
        <hr />
        <div className="customerNameRow">
          <p>{t("page.customer_name")}</p>
          <p>{orderClient?.name}</p>
        </div>
        <div className="customerNameRow">
          <p>{t("page.payment_method")}</p>
          <p>{'Online Payment'}</p>
          {/* <p>{order?.payment_detail.payment_type.toUpperCase()}</p> */}
        </div>
      </div>
    </div>
  );
};
