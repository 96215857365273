import { IGetCountrySetting } from "@interfaces";
import axiosClient from "./axiosClient";
import axiosClientStore from "./axiosClientStore";

export const merchantApi = {
  getMerchant: (id: string) => {
    const url = `/merchants/${id}`;
    return axiosClient.get(url);
  },
  getTrafficSourceId: (id: string) => {
    const url = `/traffic-source/${id}/qrcode`;
    return axiosClient.get(url);
  },
  getCouponId: (id: string) => {
    const url = `/coupons/${id}/qrcode`;
    return axiosClient.get(url);
  },
  getFreePointId: (id: string) => {
    const url = `/free-points/${id}/qrcode`;
    return axiosClient.get(url);
  },
  getMerchantConfig: () => {
    const url = "/auth/merchant-config";
    return axiosClient.get(url);
  },
  getCountrySetting: (params: IGetCountrySetting) => {
    const url = "/merchants/getCountrySetting";
    return axiosClient.post(url, params);
  },
  getMerchantConfigPrefix: () => {
    const url = "/auth/getMerchantConfigPrefix";
    return axiosClient.get(url);
  },
  getInitMerchant: (merchantId: string) => {
    const url = "/auth/merchant-config";
    const config = {
      headers: {
        "merchant-id": merchantId,
      },
    };
    return axiosClient.get(url, config);
  },
  uploadImage: (params: FormData) => {
    const url = "/upload";
    return axiosClient.post(url, params);
  },
};
