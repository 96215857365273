import { ComponentHeaderAuth } from "@components";
import { ModuleEmailResetPassword } from "@modules";

export const PageResetPasswordEmail = () => {
    return (
        <ComponentHeaderAuth>
            <ModuleEmailResetPassword />
        </ComponentHeaderAuth>
    );
};
