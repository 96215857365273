interface IProps {
  color?: string;
}
export const IconPromotion = (props: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M8.46651 24.48L4.06001 12.8925C3.97605 12.6716 3.95391 12.4319 3.99598 12.1993C4.03804 11.9667 4.14272 11.75 4.29876 11.5725L7.59351 7.82675C7.73324 7.66796 7.9096 7.54566 8.1073 7.47046C8.305 7.39527 8.51807 7.36945 8.72801 7.39525L13.6793 8.0055C13.9138 8.03449 14.136 8.12688 14.322 8.27273C14.508 8.41857 14.6507 8.61236 14.7348 8.83325L19.1413 20.4205C19.2636 20.7426 19.253 21.1002 19.1119 21.4146C18.9707 21.7289 18.7106 21.9744 18.3885 22.097L10.143 25.2327C9.82086 25.3551 9.46331 25.3445 9.14894 25.2034C8.83458 25.0622 8.58912 24.802 8.46651 24.48Z"
        fill="#303437"
      />
      <path
        d="M17.1267 27.569L5.80647 16.25C5.59056 16.0342 5.43763 15.7635 5.36415 15.4672C5.29067 15.1709 5.29942 14.8602 5.38947 14.5685L7.28947 8.413C7.3701 8.15198 7.51321 7.91459 7.70638 7.72141C7.89956 7.52824 8.13695 7.38513 8.39797 7.3045L14.5535 5.4045C14.8452 5.31445 15.1559 5.3057 15.4522 5.37918C15.7485 5.45266 16.0192 5.60559 16.235 5.8215L27.5552 17.1415C27.7111 17.2973 27.8347 17.4823 27.919 17.6859C28.0034 17.8895 28.0468 18.1077 28.0468 18.3281C28.0468 18.5485 28.0034 18.7667 27.919 18.9703C27.8347 19.1739 27.7111 19.3589 27.5552 19.5148L19.5 27.569C19.3442 27.7248 19.1592 27.8485 18.9556 27.9328C18.752 28.0172 18.5337 28.0606 18.3133 28.0606C18.093 28.0606 17.8747 28.0172 17.6711 27.9328C17.4675 27.8485 17.2825 27.7248 17.1267 27.569Z"
        fill={props.color || "#F22F46"}
      />
      <path
        d="M10.3722 11.5762C11.0507 11.5762 11.6007 11.0262 11.6007 10.3477C11.6007 9.66927 11.0507 9.11925 10.3722 9.11925C9.69376 9.11925 9.14374 9.66927 9.14374 10.3477C9.14374 11.0262 9.69376 11.5762 10.3722 11.5762Z"
        fill="white"
      />
      <path
        d="M10.373 10.9412C10.3617 10.9412 10.3507 10.9412 10.3395 10.9412C7.57898 10.7875 7.35973 9.15 7.19998 7.95525C7.04548 6.802 6.93398 5.9685 5.08173 5.72725C4.92561 5.70693 4.78397 5.62542 4.68795 5.50066C4.59193 5.3759 4.5494 5.21811 4.56973 5.062C4.59005 4.90588 4.67155 4.76424 4.79631 4.66822C4.92107 4.5722 5.07886 4.52968 5.23498 4.55C7.98973 4.909 8.21323 6.5785 8.37648 7.79775C8.53173 8.957 8.62648 9.6565 10.4055 9.755C10.5603 9.76276 10.706 9.83082 10.8113 9.94461C10.9166 10.0584 10.9733 10.2089 10.969 10.3639C10.9648 10.5189 10.9001 10.6661 10.7888 10.774C10.6774 10.8819 10.528 10.9419 10.373 10.9412Z"
        fill="#303437"
      />
      <path
        d="M19.8973 5.0575L19.5703 4.8785L19.3913 4.551C19.3646 4.50241 19.3254 4.46187 19.2777 4.43364C19.23 4.4054 19.1756 4.3905 19.1201 4.3905C19.0647 4.3905 19.0103 4.4054 18.9626 4.43364C18.9149 4.46187 18.8757 4.50241 18.849 4.551L18.67 4.8785L18.343 5.05725C18.2943 5.08387 18.2537 5.12312 18.2253 5.17087C18.197 5.21862 18.1821 5.27311 18.1821 5.32863C18.1821 5.38414 18.197 5.43864 18.2253 5.48639C18.2537 5.53413 18.2943 5.57338 18.343 5.6L18.6703 5.77875L18.8493 6.106C18.8759 6.15466 18.9151 6.19527 18.9628 6.22356C19.0105 6.25184 19.0649 6.26677 19.1204 6.26677C19.1759 6.26677 19.2303 6.25184 19.278 6.22356C19.3257 6.19527 19.3649 6.15466 19.3915 6.106L19.5705 5.77875L19.8975 5.6C19.9462 5.5734 19.9868 5.53419 20.0151 5.48648C20.0434 5.43878 20.0583 5.38434 20.0583 5.32888C20.0583 5.27342 20.0434 5.21898 20.0151 5.17127C19.9868 5.12357 19.9462 5.08436 19.8975 5.05775L19.8973 5.0575Z"
        fill="#F22F46"
        fillOpacity="0.32"
      />
      <path
        d="M23.3942 7.1295L22.7797 6.7935L22.4432 6.179C22.4076 6.11375 22.355 6.0593 22.291 6.02137C22.2271 5.98344 22.1541 5.96342 22.0797 5.96342C22.0054 5.96342 21.9324 5.98344 21.8684 6.02137C21.8045 6.0593 21.7519 6.11375 21.7162 6.179L21.38 6.7935L20.7652 7.12975C20.7 7.16547 20.6457 7.21806 20.6078 7.28201C20.5699 7.34596 20.5499 7.41892 20.5499 7.49325C20.5499 7.56758 20.5699 7.64054 20.6078 7.70449C20.6457 7.76844 20.7 7.82103 20.7652 7.85675L21.3797 8.19275L21.716 8.80725C21.7516 8.8725 21.8042 8.92695 21.8682 8.96488C21.9321 9.00281 22.0051 9.02283 22.0795 9.02283C22.1538 9.02283 22.2268 9.00281 22.2908 8.96488C22.3548 8.92695 22.4073 8.8725 22.443 8.80725L22.7795 8.1925L23.394 7.8565C23.4592 7.8208 23.5137 7.76821 23.5516 7.70423C23.5895 7.64025 23.6095 7.56725 23.6095 7.49288C23.6095 7.4185 23.5895 7.3455 23.5516 7.28153C23.5137 7.21755 23.4592 7.16495 23.394 7.12925L23.3942 7.1295Z"
        fill="#F22F46"
        fillOpacity="0.32"
      />
      <path
        d="M14.8622 21.5222L17.5335 12.2085C17.5769 12.0571 17.6786 11.9292 17.8164 11.8528C17.9541 11.7765 18.1165 11.758 18.2679 11.8014C18.4192 11.8448 18.5472 11.9465 18.6235 12.0842C18.6999 12.222 18.7184 12.3844 18.675 12.5357L16.0032 21.85C15.9598 22.0013 15.8581 22.1292 15.7204 22.2055C15.5828 22.2818 15.4204 22.3003 15.2691 22.2569C15.1178 22.2135 14.9899 22.1118 14.9136 21.9741C14.8373 21.8364 14.8188 21.6741 14.8622 21.5227V21.5222Z"
        fill="white"
      />
      <path
        d="M14.6695 17.0913C14.2627 17.2048 13.8291 17.1738 13.4426 17.0034C13.0562 16.8331 12.7407 16.5339 12.5502 16.157C12.3596 15.7801 12.3056 15.3488 12.3974 14.9365C12.4893 14.5243 12.7213 14.1567 13.0539 13.8964C13.3865 13.636 13.799 13.4991 14.2213 13.509C14.6435 13.5188 15.0493 13.6748 15.3694 13.9503C15.6895 14.2259 15.9041 14.6039 15.9766 15.02C16.0492 15.436 15.9752 15.8644 15.7672 16.232C15.6501 16.4407 15.4928 16.6241 15.3044 16.7715C15.116 16.919 14.9002 17.0277 14.6695 17.0913ZM13.5695 15.5055C13.5917 15.5859 13.6296 15.6611 13.6809 15.7268C13.7323 15.7925 13.796 15.8474 13.8686 15.8885C13.9412 15.9295 14.0212 15.9559 14.1039 15.966C14.1867 15.9761 14.2707 15.9698 14.351 15.9475C14.511 15.899 14.6462 15.7908 14.7284 15.6453C14.8107 15.4997 14.8337 15.3281 14.7927 15.166C14.7477 15.0038 14.6401 14.866 14.4935 14.7832C14.347 14.7003 14.1735 14.679 14.0112 14.724C13.849 14.769 13.7113 14.8767 13.6284 15.0232C13.5455 15.1698 13.5245 15.3433 13.5695 15.5055Z"
        fill="white"
      />
      <path
        d="M19.8077 20.5657C19.4009 20.6792 18.9674 20.6481 18.5809 20.4777C18.1945 20.3073 17.8791 20.0081 17.6886 19.6312C17.4981 19.2543 17.4441 18.823 17.536 18.4107C17.628 17.9985 17.86 17.6309 18.1926 17.3706C18.5252 17.1104 18.9378 16.9735 19.36 16.9834C19.7822 16.9933 20.188 17.1493 20.508 17.4248C20.8281 17.7004 21.0427 18.0784 21.1152 18.4945C21.1877 18.9106 21.1137 19.3389 20.9057 19.7065C20.7886 19.9152 20.6312 20.0987 20.4427 20.2462C20.2542 20.3937 20.0385 20.5023 19.8077 20.5657ZM18.7077 18.98C18.7529 19.1422 18.8605 19.2798 19.007 19.3627C19.1536 19.4456 19.327 19.4669 19.4892 19.422C19.6493 19.3736 19.7845 19.2654 19.8669 19.1198C19.9492 18.9743 19.9722 18.8026 19.9312 18.6405C19.8862 18.4783 19.7786 18.3405 19.632 18.2576C19.4855 18.1748 19.312 18.1535 19.1497 18.1985C18.9875 18.2435 18.8498 18.3511 18.7669 18.4977C18.684 18.6443 18.6627 18.8178 18.7077 18.98Z"
        fill="white"
      />
    </svg>
  );
};
