import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper";

import { couponApi, merchantApi } from "@api";
import { ComponentCouponDetail, ComponentPopUpConfirm, ComponentPopUpDone } from "@components";
import {
    PATH_404,
    enumStatus,
    enumTypes,
    PATH_HOME,
    enumValidation,
    PATH_SIGN_UP_FORM,
    PATH_SIGN_UP,
} from "@configs";
import { ICoupon } from "@interfaces";
import { selectAuth, selectConfigClient, setExternalTypes, setLoading } from "@redux";
import { useNotify } from "@utils";

export const PageCoupon = () => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id, idParent } = useParams<{ id: string; idParent: string }>();
    const history = useHistory();
    const { error, success, warning } = useNotify();
    //redux state
    // const { showConfirm } = useSelector(selectAuth);

    const { auth, userInfo } = useSelector(selectAuth);
    const configClient = useSelector(selectConfigClient);
    //page state
    const [coupon, setCoupon] = useState<ICoupon>();
    const [currentImage, setCurrentImage] = useState<number>(enumValidation.NUMBER_ONE);
    const [showPopupDone, setShowPopupDone] = useState<boolean>(false);
    const [showPopupConfirm, setShowPopupConfirm] = useState<boolean>(false);

    useEffect(() => {
        useCoupon();
    }, []);

    const useCoupon = async () => {
        try {
            dispatch(setLoading(true));
            if (id) {
                const res = await merchantApi.getCouponId(id);
                const data = res.data as ICoupon;
                setCoupon(data);
                const index = configClient.findIndex((item) => item.merchantId === data.merchantId);
                if (index < 0 || userInfo?.status === enumStatus.INACTIVE) {
                    dispatch(setExternalTypes({ type: enumTypes.COUPON, id }));
                } else {
                    dispatch(setExternalTypes(null));
                }
            } else {
                history.push(PATH_404);
            }
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                error(mess);
            }
            history.push(PATH_404);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleReceiveCoupon = async () => {
        checkIsLogin();
        if (!checkIsLogin()) {
            try {
                dispatch(setLoading(true));
                const res = await couponApi.receiveCoupon({
                    objectId: coupon?._id || "",
                    status: enumStatus.ACTIVE,
                    type: enumTypes.COUPON,
                });
                if (res.data.message) {
                    success(res.data.message);
                }
            } catch (err: any) {
                const { errors } = err.response.data;
                if (errors) {
                    error(errors);
                } else {
                    error(t("message.received.fail"));
                }
            } finally {
                dispatch(setLoading(false));
                history.push(PATH_HOME);
            }
        }

        // if (!auth?.accessToken) {
        //     warning(t("page.please_sign_up_to_collect_coupon"));
        //     return history.push(PATH_SIGN_UP);
        // } else if (userInfo?.status === enumStatus.INACTIVE) {
        //     return history.push(PATH_SIGN_UP_FORM);
        // } else {
        //     try {
        //         dispatch(setLoading(true));
        //         const res = await couponApi.receiveCoupon({
        //             objectId: coupon?._id || "",
        //             status: enumStatus.ACTIVE,
        //             type: enumTypes.COUPON,
        //         });
        //         if (res.data.message) {
        //             success(res.data.message);
        //         }
        //     } catch (err: any) {
        //         const { errors } = err.response.data;
        //         if (errors) {
        //             error(errors);
        //         } else {
        //             error(t("message.received.fail"));
        //         }
        //     } finally {
        //         dispatch(setLoading(false));
        //         history.push(PATH_HOME);
        //     }
        // }
    };

    const handleRedeemedCoupon = async () => {
        try {
            if (idParent) {
                dispatch(setLoading(true));
                const res = await couponApi.redeemedCoupon({
                    objectId: coupon?._id || "",
                    status: enumStatus.ACTIVE,
                    type: enumTypes.COUPON,
                    id: idParent,
                });
                if (res.data.message) {
                    success(res.data.message);
                }
            } else {
                dispatch(setLoading(true));
                const res = await couponApi.redeemedCouponNotId(
                    {
                        objectId: coupon?._id || "",
                        status: enumStatus.ACTIVE,
                        type: enumTypes.COUPON,
                    },
                    id || ""
                );
                if (res.data.message) {
                    success(res.data.message);
                }
            }

            // dispatch(setLoading(true));
            // const res = await couponApi.redeemedCouponNotId(
            //     {
            //         objectId: coupon?._id || "",
            //         status: enumStatus.ACTIVE,
            //         type: enumTypes.COUPON,
            //     },
            //     id || ""
            // );
            // if (res.data.message) {
            //     success(res.data.message);
            // }
            setShowPopupDone(true);
        } catch (err: any) {
            const { errors } = err.response.data;
            if (errors) {
                error(errors);
            } else {
                error(t("message.received.fail"));
            }
            history.push(PATH_HOME);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleUseCoupon = () => {
        checkIsLogin();
        setShowPopupConfirm(true);
    };

    const handleChangeImage = (swiper: SwiperCore) => {
        setCurrentImage(swiper.realIndex + 1);
    };

    const checkIsLogin = () => {
        if (!auth?.accessToken) {
            warning(t("page.please_sign_up_to_collect_coupon"));
            history.push(PATH_SIGN_UP);
            return true;
        } else if (userInfo?.status === enumStatus.INACTIVE) {
            history.push(PATH_SIGN_UP_FORM);
            return true;
        } else return false;
    };

    const handleClosePopupDone = () => {
        setShowPopupDone(false);
        history.push(PATH_HOME);
    };

    const handleClosePopupConfirm = () => {
        setShowPopupConfirm(false);
    };

    return (
        <>
            {coupon && (
                <ComponentCouponDetail
                    couponCollected={!!idParent}
                    handleChangeImage={handleChangeImage}
                    handleUseCoupon={handleUseCoupon}
                    coupon={coupon}
                    handleReceiveCoupon={handleReceiveCoupon}
                    currentImage={currentImage}
                />
            )}
            {showPopupConfirm && coupon && (
                <ComponentPopUpConfirm
                    handleCloseDetail={handleClosePopupConfirm}
                    data={coupon as ICoupon}
                    handleConfirm={handleRedeemedCoupon}
                />
            )}
            {showPopupDone && coupon && (
                <ComponentPopUpDone
                    handleCloseDetail={handleClosePopupDone}
                    data={coupon as ICoupon}
                />
            )}
        </>
    );
};
