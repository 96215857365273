import styled from "styled-components";

export const StyledBasket = styled.div<{ mainColor: string }>`
  .topDarkBackground h1 {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .topDarkBackground {
    background: #323232;
    height: 150px;
    padding: 20px;
  }
  .storeAddress {
    margin-top: 20px;
  }
  .storeAddress h2 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .storeAddress p {
    font-size: 14px;
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .storeAddress svg {
    margin-right: 5px;
    width: 14px;
    margin-bottom: 5px;
  }
  .myBasket {
    position: relative;
    top: -10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    padding: 30px 20px 0 20px;
  }
  .basketTitle {
    font-size: 22px;
    font-weight: 700;
    color: black;
  }
  .basketItems {
    display: flex;
    place-content: space-between;
    margin-bottom: 40px;
  }
  .serviceImage img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
  }
  .serviceImage {
    width: 25%;
  }
  .serviceDetails {
    margin-left: 20px;
    width: 75%;
  }
  .serviceDetails h1 {
    font-size: 22px;
    font-weight: bold;
    // font-weight: bold;
  }
  .serviceDetails ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 5px;
  }
  .serviceDetails ul li:before {
    content: "-";
    margin-right: 5px;
  }
  .serviceDetails ul li {
    font-size: 16px;
    color: #919191;
    font-weight: 600;
  }
  .serviceDetails p {
    font-size: 15px;
    display: flex;
    place-items: center;
  }
  .serviceDetails p svg {
    margin-right: 5px;
  }
  .noteButton button {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #919191;
    color: #919191;
    width: 60px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: bold;
  }
  .noteButton button:active {
    outline: none;
  }
  .priceQuantity h3 {
    font-size: 20px;
    font-weight: bold;
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .priceQuantity h4 {
    font-size: 15px;
    text-align: right;
    font-weight: bold;
  }
  .leftSide {
    display: flex;
    width: 70%;
    color: black;
  }
  .rightSide {
    display: flex;
    gap: 20px;
  }
  .priceQuantity {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    place-items: end;
  }
  .priceQuantity svg {
    margin-top: 27px;
    cursor: pointer;
  }
  // Service Css
  .staffInfo {
    margin-top: 20px;
    margin-bottom: 0;
    background: #fff;
  }
  .position {
    display: flex;
    place-content: space-between;
    place-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 18px 0;
    background: #fff;
  }
  .position .leftSide {
    display: flex;
    place-items: center;
  }
  .position .leftSide h2 {
    font-size: 20px;
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: bold;
  }
  .position .rightSide {
    display: flex;
    place-items: center;
  }
  .position .rightSide label {
    /* color: #919191; */
    margin-bottom: 0;
    font-size: 18px;
    cursor: pointer;
    span {
      margin-left: 7px;
    }
  }
  .position .leftSide img {
    width: 30px;
    border-radius: 20px;
  }
  // Service Css
  .firstHrBasket {
    margin-bottom: 20px;
  }
  .totalPriceSection label {
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
    color: #000;
  }
  .innerTotalPrice .totalPrice {
    font-size: 22px;
    font-weight: bold;
    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .totalPriceSection {
    margin-top: 40px;
    display: flex;
    place-content: end;
  }
  .extraPrice {
    font-size: 16px;
    text-align: end;
    display: flex;
    place-content: end;
    place-items: center;
    font-weight: bold;
  }
  .extraPrice svg {
    margin-left: 5px;
  }
  .grayHr {
    height: 15px;
    background: #f7f7f8;
    border: none;
  }
  .basketDetailsSection {
    padding: 0 20px;
  }
  .basketDetailsSection .titleText h1 {
    font-size: 20px;
    font-weight: 700;
  }
  .basketDetailsSection .noteButton p {
    font-size: 10px;
    color: #9b9b9b;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .basketDetailsSection .noteButton svg {
    margin-right: 5px;
  }
  .basketDetailsSection .noteButton {
    display: flex;
    place-items: end;
    flex-direction: column;
  }
  .basketDetailsSection .position {
    place-items: baseline;
    padding: 12px 0;
  }
  .basketDetailsSection .removeBotBorder {
    border: none;
  }
  .buttonSection {
    display: flex;
    place-content: end;
    padding: 40px 30px;
    /* padding: 0 20px 15px 0; */
  }
  .buttonSection button {
    background: #f22f46;
    /* background: pink; */
    font-size: 18px;
    border-radius: 40px;
    border: 1px solid #f22f46;
    color: #fff;
    width: 250px;
    height: 70px;
    cursor: pointer;
    /* font-weight: 900; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    place-content: space-evenly;
    place-items: center;
    .total {
      font-weight: 600;
    }
    .baht {
      font-weight: 300;
    }
  }
  .basketCardDetails p {
    font-size: 14px;
  }
  .timeButtons {
    background: transparent;
    border-radius: 8px;
    border: 1.5px solid #000;
    color: #000;
    width: 70px;
    height: 35px;
    cursor: pointer;
    margin: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  .timeButtons.noRightMargin {
    margin-right: 0;
  }
  .times {
    margin-top: 20px;
    padding: 0 20px 18px 20px;
    display: flex;
    flex-flow: wrap;
    place-content: center;
  }
  .datePicker {
    height: 100vh;
  }
  .datePicketButSection .Next {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
  }
  .disabledTime {
    /* color: #fff; */
    /* border: 1px solid transparent; */
    color: #919191;
    border: 1px solid #a5a5a5;
    cursor: context-menu;
    font-weight: normal;
  }
  .activeTime {
    background: #f22f46;
    color: #fff;
    border: 1px solid transparent;
    font-weight: bold;
  }
  .Mui-selected {
    background-color: #f22f46 !important;
  }
  .PrivatePickersSlideTransition-root {
    min-height: 200px;
  }
  .timeTitle h1 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px;
    padding: 0;
    color: black;
  }
  .timeSection {
    display: flex;
    place-content: center;
    flex-direction: column;
    place-items: center;
    text-align: center;
    background: #fff;
  }
  .timeTitle {
    padding: 12px 0;
    text-align: center;
    border-bottom: 1px solid #cdcdcd;
    border-top: 1px solid #cdcdcd;
    width: 100%;
  }
  .timeButtons {
    background: transparent;
    border-radius: 8px;
    border: 1.5px solid #000;
    color: #000;
    width: 70px;
    height: 35px;
    cursor: pointer;
    margin: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  .timeButtons.noRightMargin {
    margin-right: 0;
  }
  .times {
    margin-top: 20px;
    padding: 0 20px 18px 20px;
    display: flex;
    flex-flow: wrap;
    place-content: center;
  }
  .datePicker {
    height: 100vh;
  }

  .disabledTime {
    /* color: #fff; */
    /* border: 1px solid transparent; */
    color: #919191;
    border: 1px solid #a5a5a5;
    cursor: context-menu;
    font-weight: normal;
  }
  .activeTime {
    background: #f22f46;
    color: #fff;
    border: 1px solid transparent;
    font-weight: bold;
  }
  .quantityButton {
    background: transparent;
    border: none;
    outline: none;
  }
  .dateTopDiv {
    .css-epd502 {
      /* background-color: pink; */
      width: 100%;
      .MuiCalendarPicker-root {
        width: 90%;
        /* margin: 10px; */
      }
      .MuiDayPicker-header {
        /* background-color: orange; */
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e1e1e1;
        padding: 0px 15px;
        padding-top: 15px;
        .MuiDayPicker-weekDayLabel {
          color: black;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .MuiDayPicker-weekContainer {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding: 0px 15px;
        /* background-color: greenyellow; */
        /* font-size: 18px; */

        .MuiPickersDay-root {
          font-size: 14px;
          font-weight: 600;
          /* font-family: "Nunito"; */
          font-family: "NotoSans";
        }
        .Mui-disabled {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }
      .MuiPickersCalendarHeader-root {
        .MuiPickersCalendarHeader-labelContainer {
          /* background-color: #aee4df; */
          width: 65%;
          .MuiPickersFadeTransitionGroup-root {
            margin-left: auto;
            margin-right: 0px;
          }
          text-align: right;
        }

        .MuiPickersArrowSwitcher-root {
          position: relative;
          /* margin-left: auto;
                    margin-right: 0px; */
          width: 100px;
        }
      }

      .PrivatePickersSlideTransition-root {
        min-height: 25vh;
      }
      .MuiPickersCalendarHeader-label {
        font-weight: bold;
        font-size: 20px;
        color: black;
      }
    }
  }
`;
