import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

const initialState: any = {
  cart: [],
  promotionIDList: [],
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action: PayloadAction) => {
      state.cart = action.payload;
    },
    setPromotionIDList: (state, action: PayloadAction<string[]>) => {
      state.promotionIDList = action.payload;
    },
    clearCart: (state) => {
      state.cart = [];
    },
  },
});

export const selectCart = (state: RootState) => state.cart;

export const { setCart, clearCart, setPromotionIDList } = cart.actions;

export default cart.reducer;
