import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router";

import { ComponentHeaderAuth, ComponentSignUpOTP } from "@components";
import {
    addConfigClient,
    selectConfigClient,
    selectMerchant,
    setAuth,
    setConfigClient,
    setLoading,
} from "@redux";
import { enumValidation, PATH_HOME, TIME_RESEND } from "@configs";
import { authApi } from "@api";
import { useNotify } from "@utils";
import { IAuthConfig } from "@interfaces";

export const PageSignUpOTP = () => {
    //page hook
    const history = useHistory();
    const { error, success } = useNotify();
    const dispatch = useDispatch();
    //page state
    const [otp, setOtp] = useState<string>("");
    const [timeResend, setTimeResend] = useState<number>(TIME_RESEND);
    //redux state
    // const { externalLogin } = useSelector(selectAuth);
    const configClient = useSelector(selectConfigClient);
    const { _id } = useSelector(selectMerchant);
    //page variable
    const phoneNumber = window.sessionStorage.getItem("phoneNumber") || "";
    const remember = window.sessionStorage.getItem("remember");

    // useEffect(() => {
    //     if (externalLogin !== enumExternalMethod.TEL) {
    //         history.push(PATH_SIGN_UP);
    //     }
    // }, []);

    const handleChangeOtp = (otp: string) => {
        setOtp(otp);
    };

    const handleVerifyOtp = async () => {
        if (otp.length < enumValidation.LENTH_OTP) return;
        try {
            dispatch(setLoading(true));
            const res = await authApi.verifyPhone({
                phoneNumber,
                code: otp,
            });
            const data = res.data.token as IAuthConfig;
            const message = res.data.message;

            const authData = remember
                ? { ...data, merchantId: _id || "" }
                : { ...data, refreshToken: null, merchantId: _id || "" };
            dispatch(setAuth(authData));
            if (_id) {
                const index = configClient.findIndex((item) => item.merchantId === _id);
                if (index >= 1) {
                    dispatch(
                        setConfigClient({
                            index,
                            configClient: {
                                merchantId: _id || "",
                                token: authData,
                            },
                        })
                    );
                } else {
                    dispatch(
                        addConfigClient({
                            merchantId: _id || "",
                            token: authData,
                        })
                    );
                }
                success(message);
                history.push(PATH_HOME);
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleResend = async () => {
        if (timeResend !== TIME_RESEND) return;
        try {
            dispatch(setLoading(true));
            const res = await authApi.resendPhone({
                phoneNumber,
            });
            if (res.data.message) {
                success(res.data.message);
                let currentTime = timeResend;
                const id = setInterval(() => {
                    currentTime = currentTime - 1;
                    setTimeResend(() => currentTime);
                    if (currentTime === 0) {
                        setTimeResend(TIME_RESEND);
                        clearInterval(id);
                    }
                }, 1000);
            }
        } catch (errors: any) {
            const { message } = errors.response.data;
            error(message);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ComponentHeaderAuth>
            <ComponentSignUpOTP
                handleVerifyOtp={handleVerifyOtp}
                handleChangeOtp={handleChangeOtp}
                handleResend={handleResend}
                otp={otp}
                timeResend={timeResend}
            />
        </ComponentHeaderAuth>
    );
};
