import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useNotify } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant, store } from "@redux";
import { StyledSubmitButton } from "../shared";
import "../styles/Swiper.css";
import { useEffect, useState } from "react";
import { cardAPI } from "@api";
import { ICreateProductOrderPOSExample } from "@interfaces";
import { IconFreeaeCard } from "../icon/BasketDrawer/IconLock";

interface IProps {
  paymentDrawer: boolean;
  handleClose: () => void;
  paymentDetails?: ICreateProductOrderPOSExample;
  getPaymentDetails: () => void;
  setMemberCardDetail?: any;
  setDiscountMemberCardPromotion?: any;
  createProductOrderPOS: any;
  setMemberCardPromotionId?: any;
}

export const ComponentPaymentDrawer = (props: IProps) => {
  const {
    paymentDrawer,
    handleClose,
    paymentDetails,
    setMemberCardDetail,
    setDiscountMemberCardPromotion,
    createProductOrderPOS,
    setMemberCardPromotionId,
  } = props;
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const dispatch = useDispatch();
  const orgID = store.getState().auth.userInfo?.merchant?._id;
  const userInfo = store.getState().auth.userInfo;
  const cart = useSelector((state: any) => state.cart.cart);
  const { themeColor } = useSelector(selectMerchant);
  const [selectMemberCardDetail, setSelectMemberCardDetail] = useState<any>({});
  const [refRandomNumber, setRefRandomNumber] = useState<string>(Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0'));

  useEffect(() => {
    getMemberCardUser();
  }, []);

  const getMemberCardUser = async () => {
    try {
      if (userInfo && userInfo._id) {
        const response = await cardAPI.getMemberCardUser({ userId: userInfo._id });
        if (response.data && response.data.length > 0) {
          const memberCard = response.data.filter(
            (card: any) => card.isAllmember || card.isPhysical
          );
          // memberCard[0].isUse = false;    // for test
          setSelectMemberCardDetail(memberCard[0]);
          setMemberCardDetail(memberCard[0]);
          let discountMemberCardPromotion = 0;
          let memberCardPromotionId = "";
          if (
            memberCard[0].isPromotion === true &&
            memberCard[0].promotion &&
            memberCard[0].promotion.length > 0 &&
            memberCard[0].promotion[0].isUse === true
          ) {
            if (memberCard[0].promotion[0].unit === "percent") {
              const net = paymentDetails?.grand_total || 0;
              const maxSpend = Number(memberCard[0].promotion[0].maxSpend) || 0;
              const discountPromo =
              (net * (Number(memberCard[0].promotion[0].promotionBenefit) || 0)) / 100;
              discountMemberCardPromotion = discountPromo > maxSpend ? maxSpend : discountPromo;
            } else {
              discountMemberCardPromotion = Number(memberCard[0].promotion[0].promotionBenefit) || 0;
            }
            memberCardPromotionId = memberCard[0].promotion[0]._id;
          }
          setMemberCardPromotionId(memberCardPromotionId);
          setDiscountMemberCardPromotion(discountMemberCardPromotion);
        }
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  return (
    <StyledDrawer
      title={t("page.use_card")}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={paymentDrawer}
      width={"100%"}
      closable={true}
      height={"80%"}
      className={"drawer"}
    >
      <StyledPayment themeColor={themeColor}>
        {selectMemberCardDetail && selectMemberCardDetail._id && (
          <div className="cardBox">
            <img className="imgMemberCard" src={selectMemberCardDetail.imageUrl[0]} alt="test" />
            {(selectMemberCardDetail.isFreeze || !selectMemberCardDetail.isUse) && <div className="freezeMembarCard">
              <IconFreeaeCard/>
              <p className="textFreezeMemberCard">
                {selectMemberCardDetail.isFreeze ? 'Your card has been freeze.' : 'Your card is inactive.'}
              </p>
            </div>}
            <p className="cardTitle">{selectMemberCardDetail.isPhysical ? selectMemberCardDetail.cardPhysicNumber : selectMemberCardDetail.cardNumber}</p>
            <p className="amount">{`${
              selectMemberCardDetail.cardCash ? Number(selectMemberCardDetail.cardCash).toFixed(2) : "0"
            } THB`}</p>
          </div>
        )}
        <div className="orderSummarySection mainContainer">
          <div className="orderDetailsRow">
            <p>
              <b>
                {t("page.subtotal")} ({cart.length} {t("page.items")})
              </b>
            </p>
            <p>
              <b>{cart.length === 0 ? 0 : paymentDetails?.total_before_discount.toFixed(2)}฿</b>
            </p>
          </div>
          {paymentDetails?.service_charge !== 0 && (
            <div className="orderDetailsRow">
              <p>{t("page.service_charge")}</p>
              <p>{cart.length === 0 ? 0 : paymentDetails?.service_charge.toFixed(2)}฿</p>
            </div>
          )}
          <div className="orderDetailsRow">
            <p>{t("page.vat")}</p>
            <p>{cart.length === 0 ? 0 : paymentDetails?.vat_total.toFixed(2)}฿</p>
          </div>
          {paymentDetails?.discount_in_baht_by_promotion !== 0 && (
            <div className="orderDetailsRow">
              <p>{t("page.promotion_discount")}</p>
              <p style={{color: 'red'}}>{cart.length === 0 ? 0 : `-${paymentDetails?.discount_in_baht_by_promotion.toFixed(2)}`}฿</p>
            </div>
          )}
          {paymentDetails?.discount_in_baht_by_point !== 0 && (
            <div className="orderDetailsRow">
              <p>{t("page.points_discount")}</p>
              <p style={{color: 'red'}}>{cart.length === 0 ? 0 : `-${paymentDetails?.discount_in_baht_by_point.toFixed(2)}`}฿</p>
            </div>
          )}
          {paymentDetails && paymentDetails.loyalty_card_id ? (
            <div className="orderDetailsRow">
              <p>{t("page.gift_card_usage")}</p>
              <p style={{color: 'red'}}>-{paymentDetails?.discount_in_baht_by_loyalty_card.toFixed(2)}฿</p>
            </div>
          ) : null}
          {paymentDetails && paymentDetails.loyalty_card_id ? (
            <div className="orderDetailsRow">
              <p>{t("page.gift_card_usage_promo")}</p>
              <p style={{color: 'red'}}>-{paymentDetails?.discount_in_baht_promo_by_loyalty_card.toFixed(2)}฿</p>
            </div>
          ) : null}
          {paymentDetails?.discount_in_baht_promo_by_loyalty_member_card !== 0 && (
            <div className="orderDetailsRow">
              <p>{t("page.member_card_usage_promo")}</p>
              <p style={{color: 'red'}}>
                {cart.length === 0
                  ? 0
                  : `-${paymentDetails?.discount_in_baht_promo_by_loyalty_member_card.toFixed(2)}`}
                ฿
              </p>
            </div>
          )}
          <div className="orderDetailsRow pointsRow">
            <p>
              <b>{t("page.to_pay")}</b>
            </p>
            <div className="column">
              <p>
                <b>{cart.length === 0 ? 0 : paymentDetails?.grand_total.toFixed(2)}฿</b>
              </p>
            </div>
          </div>
        </div>
        <StyledSubmitButton
          type="red"
          style={(selectMemberCardDetail.isFreeze || !selectMemberCardDetail.isUse) ? {backgroundColor: "#E1E1E1"} : {}}
          text={<span>{t("page.confirm_payment")}</span>}
          className="subBtn"
          onClick={() => {
            if (selectMemberCardDetail.isFreeze || !selectMemberCardDetail.isUse) {
              return;
            }
            if (selectMemberCardDetail && selectMemberCardDetail._id) {
              createProductOrderPOS(refRandomNumber);
            } else {
              error("Member card is empty.");
            }
          }}
          disable={selectMemberCardDetail.isFreeze || !selectMemberCardDetail.isUse}
        />
      </StyledPayment>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    height: auto !important;
    margin-bottom: 0 !important;
  }
`;

const StyledPayment = styled.div<{ themeColor: string }>`
  .swiper-container {
    padding-bottom: 45px;
  }
  .cardBox {
    position: relative;
  }
  .slide {
    border-radius: 12px;
    background: #fc5064;
    position: relative;
    display: flex;
    flex-direction: column;
    place-items: baseline;
  }
  .swiper-pagination-bullet-active {
    background: ${(color) => color.themeColor};
  }
  .cardTitle {
    border-radius: 30px;
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    line-height: normal;
    padding-left: 4px;
    position: absolute;
    left: 18px;
    top: 14px;
  }
  .amount {
    // margin-top: 40px;
    border-radius: 30px;
    background: #f2aa3c;
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    padding: 4px 12px;
    width: fit-content;
    position: absolute;
    left: 10px;
    bottom: 14px;
    margin: 0px;
  }
  .amountInput {
    height: 60px;
  }
  .subBtn {
    width: 100%;
    border-radius: 74px;
    background: ${(color) => color.themeColor};
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    width: -webkit-fill-available;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .selectAmountSection {
    .selectAmountTitle {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .selectAmountSubtitle {
      color: #919191;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .amountCardRows {
    margin-top: 10px;
    .available svg .main {
      fill: #f22f46;
      opacity: 1;
    }
    .used {
      span {
        transform: rotate(-15deg);
      }
    }
    .couponIcon {
      position: relative;
      display: flex;
      place-content: center;
      place-items: center;
      span {
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .mainContainer {
    margin-bottom: 40px;
  }
  .imgMemberCard {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .orderSummarySection {
    margin-top: 20px;
  }
  .orderDetailsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    span {
      font-weight: 400;
      font-size: 12px;
      color: #676767;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      gap: 5px;
      align-items: center;
      b {
        font-weight: 700;
        margin: 0;
        color: #000;
        font-size: 14px;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      place-items: end;
    }
    &.pointsRow {
      border-top: 1px solid;
      border-color: #e1e1e1;
    }
  }
  .mainContainer {
    margin-bottom: 50px;
  }
  .freezeMembarCard {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.74);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .textFreezeMemberCard {  
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    margin-bottom: 0;
  }
`;
