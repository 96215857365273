import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { memo, useCallback } from "react";
import { useHistory } from "react-router";

import { ComponentSignInEmail } from "@components";
import { IAuthConfig, ILogin } from "@interfaces";
import { useYup } from "@validations";
import { authApi } from "@api";
import {
    addConfigClient,
    selectConfigClient,
    selectMerchant,
    setAuth,
    setConfigClient,
    setExternalLogin,
    setLoading,
} from "@redux";
import { useNotify } from "@utils";
import { enumExternalMethod, PATH_HOME } from "@configs";

interface IProps {
    remember: boolean;
}

export const ModuleEmailSignIn = memo((props: IProps) => {
    //page props
    const { remember } = props;
    // redux states
    const { _id } = useSelector(selectMerchant);
    const configClient = useSelector(selectConfigClient);
    //page hooks
    const { YubEmailSignIn } = useYup();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const { t } = useTranslation();
    const history = useHistory();
    //page variable
    const initialValues: ILogin = {
        email: "",
        password: "",
    };

    const registerSchema = Yup.object().shape(YubEmailSignIn);

    const handleLogin = useCallback(
        async (values: ILogin, { setSubmitting }: FormikHelpers<ILogin>) => {
            //
            try {
                setSubmitting(true);
                dispatch(setLoading(true));
                const res = await authApi.login(values);
                const data = res.data as IAuthConfig;
                const authData = remember
                    ? { ...data, merchantId: _id || "" }
                    : { ...data, refreshToken: null, merchantId: _id || "" };
                dispatch(setAuth(authData));
                const index = configClient.findIndex((item) => item.merchantId === _id);
                if (index >= 1) {
                    dispatch(
                        setConfigClient({
                            index,
                            configClient: {
                                merchantId: _id || "",
                                token: authData,
                            },
                        })
                    );
                } else {
                    dispatch(
                        addConfigClient({
                            merchantId: _id || "",
                            token: authData,
                        })
                    );
                }

                dispatch(setExternalLogin({ type: enumExternalMethod.EMAIL }));
                success(t("message.login.success"));
                history.push(PATH_HOME);
            } catch (errors: any) {
                const { message } = errors.response.data;
                error(message);
            } finally {
                setSubmitting(false);
                dispatch(setLoading(false));
            }
        },
        [remember]
    );

    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validationSchema: registerSchema,
            onSubmit: handleLogin,
        });

    return (
        <ComponentSignInEmail
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
        />
    );
});
