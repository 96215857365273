import { StyledOrderHistory } from "@components";
import { ComponentOrderHistory } from "src/Components/orderHistory";
import { merchantApi } from "@api";
import { IMerchant } from "@interfaces";
import { getInfo, selectAuth, setAuth, setMerchant, store } from "@redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PATH_ORDER_HISTORY } from "@configs";

export const PageOrderHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const merchantId = useSelector(selectAuth)?.userInfo?.merchantId;
  const accessToken = useSelector(selectAuth).auth?.accessToken;
  const { token } = useParams<{ token: string }>();
  const userInfo = store.getState().auth.userInfo;

  const getUserInfo = async () => {
    await dispatch(getInfo());
    dispatch(
      setAuth({
        accessToken: token,
        merchantId: merchantId || "",
        refreshToken: null,
        userPermissions: [],
      })
    );
    if (userInfo) {
      if (merchantId) {
        const merchantConfig = await merchantApi.getInitMerchant(merchantId);
        const merchant = merchantConfig.data.merchant as IMerchant;
        dispatch(setMerchant(merchant));
        history.push(PATH_ORDER_HISTORY);
      }
    }
  };

  useEffect(() => {
    if (token) {
      console.log(token);
      getUserInfo();
    }
  }, []);

  return (
    <StyledOrderHistory>
      <ComponentOrderHistory />
    </StyledOrderHistory>
  );
};
