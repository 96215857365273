interface IProps {
    color?: string;
    width?: number;
    className?: string;
}

export const IConPhone = (props: IProps) => {
    const { color = "#9B9B9B", className } = props;

    return (
        <>
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M17.1559 12.2168L13.6049 10.6428C13.2537 10.4874 12.861 10.4522 12.4876 10.5426C12.1143 10.633 11.7812 10.8439 11.5399 11.1428L10.4679 12.4828C8.73051 11.3777 7.25683 9.90474 6.15093 8.16782L7.49093 7.09682C7.7904 6.85554 8.00176 6.52208 8.09218 6.14829C8.1826 5.77449 8.14701 5.3813 7.99093 5.02982L6.41693 1.47782C6.2494 1.10059 5.95337 0.795107 5.58158 0.615809C5.2098 0.436512 4.78644 0.395053 4.38693 0.498821L1.38693 1.27882C0.972342 1.38651 0.611685 1.6426 0.373357 1.99853C0.135029 2.35445 0.0356026 2.78546 0.0939342 3.20982C0.649375 7.07901 2.44333 10.6641 5.20712 13.4283C7.97092 16.1925 11.5558 17.9869 15.4249 18.5428C15.5044 18.5541 15.5846 18.5598 15.6649 18.5598C16.0514 18.5596 16.4268 18.4312 16.7326 18.195C17.0384 17.9587 17.2572 17.6277 17.3549 17.2538L18.1339 14.2538C18.2409 13.8534 18.2011 13.428 18.0217 13.0544C17.8424 12.6808 17.5353 12.3837 17.1559 12.2168Z"
                    fill={color}
                />
            </svg>
        </>
    );
};
