import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Empty } from "antd";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { resetConfirm } from "@redux";
import { historyApi } from "@api";
import {
    IBenefit,
    ICoupon,
    IFreePoint,
    IMerchantFreePoint,
    IPagination,
    IReward,
} from "@interfaces";
import { enumTypes, INITIAL_LIST_PARAMS } from "@configs";
import { ComponentHistoryList, ComponentSpinPage } from "@components";
import { RenderMeta } from "@utils";

export const ModuleHistory = () => {
    //page hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();
    //page state
    const [histories, setHistories] = useState<(IBenefit | IReward | ICoupon | IFreePoint)[]>([]);
    const [currenHistory, setCurrenHistory] = useState<{
        data: IBenefit | IReward | ICoupon | IFreePoint | IMerchantFreePoint;
        point: number | undefined;
    }>();
    const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
    const [param, setParam] = useState<IPagination>(INITIAL_LIST_PARAMS);
    const [spinning, setSpinning] = useState<boolean>(false);

    useEffect(() => {
        getInitData();
        dispatch(resetConfirm());
    }, []);

    const getInitData = async () => {
        setSpinning(true);
        await handleGetData(param);
        setSpinning(false);
    };

    const handleGetData = async (param: IPagination) => {
        const res = await historyApi.getListHistory(param);
        const data = res.data.docs.map((item: any) => {
            //WHAT: Nếu type = reward thi as data = dataReward
            if (item.type === enumTypes.REWARD) {
                return {
                    exchangedType: item.exchangedType,
                    idCart: item._id,
                    updatedAtHistory: item.updatedAt,
                    quantityPoint: item.quantity,
                    ...item.reward,
                    point: item.points,
                };
            }
            //WHAT: Nếu type = benefit thi as data = dataBenefit
            else if (item.type === enumTypes.BENEFIT) {
                return {
                    exchangedType: item.exchangedType,
                    idCart: item._id,
                    updatedAtHistory: item.updatedAt,
                    ...item.reward,
                };
            }
            //WHAT: Nếu type = COUPON thi as data = dataCOUPON, data coupon giống FREE_POINT
            else if (item.type === enumTypes.COUPON || item.type === enumTypes.FREE_POINT) {
                return {
                    exchangedType: item.exchangedType,
                    idCart: item._id,
                    updatedAtHistory: item.updatedAt,
                    ...item.qrtable,
                };
            } else {
                return item;
            }
        }) as (IBenefit | IReward | ICoupon | IFreePoint)[];
        setHistories([...histories, ...data]);
    };

    const handleLoadMore = async () => {
        const paramPanigation = {
            ...param,
            page: (param.page || 1) + 1,
        };
        setParam(paramPanigation);
        setLoadingPagination(true);
        await handleGetData(paramPanigation);
        setLoadingPagination(false);
    };

    const handleClick = (data: IReward | IBenefit | ICoupon | IFreePoint) => {
        setCurrenHistory({ data: data, point: data?.quantityPoint || data?.point || undefined });
        // dispatch(setOverflow(false));
        document.body.style.overflow = "hidden";
    };

    const handleCloseDetail = () => {
        setCurrenHistory(undefined);
        // dispatch(setOverflow(true));
        document.body.style.overflow = "unset";
    };

    const loadingComponent = (
        <StyledLoading>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"100%"} height={30} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"100%"} height={30} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"100%"} height={30} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"100%"} height={30} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"100%"} height={30} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
                <Skeleton className="content" animation="wave" width={"40%"} height={10} />
            </div>
        </StyledLoading>
    );

    return (
        <>
            <RenderMeta title={t("meta.history_list")} />
            {!spinning ? (
                histories.length > 0 ? (
                    <>
                        <ComponentHistoryList
                            data={histories}
                            loadingPagination={loadingPagination}
                            handleLoadMore={handleLoadMore}
                            currenHistory={
                                currenHistory?.data as IReward | IFreePoint | ICoupon | IBenefit
                            }
                            handleClick={handleClick}
                            handleCloseDetail={handleCloseDetail}
                        />
                    </>
                ) : (
                    <Empty />
                )
            ) : (
                <ComponentSpinPage children={loadingComponent} />
            )}
        </>
    );
};

const StyledLoading = styled.div`
    margin: 25px 24px 0 24px;
    .card {
        margin-bottom: 16px;
        padding: 18px 14px;
        background-color: white;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 1px 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 6px;
    }
`;
