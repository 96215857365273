import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { enumValidation } from "@configs";

export const YubSocialSignUp = () => {
    //page hook
    const { t } = useTranslation();
    return {
        email: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.email"),
                })
            )
            .email(t("validation.email_invalid"))
            .max(
                enumValidation.MAX_LENGTH_INPUT,
                t("validation.max", {
                    name: t("page.email"),
                    number: enumValidation.MAX_LENGTH_INPUT,
                })
            ),
        fullName: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.name"),
                })
            )
            .max(
                enumValidation.MAX_LENGTH_INPUT,
                t("validation.max_length_number", {
                    returnObjects: true,
                    name: t("page.name"),
                    number: enumValidation.MAX_LENGTH_INPUT,
                })
            ),
        acceptTermsAndConditions: Yup.boolean().oneOf(
            [true],
            t("validation.acceptTermsAndConditions")
        ),
    };
};
