import { FormikErrors } from "formik";
import { useEffect, useState } from "react";
import parsePhoneNumber from "libphonenumber-js";

import { IEmailForm, IPhone, IUserSetting } from "@interfaces";
import { CountryData } from "react-intl-tel-input";
import { SharedIntlTelInput } from "@components";
import { useTranslation } from "react-i18next";

interface IProps {
    setFieldTouched: (
        field: string,
        touched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IUserSetting | IEmailForm | IPhone>>;
    setFieldValue: any;
    value: string;
    errorPhone: string;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
    disabled?: boolean | undefined;
}

export const ModulesTelInput = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();
    //page state
    const [country, setCountry] = useState<string>();
    //page props
    const { setFieldTouched, value, errorPhone, setFieldValue, setError, disabled } = props;

    useEffect(() => {
        const phoneNumber = parsePhoneNumber(value);
        setCountry(phoneNumber?.country);
    }, []);

    const handleSelectFlag = (
        currentNumber: string,
        selectedCountryData: CountryData,
        fullNumber: string
    ) => {
        setFieldValue("phoneNumber", currentNumber.replace(/(\s|-)/g, ""));
        setFieldValue("phone", fullNumber.replace(/(\s|-)/g, ""));
    };

    const handleChangePhoneNumber = (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string
    ) => {
        if (!isValid) setError(t("validation.isNumber"));
        else {
            setError("");
        }

        setFieldValue("phoneNumber", value);
        setFieldValue("phone", fullNumber.replace(/(\s|-)/g, ""));
    };
    return (
        <SharedIntlTelInput
            setFieldTouched={setFieldTouched}
            value={value}
            errorPhone={errorPhone}
            disabled={disabled}
            country={country}
            handleSelectFlag={handleSelectFlag}
            handleChangePhoneNumber={handleChangePhoneNumber}
        />
    );
};
