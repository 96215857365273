interface IProps {
    size: number;
    firstColor?: string;
    secondColor?: string;
}

export const IconLoading = (props: IProps) => {
    const { size, firstColor, secondColor } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{ margin: "auto", background: "none", display: "block", shapeRendering: "auto" }}
            width={size}
            height={size}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle cx="40" cy="50" fill={firstColor || "#e99a9b"} r="10">
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="40;60;40"
                    begin="-0.5s"
                ></animate>
            </circle>
            <circle cx="60" cy="50" fill={firstColor || "#e99a9b"} r="10">
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="40;60;40"
                    begin="0s"
                ></animate>
            </circle>
            <circle cx="40" cy="50" fill={secondColor || "#df1317"} r="10">
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="40;60;40"
                    begin="-0.5s"
                ></animate>
                <animate
                    attributeName="fill-opacity"
                    values="0;0;1;1"
                    calcMode="discrete"
                    keyTimes="0;0.499;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                ></animate>
            </circle>
        </svg>
    );
};

export const IconLoadMore = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{ margin: "auto", background: "none", display: "block", shapeRendering: "auto" }}
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle cx="50" cy="23" r="5" fill="#e15b64">
                <animate
                    attributeName="cy"
                    dur="1s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
                    keyTimes="0;0.5;1"
                    values="23;77;23"
                ></animate>
            </circle>{" "}
        </svg>
    );
};
