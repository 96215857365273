import { BLANK_IMAGE_URL, enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { SharedComponentDrawer } from "../shared/Drawer";
import { MinusIcon } from "../icon/MinusIcon";
import { PlusIcon } from "../icon/PlusIcon";
import styled from "styled-components";
import { IBasketItems, ICreateProductOrderPOSExample, IGetStoreExample } from "@interfaces";
import { clearCart, selectMerchant, setCart, setPromotionIDList, store } from "@redux";
import {
  arrow,
  basketCard,
  basketCoupon,
  basketStar,
  basketTrashBin,
  PromotionCode,
  WhiteIconPin,
} from "../icon";
import { Row } from "antd";
import { StyledSubmitButton } from "../shared";
import { productAPI } from "@api";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { DiscountIcon } from "../icon/DiscountIcon";
import { SmallGrayPointIcon } from "../icon/SmallGrayPointIcon";
import { ComponentFreebieDrawer } from "./FreebieDrawer";
import { IconPromotion } from "../icon/BasketDrawer/IconPromotion";
import { posPromotionApi } from "src/API/posPromotion";
import { useNotify } from "@utils";
import { ISelectPOSPromotionFreeItemByPromotion } from "src/Interfaces/posPromotion";
import { ComponentPromotionsDrawer } from "./PromotionsDrawer";
import { ComponentPromotionsCodeDrawer } from "./PromotionsCodeDrawer";
import { TotalQueueIcon } from "../icon/TotalQueueIcon";
import { ComponentDynamicFreebieDrawer } from "./DynamicFreebieDrawer";
import { ComponentPaymentGatewayDrawer } from "./PaymentGatewayDrawer";

interface IProps {
  basketDrawer: boolean;
  handleClose: () => void;
  storeDetails?: IGetStoreExample;
  setUsePointsDrawer?: any;
  setApplyCardDrawer?: any;
  paymentDetailsHome?: ICreateProductOrderPOSExample;
  getPaymentDetails: () => void;
  setPaymentDetailsHome?: any;
  pointsInput?: number;
  setIsApplyPromotionAuto?: any;
  isApplyPromotionAuto?: boolean;
  setPaymentDrawer?: any;
  totalQueueToday?: number;
  setPromoCode?: any;
  setPartnerPromoCode?: any;
  setPromotionIdByPromoCode?: any;
  promoCode?: string;
  partnerPromoCode?: any;
  promotionIdByPromoCode?: string;
  selectedFreebies: any[];
  setSelectedFreebies: any;
  setApplyCouponDrawer: any;
  createProductOrderPOSByPaySolution: any;
}

export const ComponentBasketDrawer = (props: IProps) => {
  const {
    basketDrawer,
    handleClose,
    totalQueueToday,
    setUsePointsDrawer,
    setApplyCardDrawer,
    paymentDetailsHome,
    getPaymentDetails,
    setPaymentDetailsHome,
    pointsInput,
    setIsApplyPromotionAuto,
    isApplyPromotionAuto,
    setPaymentDrawer,
    setPromoCode,
    setPartnerPromoCode,
    setPromotionIdByPromoCode,
    promoCode,
    partnerPromoCode,
    promotionIdByPromoCode,
    selectedFreebies,
    setSelectedFreebies,
    setApplyCouponDrawer,
    createProductOrderPOSByPaySolution
  } = props;
  const { t } = useTranslation();
  const org = useSelector((state: any) => state.merchant);
  const cart = useSelector((state: any) => state.cart.cart);
  const userInfo = store.getState().auth.userInfo;
  const dispatch = useDispatch();
  const { error } = useNotify();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { themeColor } = useSelector(selectMerchant);
  const [freebieDrawer, setFreebieDrawer] = useState<boolean>(false);
  const [productAddonList, setProductAddonList] = useState<
    ISelectPOSPromotionFreeItemByPromotion[]
  >([]);
  const promotionIDList = useSelector((state: any) => state.cart.promotionIDList);
  const [promotionsDrawer, setPromotionsDrawer] = useState<boolean>(false);
  const [promotionsCodeDrawer, setPromotionsCodeDrawer] = useState<boolean>(false);
  const [posPromotionMatchList, setPosPromotionMatchList] = useState<any>();
  const [isEditApplied, setIsEditApplied] = useState<boolean>(false);

  const [dynamicFreebieDrawer, setDynamicFreebieDrawer] = useState<boolean>(false);
  const [listPromoDynamicFreebie, setListPromoDynamicFreebie] = useState<any>();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [paymentGatewaysDrawer, setPaymentGatewaysDrawer] = useState<boolean>(false);
  const [refRandomNumber, setRefRandomNumber] = useState<string>(Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0'));

  useEffect(() => {
    if (basketDrawer) {
      setSelectedFreebies([]);
      setProductAddonList([]);
      getPromotions();
    }
  }, []);

  useEffect(() => {
    if (cart.length !== 0 && basketDrawer) {
      getPaymentDetails();
    }
  }, [selectedFreebies]);

  useEffect(() => {
    if (basketDrawer) {
      setPaymentDetails(paymentDetailsHome);
    }
  }, [paymentDetailsHome]);

  useEffect(() => {
    if (cart.length !== 0 && basketDrawer) {
      if (promotionIDList.length > 0) {
        getPromotions(promotionIDList, true);
      } else {
        getPromotions();
      }
    }
  }, [promoCode, isEditApplied, basketDrawer]);

  const getPromotions = (promotionList?: any, isManual?: boolean) => {
    dispatch(setCart(cart.filter((item: any) => !item.is_freebie)));
    if (!isManual) {
      getProductAddon();
    } else {
      getPromotionByPriorityManual(promotionList);
    }
  };

  const getProductAddon = async () => {
    let params: any = formatIOrderList();
    try {
      if (promoCode && promoCode !== "") {
        params.promo_code_list = promoCode;
      }
      if (partnerPromoCode && partnerPromoCode._id) {
        params.partner_id = partnerPromoCode._id;
      }
      const get = await posPromotionApi.selectPosPromotionByPriorityAuto(params);
      if (get.data.error === "0") {
        setPosPromotionMatchList(get.data.result.pos_promotion_match_list);
        const listDynamicFreebie = get.data.result.pos_promotion_match_list.filter(
          (promo: any) => promo.promotion_type === "freebie" && promo.is_dynamic_freebie === true
        );
        const promoFreebieStatic = get.data.result.pos_promotion_match_list.filter(
          (promo: any) => promo.promotion_type === "freebie" && promo.is_dynamic_freebie === false
        );
        let productsNonAddon: any[] = [];
        let productsAddon: any[] = [];
        promoFreebieStatic.map((promotion: any) => {
          promotion.result_free_item.map((item: any) => {
            const obj = { ...item, pos_promotion_id: promotion.pos_promotion_id };
            if (obj.addon_option_list && obj.addon_option_list.length > 0) {
              productsAddon.push(obj);
            } else if (!obj.addon_option_list || obj.addon_option_list.length === 0) {
              productsNonAddon.push(obj);
            }
          });
        });

        if (listDynamicFreebie.length > 0) {
          setListPromoDynamicFreebie(listDynamicFreebie);
          if (productsAddon.length > 0) {
            setProductAddonList(productsAddon);
          }
          if (productsNonAddon.length > 0) {
            addListToBasket(productsNonAddon, true);
          }
          setSelectedFreebies([]);
          setDynamicFreebieDrawer(true);
        } else {
          if (productsAddon.length > 0) {
            setProductAddonList(productsAddon);
            setFreebieDrawer(true);
          }
          if (productsNonAddon.length > 0) {
            addListToBasket(productsNonAddon, true);
          }
        }
      } else {
        error(get.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };
  const formatIOrderList = () => {
    let orderList: any = [];
    cart.map((item: any) => {
      const obj = {
        variant_id: item.variant_id ? item.variant_id.toString() : item.variant_id,
        qty: item.qty,
        note: "",
        addon_list: item.addon_list || [],
      };
      orderList.push(obj);
    });
    const params = {
      organization_id: org._id,
      store_id: org.store_id,
      sale_channel_id: "",
      profile_id: "",
      promo_code_list: "",
      order_list: orderList,
    };
    return params;
  };

  const getPromotionByPriorityManual = async (promotionList: any) => {
    let params: any = formatIOrderListManual(promotionList);
    try {
      if (promoCode && promoCode !== "") {
        params.promo_code_list = promoCode;
      }
      if (partnerPromoCode && partnerPromoCode._id) {
        params.partner_id = partnerPromoCode._id;
      }
      if (promotionIdByPromoCode && promotionIdByPromoCode !== "") {
        const newPromotionIdList = [...promotionList, promotionIdByPromoCode];
        params.pos_promotion_id_list = newPromotionIdList;
      }
      const get = await posPromotionApi.selectPosPromotionByPriorityManual(params);
      if (get.data.error === "0") {
        setPosPromotionMatchList(get.data.result.pos_promotion_match_list);
        const listDynamicFreebie = get.data.result.pos_promotion_match_list.filter(
          (promo: any) => promo.promotion_type === "freebie" && promo.is_dynamic_freebie === true
        );
        const promoFreebieStatic = get.data.result.pos_promotion_match_list.filter(
          (promo: any) => promo.promotion_type === "freebie" && promo.is_dynamic_freebie === false
        );
        let productsNonAddon: any[] = [];
        let productsAddon: any[] = [];
        promoFreebieStatic.map((promotion: any) => {
          promotion.result_free_item.map((item: any) => {
            const obj = { ...item, pos_promotion_id: promotion.pos_promotion_id };
            if (obj.addon_option_list && obj.addon_option_list.length > 0) {
              productsAddon.push(obj);
            } else if (!obj.addon_option_list || obj.addon_option_list.length === 0) {
              productsNonAddon.push(obj);
            }
          });
        });

        if (listDynamicFreebie.length > 0) {
          setListPromoDynamicFreebie(listDynamicFreebie);
          if (productsAddon.length > 0) {
            setProductAddonList(productsAddon);
          }
          if (productsNonAddon.length > 0) {
            addListToBasket(productsNonAddon, true);
          }
          setSelectedFreebies([]);
          setDynamicFreebieDrawer(true);
        } else {
          if (productsAddon.length > 0) {
            setProductAddonList(productsAddon);
            setFreebieDrawer(true);
          }
          if (productsNonAddon.length > 0) {
            addListToBasket(productsNonAddon, true);
          }
        }
      } else {
        error(get.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const formatIOrderListManual = (promotionList: any) => {
    let orderList: any = [];
    cart.map((item: any) => {
      const obj = {
        variant_id: item.variant_id ? item.variant_id.toString() : item.variant_id,
        qty: item.qty,
        note: "",
        addon_list: item.addon_list || [],
      };
      orderList.push(obj);
    });
    const params = {
      organization_id: org._id,
      store_id: org.store_id,
      sale_channel_id: "",
      profile_id: "",
      promo_code_list: "",
      pos_promotion_id_list: promotionList,
      order_list: orderList,
    };
    return params;
  };

  const handleFreebieDrawerClose = () => {
    setFreebieDrawer(false);
    setSelectedFreebies([]);
  };
  const handlePromotionsCodeDrawerClose = () => {
    setPromotionsCodeDrawer(false);
  };
  const handlePromotionsDrawerClose = () => {
    setPromotionsDrawer(false);

    const uniqueVariantIds = new Set();
    const filteredSelectedFreebies: any = [];
    setSelectedFreebies([]);
    setProductAddonList([]);
    getPromotions(promotionIDList, !isApplyPromotionAuto);
  };

  const addListToBasket = (listProduct: any, isReset: boolean) => {
    let temp: any = isReset ? [] : [...selectedFreebies];
    for (let index = 0; index < listProduct.length; index++) {
      const element = listProduct[index];
      const item = {
        is_freebie: true,
        variant_id: element.variant_id,
        qty: 1,
        note: "",
        product_image_url: element.image_url,
        product_name: element.name,
        variant_name: element.name,
        pos_promotion_id: element.pos_promotion_id,
      };
      temp.push(item);
    }
    setSelectedFreebies(temp);
  };

  return (
    <StyledBasketHeaderDrawer
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>
            {t("page.customer_name")}: {userInfo && userInfo.fullName}
          </span>
          <div className="totalQueueBadge" style={{ background: themeColor + 40 }}>
            <div className="totalQueueIcon" style={{ background: themeColor }}>
              <TotalQueueIcon />
            </div>
            <span className="body3">คิวขณะนี้ :</span>
            <span className="h3">{totalQueueToday}</span>
          </div>
        </div>
      }
      placement={enumDrawerPlacement.BOTTOM}
      onClose={() => {
        if (!editMode) {
          handleClose();
        } else {
          error("Please save your changes");
        }
      }}
      visible={basketDrawer}
      width={"100%"}
      closable={false}
      height={"80%"}
    >
      <StyledBasketDrawer themeColor={themeColor}>
        <div className="secondTitleRow">
          <p>
            {t("page.my_basket_orders")} ({cart.length})
          </p>
          <span
            onClick={() => {
              if (!editMode) {
                handleClose();
              } else {
                error("Please save your changes");
              }
            }}
          >
            + {t("page.add_order")}
          </span>
          {!editMode && (
            <span
              onClick={() => {
                setEditMode(!editMode);
              }}
              style={{ fontWeight: "700", fontSize: "14px", color: themeColor }}
            >
              + {t("page.edit_mode")}
            </span>
          )}
        </div>
        <hr />
        <div
          className="topDarkBackground"
          onClick={() => {
            if (!editMode) {
              handleClose();
            } else {
              error("Please save your changes");
            }
          }}
        >
          <h1 className="text-center">{org.name}</h1>
          <div className="storeAddress">
            <h2>
              <StyledIconPin />
              {org.name} ({org.store_name})
            </h2>
            <p>{org.address}</p>
          </div>
        </div>
        <div className="myBasket">
          {posPromotionMatchList &&
            posPromotionMatchList.length !== 0 &&
            !editMode &&
            posPromotionMatchList
              .sort((a: any, b: any) => {
                if (a.promotion_type === "freebie" && b.promotion_type !== "freebie") {
                  return -1;
                } else {
                  return 1;
                }
              })
              .map((promotion: any, i: number) => (
                <div key={i}>
                  <div className="promotionRow" key={i}>
                    <div className="leftProSide">
                      <DiscountIcon color={themeColor} />
                      <p>{promotion.pos_promotion_name}</p>
                    </div>
                    {promotion.promotion_type !== "freebie" && (
                      <div className="rightProSide">
                        <span>฿{promotion.result_discount}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          {selectedFreebies &&
            selectedFreebies.map((freeItem: any, j: number) => {
              const selectedFreebie = selectedFreebies.find(
                (cartItem: any) => cartItem.variant_id === freeItem.variant_id
              );

              return (
                <div key={j}>
                  {selectedFreebie ? (
                    <div className="basketItems">
                      <div className="leftSide">
                        <div className="serviceImage">
                          <span className="freeBadge">{t("page.free")}</span>
                          <img src={`${freeItem.product_image_url || BLANK_IMAGE_URL}`} />
                        </div>
                        <div className="serviceDetails">
                          <h1>
                            {freeItem.variant_name ? freeItem.variant_name : freeItem.product_name}
                          </h1>
                          {selectedFreebie.addon_list && (
                            <ul>
                              {freeItem.addon_list.map((addon: any, k: number) => (
                                <React.Fragment key={k}>
                                  {addon.option_selected.map((option: any, n: number) => (
                                    <li key={n}>
                                      {addon.addon_name}: {option.option_name}
                                    </li>
                                  ))}
                                </React.Fragment>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="rightSide">
                        <div className="qtySection">
                          <span>x{freeItem.qty}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="basketItems">
                      <div className="leftSide">
                        <div className="serviceImage">
                          <span className="freeBadge">{t("page.free")}</span>
                          <img src={`${freeItem.product_image_url || BLANK_IMAGE_URL}`} />
                        </div>
                        <div className="serviceDetails">
                          <h1>
                            {freeItem.variant_name ? freeItem.variant_name : freeItem.product_name}
                          </h1>
                        </div>
                      </div>
                      <div className="rightSide">
                        <div className="qtySection">
                          <span>x{freeItem.qty}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          {paymentDetails &&
            paymentDetails.pos_promotion_match_list &&
            paymentDetails?.pos_promotion_match_list?.length !== 0 &&
            !editMode &&
            cart.length !== 0 && (
              <div className="promotionRow">
                <div className="leftProSide">
                  <DiscountIcon color={themeColor} />
                  <p>{t("page.basket")}</p>
                </div>
              </div>
            )}
          {Array.isArray(cart) ? (
            cart
              .filter((item: IBasketItems) => !item.is_freebie)
              .map((item: IBasketItems, i: number) => (
                <div className="basketItems" key={i}>
                  <div className="leftSide">
                    <div className="serviceImage">
                      <img src={`${item.product_image_url || BLANK_IMAGE_URL}`} />
                    </div>
                    <div className="serviceDetails">
                      <h1>{item.variant_name ? item.variant_name : item.product_name}</h1>
                      {item.addon_list?.length !== 0 && (
                        <ul>
                          {item.addon_list?.map((addon: any, j: number) => (
                            <React.Fragment key={j}>
                              {addon.option_selected.map((optionSelected: any, z: number) => (
                                <li key={z}>
                                  {addon.addon_name}:{" "}
                                  <React.Fragment key={z}>
                                    {optionSelected.option_name}
                                  </React.Fragment>
                                </li>
                              ))}
                            </React.Fragment>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="priceQuantity">
                      <h3>
                        <span>฿</span>
                        {item.price_unit}
                      </h3>
                      <div className="qtySection">
                        {editMode && (
                          <div
                            className="minusDiv"
                            onClick={() => {
                              if (item.qty > 1) {
                                const newState: any = cart.map(
                                  (obj: IBasketItems, index: number) => {
                                    if (index === i) {
                                      const priceByQty = obj.price_unit / obj.qty;
                                      return {
                                        ...obj,
                                        qty: item.qty - 1,
                                        price_unit: priceByQty * (obj.qty - 1),
                                      };
                                    }
                                    return obj;
                                  }
                                );
                                dispatch(setCart(newState));
                              }
                              if (item.qty === 1) {
                                const filteredCart: any = cart.filter((_, index) => index !== i);
                                dispatch(setCart(filteredCart));
                                if (cart.length === 1) {
                                  dispatch(clearCart());
                                }
                              }
                            }}
                          >
                            <MinusIcon size={20} />
                          </div>
                        )}
                        <span>{editMode ? item.qty : "x" + item.qty}</span>
                        {editMode && (
                          <div
                            className="plusDiv"
                            onClick={() => {
                              const newState: any = cart.map((obj, index) => {
                                if (index === i) {
                                  const priceByQty = obj.price_unit / item.qty;
                                  return {
                                    ...obj,
                                    qty: obj.qty + 1,
                                    price_unit: priceByQty * (obj.qty + 1),
                                  };
                                }
                                return obj;
                              });

                              dispatch(setCart(newState));
                            }}
                          >
                            <PlusIcon size={20} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p>Nothing to show</p>
          )}
          <hr />
          {(editMode) && (
            <div className="saveChangesRow">
              <span>{t("page.save_all_changes")}</span>
              <StyledSubmitButton
                type="red"
                text={t("page.save")}
                onClick={async () => {
                  await Promise.all([
                    setEditMode(false),
                    setIsApplyPromotionAuto(true),
                    setIsEditApplied(!isEditApplied),
                    setPromoCode(""),
                    setPartnerPromoCode({}),
                    setPromotionIdByPromoCode(""),
                    dispatch(setPromotionIDList([])),
                    setSelectedFreebies([]),
                    setPaymentDetails({}),
                    setProductAddonList([])
                  ]);
                  if (cart.length === 0) {
                    handleClose();
                    setPaymentDetails({});
                    setPaymentDetailsHome({});
                  } else {
                    // getPaymentDetails();
                  }
                }}
                style={{ background: themeColor }}
              />
            </div>
          )}
          {(!editMode) && (
            <>
              <div className="staffInfo">
                <div
                  className="promotionRedRow"
                  onClick={() => {
                    setSelectedFreebies([]);
                    setProductAddonList([]);
                    setPromotionsDrawer(true);
                  }}
                >
                  <div className="leftSide">
                    <div className="iconWidth">
                      <IconPromotion color={themeColor} />
                    </div>
                    <p style={{ margin: 0 }} className="h4">
                      {t("page.promotions")}
                    </p>
                  </div>
                  <div className="rightSide">
                    <label className="body2">
                      {t("page.edit_promotions")}{" "}
                      <span>
                        <Styledarrow color="#919191" />
                      </span>
                    </label>
                  </div>
                </div>
                {/* PO informed me to remove first. 16/05/2024 */}
                {/* <div className="position" onClick={() => setUsePointsDrawer(true)}>
                  <div className="leftSide">
                    <div className="iconWidth">
                      <StyledStar />
                    </div>
                    <h2>{t("page.points")}</h2>
                  </div>
                  <div className="rightSide">
                    <label>
                      {t("page.apply_points")}{" "}
                      <span>
                        <Styledarrow color="#919191" />
                      </span>
                    </label>
                  </div>
                </div> */}
                <div
                  className="position"
                  onClick={() => {
                    setSelectedFreebies([]);
                    setProductAddonList([]);
                    setApplyCouponDrawer(true);
                  }}
                >
                  <div className="leftSide">
                    <div className="iconWidth">
                      <StyledCoupon />
                    </div>
                    <h2>{t("page.coupon")}</h2>
                  </div>
                  <div className="rightSide">
                    <label>
                      {t("page.apply_coupon")}{" "}
                      <span>
                        <Styledarrow color="#919191" />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="position" onClick={() => setApplyCardDrawer(true)}>
                  <div className="leftSide timeIconDiv">
                    <div className="iconWidth">
                      <StyledCard />
                    </div>
                    <h2>{t("page.card")}</h2>
                  </div>
                  <div className="rightSide">
                    <label>
                      {t("page.apply_card")}{" "}
                      <span>
                        <Styledarrow color="#919191" />
                      </span>
                    </label>
                  </div>
                </div>
                <div
                  className="position rmBotBorder"
                  onClick={() => {
                    if (promoCode && promoCode !== "") {
                      setPromoCode("");
                      setPartnerPromoCode({});
                      setPromotionIdByPromoCode("");
                    } else {
                      setSelectedFreebies([]);
                      setProductAddonList([]);
                      setPromotionsCodeDrawer(true);
                    }
                  }}
                >
                  <div className="leftSide timeIconDiv">
                    <div className="iconWidth">
                      <StyledPromotionCode />
                    </div>
                    <h2>{t("page.promotion_code")}</h2>
                  </div>
                  <div className="rightSide">
                    {promoCode && promoCode !== "" ? (
                      <div
                        style={{
                          flexDirection: "row",
                          backgroundColor: "#F2FFE7",
                          padding: 6,
                          paddingTop: 4,
                          paddingBottom: 4,
                          gap: 3,
                        }}
                      >
                        <label style={{ color: "#7FCB3D" }}>
                          {`${promoCode}`}
                          <span>
                            <StyledBasketTrashBin color="#7FCB3D" />
                          </span>
                        </label>
                      </div>
                    ) : (
                      <label>
                        {t("page.apply_promotion_code")}{" "}
                        <span>
                          <Styledarrow color="#919191" />
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="orderSummarySection">
                <div className="orderDetailsRow">
                  <p>
                    <b>
                      {t("page.subtotal")} ({cart.length} {t("page.items")})
                    </b>
                  </p>
                  <p>
                    <b>{cart.length === 0 || !(paymentDetails && paymentDetails.total_before_discount) ? 0 : paymentDetails?.total_before_discount.toFixed(2)}฿</b>
                  </p>
                </div>
                {paymentDetails && paymentDetails.discount_in_baht_by_promotion && paymentDetails.discount_in_baht_by_promotion !== 0 ? (
                  <div className="orderDetailsRow">
                    <p>{t("page.promotion_discount")}</p>
                    <p style={{color: 'red'}}>{cart.length === 0 ? 0 : `-${paymentDetails?.discount_in_baht_by_promotion.toFixed(2)}`}฿</p>
                  </div>
                ) : null}
                {paymentDetails && paymentDetails.discount_in_baht_by_point && paymentDetails.discount_in_baht_by_point !== 0 ? (
                  <div className="orderDetailsRow">
                    <p>{t("page.points_discount")}</p>
                    <p style={{color: 'red'}}>{cart.length === 0 ? 0 : `-${paymentDetails?.discount_in_baht_by_point.toFixed(2)}`}฿</p>
                  </div>
                )  : null}
                {paymentDetails && paymentDetails.service_charge && paymentDetails.service_charge !== 0 ? (
                  <div className="orderDetailsRow">
                    <p>{t("page.service_charge")}</p>
                    <p>
                      {cart.length === 0
                        ? 0
                        : paymentDetails && paymentDetails.service_charge
                        ? paymentDetails?.service_charge.toFixed(2)
                        : "-"}
                      ฿
                    </p>
                  </div>
                ) : null}
                {paymentDetails && paymentDetails.loyalty_card_id ? (
                  <div className="orderDetailsRow">
                    <p>{t("page.gift_card_usage")}</p>
                    <p style={{color: 'red'}}>-{paymentDetails?.discount_in_baht_by_loyalty_card.toFixed(2)}฿</p>
                  </div>
                ) : null}
                {paymentDetails && paymentDetails.loyalty_card_id ? (
                  <div className="orderDetailsRow">
                    <p>{t("page.gift_card_usage_promo")}</p>
                    <p style={{color: 'red'}}>-{paymentDetails?.discount_in_baht_promo_by_loyalty_card.toFixed(2)}฿</p>
                  </div>
                ) : null}
                <div className="orderDetailsRow">
                  <p>{t("page.vat")}</p>
                  <p>
                    {cart.length === 0
                      ? 0
                      : paymentDetails && paymentDetails.vat_total
                      ? paymentDetails?.vat_total.toFixed(2)
                      : "-"}
                    ฿
                  </p>
                </div>
                <div className="orderDetailsRow pointsRow">
                  <p>
                    <b>{t("page.total")}</b>
                  </p>
                  <div className="column">
                    <p>
                      <b>
                        {cart.length === 0
                          ? 0
                          : paymentDetails && paymentDetails.grand_total
                          ? paymentDetails?.grand_total.toFixed(2)
                          : "-"}
                        ฿
                      </b>
                    </p>
                    {/* {paymentDetails &&
                      paymentDetails.earn_points &&
                      paymentDetails.earn_points !== 0 && (
                        <span>
                          +
                          {`${
                            cart.length === 0
                              ? 0
                              : paymentDetails && paymentDetails.earn_points
                              ? paymentDetails?.earn_points
                              : "-"
                          } ` + t("page.points")}{" "}
                          <SmallGrayPointIcon />
                        </span>
                      )} */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {(basketDrawer) && (
          <div>
            <div className="addItemRow">
              <div className="separator"></div>
              <p>
                {t("page.summary_order")} : {cart.length || 0}
              </p>
              <div className="btn-layout">
                <form>
                  <StyledSubmitButton
                    type="red"
                    text={
                      <Row className="addToOrderRow">
                        <span>
                          ฿
                          {cart.length === 0
                            ? 0
                            : paymentDetails && paymentDetails.grand_total
                            ? paymentDetails?.grand_total.toFixed(2)
                            : "-"}
                        </span>
                        <span>{t("page.payment")}</span>
                      </Row>
                    }
                    style={cart.length > 0 && paymentDetails && paymentDetails.grand_total ? {} : {backgroundColor: "#E1E1E1"}}
                    htmlType="button"
                    onClick={() => {
                      if (cart.length > 0 && paymentDetails && paymentDetails.grand_total) {
                        setPaymentGatewaysDrawer(true);
                      }
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        )}
      </StyledBasketDrawer>
      {freebieDrawer && (
        <ComponentFreebieDrawer
          freebieDrawer={freebieDrawer}
          handleClose={handleFreebieDrawerClose}
          paymentDetails={paymentDetails}
          getPaymentDetails={getPaymentDetails}
          productAddonList={productAddonList}
          setProductAddonList={setProductAddonList}
          setSelectedFreebies={setSelectedFreebies}
          selectedFreebies={selectedFreebies}
          addListToBasket={(list: any) => addListToBasket(list, false)}
        />
      )}
      {promotionsDrawer && (
        <ComponentPromotionsDrawer
          promotionsDrawer={promotionsDrawer}
          handleClose={handlePromotionsDrawerClose}
          isApplyPromotionAuto={isApplyPromotionAuto}
          setIsApplyPromotionAuto={setIsApplyPromotionAuto}
          setPaymentDetails={setPaymentDetails}
          getPromotions={getPromotions}
          setSelectedFreebies={setSelectedFreebies}
          selectedFreebies={selectedFreebies}
          productAddonList={productAddonList}
          promoCode={promoCode}
        />
      )}
      {promotionsCodeDrawer && (
        <ComponentPromotionsCodeDrawer
          promotionsCodeDrawer={promotionsCodeDrawer}
          handleClose={handlePromotionsCodeDrawerClose}
          setPromoCode={setPromoCode}
          setPartnerPromoCode={setPartnerPromoCode}
          setPromotionIdByPromoCode={setPromotionIdByPromoCode}
          isApplyPromotionAuto={isApplyPromotionAuto}
        />
      )}
      {dynamicFreebieDrawer && (
        <ComponentDynamicFreebieDrawer
          dynamicFreebieDrawer={dynamicFreebieDrawer}
          handleClose={() => setDynamicFreebieDrawer(false)}
          listPromoDynamicFreebie={listPromoDynamicFreebie}
          listFreebieAddon={productAddonList}
          addListToBasket={(list: any) => addListToBasket(list, false)}
          addProductsFreebieAddAddon={(list: any) => setProductAddonList(list)}
          openProductFreebieAddon={() => {
            setDynamicFreebieDrawer(false);
            setTimeout(() => {
              setFreebieDrawer(true);
            }, 500);
          }}
        />
      )}
      {paymentGatewaysDrawer && (
        <ComponentPaymentGatewayDrawer
          paymentGatewaysDrawer={paymentGatewaysDrawer}
          handleClose={() => setPaymentGatewaysDrawer(false)}
          handleSelectMemberCard={() => {
            setPaymentDrawer(true);
            setPaymentGatewaysDrawer(false);
          }}
          handleSelectPaySolution={() => {
            setPaymentGatewaysDrawer(false);
            createProductOrderPOSByPaySolution(refRandomNumber);
          }}
        />
      )}
    </StyledBasketHeaderDrawer>
  );
};
const StyledBasketHeaderDrawer = styled(SharedComponentDrawer)`
  .totalQueueBadge {
    border-radius: 48px;
    padding: 2px 12px 2px 2px;
    display: flex;
    gap: 4px;
    place-items: center;
    width: 40%;
    span {
      margin: 0;
    }
    .totalQueueIcon {
      border-radius: 50%;
      padding: 4px 8px;
    }
  }
  .ant-drawer-body {
    margin-top: 4.2rem;
  }
  .ant-drawer-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }
`;
const StyledBasketDrawer = styled.div<{ themeColor: string }>`
  .topDarkBackground h1 {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .topDarkBackground {
    background: #323232;
    height: 25%;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: -1;
  }
  .storeAddress {
    margin-top: 20px;
  }
  .storeAddress h2 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .storeAddress p {
    font-size: 14px;
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .storeAddress svg {
    margin-right: 5px;
    width: 14px;
    margin-bottom: 5px;
  }
  .myBasket {
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 10rem;
  }
  .basketTitle {
    font-size: 22px;
    font-weight: 700;
    color: black;
  }
  .basketItems {
    display: flex;
    place-content: space-between;
    margin-bottom: 20px;
  }
  .serviceImage img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 10px;
  }
  .serviceDetails {
    margin-left: 15px;
  }
  .serviceDetails h1 {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }
  .serviceDetails ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 5px;
  }
  .serviceDetails ul li:before {
    content: "-";
    margin-right: 5px;
  }
  .serviceDetails ul li {
    font-size: 12px;
    color: #919191;
    font-weight: 600;
  }
  .serviceDetails p {
    font-size: 15px;
    display: flex;
    place-items: center;
  }
  .serviceDetails p svg {
    margin-right: 5px;
  }
  .noteButton button {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #919191;
    color: #919191;
    width: 60px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: bold;
  }
  .noteButton button:active {
    outline: none;
  }
  .priceQuantity h3 {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #000000;
    span {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .priceQuantity h4 {
    font-size: 15px;
    text-align: right;
    font-weight: bold;
  }
  .leftSide {
    display: flex;
    color: black;
  }
  .rightSide {
    display: flex;
    gap: 20px;
  }
  .priceQuantity {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    place-items: end;
    gap: 12px;
  }
  .priceQuantity svg {
    margin-top: 27px;
    cursor: pointer;
  }
  .secondTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    hr {
      width: 100%;
    }
    p {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      margin: 0;
    }
    span {
      font-weight: 700;
      font-size: 14px;
      color: ${(color) => color.themeColor};
    }
  }
  .qtySection {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: end;
    span {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
  }
  // Service Css
  .staffInfo {
    margin-top: 20px;
    margin-bottom: 0;
    background: #fff;
  }
  .position {
    display: flex;
    place-content: space-between;
    place-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 18px 0;
    background: #fff;
  }
  .promotionRedRow {
    border-radius: 8px;
    background: ${(color) => color.themeColor}10;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftSide {
      display: flex;
      place-items: center;
      gap: 8px;
    }
    .rightSide {
      display: flex;
      place-items: center;
      label {
        color: #919191;
        margin: 0;
        font-weight: 500;
      }
    }
  }
  .position .leftSide {
    display: flex;
    place-items: center;
  }
  .position .leftSide h2 {
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 0;
    color: #000000;
  }
  .position .rightSide {
    display: flex;
    place-items: center;
  }
  .position .rightSide label {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: #919191;
    margin: 0;
    span {
      margin-left: 7px;
    }
  }
  .position .leftSide img {
    width: 30px;
    border-radius: 20px;
  }
  // Service Css
  .totalPriceSection label {
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
    color: #000;
  }
  .totalDiscountSection {
    display: flex;
    place-content: end;
    align-items: center;
    justify-content: space-between;
    label {
      font-size: 14px;
      font-weight: 500;
      margin-right: 15px;
      color: ${(color) => color.themeColor};
    }
    .totalDiscount {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      color: ${(color) => color.themeColor};
      span {
        font-size: 14px;
        font-weight: 500;
        color: ${(color) => color.themeColor};
      }
    }
  }
  .innerTotalPrice .totalPrice {
    font-size: 22px;
    font-weight: bold;
    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .orderSummarySection {
    margin-top: 20px;
  }
  .orderDetailsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    span {
      font-weight: 400;
      font-size: 12px;
      color: #676767;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      gap: 5px;
      align-items: center;
      b {
        font-weight: 700;
        margin: 0;
        color: #000;
        font-size: 14px;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      place-items: end;
    }
    &.pointsRow {
      place-items: baseline;
    }
  }
  .addItemRow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px;
    align-items: center;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
    .separator {
      position: absolute;
      top: -10px;
      margin: 0;
      left: 0;
      right: 0;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    button {
      margin: 0;
      width: 185px;
      height: 55px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  .separator {
    margin: 16px auto;
    background: #fbfbfb;
    width: 100%;
    height: 10px;
  }
  .addToOrderRow {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .saveChangesRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 10px 32px;
      margin: 0;
    }
  }
  .promotionRow {
    background: #f5f5f6;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
    .leftProSide {
      display: flex;
      gap: 10px;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        margin: 0;
      }
    }
    .rightProSide {
      span {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        margin: 0;
        padding-right: 10px;
      }
    }
  }
  .serviceImage {
    position: relative;
    .freeBadge {
      position: absolute;
      left: 0;
      top: -12px;
      background: ${(color) => color.themeColor};
      border-radius: 6px;
      padding: 2px 6px;
      font-weight: 600;
      font-size: 10px;
      color: #fff;
    }
  }
`;
const StyledIconPin = styled(WhiteIconPin)`
  cursor: pointer;
`;
const StyledStar = styled(basketStar)`
  cursor: pointer;
`;
const StyledCoupon = styled(basketCoupon)`
  cursor: pointer;
`;
const StyledCard = styled(basketCard)`
  cursor: pointer;
`;

const Styledarrow = styled(arrow)`
  cursor: pointer;
`;
const StyledPromotionCode = styled(PromotionCode)`
  cursor: pointer;
`;
const StyledBasketTrashBin = styled(basketTrashBin)`
  cursor: pointer;
`;
