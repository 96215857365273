import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FormikErrors, FormikTouched } from "formik";

import { SharedInput, SharedButton, IConEmail } from "..";
import { selectMerchant } from "@redux";
import { IEmail, IHandleBlur, IHandleChange } from "@interfaces";

interface IProps {
    values: IEmail;
    handleBlur: IHandleBlur;
    handleChange: IHandleChange;
    errors: FormikErrors<IEmail>;
    touched: FormikTouched<IEmail>;
    isSubmitting: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const ComponentSignUpEmail = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    //page props
    const { values, handleBlur, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    //page variable
    const merchant = useSelector(selectMerchant);

    return (
        <form onSubmit={handleSubmit}>
            <SharedInput
                prefixIcon={<IConEmail />}
                placeholder={t("page.email")}
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.email}
                touched={touched.email}
            />
            <StyledButton
                disabled={isSubmitting}
                size="large"
                bg_color={merchant.themeColor}
                type="submit"
            >
                {t("page.sign_up")}
            </StyledButton>
        </form>
    );
};

const StyledButton = styled(SharedButton)`
    margin-top: 50px;
`;
