import { enumNavigation } from "@configs";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface INotSaveLocal {
    couponCollected: boolean;
    showTermCondition: boolean;
    navigationType: enumNavigation;
}

const initialState: INotSaveLocal = {
    couponCollected: false,
    showTermCondition: false,
    navigationType: enumNavigation.USER_POINT,
};

const notSaveLocal = createSlice({
    name: "notSaveLocal",
    initialState,
    reducers: {
        setCouponCollected: (state, action: PayloadAction<boolean>) => {
            state.couponCollected = action.payload;
        },
        setShowTermCondition: (state, action: PayloadAction<boolean>) => {
            state.showTermCondition = action.payload;
        },
        setNavigationType: (state, action: PayloadAction<enumNavigation>) => {
            state.navigationType = action.payload;
        },
    },
});

export const selectNotSaveLocal = (state: RootState) => state.notSaveLocal;

export const { setCouponCollected, setShowTermCondition, setNavigationType } = notSaveLocal.actions;

export default notSaveLocal.reducer;
