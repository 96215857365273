import styled from "styled-components";

export const StyledSingleOrderHistory = styled.div<{ themeColor: string }>`
  .container {
    background: #fbfbfb;
    min-height: 100vh;
    padding: 16px;
    display: flex;
    flex-direction: column;
    padding-bottom: 6rem;
    gap: 8px;
    .basketOrderSummary {
      background: #ffffff;
      border-radius: 4px;
      padding: 16px;
      .basketHeader {
        border-bottom: 0.5px solid #e1e1e1;
      }
      .basketOrderSummaryTitleRow {
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          margin: 0;
        }
        span {
          font-weight: 500;
          font-size: 12px;
          color: #9b9b9b;
        }
      }
      .eachItemsRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        .leftSide {
          display: flex;
          gap: 8px;
          img {
            width: 42px;
            height: 42px;
            border-radius: 4px;
          }
          .itemTitleDesc {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: space-between;
            h2 {
              font-weight: 500;
              font-size: 16px;
              color: #000000;
              margin: 0;
            }
            p {
              font-weight: 300;
              font-size: 10px;
              color: #919191;
              margin: 0;
            }
          }
        }
        .rightSide {
          .itemTitleDesc {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: space-between;
            p {
              font-weight: 500;
              font-size: 16px;
              color: #000000;
              margin: 0;
              span {
                font-weight: 300;
                font-size: 10px;
                color: #000000;
                margin-right: 5px;
                text-decoration-line: line-through;
              }
            }
            small {
              font-weight: 400;
              font-size: 10px;
              text-align: right;
              color: #000000;
            }
          }
        }
      }
      .orderDetailsRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        p {
          margin: 0;
          color: #000;
          font-weight: 500;
          font-size: 14px;
          display: flex;
          gap: 5px;
          align-items: center;
          b {
            font-weight: 700;
            margin: 0;
            color: #000;
            font-size: 14px;
            display: flex;
            gap: 5px;
            align-items: center;
          }
        }
      }
    }
    .branchDetails {
      background: #ffffff;
      border-radius: 4px;
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 14px;
      .branchNameDesc {
        display: flex;
        flex-direction: column;
        p {
          margin: 0;
          font-weight: 700;
          font-size: 14px;
          color: #000000;
        }
        .desc {
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          color: #000000;
        }
      }
    }
    .customerDetails {
      background: #ffffff;
      border-radius: 4px;
      padding: 16px;
      h1 {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        margin: 0;
      }
      .customerNameRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
          margin: 0;
          color: #000;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .showMoreBtn {
    width: 100%;
    border: none;
    box-shadow: none;
    span {
      font-weight: 700;
      font-size: 12px;
      color: #000;
    }
  }
  .queueStatusBadge {
    border-radius: 8px;
    background: ${(color) => color.themeColor}30;
    display: flex;
    margin: 10px auto 16px auto;
    .separatorIcon {
      margin: auto 10px;
    }
    .leftSide {
      padding: 8px;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      place-items: center;
      p {
        margin: 0;
      }
      .h1 {
        color: ${(color) => color.themeColor};
        font-weight: 700;
      }
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      place-items: center;
      border-left: 2px dashed #fff;
      padding: 6px 16px;
      width: 40%;
      p {
        margin: 0;
      }
      .iconRow {
        display: flex;
        place-items: center;
        gap: 4px;
      }
      .h1 {
        color: ${(color) => color.themeColor};
        font-weight: 700;
      }
      .body5 {
        color: #919191;
      }
    }
  }
  .promotionRow {
    background: #f5f5f6;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    .leftProSide {
      display: flex;
      gap: 10px;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        margin: 0;
      }
    }
    .rightProSide {
      span {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        margin: 0;
        padding-right: 10px;
      }
    }
  }
`;
