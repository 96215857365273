import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMerchant } from "@interfaces";

import { RootState } from ".";
import { theme } from "@utils";

const initialState: IMerchant = {
  name: "",
  themeColor: "",
  subColor: "",
  logoUrl: "",
  trafficSourceId: "",
  store_id: "",
  image: "",
  logo: "",
  fullName: "",
};
const colorThemes = theme.colors.colorThemes;

const merchant = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setMainColor: (state, action: PayloadAction<string>) => {
      state.themeColor = action.payload;
      colorThemes.forEach((color) => {
        if (action.payload === color.main) {
          state.subColor = color.sub;
        }
      });
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setMerchant: (state, action: PayloadAction<IMerchant>) => {
      return action.payload;
    },
    setTrafficSourceId: (state, action: PayloadAction<string>) => {
      state.trafficSourceId = action.payload;
    },
    resetMerchant: () => {
      return initialState;
    },
  },
});

export const selectMerchant = (state: RootState) => state.merchant;

export const {
  setMainColor,
  setMerchant,
  setTrafficSourceId,
  resetMerchant,
  setImage,
  setLogo,
  setFullName,
} = merchant.actions;

export default merchant.reducer;
