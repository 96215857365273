import { CSSProperties } from "styled-components";

interface IProps {
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    size?: number;
}

export const IConUser = (props: IProps) => {
    const { className, style, onClick, size } = props;

    return (
        <svg
            width={size || "57"}
            height={size || "57"}
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d="M28.5 57C44.2401 57 57 44.2401 57 28.5C57 12.7599 44.2401 0 28.5 0C12.7599 0 0 12.7599 0 28.5C0 44.2401 12.7599 57 28.5 57Z"
                fill="#A5A5A5"
            />
            <path
                d="M28.4014 27.5469C31.4389 27.5469 33.9014 24.8606 33.9014 21.5469C33.9014 18.2332 31.4389 15.5469 28.4014 15.5469C25.3638 15.5469 22.9014 18.2332 22.9014 21.5469C22.9014 24.8606 25.3638 27.5469 28.4014 27.5469Z"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.5014 32.0332C21.3464 32.0332 17.9014 36.4962 17.9014 39.0992V41.4542H39.1014V39.0992C39.1014 36.5082 35.6554 32.0332 28.5014 32.0332Z"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
