interface IProps {
    color?: string;
}

export const IConTick = (props: IProps) => {
    const { color = "#970000" } = props;
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.58 1.69897L5.97998 14.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path d="M5.98 14.5L1.5 10.02" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};
