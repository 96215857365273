import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Empty } from "antd";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { couponApi } from "@api";
import { ComponentCouponList, ComponentSpinPage } from "@components";
import { ICoupon, ICouponList, IPagination } from "@interfaces";
import { PATH_COUPON, enumNavigation, INITIAL_LIST_PARAMS } from "@configs";
import { setCouponCollected } from "@redux";
import { RenderMeta } from "@utils";

interface IProps {
    under: enumNavigation;
}

export const ModulesCoupon = (props: IProps) => {
    //page hooks
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    //page state
    const [coupons, setCoupons] = useState<ICouponList[] | ICoupon[]>([]);
    const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
    const [param, setParam] = useState<IPagination>(INITIAL_LIST_PARAMS);
    const [spinning, setSpinning] = useState<boolean>(false);
    //page props
    const { under } = props;

    useEffect(() => {
        setParam(INITIAL_LIST_PARAMS);
        setCoupons([]);
        getInitCoupon();
    }, [under]);

    const getInitCoupon = async () => {
        setSpinning(true);
        await getCoupons(INITIAL_LIST_PARAMS);
        setSpinning(false);
    };

    const getCoupons = async (params: IPagination) => {
        if (under === enumNavigation.COUPON_MERCHANT) {
            const res = await couponApi.getListCoupon(params);
            const data = res?.data.docs as ICouponList[];
            if (params === INITIAL_LIST_PARAMS) {
                setCoupons(data);
            } else {
                setCoupons([...(coupons as ICouponList[]), ...data]);
            }
        } else {
            const res = await couponApi.getListCouponMerchant(params);
            const data = res?.data.docs as ICoupon[];
            if (params === INITIAL_LIST_PARAMS) {
                setCoupons(data);
            } else {
                setCoupons([...(coupons as ICoupon[]), ...data]);
            }
        }
    };

    const handleClickItem = (id: string) => {
        dispatch(setCouponCollected(true));
        if (under === enumNavigation.COUPON_MERCHANT) {
            const index = coupons.findIndex((coupon) => coupon?.qrtable?.qrToken === id);
            if (index >= 0) {
                history.push(PATH_COUPON + "/" + id + "/" + coupons[index]._id);
            } else {
                history.push(PATH_COUPON + "/" + id);
            }
        } else {
            history.push(PATH_COUPON + "/" + id);
        }
    };

    const handleLoadMore = async () => {
        const paramPanigation = {
            ...param,
            page: (param.page || 1) + 1,
        };
        setParam(paramPanigation);
        setLoadingPagination(true);
        await getCoupons(paramPanigation);
        setLoadingPagination(false);
    };

    const loadingComponent = (
        <StyledLoading>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
            </div>
            <div className="card">
                <Skeleton className="title" animation="wave" width={"30%"} height={30} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
                <Skeleton className="content" animation="wave" width={"100%"} height={20} />
            </div>
        </StyledLoading>
    );

    return (
        <>
            <RenderMeta title={t("meta.coupon_list")} />
            {!spinning ? (
                coupons?.length > 0 ? (
                    <ComponentCouponList
                        handleLoadMore={handleLoadMore}
                        data={coupons}
                        handleClickItem={handleClickItem}
                        loadingPagination={loadingPagination}
                        under={under}
                    />
                ) : (
                    <Empty />
                )
            ) : (
                <ComponentSpinPage children={loadingComponent} />
            )}
        </>
    );
};

const StyledLoading = styled.div`
    margin: 25px 18px 0 18px;
    .card {
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 1px 3px 6px rgba(0, 0, 0, 0.23);
        margin-bottom: 24px;
        background-color: white;
        border-radius: 6px;
        padding: 24px;
        .title {
            margin-bottom: 10px;
        }
    }
`;
