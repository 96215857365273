import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { selectMerchant } from "@redux";
import { StyledSubmitButton } from "../shared";
import "../styles/Swiper.css";
import { Row } from "antd";
import { useEffect, useState } from "react";

interface IProps {
  paymentGatewaysDrawer: boolean;
  handleClose: () => void;
  handleSelectMemberCard: () => void;
  handleSelectPaySolution: () => void;
}

export const ComponentPaymentGatewayDrawer = (props: IProps) => {
  const {
    paymentGatewaysDrawer,
    handleClose,
    handleSelectMemberCard,
    handleSelectPaySolution,
  } = props;
  const { t } = useTranslation();
  const { themeColor } = useSelector(selectMerchant);

  useEffect(() => {
    //
  }, []);

  return (
    <StyledDrawer
      // title={t("page.use_card")}
      title={'Select Channel Payment'}
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={paymentGatewaysDrawer}
      width={"100%"}
      closable={true}
      height={"40%"}
      className={"drawer"}
    >
      <StyledApplyCard themeColor={themeColor}>
        {/* <div className="card-skeleton">
        
        </div> */}
        <div className="content-skeleton">
          {/* <div>
            {'Pay solution'}
          </div>
          <div>
            {'Member card'}
          </div> */}
          <div className="btn-layout">
            <form>
              <StyledSubmitButton
                type="red"
                style={{padding: 0}}
                text={
                  <Row className="addToOrderRow">
                    <span className="left">{'Online Payment'}</span>
                    <img className="right" src="/images/choicePaySolution.png" />
                  </Row>
                }
                htmlType="button"
                onClick={() => {
                  handleSelectPaySolution();
                  return;
                }}
              />
              <StyledSubmitButton
                type="red"
                style={{padding: 0, marginTop: 16}}
                text={
                  <Row className="addToOrderRow">
                    <span className="left">{'Member Card'}</span>
                    <img className="right" src="/images/choiceMemberCard.png" />
                  </Row>
                }
                htmlType="button"
                onClick={() => {
                  handleSelectMemberCard();
                }}
              />
            </form>
          </div>
        </div>
      </StyledApplyCard>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    height: auto !important;
    margin-bottom: 0 !important;
    padding: 16px;
  }
`;

const StyledApplyCard = styled.div<{ themeColor: string }>`
  .card-skeleton {
    margin-bottom: 50px;
    .ant-skeleton {
      .ant-skeleton-title {
        marign-top:0;
        margin-bottom:0;
        height: 170px;
        border-radius: 16px;
      }
    }
  }
  .content-skeleton {
    .ant-skeleton {
      .ant-skeleton-paragraph {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        li {
          height: 74px;
          border-radius: 100%;
          width: 74px;
          margin: 0 auto;
        }
      }
    }
  }
  .swiper-container {
    padding-bottom: 45px;
  }
  .cardBox {
    padding: 20px;
  }
  .slide {
    border-radius: 12px;
    background: #fc5064;
    position: relative;
    display: flex;
    flex-direction: column;
    place-items: baseline;
    img {
      position: absolute;
      right: 0;
      width: 150px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      top: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: ${(color) => color.themeColor};
  }
  .cardTitle {
    border-radius: 30px;
    background: rgba(242, 47, 70, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
  }
  .amount {
    margin-top: 40px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
    width: fit-content;
  }
  .amountInput {
    height: 60px;
  }
  .subBtn {
    width: 100%;
    border-radius: 74px;
    background: ${(color) => color.themeColor};
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    width: -webkit-fill-available;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .selectAmountSection {
    .selectAmountTitle {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .selectAmountSubtitle {
      color: #919191;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .amountCardRows {
    margin-top: 10px;
    .available svg .main {
      fill: #f22f46;
      opacity: 1;
    }
    .used {
      span {
        transform: rotate(-15deg);
      }
    }
    .couponIcon {
      position: relative;
      display: flex;
      place-content: center;
      place-items: center;
      span {
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .mainContainer {
    margin-bottom: 40px;
  }
  .freezeMembarCard {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.74);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .textFreezeMemberCard {  
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    margin-bottom: 0;
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    button {
      margin: 0;
      width: 100%;
      height: 55px;
      // background: ${(color) => color.themeColor};
      background: #FFF;
      border-radius: 8px;
      border: solid 1px #80808042;
    }
  }
  .addToOrderRow {
    span {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }
    width: 100%;
  //   padding: 0; /* Reset padding */
  //   margin: 0; /* Reset margin */
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   align-items: center;
    overflow: hidden; /* ใช้เพื่อให้ container ขยายตามเนื้อหาภายใน */
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .left {
    float: left;
    width: 50%; /* กำหนดความกว้างของ div ซ้าย */
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    text-align: left;
  }

  .right {
    float: right;
    // width: 50%; /* กำหนดความกว้างของ div ขวา */
    box-sizing: border-box;
    padding: 10px;
  }
`;
