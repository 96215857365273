import styled from "styled-components";

export const StyledSquare = styled.div`
    position: relative;
    width: 100%;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .image {
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        margin: 24px auto 0 auto;
        max-height: 456px;
        object-fit: cover;
        border-radius: 12px;
    }
`;
