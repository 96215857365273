export default {
    delete: {
        success: "Delete successfully",
        fail: "Delete failed",
    },
    duplicate: {
        success: "Duplicate successfully",
        fail: "Duplicate failed",
    },
    create: {
        success: "Create successfully",
        fail: "Create failed",
    },
    update: {
        success: "Update successfully",
        fail: "Update failed",
    },
    form_validate: {
        warning: "Some fields are invalid. Please check!",
    },
    reset_pass: {
        fail: "Reset password not successfully!",
        success: "Reset password successfully!",
    },
    sign_up: {
        success: "Sign up successfully!",
        fail: "Sign up not successfully!",
    },
    upload_image: {
        success: "Upload image successfully!",
        fail: "Upload image  fail!",
    },
    login: {
        success: "Login successfully!",
        fail: "Login fail!",
    },
    merge: {
        success: "Merge {{number}} successfully! ",
    },
    use_coupon: {
        success: "Use coupon successfully!",
        fail: "Use coupon fail!",
    },
    received: {
        success: "Received coupon successfully!",
        fail: "Received coupon not successfully!",
    },
    image: {
        max_size: "Image no more than 10MB",
    },
    verify_email: {
        success: "Verified email successfully!",
        fail: "Verified email unsuccessfully!",
    },
    verify_phone: {
        success: "Verified phone successfully!",
        fail: "Verified phone unsuccessfully!",
    },
    email_send: "Email send",
    phone_send: "Phone send",
    email_correct: "Email correct",
    phone_correct: "Phone correct",
    upload_image_big_size: "Image too big size",
    delete_confirm: "Are you sure delete this {{name}}?",
    exist_email: "Email already exist!",
    not_found: "This {{name}} is not found",
    delete_title: "Are you sure?",
    try_again_text: "Have an error. Please try again",
    invalid: "Invalid page",
    not_permission: "You don't have this permission",
    error: "Have an undefine error. Please try again",
    you_need_login: "You need login to do this action",
    forbidden: "You don't have this permission",
    copied: "Copied",
    export_file_fail: "Export file fail",
    sign_failed: "Sign failed",
    page_404: "Sorry, the page you visited does not exist.",
    update_link_line_success: "Update link line successfully!",
};
